
import React, { useState } from 'react';
import { Pagination } from 'antd';

const MyPagination = ({ totalItems, limit, offset, setLimit, setOffset}) => {
  const [currentPage, setCurrentPage] = useState(offset / limit + 1);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    const newOffset = (page - 1) * limit;
    setOffset(newOffset)
    setLimit(pageSize)
  };

  return (
    <Pagination
      current={currentPage}
      total={totalItems}
      pageSize={limit}
      onChange={handlePageChange}
      showQuickJumper
      showSizeChanger
      style={{paddingTop:20}}
      pageSizeOptions={['5', '10', '100', '1000']}
    />
  );
};

export default MyPagination;



//* Usage:

// const [totalItems, setTotalItems] = useState(0)
// const [limit, setLimit] = useState(10)
// const [offset, setOffset] = useState(0)

// useEffect(() => {
//     getItems()
// }, [offset, limit])

// const params = {
//     limit,
//     offset,
//     searchByWarehouseID,
//     searchByItemName,
// };
// const url = `/in-stock?${buildQueryParams(params)}`;

// <MyPagination totalItems={totalItems} limit={limit} offset={offset} setLimit={setLimit} setOffset={setOffset} /> 

//* Usage
