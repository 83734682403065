import {  App, Input, Button, Space } from "antd";
import {useContext, useState} from 'react';
import axioss from '../../services/restAPI';
import { AuthContext } from "../../components/Auth/AuthContext";


const PONotes = (props) => {
    const { record, setLoading } = props
    const { authConfig } = useContext(AuthContext)
    const { modal, message } = App.useApp()

    const [notes, setNotes] = useState(props.record.notes);
    const [isEditing, setIsEditing] = useState(false);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleChange = (e) => {
        setNotes(e.target.value);
    };

    const handleSubmit = async () => {
        await submitNotes(notes)
    };

    const handleKeyPress = (e) => {
        console.log(e)
        if (e.code === 'Escape') {
            setIsEditing(false)
        }
      };

    const submitNotes = async (notes) => {
        try {
            setLoading(true);
            const id = record.purchase_order_id;
            await axioss.put(`/po/${id}/`, { notes: notes }, authConfig);
            message.success("Notes have been updated successfully");
            setIsEditing(false);
        } catch (error) {
            console.error(error);
            modal.warning({
                title: 'Error while updating status',
                content: error.message,
            });
        } finally {
            setLoading(false);
        }
    };


    return (
        <Space>
            {isEditing ? (
                <Input.TextArea value={notes} onChange={handleChange}  maxLength={999} placeholder="Add Notes" showCount onKeyDown={handleKeyPress}/>
            ) : (
                <p>{notes}</p>
            )}
            {isEditing ? (
                <Button type="link" onClick={handleSubmit}>Submit</Button>
            ) : (
                <>
                    {record.notes?(
                    <Button size="small"  type="link" onClick={handleEdit}>Edit</Button>
                    ):(
                    <Button size="small" type="text" onClick={handleEdit}>Add</Button>
                    )}
                </>
            )}
        </Space>
    );
}

export default PONotes