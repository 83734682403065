import { Table, Button, App } from "antd";

import { CreateNewPOrow } from "./row"

export function POCartTable(props) {
    const { itemsInTheCart, setItemsInTheCart } = props;
    const dataSource = [...props.itemsInTheCart]
    const { message } = App.useApp()



    dataSource.forEach((item,index) => {
        item.idx = index+1;
        item.key = item.id;
        return item
    })

    const onRemove = (record) => {
        // console.log(record)
        let tempArr = [...itemsInTheCart]
        tempArr = tempArr.filter(item => item.id !== record.id)
        setItemsInTheCart(tempArr)
        message.info("Removed")
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'idx',
            key: 'idx',
        },
        {
            title: 'Item number',
            dataIndex: 'item_number',
            key: 'item_number',
            render: (_, record) => <>{record.selectedItem.item_number}</>

        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Amount',
            dataIndex: 'amountOfItems',
            key: 'amountOfItems',
        },
        {
            title: 'Item cost',
            dataIndex: 'itemCost',
            key: 'itemCost',
            render: (_, record) => <>${record.itemCost}</>
        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
            render: (_, record) =><>{record.selectedItem.department}</>
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Button type='link' onClick={() => { onRemove(record) }}>Remove</Button>
            ),
        },

    ];

    // console.log(itemsInTheCart)

    // const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    return (
        <>
            <div className="scrolledBox">
                <Table
                    columns={columns}
                    expandRowByClick={true}
                    expandable={{ expandedRowRender: (record) => (<div style={{ margin: 0, }} > <CreateNewPOrow record={record.selectedItem} customComponent={props.customComponent} /> </div>), rowExpandable: (record) => record.name !== 'Not Expandable', }}
                    dataSource={dataSource}
                    pagination={false}
                    size="small"
                    scroll={{ x: "100%" }}
                />
            </div>
        </>
    );
}

