import {Space} from 'antd'

const TableRowDiscardedNotes = (props) => {
    const { record } = props
    
    return (
        <Space direction="vertical">
            <b>Notes:</b>
            <>{record.discarded_notes}</>
        </Space>
    )
}

export default TableRowDiscardedNotes