import { Typography } from "antd"

const Logo = () => {
    const { Title } = Typography
    return (
        <div style={{ height: 150, textAlign: "center", paddingTop: '1px' }} >
            <Title style={{ marginBottom: '-10px', color:'#2FB394' }}>INVENTORY</Title>
            <p>by Empire City Laboratories</p>
        </div>
    )
}

export default Logo