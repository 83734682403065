/**
 * *UserAccessWatcher Component
 * 
 * This component monitors user access to specific routes within the application.
 * It leverages the React context, hooks, and routing to manage access control based on user roles.
 * 
 * *Usage:
 * Wrap any (e.g.AuthRequired) component with <UserAccessWatcher> to monitor and restrict access to that component.
 * 
 * *Access Control:
 * - The `useEffect` hook listens for changes in the current route.
 * - If the current route is restricted (`limitedPage`), the component checks if the current
 *   user's ID is in the list of allowed users for this route.
 * - If the user is not allowed, they are redirected to the '/401' route (Unauthorized access page).
 */


import { AuthContext } from "../AuthContext";
import { useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const UserAccessWatcher = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(AuthContext);

  const admins = [
    1,    //Serheii
    10,   //Anatoly
    12,   //Oksana
    16,   //Patrick
    26,   //Ainur
    27,   //Aleksei
    30,   //Massimi
    31,   //Edison
    66,   //Natalia
    77,   //Jake
  ]

  const supervisors = [
    32,   //Sib
    24,   //24 Paolo
    33,   //33 Tony
    117,  //117 Hope Thomas	
  ]

  const scanINusers = [
    127, // Elijah Hill
    29,  //	Jessie Harris
    132  //Sean Ehring
  ]

  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const accesses = [
    //* Management section
    // {
    //     route: "/PO",
    //     allowedUsers: [...admins],
    // },
    {
      route: "/PO/create-new-PO",
      allowedUsers: [...admins],
    },
    {
        route: "/users",
        allowedUsers: [...admins],
    },
    {
        route: "/warehouses",
        allowedUsers: [...admins],
    },
    {
        route: "/warehouses",
        allowedUsers: [...admins],
    },
    {
        route: "/orders",
        allowedUsers: [...admins],
    },
    {
        route: "/vendors",
        allowedUsers: [...admins],
    },
    {
        route: "/items/add-new",
        allowedUsers: [...admins],
    },
    {
        route: "/transactions-management-data",
        allowedUsers: [...admins],
    },
    //* Assets section
    {
      route: "/receive-single-asset",
      allowedUsers: [...admins, ...scanINusers],
    },
    {
      route: "/receive-by-PO",
      allowedUsers: [...admins, ...scanINusers],
    },
    {
      route: "/adaptive-receiving",
      allowedUsers: [...admins, ...scanINusers],
    },
    {
      route: "/custom-barcodes-receiving",
      allowedUsers: [...admins, ...scanINusers],
    },
    {
      route: "/asset-discarding/",
      allowedUsers: [...admins],
    },
    {
      route: "/asset-adjustment/",
      allowedUsers: [...admins],
    },

    // {
    //   route: "/distributing-authorization/",
    //   allowedUsers: [...admins],
    // },
    // {
    //   route: "/withdraw-an-asset/",
    //   allowedUsers: [...admins],
    // },
    {
      route: "/in-stock/",
      allowedUsers: [...admins, ...supervisors],
    },
    {
      route: "/transactions-stock/",
      allowedUsers: [...admins, ...supervisors],
    },
    //*Reports
    {
      route: "/reports/stock/items-usage",
      allowedUsers: [...admins, ...supervisors],
    },
    {
      route: "/reports/stock/used-items-report",
      allowedUsers: [...admins, ...supervisors],
    },
  ];
 

  useEffect(() => {
    const currentRoute = location.pathname;
    const limitedPage = accesses.some((access) => {return access.route === currentRoute})
    
    if (limitedPage){
      if(user.id){
        let allowedUsers = accesses.filter((access) => {return access.route ===currentRoute})
        allowedUsers = allowedUsers[0].allowedUsers

        if(!allowedUsers.includes(user.id)){
            navigate("/401");
        }
      }
    }

  }, [location.pathname, navigate, user, accesses]);

  return children;
};

