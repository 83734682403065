import {React, useContext} from 'react'
import {Space} from 'antd'
import {PrintLabelZebra} from '../../components/PrintLabelZebra'
import { GeneralContext } from '../../components/GeneralContext/GeneralContext'
import dayjs from 'dayjs';

const LabelRePrint = (props) => {
    const {connectedZebraPrinter, setConnectedZebraPrinter} = useContext(GeneralContext)
    const { barcodes, itemName, lotNumber, expirationDate } = props

    // console.log(form)
   
    const printedDate = dayjs(Date.now()).format('DD MMM YYYY') //YYYY-MM-DD

    let labels = []

    barcodes.forEach((barcode) => {
      const labelText = {
        line1: `Item     : ${itemName}`,
        line2: `Printed ${printedDate}`,
        line3: `LOT     : ${lotNumber}, Exps.: ${expirationDate}`,
        line4: `Notes   :`
      };

      labels.push({ barcode, labelText });
    });

    // console.log(labels)

    return (
      <Space direction='horizontal'>
        <Space.Compact>
            {/* <InputNumber min={1} value={amountOfLabels} onChange={(value) => { setAmountOfLabels(value) }} placeholder="Amount of Labels"  /> */}
            <PrintLabelZebra connectedZebraPrinter={connectedZebraPrinter} setConnectedZebraPrinter={setConnectedZebraPrinter} amountOfLabels={barcodes.length} labels={labels}/>
        </Space.Compact>
      </Space>
    )
  }

  export default LabelRePrint