import { React, useState, useContext, useEffect } from 'react'
import { Space, Button, App } from 'antd'
import { AuthContext } from '../../../components/Auth/AuthContext'
import axioss from '../../../services/restAPI';
import { LoadingSpinLocal } from '../../../components/LoadingSpinLocal'
import PossiblePOs from './PossiblePOs'
import BarcodesCheckList from './BarcodesCheckList'
import MyCollapse from '../../../components/Collapse'
import TaskDoneMarker from '../../../components/TaskDoneMarker'
import ItemData from './ItemData'
import LABELtimestampBarcode from '../../../components/PrintZebraLabel/LABELtimestampBarcode';


const CustomBarcodesReceiving = () => {
    const { authConfig } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const { message, modal } = App.useApp()
    const [warehouse, setWarehouse] = useState(1)
    const [selectedItem, setSelectedItem] = useState(null)
    const [amount, setAmount] = useState(null);
    const [lotNumber, setLotNumber] = useState(null)
    const [expirationDate, setExpirationDate] = useState(null);
    const [location, setLocation] = useState(null)
    const [itemNumber, setItemNumber] = useState(null)
    // const [rerenderTrigger, setRerenderTrigger] = useState("")
    const [isRocheItemsQRreaderInUse, setIsRocheItemsQRreaderInUse] = useState(false)
    const [selectedPO, setSelectedPO] = useState(null)
    const [barcodes, setBarcodes] = useState([])

    const [itemDataCompleted, setItemDataCompleted] = useState(false);
    const [itemBarcodesCompleted, setItemBarcodesCompleted] = useState(false)
    const [possiblePOsCompleted, setPossiblePOsCompleted] = useState(false)


    const formFilledOut = itemDataCompleted && itemBarcodesCompleted && possiblePOsCompleted

    const itemDataFields = { warehouse, setWarehouse, setLocation, isRocheItemsQRreaderInUse, setIsRocheItemsQRreaderInUse, setExpirationDate, setLotNumber, setItemNumber, setSelectedItem, selectedItem, itemNumber, amount, setAmount, lotNumber, expirationDate, location }

    const onSubmit = async () => {
        try {
            setLoading(true)
            console.log({
                item_id: selectedItem.id,
                actual_item: selectedItem,
                purchase_order_id: selectedPO.purchase_order_id,
                amount: amount,
                lotNumber,
                expirationDate,
                newLocation: location,
                barcodes
            })
            await axioss.post('/action/create-stocks-by-PO-custom-barcodes/', {
                items: [{
                    item_id: selectedItem.id,
                    actual_item: selectedItem,
                    purchase_order_id: selectedPO.purchase_order_id,
                    amount: amount,
                    lotNumber,
                    expirationDate,
                    newLocation: location,
                    barcodes
                }]
            }, authConfig)
            message.success('Submitted successfully')
            // setRerenderTrigger(Math.random())
            window.location.reload();
        } catch (error) {
            console.error(error.response.data)
            modal.warning({ title: "Error while submitting:", content: error.message })
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        //reset location if a warehouse was changed
        setLocation(null)
    }, [warehouse])

    useEffect(() => {
        setSelectedPO(null)
    }, [selectedItem])


    return (
        <LoadingSpinLocal loading={loading}>

            {/* <Space>
                    <h2>Receive a Single Asset</h2>
                    <Tooltip title="This section is designed for receiving a single / existing asset that is not related to a Purchase Order. ">
                        <QuestionCircleOutlined />
                    </Tooltip>
                 </Space> */}

            <Space wrap align='top' size={'large'}>

                <MyCollapse title={<><TaskDoneMarker taskDone={itemDataCompleted} /> <b>Item Data</b> </>} child={
                    <>
                        <ItemData itemDataFields={itemDataFields} setItemDataCompleted={setItemDataCompleted} />
                        <br />
                        <br />
                        <Button disabled={!formFilledOut || loading} type='primary' onClick={onSubmit}>Submit</Button>
                    </>

                } />

                {amount && (
                    <MyCollapse title={<><TaskDoneMarker taskDone={itemBarcodesCompleted} /> <b>Item Barcodes</b> </>} child={
                        <BarcodesCheckList barcodes={barcodes} setBarcodes={setBarcodes} amount={amount} setItemBarcodesCompleted={setItemBarcodesCompleted} />
                    } />
                )}

                <div  style={{width:'450px'}}>
                    {selectedItem && (
                        <MyCollapse title={<><TaskDoneMarker taskDone={possiblePOsCompleted} /> <b>Possible POs</b></>} child={
                            <PossiblePOs selectedPO={selectedPO} setSelectedPO={setSelectedPO} selectedItem={selectedItem} setPossiblePOsCompleted={setPossiblePOsCompleted} />
                        } />
                    )}
                </div>
                
            </Space>
            <br />
            <br />
            <div style={{width:'450px'}}>
                <MyCollapse title={'Generale Labels'} child={ <LABELtimestampBarcode/> } collapsed/>
            </div>

        </LoadingSpinLocal>
    );

}

export default CustomBarcodesReceiving


