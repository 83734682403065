import  { React } from "react";
import { Table } from "antd";
import { UserRoleInterpreter } from "./UserRoles";
import {TableRow} from "./row"

export function UsersTable(props) {
    const dataSource = [...props.dataSource]
    dataSource.forEach((user)=>{
        user.name = `${user.first_name} ${user.last_name}`;
        user.key = user.email;
        return user
    })

    const columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Role',
          dataIndex: 'role',
          key: 'role',
          render: (_)=>(<UserRoleInterpreter role={_}/>)
        },
    ];

    
    // const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    return (
        <>
            <div className="scrolledBox">
                <Table
                    columns={columns}
                    expandRowByClick={true}
                    expandable={{ expandedRowRender: (record) => (<div style={{ margin: 0, }} > <TableRow record={record} customComponent={props.customComponent}/> </div>), rowExpandable: (record) => record.name !== 'Not Expandable', }}
                    dataSource={dataSource}
                    pagination={false}
                    scroll={{ x: "100%"}}
                />
            </div>
        </>
    );
}

