import { Select } from 'antd'
import { useState, useEffect, useContext } from "react";
import axioss from '../../../services/restAPI';
import { AuthContext } from "../../../components/Auth/AuthContext";
import { App } from 'antd'
import { LoadingSpinLocal } from "../../../components/LoadingSpinLocal";


const LocationTypesDropdown = (props) => {
    const optionWeight = 300

    const [loading, setLoading] = useState(false)
    const { authConfig } = useContext(AuthContext)
    const { modal } = App.useApp()
    const { setSelectedType } = props
    const [types, setTypes] = useState([])

    useEffect(() => {
        if (types.length === 0) {
            getTypes()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getTypes = async () => {
        try {
            setLoading(true)
            const response = await axioss.get("/locations/types", authConfig)
            setTypes(response.data)
        }
        catch (err) {
            console.log(err)
            modal.warning({
                title: 'Error while loading departments',
                content: err.message,
            });
        }
        finally {
            setLoading(false)
        }
    }

    const typesOptions = types.map(type => {
        return {
            value: type.id,
            label: type.name,
        }
    })

    return (
        <LoadingSpinLocal loading={loading}>
            <Select
                onChange={(value) => {setSelectedType(value)}}
                placeholder="Select Location Type"
                options={typesOptions}
                style={{ minWidth: optionWeight }}
            />
        </LoadingSpinLocal>
    )
}

export { LocationTypesDropdown }