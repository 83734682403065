import {React, useEffect, useState, useContext} from 'react'
import { GeneralContext } from '../../components/GeneralContext/GeneralContext';
import { OrdersTable } from './table';
import { MockOrders } from '../../mock/mockBackEnd';

//TODO add search filters

const Orders = () => {
    useEffect(()=>{
        if(fistLoading){
            getOrders();
            setFirstLoading(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const {setLoading} = useContext(GeneralContext)
    const [orders, setOrders] = useState([])
    const [fistLoading, setFirstLoading] = useState(true)

    const getOrders = ()=>{
        try{
            setLoading(true)
            setOrders(MockOrders)
        }
        catch(err){
            //TODO - add modal
            console.log(err)
        }
        finally{
            setLoading(false)
        }
    }

    return (
        <>
            <h2>Items</h2>
            <OrdersTable dataSource={orders} />
        </>
    )
}

export {Orders}