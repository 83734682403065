import { useState, useEffect, useMemo } from 'react';
import { Table, Switch, Space, Tooltip } from 'antd';
import TableRow from './TableRow';
import JsonToCSV from '../../components/JsonToCSV'
import DepartmentByID from '../../components/Departments/DepartmentByID'
import DateFormatter from '../../components/DateFormatter';


function groupJsons(jsonArray) {
    const grouped = {};

    jsonArray.forEach(jsonObj => {
        // Create a unique key based on fields except 'item_amount' and 'id'
        const key = JSON.stringify({
            item_number: jsonObj.item_number,
            item_id: jsonObj.item_id,
            item_name: jsonObj.item_name,
            transaction_type: jsonObj.transaction_type,
            // date: jsonObj.date.split('T')[0]
        });

        // Initialize a new group if key is not present
        if (!grouped[key]) {
            grouped[key] = {
                ...JSON.parse(key),
                item_amount: 0,
                elements: []
            };
        }

        // Add current object to the group and update total_amount
        grouped[key].elements.push(jsonObj);
        grouped[key].item_amount += jsonObj.item_amount;
    });

    // Convert grouped objects back to an array
    return Object.values(grouped);
}

const TransactionsTable = (props) => {
    const { data } = props

    const [groupDataByItem, setGroupDataByItem] = useState(false);
    const [dataSource, setDataSource] = useState([]);

    const groupedData = useMemo(() => {
        return groupJsons([...data]);
    }, [data]);


    useEffect(() => {
        setDataSource(groupDataByItem ? groupedData : data);
    }, [groupDataByItem, data, groupedData]);


    const columns = [
        ...(!groupDataByItem ? [{
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        }] : []),
        ...(!groupDataByItem ? [{
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (_) => (<DateFormatter dateString={_}/>) 
        }] : []),
        ...(!groupDataByItem ? [{
            title: "User Information",
            children: [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    render: (_, record) => `${record.user_first_name} ${record.user_last_name}`,
                },
                {
                    title: 'Department',
                    dataIndex: 'user_department',
                    key: 'user_department',
                    render: (_) => (<DepartmentByID departmentID={_} key={_} />)
                }
            ]
        }] : []),

        // {
        //     title: 'Item ID',
        //     dataIndex: 'item_id',
        //     key: 'item_id',
        // },
        {
            title: 'Item number',
            dataIndex: 'item_number',
            key: 'item_number',
        },
        {
            title: 'Item',
            dataIndex: 'item_name',
            key: 'item_name',
        },
        {
            title: 'Vendor',
            dataIndex: 'item_vendor',
            key: 'item_vendor',
        },
        {
            title: 'Transaction Type',
            dataIndex: 'transaction_type',
            key: 'transaction_type',
        },
        {
            title: 'Amount of Items',
            dataIndex: 'item_amount',
            key: 'item_amount',
        },
    ];



    return (
        <>
            <Space>
                <JsonToCSV data={dataSource} includeFields={['date', 'transaction_type', 'user_first_name', 'user_last_name', 'item_number', 'item_name', 'item_amount', 'barcodes']} />

                <Space>
                    Group by Item
                    <Tooltip title="Switch this to group or ungroup the displayed transactions. When on, transactions are grouped by item. When off, all transactions are listed individually and contain more details.">
                        <Switch checked={groupDataByItem} onChange={() => setGroupDataByItem(!groupDataByItem)} />
                    </Tooltip>
                </Space>
            </Space>

            <Table
                bordered
                dataSource={dataSource}
                columns={columns}
                expandable={{
                    expandedRowRender: (record) => (<TableRow record={record} />),
                    rowExpandable: (record) => record.location_log || record.barcodes,
                }}
            />
        </>
    );
};

export default TransactionsTable;
