import { useState, React, useContext } from "react";
import { AuthContext } from "../../components/Auth/AuthContext"
import axioss from '../../services/restAPI';
import buildQueryParams from "../../services/utilites/buildQueryParams";
import SearchFilters from "./SearchFilters";
import MainTable from "./MainTable"; 
import { LoadingSpinLocal } from '../../components/LoadingSpinLocal'
import { WarehouseDropdown } from '../Warehouses/WarehousesSelect'
import {Tag} from 'antd'
// import RequestErrorHandler from "../../components/RequestErrorHandler";
const InStock = () => {
    const [loading, setLoading] = useState(false)
    const { authConfig } = useContext(AuthContext)
    const [inStockProducts, setInStockProducts] = useState([])

    const [searchByItemNumber, setSearchByItemNumber] = useState("")
    const [searchByItemName, setSearchByItemName] = useState("")
    const [searchByWarehouseID, setSearchByWarehouseID] = useState("")
    const [searchByVender, setSearchByVender] = useState("")
    const [searchByExpiresIn, setSearchByExpiresIn] = useState("")
    const [searchByLowInStock, setSearchByLowInStock] = useState(null)
    const [searchByLocation, setSearchByLocation] = useState("")
    const [searchByLOT, setSearchByLOT] = useState("")
    const [searchByLocationDepartment, setSearchByLocationDepartment] = useState("")
    
    const triggers = [searchByItemName, searchByWarehouseID, searchByVender, searchByExpiresIn, searchByLowInStock, searchByLocation, searchByItemNumber, searchByLOT, searchByLocationDepartment]
    
    const filters = {
        searchByItemNumber, setSearchByItemNumber,

        searchByItemName,setSearchByItemName,

        searchByWarehouseID, setSearchByWarehouseID,

        searchByVender, setSearchByVender,

        searchByExpiresIn, setSearchByExpiresIn,

        searchByLowInStock, setSearchByLowInStock,

        searchByLocation, setSearchByLocation,

        searchByLOT, setSearchByLOT,

        searchByLocationDepartment, setSearchByLocationDepartment
    }


    const getProductsInStock = async () => {
        try {
            setLoading(true)

            const params = {
                // limit,
                // offset,
                searchByWarehouseID,
                searchByItemName,
                searchByVender,
                searchByExpiresIn,
                searchByLowInStock,
                searchByLocation:searchByLocation.locationID,
                searchByItemNumber,
                searchByLOT,
                searchByLocationDepartment
                // searchByBarcode:1701979109838
            }; 
            // console.log(params)
            const url = `/in-stock?${buildQueryParams(params)}`;

            const response = await axioss.get(url, authConfig);
            setInStockProducts(response.data)

            // console.log(response.data)

        }
        catch (err) {
            console.log(err)
            if(err.response.status===403){
                alert("access forbidden")
            }
        }
        finally {
            setLoading(false)
        }
    }





    return (
        <>
            <h1>In Stock</h1>
            <WarehouseDropdown warehouse={searchByWarehouseID} setWarehouse={setSearchByWarehouseID} allowAllOption />
            <SearchFilters filters={filters} getData={getProductsInStock}/>

            {inStockProducts.length===0&&triggers.every(trigger=>!trigger)&&( <div><Tag color='volcano' size="big">Please specify at least one filter above to view products in stock.</Tag></div> )}
            
            <LoadingSpinLocal loading={loading}>
                <MainTable inStockProducts={inStockProducts} setInStockProducts={setInStockProducts} expandable hideAmountToOrder/>
            </LoadingSpinLocal>
        </>
    )
}

export default InStock