import React, { useEffect, useState } from 'react';
import { Table, Spin, Alert, Tabs } from 'antd';
import axios from '../../../../services/restAPI';
import JsonToCSV from '../../../../components/JsonToCSV';

const { TabPane } = Tabs;

const DepartmentStocks = () => {
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const url = `/reports/stock/department-stocks/department-stocks`;
        axios.get(url)
            .then(response => {
                const departments = response.data.map(department => {
                    const totalItems = department.stocks.reduce((sum, stock) => sum + stock.amount_of_items, 0);
                    return { ...department, totalItems };
                });
                // Sort departments by totalItems in descending order
                departments.sort((a, b) => b.totalItems - a.totalItems);
                setDepartments(departments);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);

    const columns = [
        { title: 'Item Number', dataIndex: 'item_number', key: 'item_number' },
        { title: 'Item Name', dataIndex: 'item_name', key: 'item_name' },
        { title: 'Item Vendor', dataIndex: 'item_vendor', key: 'item_vendor' },
        { title: 'Item Type', dataIndex: 'item_type', key: 'item_type' },
        { title: 'Item Threshold', dataIndex: 'item_threshold', key: 'item_threshold' },
        { title: 'Amount of Items', dataIndex: 'amount_of_items', key: 'amount_of_items' },
    ];

    if (loading) {
        return <Spin tip="Loading..." />;
    }

    if (error) {
        return <Alert message="Error" description="An error occurred while fetching the data." type="error" showIcon />;
    }

    return (
        <>
            <h1>Stock By Department Report</h1>
            <Tabs defaultActiveKey="0">
                {departments.map((department, index) => (
                    <TabPane tab={`${department.name} (${department.totalItems})`} key={index}>
                        <JsonToCSV data={department.stocks} excludeFields={'items'}/>
                        <Table
                            columns={columns}
                            dataSource={department.stocks}
                            rowKey="item_number"
                            pagination={false}
                        />
                    </TabPane>
                ))}
            </Tabs>
        </>
    );
};

export default DepartmentStocks;
