import { useState, useContext, } from 'react'
import { Space, Button, App } from 'antd';
import { POCartTable } from './table';
import axioss from '../../../../services/restAPI';
import { LoadingSpinLocal } from '../../../../components/LoadingSpinLocal';
import { AuthContext } from '../../../../components/Auth/AuthContext';


const POCart = (props) => {
    const { authConfig } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const { itemsInTheCart, setItemsInTheCart } = props;
    const { message, modal } = App.useApp();



    const onClear = () => {
        message.success('Cleared')
        setItemsInTheCart([])
    }

    const onCreatePO = async () => {
        let items = []
        console.log(itemsInTheCart)
        // const department = itemsInTheCart[0].selectedItem.department
        // let barcode = department.slice(0, 2) + Date.now()
        // barcode = barcode.toUpperCase()
        // console.log(barcode)


        itemsInTheCart.forEach(item => {
            items.push({
                item_id: item.id,
                quantity_purchased: item.amountOfItems,
                purchase_price: parseFloat(item.itemCost),
            })
        })

        try {
            setLoading(true)
            const data = {
                warehouseID: 1, // replace with actual warehouse_id
                // barcode: barcode,
                status: 'processing', // replace with actual status
                items:items
            }
            await axioss.post('/po/', data, authConfig)
            setItemsInTheCart([])
            message.success('New PO was created successfully')
        } catch (error) {
            console.error(error)
            modal.warning({
                title:'Error creating new PO:',
                content:error.message
            })
        }
        finally{
            setLoading(false)
        }
    }

    return (
        <LoadingSpinLocal loading={loading}>
            <Space direction='vertical'>
                {itemsInTheCart.length > 0 && (
                    <>
                        <h2>Items in the cart</h2>

                        <POCartTable itemsInTheCart={itemsInTheCart} setItemsInTheCart={setItemsInTheCart} />

                        {itemsInTheCart.length > 0 && (
                            <Space  >
                                <Button type='primary' onClick={onCreatePO}>Submit PO</Button>
                                <Button type='link' onClick={onClear}>Clear</Button>
                            </Space>
                        )}
                    </>
                )}
            </Space>
        </LoadingSpinLocal>
    );
};

export { POCart };
