import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import Logout from "./components/Auth/Logout";

const items = [
  {
    label: 'Home',
    key: "home",
    path: "/home",
  },
  {
    label: "Management",
    key: "management",
    children: [
      {
        label: 'PO',
        key: "PO",
        path: "/PO",
      },
      {
        label: 'Users',
        key: "users",
        path: "/users",
      },
      {
        label: 'Warehouses',
        key: "warehouses",
        path: "/warehouses",
      },
      {
        label: 'Items',
        key: "items",
        path: "/items",
      },
      {
        label: 'Orders',
        key: "orders",
        path: "/orders",
        disabled: true,
      },
      {
        label: 'Vendors',
        key: "vendors",
        path: "/vendors",
        disabled: true,
      },
      {
        label: 'Management Logs',
        key: "logs",
        path: "/transactions-management-data",
      },
    ],
  },
  {
    label: 'Assets',
    key: 'assets',
    children: [
      {
        type: 'group',
        label: 'Receiving',
        children: [
          {
            label: 'Receiving',
            key: "adaptive-receiving",
            path: "/adaptive-receiving",
          },
          {
            label: 'Receive a non-PO / Existing Asset',
            key: "receiveSingle",
            path: "/receive-single-asset",
            disabled: true,
          },
          // {
          //   label: <i>Legacy: Receive PO Assets</i>,
          //   key: "receiveByPO",
          //   path: "/receive-by-PO",
          // },
          {
            label: 'Custom Barcodes Receiving',
            key: "custom-barcodes-receiving",
            path: "/custom-barcodes-receiving",
            disabled: true,
          },
        ],
      },
      {
        type: 'group',
        label: 'Withdrawal',
        children: [
          {
            label: 'Distribute an Asset',
            key: 'ProductsDistributing',
            path: "/distributing-authorization/",
          },
          {
            label: 'Withdraw an Asset',
            key: 'WithdrawAsset',
            path: "/withdraw-an-asset/",
          },
          {
            label: 'Discard an Asset',
            key: 'DiscardAsset',
            path: "/asset-discarding/",
          },
        ],
      },
      {
        type: 'group',
        label: 'Stock',
        children: [
          {
            label: 'Adjustment',
            key: 'AssetAdjustment',
            path: "/asset-adjustment/",
          },
          {
            label: 'Products in stock',
            key: 'InStock',
            path: "/in-stock/",
          },
          {
            label: 'Stock Logs',
            key: 'StockLogs',
            path: "/transactions-stock/",
          },
        ],
      },
    ],
  },
  {
    label: 'Reports',
    key: 'reports',
    children: [
      {
        type: 'group',
        label: 'Stock',
        children: [
          {
            label: 'Items Usage',
            key: "items usage",
            path: "/reports/stock/items-usage",
          },
          {
            label: 'Used Items',
            key: "used items report",
            path: "/reports/stock/used-items-report",
          },
          {
            label: 'Stock By Department',
            key: "Stock By Department",
            path: "/reports/stock/department-stock",
          },
        ],
      },
    ],
  },
  {
    label: 'About',
    key: "about",
    path: "/about",
  },
  {
    label: <Logout />,
    key: "logout",
  },
];

export default function Navigation() {
  const [current, setCurrent] = useState("mail");
  const navigate = useNavigate();

  const onClick = (e) => {
    const item = findItemByKey(items, e.key);
    if (item.disabled) {
      e.domEvent.preventDefault();
    } else if (item.path) {
      navigate(item.path);
      setCurrent(e.key);
    }
  };

  const findItemByKey = (items, key) => {
    for (const item of items) {
      if (item.key === key) return item;
      if (item.children) {
        const childItem = findItemByKey(item.children, key);
        if (childItem) return childItem;
      }
    }
    return null;
  };

  return (
    <Menu
      theme="light"
      onClick={onClick}
      selectedKeys={[current]}
      mode="horizontal"
      items={items}
    />
  );
}
