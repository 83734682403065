import { React, useState, useContext, useEffect } from 'react'
import { Space, Button, Row, Col, InputNumber, DatePicker, Input, App, Tooltip } from 'antd'
import { ItemsSearcher } from '../../Items/ItemsSearcher'
// import { ItemInformation } from '../../Items/ItemInformation'
import { GeneralContext } from '../../../components/GeneralContext/GeneralContext'
import { AfterSubmitModal } from './AfterSubmitModal'
import { WarehouseDropdown } from '../../Warehouses/WarehousesSelect'
import { AuthContext } from '../../../components/Auth/AuthContext'
import axioss from '../../../services/restAPI';
import { LoadingSpinLocal } from '../../../components/LoadingSpinLocal'
import Locations from '../../Warehouses/Locations'
import { QuestionCircleOutlined, } from '@ant-design/icons';
import RocheItemsQRreader from '../../Items/RocheItemsQRreader'


const ReceiveSingleAsset = () => {
    const { authConfig } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const { message, modal } = App.useApp()
    const [warehouse, setWarehouse] = useState(1)
    const { isSmallScreen } = useContext(GeneralContext)
    const [selectedItem, setSelectedItem] = useState(null)
    const [amount, setAmount] = useState(null);
    const [lotNumber, setLotNumber] = useState(null)
    const [expirationDate, setExpirationDate] = useState(null);
    const smallScreenStyle = isSmallScreen ? ('MSSurfaceTablet') : null
    const [showAfterSubmitModal, setShowAfterSubmitModal] = useState(false)
    const [afterSubmitServerResponse, setAfterSubmitServerResponse] = useState(null)
    const [location, setLocation] = useState(null)
    const [itemNumber, setItemNumber] = useState(null)
    const [rerenderTrigger, setRerenderTrigger] = useState("")
    const [isRocheItemsQRreaderInUse, setIsRocheItemsQRreaderInUse] = useState(false)

    console.log('selected itm', selectedItem)

    const formFilledOut = selectedItem !== null &&
        amount !== null &&
        (selectedItem.expirationDateRequired ? expirationDate !== null : true) &&
        (selectedItem.lotRequired ? lotNumber !== null : true) &&
        location !== null//checks if all the fields are filled out. Checks lot and expiration only if selectedItem.lotRequired and expirationdDateRequired are true


    const form = {
        selectedItem: selectedItem,
        setSelectedItem: setSelectedItem,

        amount: amount,
        setAmount: setAmount,

        lotNumber: lotNumber,
        setLotNumber: setLotNumber,

        expirationDate: expirationDate,
        setExpirationDate: setExpirationDate,

        warehouse: warehouse,
        setWarehouse: setWarehouse,

        location: location,
        setLocation: setLocation,

        afterSubmitServerResponse: afterSubmitServerResponse,
        setAfterSubmitServerResponse: setAfterSubmitServerResponse
    }

    const onSubmit = async () => {
        try {
            setLoading(true)
            const response = await axioss.post('/action/create-stock/', {
                itemID: selectedItem.id,
                location: location.locationID,
                quantity: amount,
                expirationDate: expirationDate,
                lot: lotNumber,
            }, authConfig)

            // console.log(response.data)
            setAfterSubmitServerResponse(response.data)
            message.success('Submitted successfully')
            setShowAfterSubmitModal(true)
            setRerenderTrigger(Date.now())
        } catch (error) {
            console.error(error.response.data)
            modal.warning({ title: "Error while submitting:", content: error.message })
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        //reset location if a warehouse was changed
        setLocation(null)
    }, [warehouse])


    return (
        <LoadingSpinLocal loading={loading}>
            <Space wrapped>

                <AfterSubmitModal setRerenderTrigger={setRerenderTrigger} showAfterSubmitModal={showAfterSubmitModal} setShowAfterSubmitModal={setShowAfterSubmitModal} form={form} />

                <Row className={smallScreenStyle}>
                    <Col xs={{ span: 23 }} lg={{ span: 11 }} >
                        <Space>
                            <h2>Receive a Single Asset</h2>
                            <Tooltip title="This section is designed for receiving a single / existing asset that is not related to a Purchase Order. ">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </Space>

                        <Space direction="vertical" style={{ width: '100%' }} wrap>
                            <Space wrap>
                                <WarehouseDropdown warehouse={warehouse} setWarehouse={setWarehouse} />
                                <Locations key={`locations ${warehouse} ${rerenderTrigger} `} selectedWarehouse={warehouse} location={location} setLocation={setLocation} />
                            </Space>

                            <Space wrap>
                                <div style={isRocheItemsQRreaderInUse ? { pointerEvents: "none", opacity: "0.4" } : null}><ItemsSearcher setSelectedItem={setSelectedItem} selectedItem={selectedItem} predefinedItemNumber={itemNumber} key={`ItemsSearcher ${rerenderTrigger}`} /></div>
                                <>or</>
                                <RocheItemsQRreader
                                    isComponentInUse={isRocheItemsQRreaderInUse}
                                    setIsComponentInUse={setIsRocheItemsQRreaderInUse}
                                    setExpirationDate={setExpirationDate}
                                    setLotNumber={setLotNumber}
                                    setItemNumber={setItemNumber}
                                    key={`RocheItemsQRreader ${rerenderTrigger}`}
                                />
                            </Space>


                            <Space >
                                <InputNumber min={1} value={amount} onChange={(value) => { setAmount(value) }} placeholder="Amount of Items" key={`InputAmount ${rerenderTrigger}`} />

                                {selectedItem?.lotRequired &&
                                    <Input value={lotNumber} onChange={(e) => { setLotNumber(e.target.value) }} allowClear placeholder="Lot Number" key={`InputLotNumber ${rerenderTrigger}`} />
                                }


                                {selectedItem?.expirationDateRequired &&
                                    <DatePicker value={expirationDate} placeholder="Expiration Date" onChange={(dateString) => { setExpirationDate(dateString) }} disabledDate={(current) => current && current.valueOf() < Date.now()} key={`ExpirationDate ${rerenderTrigger}`} />
                                }
                            </Space>

                            <Button disabled={!formFilledOut || loading} type='primary' onClick={onSubmit}>Submit</Button>

                        </Space>
                    </Col>
                    <Col lg={{ span: 1 }} />
                    {/* <Col xs={{ span: 23 }} lg={{ span: 11 }} >
                        {selectedItem && (
                            <>
                                <h3>Item Information</h3>
                                <ItemInformation selectedItem={selectedItem} />
                            </>
                        )}

                    </Col> */}
                </Row>
            </Space>

        </LoadingSpinLocal>
    );

}

export { ReceiveSingleAsset }