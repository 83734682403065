import { React } from 'react'
import { Collapse, Space, Select, DatePicker,  } from 'antd'
import UserSelect from '../Users/UserSelect'

const { RangePicker } = DatePicker;

const TableFilters = (props) => {
  const { Panel } = Collapse;
  const { searchFilters } = props

  // const onClear = () => {
  //   searchFilters.setSearchByName("")
  // }
  return (
    <Collapse defaultActiveKey={["1"]} bordered={false}>
      <Panel header="Search" key="1">
        <Space size="large" wrap>

          <RangePicker onChange={(dates) => {
            const dateString = dates ? [dates[0].format('YYYY-MM-DD'), dates[1].format('YYYY-MM-DD')] : [];
            searchFilters.setSearchByDateRange(dateString);
          }}/>

          <UserSelect selectedUser={searchFilters.searchByUser} setSelectedUser={searchFilters.setSearchByUser} />


          <Select showSearch placeholder='Search by Type' onChange={(value) => searchFilters.setSearchByTransactionType(value)} allowClear style={{ width: "150px" }} >
            <Select.Option value="creating">Creating</Select.Option>
            <Select.Option value="editing">Editing</Select.Option>
            <Select.Option value="removing">Removing</Select.Option>
          </Select>

        </Space>
      </Panel>
    </Collapse>
  )
}

export default TableFilters

// value={searchFilters.searchByTransactionType}
