import axioss from "../../restAPI";

const getWarehousesService = async ( authConfig ) => {
  try {
    const response = await axioss.get("/warehouse/list/", authConfig);
    const tempArr = response.data.map((warehouse) => {
      const key = `${warehouse.name}-${warehouse.phone}-${warehouse.email}-${warehouse.address}-${warehouse.city}-${warehouse.contactPerson}-${warehouse.country}-${warehouse.fax}-${warehouse.isDeleted}-${warehouse.memo}-${warehouse.state}-${warehouse.zip}`;
      //  warehouse.key = `${warehouse.name}-${Date.now()}`;
      warehouse.key = key;
      return warehouse;
    });

    tempArr.sort((a, b) => a.id - b.id);

    return tempArr;
  } catch (error) {
    console.error(error);
    throw error
  }
};

export { getWarehousesService };
