// this component is designed to be used within a row of a table. This component requires an access to the dataset that defines the table
// you can consider using poor location component from the index.jsx

import { useState, useEffect } from 'react'
import Locations from '.'
import FormattedLocation from './FormattedLocation'

const LocationForRows = (props) => {
    const { record, dataSource, setDataSource, warehouseID } = props

    const [location, setLocation] = useState(null)

    // console.log(location)

    const resetLocation = () => {
        let tempArr = [...dataSource]
        tempArr = tempArr.map((product) => {
            if (product.barcode === record.barcode) {
                product.newLocation = undefined;
            }
            return product
        })

        setDataSource(tempArr)

    }

    useEffect(() => {
        //adds new section to the product once a new section is selected by a user

        if (location !== null && record.newLocation === undefined) {


            let tempArr = [...dataSource]
            tempArr = tempArr.map((product) => {
                if (product.barcode === record.barcode) {
                    product.newLocation = location;
                }
                return product
            })

            setDataSource(tempArr)


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        <>
            {warehouseID ? (
                <>
                    {record.newLocation === undefined ?
                        (
                            <Locations selectedWarehouse={warehouseID} location={location} setLocation={setLocation} />
                        ) : (
                            <FormattedLocation location={record.newLocation} onChangeLocation={resetLocation} />
                        )}
                </>
            ) : (
                <>Select warehouse</>
            )}
        </>
    )
}

export default LocationForRows