import {React,useState} from "react";
import { Collapse, Button, Space, Col, Row,  } from "antd";
import {ItemInformation} from './ItemInformation';
const { Panel } = Collapse;

export function TableRow(props) {
  const {record, setRepeatGetItems} = props
  const [editItem,setEditItem] = useState(false)
  return (
    <div className="rowStyle">
      <Row>
        <Col xs={{ span: 23 }} lg={{ span: 11 }}>
          <Collapse defaultActiveKey={["1"]} bordered={false}>
            <Panel header="Item Information" key="1">
              <ItemInformation selectedItem={record} editItem={editItem} setEditItem={setEditItem}  setRepeatGetItems={setRepeatGetItems} />
            </Panel>
          </Collapse>
        </Col>
        <Col span={1} />
        <Col xs={{ span: 23 }} lg={{ span: 11 }}>
          <Collapse defaultActiveKey={["0"]} bordered={false}>
            <Panel header="Actions" key="1">
              <Actions editItem={editItem} setEditItem={setEditItem} />
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
}


const Actions = (props) => {
  // const record = {...props.record}
  const {editItem, setEditItem} = props

  return (
    <div >
      <Space direction="horizontal" size='large'>
        {editItem?(<Button onClick={()=>{setEditItem(false)}}>Cancel Editing</Button>):<Button onClick={()=>{setEditItem(true)}}>Edit Item</Button>}
      </Space>
    </div>
  );
};
