import { } from "antd";
import { useState, useEffect, React, useContext } from "react";
import axioss from "../../../../services/restAPI";
import { AuthContext } from "../../../../components/Auth/AuthContext";
import { LoadingSpinLocal } from "../../../../components/LoadingSpinLocal";
import buildQueryParams from "../../../../services/utilites/buildQueryParams";
import GroupProducts from "./GroupProducts";

const TodaysStocks = () => {
    const [loading, setLoading] = useState(false);
    const { authConfig } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const today = new Date().toISOString().split('T')[0];

    console.log(data)

    const getData = async () => {
        try {
            setLoading(true)

            const params = {
                // limit,
                // offset,
                searchByDateReceived: today,
                searchByWarehouseID: 1,
                // searchByItemName,
                // searchByVender,
                // searchByExpiresIn,
                // searchByLowInStock,
                // searchByLocation:searchByLocation.locationID,
                // searchByItemNumber,
                // searchByLOT,
                // searchByLocationDepartment
                // searchByBarcode:1701979109838
            };
            // console.log(params)
            const url = `/in-stock?${buildQueryParams(params)}`;

            const response = await axioss.get(url, authConfig);
            setData(response.data)

            console.log('response.data', response.data)

        }
        catch (err) {
            console.log(err)
            if (err.response.status === 403) {
                alert("access forbidden")
            }
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <LoadingSpinLocal loading={loading}>
            {data.length > 0 && (
                <GroupProducts products={data} bordered expandable />
            )}
        </LoadingSpinLocal>
    );
};


export default TodaysStocks;





