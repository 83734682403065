import { Modal, } from "antd";
import { ReceiveSingleAsset } from "../ReceiveSingleAsset";

const AddNonPO = (props) => {
    const { showAddNonPO, setShowAddNonPO } = props;




    return (
        <Modal
            title="Add a Non PO Item"
            open={showAddNonPO}
            closable={true}
            keyboard={true}
            footer={null}
          onCancel={() => { setShowAddNonPO(false); }}
        >
            <ReceiveSingleAsset />

        </Modal>

    );
};

export default AddNonPO
