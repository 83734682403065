import {Input} from 'antd'
import {useState, useEffect} from 'react'

const TableFilterByNumber = (props) => {
    const {setFilteredDataSource, dataSource,} = props
    const [filterValue, setFilterValue] = useState([])

    console.log(filterValue)
    

    useEffect(()=>{
        if(filterValue.length>0){
            const tempArr = dataSource.filter((item) => (item.actual_item.item_number.includes(filterValue)))
            setFilteredDataSource(tempArr)
        }
        else{
            setFilteredDataSource(dataSource)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue])

    return (
        <>
            <Input placeholder='Filter By Item Number' value={filterValue} onChange={(e) => {setFilterValue(e.target.value)}} key={"Filter by item number"}/>
        </>
    )
}

export default TableFilterByNumber