import {React, useContext} from 'react';
import { Spin } from 'antd';
import { GeneralContext } from '../GeneralContext/GeneralContext';

const LoadingSpin = ( {children}) => {
 const {loading} = useContext(GeneralContext)
  return (
    <Spin spinning={loading} tip='loading'>
    {children}
  </Spin>
  );
};

export default LoadingSpin;