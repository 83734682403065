import { useState, useContext } from 'react';
import { ItemTypeSelect } from '../ItemTypeSelect'
import { DepartmentsSelector } from '../../../components/Departments'
import { Button, App, Input,InputNumber, Space, Checkbox,Descriptions } from 'antd';
import { Link } from "react-router-dom";
import { AuthContext } from '../../../components/Auth/AuthContext';
import axioss from '../../../services/restAPI';
import { LoadingSpinLocal } from '../../../components/LoadingSpinLocal';
import { GeneralContext } from '../../../components/GeneralContext/GeneralContext';
import ItemUnitOfMeasureSelect from '../ItemUnitOfMeasureSelect';

const AddNewItem = () => {
    const { authConfig } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const { message, modal } = App.useApp()
    const {isSmallScreen} = useContext(GeneralContext)
    const width = isSmallScreen?{width:"100%"}:{width:"30%"}

    const [number, setNumber] = useState('');
    const [name, setName] = useState('');
    const [vendor, setVendor] = useState('');
    const [barcode, setBarcode] = useState('');
    const [memo, setMemo] = useState('');
    const [price, setPrice] = useState("");
    const [threshold, setThreshold] = useState("");
    const [type, setType] = useState('');
    const [unitOfMeasure, setUnitOfMeasure] = useState('');
    const [department, setDepartment] = useState('');
    const [amountOfAtomsInABox, setAmountOfAtomsInABox] = useState("");
    const [lotRequired, setLotRequired] = useState(true);
    const [expirationDateRequired, setExpirationDateRequired] = useState(true);
    const formNotReady = ([name, vendor, threshold, type, unitOfMeasure, department, amountOfAtomsInABox,number,].some(field => field === ""))

    const itemData = {
        name: name,
        vendor: vendor,
        barcode: barcode,
        memo: memo,
        price: price,
        threshold: threshold,
        type: type,
        unitOfMeasure: unitOfMeasure,
        department: department,
        amountOfAtomsInABox: amountOfAtomsInABox,
        lotRequired: lotRequired,
        expirationDateRequired: expirationDateRequired,
        number:number,
    }

    const onClear = () => {
        const resetStateToEmptyString = (setStateFunc) => {
            setStateFunc("");
        };

        resetStateToEmptyString(setName);
        resetStateToEmptyString(setVendor);
        resetStateToEmptyString(setBarcode);
        resetStateToEmptyString(setMemo);
        resetStateToEmptyString(setPrice);
        resetStateToEmptyString(setThreshold);
        resetStateToEmptyString(setType);
        resetStateToEmptyString(setUnitOfMeasure);
        resetStateToEmptyString(setDepartment);
        resetStateToEmptyString(setAmountOfAtomsInABox);
        resetStateToEmptyString(setNumber);
    };

    const submit = async () => {
        try {
            setLoading(true)
            await axioss.post('/items/items-old/', {...itemData }, authConfig)
            // console.log(response.data)
            message.success("New item was added successfully")
            onClear()
        } catch (error) {
            console.error(error)
            modal.warning({
                title: 'Error while adding the new item',
                content: error.message,
            });
        }
        finally {
            setLoading(false)
        }
    }

    const items = [
        {
            key: '0',
            label: 'Number',
            children: <Input placeholder='Number' value={number} onChange={(e) => setNumber(e.target.value)} />
        },
        {
            key: '1',
            label: 'Name',
            children: <Input placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} />
        },
        {
            key: '2',
            label: 'Vendor',
            children: <Input placeholder='Vendor' value={vendor} onChange={(e) => setVendor(e.target.value)} />
        },
        {
            key: '3',
            label: 'Type',
            children: <ItemTypeSelect selectedItemType={type} setSelectedItemType={setType} />
        },
        {
            key: '4',
            label: 'Unit of measure',
            children: <ItemUnitOfMeasureSelect selectedUnitOfMeasure={unitOfMeasure} setSelectedUnitOfMeasure={setUnitOfMeasure} />
        },
        {
            key: '5',
            label: 'Department',
            children: <DepartmentsSelector selectedDepartment={department} setSelectedDepartment={setDepartment} />
        },
        {
            key: '6',
            label: 'Price',
            children: <InputNumber style={{ width: 100 }} placeholder='Price' min={0} value={price} onChange={(value) => setPrice(value)} addonAfter="$" />

        },
        {
            key: '7',
            label: 'Barcode',
            children: <Input placeholder='Barcode' value={barcode} onChange={(e) => setBarcode(e.target.value)} />
        },
        {
            key: '8',
            label: 'Threshold',
            children: <Input placeholder='Threshold' allowClear value={threshold} onChange={(e) => { if (/\D/.test(e.target.value)) { return } else { setThreshold(e.target.value) } }} />
        },
        {
            key: '9',
            label: 'Amount of items in a box',
            children: <InputNumber style={{ width: '100%' }} placeholder='Amount of items in a box' min={0} value={amountOfAtomsInABox} onChange={(value) => setAmountOfAtomsInABox(value)} />

        },
        {
            key: '10',
            label: 'Memo',
            children: <Input placeholder='Memo' value={memo} onChange={(e) => setMemo(e.target.value)} />

        },
        {
            key: '11',
            children: <Checkbox checked={lotRequired} onChange={(e) => setLotRequired(e.target.checked)}>Is LOT required?</Checkbox>

        },
        {
            key: '12',
            children: <Checkbox checked={expirationDateRequired} onChange={(e) => setExpirationDateRequired(e.target.checked)} >Is expiration date required?</Checkbox>

        },
    ];

    return (
        <LoadingSpinLocal loading={loading}>

            <Space direction='vertical' style={width} size={"middle"}>
                <h2>Add a New Item</h2>
                
                <Descriptions items={items} column={1} bordered size='small'/>

                <br />

                <Space size={'large'}>
                    <Button type="primary" onClick={submit} disabled={formNotReady}> Submit </Button>
                    <Link to="/items" style={{ paddingTop: 8 }}>back</Link>
                </Space>

            </Space>

        </LoadingSpinLocal>
    )
}
export default AddNewItem;