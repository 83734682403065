import { React, useState, useContext, useEffect } from 'react'
import { Space, Button, Row, Col, InputNumber, DatePicker, Input, App, } from 'antd'
import { ItemsSearcher } from '../../Items/ItemsSearcher'
// import { ItemInformation } from '../../Items/ItemInformation'
import { GeneralContext } from '../../../components/GeneralContext/GeneralContext'
import { AfterSubmitModal } from './AfterSubmitModal'
import { WarehouseDropdown } from '../../Warehouses/WarehousesSelect'
import { AuthContext } from '../../../components/Auth/AuthContext'
import axioss from '../../../services/restAPI';
import { LoadingSpinLocal } from '../../../components/LoadingSpinLocal'
// import Locations from '../../Warehouses/Locations'
// import { QuestionCircleOutlined, } from '@ant-design/icons';
import RocheItemsQRreader from '../../Items/RocheItemsQRreader'
// import MyCollapse from '../../../components/Collapse'
import PossiblePOs from './PossiblePOs'
import ReceivingLocations from '../../Warehouses/Locations/ReceivingLocations'
import AddNonPO from './AddNonPO'
import TodaysStocks from '../../InStock/Components/TodaysStocks'
import MyCollapse from '../../../components/Collapse'

const AdaptiveReceiving = () => {

    const { authConfig } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const { message, modal } = App.useApp()
    const [warehouse, setWarehouse] = useState(1)
    const { isSmallScreen } = useContext(GeneralContext)
    const [selectedItem, setSelectedItem] = useState(null)
    const [amount, setAmount] = useState(null);
    const [lotNumber, setLotNumber] = useState(null)
    const [expirationDate, setExpirationDate] = useState(null);
    const smallScreenStyle = isSmallScreen ? ('MSSurfaceTablet') : null
    const [showAfterSubmitModal, setShowAfterSubmitModal] = useState(false)
    const [afterSubmitServerResponse, setAfterSubmitServerResponse] = useState(null)
    const [location, setLocation] = useState(null)
    const [itemNumber, setItemNumber] = useState(null)
    const [rerenderTrigger, setRerenderTrigger] = useState("")
    const [isRocheItemsQRreaderInUse, setIsRocheItemsQRreaderInUse] = useState(false)
    const [selectedPO, setSelectedPO] = useState(null)
    const [selectedPOnotReceivedItems, setSelectedPOnotReceivedItems] = useState(null)
    const [showAddNonPO, setShowAddNonPO] = useState(false)

    console.log(loading)
    const formFilledOut = selectedItem !== null &&
        amount !== null &&
        (selectedItem.expirationDateRequired ? expirationDate !== null : true) &&
        (selectedItem.lotRequired ? lotNumber !== null : true) &&
        location !== null &&
        selectedPO !== null &&
        selectedPOnotReceivedItems !== null

    const form = {
        selectedItem: selectedItem,
        setSelectedItem: setSelectedItem,

        amount: amount,
        setAmount: setAmount,

        lotNumber: lotNumber,
        setLotNumber: setLotNumber,

        expirationDate: expirationDate,
        setExpirationDate: setExpirationDate,

        warehouse: warehouse,
        setWarehouse: setWarehouse,

        location: location,
        setLocation: setLocation,

        afterSubmitServerResponse: afterSubmitServerResponse,
        setAfterSubmitServerResponse: setAfterSubmitServerResponse
    }


    const onSubmit = async () => {
        try {
            setLoading(true)
            const POid = selectedPO.purchase_order_id
            let items = []

            for (let i = 0; i < amount; i++) {
                const item = {
                    purchase_order_item_id: selectedPOnotReceivedItems[i].purchase_order_item_id,
                    item_id: selectedItem.id,
                    actual_item: selectedItem,
                    purchase_order_id: POid,
                    amount: 1,
                    lotNumber,
                    expirationDate,
                    newLocation: location
                }

                items.push(item)
            }

            const response = await axioss.post('/action/create-stocks-by-PO/', { items: items }, authConfig)
            setAfterSubmitServerResponse(response.data)
            message.success('Submitted successfully')
            setShowAfterSubmitModal(true)
            setRerenderTrigger(Date.now())
        } catch (error) {
            console.error(error.response.data)
            modal.warning({ title: "Error while submitting:", content: error.message })
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        //reset location if a warehouse was changed
        setLocation(null)
    }, [warehouse])

    useEffect(() => {
        setSelectedPO(null)
    }, [selectedItem])

    useEffect(() => {
        if (selectedPO) {
            setLoading(true);
            axioss.get('/po/get-po-items/', {
                ...authConfig,
                params: { POid: selectedPO.purchase_order_id }
            })
                .then((response) => {
                    setSelectedPOnotReceivedItems(response.data[0].purchase_items.filter((item) => {
                        return item.received === false && item.item_id === selectedItem.id;
                    }));
                    console.log('done');
                })
                .catch((error) => {
                    console.error("Error getting PO item ID:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPO]);




    return (
        <LoadingSpinLocal loading={loading}>

            <AddNonPO showAddNonPO={showAddNonPO} setShowAddNonPO={setShowAddNonPO} />

            <AfterSubmitModal key={`AfterSubmitModal ${rerenderTrigger}`} setRerenderTrigger={setRerenderTrigger} showAfterSubmitModal={showAfterSubmitModal} setShowAfterSubmitModal={setShowAfterSubmitModal} form={form} />

            <Row className={smallScreenStyle}>
                <Col xs={{ span: 23 }} lg={{ span: 11 }} >
                    <h2>Receiving <Button type='link' onClick={() => { setShowAddNonPO(true) }}>Add Non PO Item</Button></h2>

                    {/* <Space>
                                        <h2>Receive a Single Asset</h2>
                                        <Tooltip title="This section is designed for receiving a single / existing asset that is not related to a Purchase Order. ">
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </Space> */}

                    <Space direction="vertical" style={{ width: '100%' }} >
                        <Space>
                            <WarehouseDropdown warehouse={warehouse} setWarehouse={setWarehouse} />
                            {/* <Locations key={`RocheItemsQRreader ${rerenderTrigger} ${warehouse}`} selectedWarehouse={warehouse} location={location} setLocation={setLocation} /> */}
                            <ReceivingLocations key={`RocheItemsQRreader ${rerenderTrigger} ${warehouse}`} selectedWarehouse={warehouse} location={location} setLocation={setLocation} />
                        </Space>

                        <Space wrap>
                            <div style={isRocheItemsQRreaderInUse ? { pointerEvents: "none", opacity: "0.4" } : null}><ItemsSearcher setSelectedItem={setSelectedItem} selectedItem={selectedItem} predefinedItemNumber={itemNumber} key={`ItemsSearcher ${rerenderTrigger}`} /></div>
                            <>or</>
                            <RocheItemsQRreader isComponentInUse={isRocheItemsQRreaderInUse} setIsComponentInUse={setIsRocheItemsQRreaderInUse} setExpirationDate={setExpirationDate} setLotNumber={setLotNumber} setItemNumber={setItemNumber} key={`RocheItemsQRreader ${rerenderTrigger}`} />
                        </Space>

                        <Space >
                            <InputNumber min={1} value={amount} onChange={(value) => { setAmount(value) }} placeholder="Amount of Items" key={`InputAmount ${rerenderTrigger}`} />

                            {selectedItem?.lotRequired &&
                                <Input value={lotNumber} onChange={(e) => { setLotNumber(e.target.value) }} allowClear placeholder="Lot Number" key={`InputLotNumber ${rerenderTrigger}`} />
                            }

                            {selectedItem?.expirationDateRequired &&
                                <DatePicker value={expirationDate} placeholder="Expiration Date" onChange={(dateString) => { setExpirationDate(dateString) }} disabledDate={(current) => current && current.valueOf() < Date.now()} key={`ExpirationDate ${rerenderTrigger}`} />
                            }
                        </Space>

                        <Button disabled={!formFilledOut || loading} type='primary' onClick={onSubmit}>Submit</Button>

                    </Space>
                </Col>
                <Col lg={{ span: 1 }} />
                <Col xs={{ span: 23 }} lg={{ span: 11 }} >
                    {selectedItem && (
                        <>
                            {/* <MyCollapse child={<ItemInformation selectedItem={selectedItem} />} title={'Item information'} collapsed  /> */}
                            <h3>Possible PO</h3>
                            <PossiblePOs selectedPO={selectedPO} setSelectedPO={setSelectedPO} selectedItem={selectedItem} />
                        </>
                    )}




                </Col>

            </Row>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <MyCollapse title='Recently Received' child={
                <LoadingSpinLocal loading={loading}>
                    <TodaysStocks key={`TodaysStocks ${rerenderTrigger}`} />
                </LoadingSpinLocal>
            } />

        </LoadingSpinLocal>
    );

}

export default AdaptiveReceiving