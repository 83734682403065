import { Tabs } from "antd"
import LABELtimestampBarcode from './LABELtimestampBarcode'
import LABELcustomBarcode from "./LABELcustomBarcode"
import LABELplainText from "./LABELplainText"

const PrintZebraLabel = (props) => {
    const {hideCustomBarcode, hideTimestampsBarcode} = props
    return (
        <Tabs defaultActiveKey="1" items={[
            {
                key: '1',
                label: 'Plain Text',
                children: <LABELplainText />,
                disabled: hideCustomBarcode
            },
            {
                key: '2',
                label: 'Custom Barcode',
                children: <LABELcustomBarcode />,
                disabled: hideCustomBarcode
            },
            {
                key: '3',
                label: 'Timestamp Barcodes',
                children: <LABELtimestampBarcode />,
                disabled: hideTimestampsBarcode
            },
        ]} />
    )
}
export default PrintZebraLabel