import React from "react";
import { Outlet } from "react-router-dom";
import Navigation from "./Navigation";
import InventoryLayout from "./components/PageLayout/InventoryLayout";
const AppLayout = () => {
  return (
    <>
      <Navigation />
      <InventoryLayout>
        <Outlet />
      </InventoryLayout>
    </>
  );
};

export default AppLayout;
