import {React} from 'react'
import {Input, Collapse,Space, Button} from 'antd'
import {DepartmentsSelector} from '../../../components/Departments'
import {ItemTypeSelect} from '../ItemTypeSelect'
const ItemsSearchBars = (props)=>{
    const { Panel } = Collapse;
    const {searchFilters} = props
    const onClear = () => {
      searchFilters.setSearchByName("")
      searchFilters.setSearchByVendor("")
      searchFilters.setSearchByDepartment("")
      searchFilters.setSearchByType("")
      searchFilters.setSearchByNumber("")
    }

    return(
          <Collapse defaultActiveKey={["1"]} bordered={false}>
            <Panel header="Search" key="1">
              <Space size="large" wrap>
                <Input placeholder='Search by Number' value={searchFilters.searchByNumber} onChange={(e) => {searchFilters.setSearchByNumber(e.target.value)}} allowClear/>
                <Input placeholder='Search by Name' value={searchFilters.searchByName} onChange={(e) => {searchFilters.setSearchByName(e.target.value)}} allowClear/>
                <Input placeholder='Search by Vendor' value={searchFilters.searchByVendor} onChange={(e) => {searchFilters.setSearchByVendor(e.target.value)}} allowClear/>
                <DepartmentsSelector selectedDepartment={searchFilters.searchByDepartment} setSelectedDepartment={searchFilters.setSearchByDepartment}/>
                <ItemTypeSelect selectedItemType={searchFilters.searchByType} setSelectedItemType={searchFilters.setSearchByType}/>
                <Button type="link" onClick={onClear}>clear</Button>
             </Space>
            </Panel>
          </Collapse>
    )
}

export {ItemsSearchBars}

