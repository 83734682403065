
import React from 'react';
import { Table, } from 'antd';
import { PrintLabel } from './PrintLabel';

const SubmittedItems = (props) => {
    const { selectedItems, barcodes } = props
    const data = [...selectedItems].map((item) => {
        item.barcode = [...barcodes].filter(barcode => barcode.item_id === item.item_id)[0].barcode

        item.barcodes = [...barcodes].filter(barcode => barcode.item_id === item.item_id)

        return item
    })

    // console.log("data", data)


    const columns = [
        {
            title: 'ID',
            dataIndex: 'item_id',
            key: 'item_id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {return record.actual_item.name},
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Label',
            key: 'action',
            render: (_, record) => (<PrintLabel record={record}/>),
        },
    ];
    return (
        <Table
            columns={columns}
            dataSource={data}
            pagination={false}
        />
    )
}

export { SubmittedItems }


/* barcodes
[
    {
        "item_id": 1,
        "barcode": 1689335683526
    },
    {
        "item_id": 2,
        "barcode": 1689335683527
    }
]
*/

/* selectedItems
[
    {
        "purchase_order_item_id": 1,
        "purchase_order_id": 2,
        "item_id": 1,
        "quantity_purchased": 10,
        "purchase_price": "50.00",
        "key": 1,
        "lotNumber": "2",
        "amount": "2",
        "expirationDate": "2023-07-21",
        "warehouse": 1,
        "section": {
            "value": 1,
            "label": "Section 1",
            "section": {
                "id": 1,
                "warehouseId": 1,
                "name": "Section 1",
                "department": "Department 1",
                "description": "This is a section in warehouse",
                "created": "2023-06-09T23:08:50.023Z",
                "updated": "2023-06-09T23:08:50.023Z"
            }
        }
    },
    {
        "purchase_order_item_id": 2,
        "purchase_order_id": 2,
        "item_id": 2,
        "quantity_purchased": 5,
        "purchase_price": "100.00",
        "key": 2,
        "lotNumber": "2",
        "amount": "2",
        "expirationDate": "2023-07-21",
        "warehouse": 1,
        "section": {
            "value": 1,
            "label": "Section 1",
            "section": {
                "id": 1,
                "warehouseId": 1,
                "name": "Section 1",
                "department": "Department 1",
                "description": "This is a section in warehouse",
                "created": "2023-06-09T23:08:50.023Z",
                "updated": "2023-06-09T23:08:50.023Z"
            }
        }
    }
]
*/