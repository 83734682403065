import { React} from "react";
import { WarehousesTable } from "./WarehousesTable";
import { Space } from "antd";
import { Link } from "react-router-dom";
import { WarehouseContextProvider } from "./WarehouseContext";

//the purpose of this component is to wrap the actual logic in the WarehouseContext

const Warehouses = () => {
  return (
    <>
        <WarehouseContextProvider>
          <Space direction='horizontal' size='large' >
            <h2>Warehouses</h2>
            <Link to="/warehouses/add-new-warehouse" style={{ paddingTop: 8 }}>Add a New Warehouse</Link>
          </Space>
          <WarehousesTable />
        </WarehouseContextProvider>
    </>
  );
};

export { Warehouses };
