import {  App, Tag, Select } from "antd";
import {useContext} from 'react';
import axioss from '../../services/restAPI';
import { AuthContext } from "../../components/Auth/AuthContext";


const POStatuses = (props) => {
    const { record, setLoading } = props

    const { Option } = Select;
    const { authConfig } = useContext(AuthContext)
    const { modal, message } = App.useApp()

    const handleChange = (value) => {
        submitNewStatus(value);
    };
    

    const submitNewStatus = async (newStatus) => {
        try {
            setLoading(true);
            const id = record.purchase_order_id;
            await axioss.put(`/po/${id}/`, { status: newStatus }, authConfig);
            message.success("Status has been updated successfully");
        } catch (error) {
            console.error(error);
            modal.warning({
                title: 'Error while updating status',
                content: error.message,
            });
        } finally {
            setLoading(false);
        }
    };


    return (
        <Select bordered={false} defaultValue={record.status} onChange={handleChange}>
            <Option value="pending"><Tag style={{ letterSpacing : '1px'}}color="volcano">Pending</Tag></Option>
            <Option value="pre order"><Tag color="cyan">In Process</Tag></Option>
            <Option value="completed"><Tag color="green">Completed</Tag></Option>
        </Select>
    );
}

export default POStatuses