import React from 'react';
import { Collapse, Space, Button } from 'antd';
import LabelRePrint from '../../components/LabelRePrint'

const DocRender = (props) => {
  const {src} = props
  return (
    <iframe
      title={"FAQ"}
      src={src}
      style={{
        width: '100%',
        height: 600,
        border: '0',
      }}
    ></iframe>
  )
}

const GettingReady = () => {
  const DownloadButton = () => {
    const handleDownload = () => {
      // Replace with your Google Drive shareable link
      const googleDriveLink = 'https://drive.google.com/file/d/1rK4vIIhR_F13VQSgYrF6H1GC3AF2zxx-/view?usp=drive_link';
      window.open(googleDriveLink, '_blank');
    };
  
    return (
      <Button type="primary" onClick={handleDownload}>
        Download
      </Button>
    );
  };

  return(
   <Space direction={'vertical'} size={'large'}>
    <div><b>The Zebra Label Printer driver is necessary only for Windows computers; it's not needed on MacOS/Linux systems.</b></div>
    <Space>1. Download Zebra Driver <DownloadButton/></Space>
    <Space>2. List all devices; Select a ZTE device; Replace driver</Space>
    <Space>3. Test Zebra Printer<LabelRePrint barcodes={['12345']} itemName={'Test Label'} lotNumber={'N/A'} expirationDate={'N/A'}/></Space>
   </Space>
  )
}

const items = [
  {
    key: '1',
    label: 'Receive a non-PO / Existing Asset',
    children: <DocRender src = {"https://docs.google.com/document/d/1SUk2NcZo8_kTPU8aaeiEpTejzQoliVs-NO4ItlgHUPs/edit?usp=sharing"}/>,

  },
  {
    key: '2',
    label: 'Receiving PO assets',
    children: <DocRender src = {"https://docs.google.com/document/d/1GFYJF_kWTEBKarfCGJrDLKAarGz3S_wF1h7Cr84xDok/edit?usp=sharing"}/>,
  },
  {
    key: '3',
    label: 'Assets distributing (locations changing)',
    children: <DocRender src = {"https://docs.google.com/document/d/1vTvXe4VeYEs-cehrqj-rbyO4L2rCL3S3fqmbldciKjQ/edit?usp=sharing"}/>,
  },
  {
    key: '4',
    label: 'Asset Withdrawal',
    children: <DocRender src = {"https://docs.google.com/document/d/1BPJKRO-IgLnNiALlJP-XNIP92UDX668aSsBHGVChOBs/edit?usp=sharing"}/>,
  },
  {
    key: '5',
    label: 'Getting Inventory on a Computer',
    children: <GettingReady/>,
  },
];

const FAQ = () => {
    const onChange = (key) => {
        console.log(key);
      };

  return(
    <div style={{padding:'20px'}}>
      <h1>How To..</h1>
      <Collapse items={items} defaultActiveKey={['0']} onChange={onChange} />
    </div>
  )
}

export default FAQ