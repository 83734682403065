import { Collapse, Row, Col, } from "antd";
import { PurchasedItems } from "./PurchasedItems";
// import POstocksReceivingLog from "./POstocksReceivingLog";

export function POrow(props) {
  const {record} = props;
  const {Panel} = Collapse

  return (
    <div className="rowStyle">
      <Row>
        <Col xs={{ span: 23 }} lg={{ span: 11 }}>
          <Collapse defaultActiveKey={["1"]} bordered={false}>
            <Panel header="Purchased items" key="1">
              {/* {dataToExport.length>0&&(
                < JsonToCSV data={dataToExport} />
              )} */}
              <PurchasedItems record={record} />
            </Panel>
          </Collapse>
        </Col>
        <Col span={1} />
        <Col xs={{ span: 23 }} lg={{ span: 11 }}>
          <Collapse defaultActiveKey={["0"]} bordered={false}>
            <Panel header="Receiving log" key="0">
              <>coming soon</>
              {/* <POstocksReceivingLog record={record}/> */}
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </div>
  )
}



