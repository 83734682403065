import { Select } from 'antd';
import { useState, useEffect, useContext } from 'react';
import axios from '../../../services/restAPI';
import { AuthContext } from '../../../components/Auth/AuthContext';
import { App } from 'antd';
import { LoadingSpinLocal } from '../../../components/LoadingSpinLocal';

const VendorsSelect = ({ selectedVendor, setSelectedVendor }) => {
    const [loading, setLoading] = useState(false);
    const { authConfig } = useContext(AuthContext);
    const { modal } = App.useApp();
    const [vendors, setVendors] = useState([]);

    useEffect(() => {
        if (vendors.length === 0) {
            fetchVendors();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (selectedVendor && isNaN(selectedVendor) && vendors.length > 0) {
            const vendor = vendors.find(v => v.name === selectedVendor);
            if (vendor) {
                setSelectedVendor(vendor.name);
            }
        }
        // eslint-disable-next-line
    }, [selectedVendor, vendors]);

    const fetchVendors = async () => {
        try {
            setLoading(true);
            const response = await axios.get("/vendors/", authConfig);
            setVendors(response.data);
        } catch (err) {
            console.error(err);
            modal.warning({
                title: 'Error while loading vendors',
                content: err.message,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSelect = (value) => {
        setSelectedVendor(value);
    };

    const vendorsOptions = vendors.map(vendor => ({
        value: vendor.name,
        label: vendor.name,
    }));

    return (
        <LoadingSpinLocal loading={loading}>
            <Select
                value={selectedVendor || null}
                placeholder="Select vendor"
                options={vendorsOptions}
                onSelect={handleSelect}
                showSearch
                filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: 300 }}
            />
        </LoadingSpinLocal>
    );
};

export default VendorsSelect;
