import { useState, useEffect } from 'react'
import { InputNumber, Space, Input } from "antd"
import Processor from './Processor'
import generateBarcode from './utilites/generateBarcode';
import MyCollapse from '../Collapse'

const LABELcustomBarcode = () => {
    const [quantity, setQuantity] = useState(0)
    const [barcodeValue, setBarcodeValue] = useState("")
    const [labels, setLabels] = useState([])

    const [barcodeHeight, setBarcodeHeight] = useState(70) 
    const [barcodePaddingLeft, setBarcodePaddingLeft] = useState(15) 
    const [barcodePaddingTop, setBarcodePaddingTop] = useState(30)
    const [barcodeFontSize, setBarcodeFontSize] = useState(30)
    

    const generateBarcodes = () => {
        const newLabels = []
        for (let index = 0; index < quantity; index++) {
            const label = generateBarcode(barcodeValue, barcodeHeight, barcodePaddingLeft, barcodePaddingTop, barcodeFontSize)
            newLabels.push(label)
        }
        setLabels(newLabels)
    }

    useEffect(() => {
        generateBarcodes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [barcodeValue, quantity,barcodeHeight, barcodePaddingLeft, barcodePaddingTop, barcodeFontSize])


    return (
        <Space direction='vertical'>
            <Input placeholder="Barcode Value"value={barcodeValue} onChange={(e) => {setBarcodeValue(e.target.value)}}/>
            <Space>
                Quantity of labels:
                <InputNumber min={1} value={quantity} onChange={(value) => { setQuantity(value) }} style={{width:'50px'}} />
                {quantity>0&&( <Processor labels={labels}/> )}
            </Space>

            <MyCollapse title='Label settings' collapsed child={
                <Space direction='vertical'>
                    <div>Barcode Height: <InputNumber value={barcodeHeight} onChange={(value) => { setBarcodeHeight(value) }} style={{width:'70px'}} /></div>
                    <div>Barcode Padding Left: <InputNumber value={barcodePaddingLeft} onChange={(value) => { setBarcodePaddingLeft(value) }} style={{width:'70px'}} /></div>
                    <div>Barcode Padding Top: <InputNumber value={barcodePaddingTop} onChange={(value) => { setBarcodePaddingTop(value) }} style={{width:'70px'}} /></div>
                    <div>Font Size: <InputNumber value={barcodeFontSize} onChange={(value) => { setBarcodeFontSize(value) }} style={{width:'70px'}} /></div>
                </Space>
            }/>
            
        </Space>
    )
}

export default LABELcustomBarcode
