import { Table } from 'antd';
import LocationTypeFormatted from '../../Warehouses/Locations/LocationTypeFormatted';

const TableAdjustment = (props) => {
    const { itemBarcodes, selectedRowKeys, setSelectedRowKeys } = props;

    // console.log('selectedRowKeys', selectedRowKeys);

    let dataSource = itemBarcodes.map((item, index) => ({
        ...item,
        key: index,
        location: {
            type: item.location_type,
            department: item.location_department,
            number: item.location_number
        }
    }));

    dataSource = dataSource.sort((a, b) => a.location_id - b.location_id);

    const uniqueLocationDepartments = [...new Set(dataSource.map(item => item.location_department))]
        .map(department => ({ text: department, value: department }));
    const uniqueLocationTypes = [...new Set(dataSource.map(item => item.location_type))]
        .map(type => ({ text: type, value: type }));
    const uniqueLocationNumbers = [...new Set(dataSource.map(item => item.location_number))]
        .map(number => ({ text: number, value: number }));

    const onSelectChange = (newSelectedRowKeys) => {
        const newSelectedKeys = newSelectedRowKeys.map(key => ({
            index: key,
            value: itemBarcodes[key]
        }));
        setSelectedRowKeys(newSelectedKeys);

        // const newSelectedBarcodes = newSelectedKeys.map(keyInfo => keyInfo.value);
        // setSelectedBarcodes(newSelectedBarcodes);
    };

    const rowSelection = {
        selectedRowKeys: selectedRowKeys.map(item => item.index),
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
        {
            title: 'Barcode',
            dataIndex: 'barcode',
            key: 'barcode',
        },
        {
            title: "Location",
            children: [
                {
                    title: 'Department',
                    dataIndex: 'location_department',
                    key: 'location_department',
                    filters: uniqueLocationDepartments,
                    onFilter: (value, record) => record.location_department.indexOf(value) === 0,
                },
                {
                    title: 'Location Type',
                    dataIndex: 'location_type',
                    key: 'location_type',
                    render: (_) => (<LocationTypeFormatted type={_} />),
                    filters: uniqueLocationTypes,
                    onFilter: (value, record) => record.location_type.indexOf(value) === 0,
                },
                {
                    title: 'Location Number',
                    dataIndex: 'location_number',
                    key: 'location_number',
                    filters: uniqueLocationNumbers,
                    onFilter: (value, record) => record.location_number.indexOf(value) === 0,
                },
            ]
        }
    ];

    return (
        <div>
            <Table
                size='small'
                className='scrolledBox'
                rowSelection={rowSelection}
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                bordered
            />
            <div style={{ marginBottom: 16 }}>
                <span style={{ marginLeft: 8 }}>
                    {hasSelected ? `Selected ${selectedRowKeys.length} barcodes` : ''}
                </span>
            </div>
        </div>
    );
}

export default TableAdjustment;
