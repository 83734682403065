import { React, useState, useContext } from "react";
import { Descriptions, Input, Space, InputNumber, Button,App } from "antd";
import { USAstates } from "../../../../../components/USAstates";
import { LoadingSpinLocal } from "../../../../../components/LoadingSpinLocal";
import { AuthContext } from "../../../../../components/Auth/AuthContext";
import { WarehouseContext } from "../../../WarehouseContext";
import axioss from '../../../../../services/restAPI';


const WarehouseInformation = (props) => {
  const {setReloadParent} = useContext(WarehouseContext)
  const {authConfig} = useContext(AuthContext)
  const {message, modal} =  App.useApp()
  const { record, editMode, setEditMode } = props;
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState(record.name);
  const [phone, setPhone] = useState(record.phone);
  const [email, setEmail] = useState(record.email);
  const [address, setAddress] = useState(record.address);
  const [city, setCity] = useState(record.city);
  const [contactPerson, setContactPerson] = useState(record.contactPerson);
  // const [country, setCountry] = useState('');
  const [fax, setFax] = useState(record.fax);
  const [memo, setMemo] = useState(record.memo);
  const [state, setState] = useState(record.state);
  const [zip, setZip] = useState(record.zip);

  const submit = async () => {
    try {
      setLoading(true)
      await axioss.put(`/warehouse/${record.id}/`, {
        name,
        phone,
        email,
        address,
        city,
        contactPerson,
        // country,
        fax,
        isDeleted: false,
        memo,
        state,
        zip,
      },
        authConfig
      )
      // console.log(response.data)
      message.success("Record updated successfully")
      setReloadParent(true)
    } catch (error) {
      console.error(error)
      modal.warning({
        title: 'Something went wrong',
        content: error.message,
      });
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <LoadingSpinLocal loading={loading}>
      <Descriptions bordered size="small" layout="horizontal" column={1}>


        <Descriptions.Item label="ID">
          {record.id}
        </Descriptions.Item>


        <Descriptions.Item label="Name">
          {editMode ? (<Input placeholder="Name" value={name} onChange={e => setName(e.target.value)} />)
            : (record.name)}
        </Descriptions.Item>


        <Descriptions.Item label="Phone">
          {editMode ? (
            <Input placeholder="Phone" value={phone} onChange={e => setPhone(e.target.value)} />
          ) : (record.phone)}
        </Descriptions.Item>


        <Descriptions.Item label="Fax">
          {editMode ? (
            <Input placeholder="Fax" value={fax} onChange={e => setFax(e.target.value)} />
          ) : (record.fax)}
        </Descriptions.Item>


        <Descriptions.Item label="Contact Person">
          {editMode ? (
            <Input placeholder="Contact Person" value={contactPerson} onChange={e => setContactPerson(e.target.value)} />
          ) : (record.contactPerson)}
        </Descriptions.Item>


        <Descriptions.Item label="Email">
          {editMode ? (
            <Input placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
          ) : (record.email)}
        </Descriptions.Item>


        <Descriptions.Item label="Address">
          {editMode ? (
            <Space>
              <Input placeholder="Address" value={address} onChange={e => setAddress(e.target.value)} />
              <Input placeholder="City" value={city} onChange={e => setCity(e.target.value)} />
              <USAstates setState={setState} />
              <InputNumber value={zip} onChange={(value) => { setZip(value) }} placeholder="ZIP code" style={{ width: '100%' }} />
            </Space>
          ) : <>{record.address}, {record.city}, {record.state}, {record.zip}</>}
        </Descriptions.Item>


        <Descriptions.Item label="Memo">
          {editMode ? (
            <Input placeholder="Memo" value={memo} onChange={e => setMemo(e.target.value)} />
          ) : (record.memo)}
        </Descriptions.Item>

        <Descriptions.Item >
          {editMode ? (
            <Space >
              <Button type='primary' onClick={submit}>Submit</Button>
              <Button onClick={() => { setEditMode(false) }}>Cancel</Button>
            </Space>
          ) : null}
        </Descriptions.Item>

      </Descriptions>
    </LoadingSpinLocal>
  );
};

export { WarehouseInformation };
