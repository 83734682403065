import { useState } from 'react'
import { Space, InputNumber, Button, Row, Col, App } from 'antd'
import { Link } from 'react-router-dom'
import { ItemsSearcher } from '../../Items/ItemsSearcher'
import { ItemInformation } from '../../Items/ItemInformation'
import { POCart } from './POCart'


const CreateNewPO = () => {
    const [selectedItem, setSelectedItem] = useState(null)
    const [itemCost, setItemCost] = useState(null)
    const [amountOfItems, setAmountOfItems] = useState(null)
    const [itemsInTheCart, setItemsInTheCart] = useState([])
    const { message } = App.useApp()


    const formIsNotCompleted = selectedItem === null || amountOfItems === null 


    const onAddItemClick = () => {
        const tempArr = [...itemsInTheCart]

        if (tempArr.some(item => item.id === selectedItem.id)) {
            message.error('This item is already in the cart')
            return
        }

        // if (tempArr.some(item => selectedItem.department !== tempArr[0].selectedItem.department)){
        //     message.error("This Item belongs to a different departments")
        //     return
        // }

        tempArr.push({
            id: selectedItem.id,
            name: selectedItem.name,
            amountOfItems,
            itemCost,
            selectedItem
        })

        setAmountOfItems(null)
        setSelectedItem(null)
        setItemCost(null)
        setItemsInTheCart(tempArr)
        message.success('Added')
    }


    return (

        <Row>
            <Col xs={{ span: 23 }} lg={{ span: 11 }}>
                <POCart itemsInTheCart={itemsInTheCart} setItemsInTheCart={setItemsInTheCart} />
            </Col>

            <Col span={1} />

            <Col xs={{ span: 23 }} lg={{ span: 11 }}>

                <Space direction='vertical'>
                    <Space direction='horizontal' size='large' >
                        <h2>Create new PO</h2>
                        <Link to="/PO" style={{ paddingTop: 8 }}>back</Link>
                    </Space>

                    <ItemsSearcher selectedItem={selectedItem} setSelectedItem={setSelectedItem} />

                    {selectedItem !== null && (
                        <Space direction='vertical'>
                            <ItemInformation selectedItem={selectedItem} />
                            <Space space='large'>
                                Item cost:
                                <InputNumber min={0.01} value={itemCost} onChange={(value) => { setItemCost(value) }} placeholder="Item cost" prefix=" $ " />
                                Amount of items:
                                <InputNumber min={1} value={amountOfItems} onChange={(value) => { setAmountOfItems(value) }} placeholder="Amount of items" />
                                <Button type='primary' onClick={onAddItemClick} disabled={formIsNotCompleted}>Add</Button>
                            </Space>
                        </Space>
                    )}
                </Space>

            </Col>
        </Row>
    )
}

export { CreateNewPO }