import { useState, useEffect, useContext } from "react"; // Use useEffect instead of useState here
import axioss from '../../../services/restAPI';
import { AuthContext } from "../../../components/Auth/AuthContext";
import { LoadingSpinLocal } from "../../../components/LoadingSpinLocal";
import buildQueryParams from "../../../services/utilites/buildQueryParams";
import { ItemsSearcher } from "../../Items/ItemsSearcher";
import { Space, Button, App } from "antd";

const GetBarcodes = (props) => {
    const [loading, setLoading] = useState(false);
    const { authConfig } = useContext(AuthContext);
    const { setData } = props;
    const [selectedItem, setSelectedItem] = useState(null);
    const [itemName, setItemName] = useState(null)
    const {message}= App.useApp()


    const getData = async () => {
        try {
            setLoading(true);

            const params = {
                searchByItemNumber: selectedItem.item_number,
            };
            const url = `/in-stock?${buildQueryParams(params)}`;

            const response = await axioss.get(url, authConfig);

            console.log(response)

            message.success(`${response.data.length} barcodes found`)

            setData(response.data);
            setItemName(`${response.data[0].actual_item[0].item_number} | ${response.data[0].actual_item[0].name} | ${response.data.length} barcodes`);
        } catch (err) {
            console.log(err);
            if (err.response && err.response.status === 403) {
                alert("access forbidden");
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => { // Corrected to useEffect
        if (selectedItem !== null) {
            getData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem]); 

    return (
        <LoadingSpinLocal loading={loading}>
            {itemName?
            (
                <Space>
                    <h3>{itemName} </h3>
                    <Button type="text" onClick={() => {setItemName(null); setData([])}}>change</Button>
                </Space>
            ):(
                <ItemsSearcher selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
            )}
        </LoadingSpinLocal>
    );
};

export default GetBarcodes;
