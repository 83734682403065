import { Table } from "antd";
const InventoryUpdates = () => {
    let dataSource = [
        {
            key: '1',
            date: '2023-12-11',
            page: 'transactions-stock',
            event: 'Stock Logs page added. The page represents all the changes made on assets: receiving, withdrawing, distributing.',
        },
        {
            key: '2',
            date: '2023-12-11',
            page: 'products-distributing',
            event: 'Multiply location option added. Now a specific location can be applied to the all scanned products.',
        },
        {
            key: '3',
            date: '2023-12-11',
            page: 'in-stock',
            event: 'Search by Product number option added',
        },
        {
            key: '4',
            date: '2023-12-12',
            page: 'backend',
            event: 'Stock transactions errors tracking system implemented',
        },
        {
            key: '5',
            date: '2023-12-12',
            page: 'po',
            event: 'PO Statuses option added.',
        },
        {
            key: '6',
            date: '2023-12-12',
            page: 'po',
            event: 'PO Notes option added.',
        },
        {
            key: '7',
            date: '2023-12-12',
            page: 'home',
            event: 'Low in Stock widget has been updated',
        },
        {
            key: '8',
            date: '2023-12-14',
            page: 'in-stock',
            event: 'A product locations added to the In Stock page. Now all the locations of a product are easily seen',
        },
        {
            key: '9',
            date: '2023-12-14',
            page: 'transactions-stock',
            event: 'Searching filters added',
        },
        {
            key: '10',
            date: '2023-12-14',
            page: 'transactions-stock',
            event: 'Exporting of CSV reports added',
        },
        {
            key: '11',
            date: '2023-12-18',
            page: 'transactions-stock',
            event: 'Locations log option added',
        },
        {
            key: '12',
            date: '2023-12-18',
            page: 'in-stock',
            event: 'In Stock search and loading improved',
        },
        {
            key: '13',
            date: '2023-12-18',
            page: 'receive-by-PO',
            event: 'Roche QR code reader added to receive PO items',
        },
        {
            key: '14',
            date: '2023-12-19',
            page: 'transactions-stock',
            event: 'Transactions can be now grouped by Item',
        },
        {
            key: '15',
            date: '2023-12-19',
            page: 'transactions-stock',
            event: 'Improved CSV exporting',
        },
        {
            key: '16',
            date: '2023-12-19',
            page: 'transactions-stock',
            event: 'Barcodes log added',
        },
        {
            key: '17',
            date: '2023-12-19',
            page: 'transactions-stock',
            event: 'Search by Barcode added',
        },
        {
            key: '18',
            date: '2023-12-20',
            page: 'reports/stock/items-usage',
            event: 'New report added',
        },
        {
            key: '19',
            date: '2024-01-05',
            page: 'items',
            event: 'Search by item number added',
        },
        {
            key: '20',
            date: '2024-01-08',
            page: 'items',
            event: '980 new items added',
        },
        {
            key: '21',
            date: '2024-01-11',
            page: 'items',
            event: 'Search by vendor added',
        },
        {
            key: '22',
            date: '2024-01-11',
            page: 'about/how to',
            event: 'Interactive Getting Ready section added',
        },
        {
            key: '23',
            date: '2024-01-11',
            page: 'general',
            event: 'basic access levels system created',
        },
        {
            key: '24',
            date: '2024-01-12',
            page: '/items/add-new',
            event: 'Memo, barcode and a price are not a requirement anymore',
        },
        {
            key: '25',
            date: '2024-01-12',
            page: 'general',
            event: 'Management data logging developed',
        },
        {
            key: '26',
            date: '2024-01-12',
            page: 'general',
            event: 'Creating and editing items are logged',
        },
        {
            key: '27',
            date: '2024-01-15',
            page: '/transactions-management-data',
            event: 'Management logs page created',
        },
        {
            key: '28',
            date: '2024-01-15',
            page: '/transactions-management-data',
            event: 'users changes are now monitored by the logs',
        },
        {
            key: '29',
            date: '2024-01-19',
            page: '/home',
            event: 'Expires soon: Receiving and expiration dates added',
        },
        {
            key: '31',
            date: '2024-01-19',
            page: '/home',
            event: 'Expires soon: Item number added',
        },
        {
            key: '32',
            date: '2024-01-23',
            page: '/asset-discarding/',
            event: 'New section added. This page allows discard spoiled, expired, etc items',
        },
        {
            key: '33',
            date: '2024-01-23',
            page: '/reports/stock/items-usage',
            event: 'More searching filters added',
        },
        {
            key: '44',
            date: '2024-01-25',
            page: '/home',
            event: 'Hide expired products option added',
        },
        {
            key: '45',
            date: '2024-01-26',
            page: '/home',
            event: 'Shelf life option added',
        },
        {
            key: '46',
            date: '2024-01-29',
            page: '/PO',
            event: 'Creating PO now updates its status automatically (sets to Pending)',
        },
        {
            key: '46',
            date: '2024-01-29',
            page: '/PO',
            event: 'Purchased items of a PO have vendors now',
        },
        {
            key: '47',
            date: '2024-01-30',
            page: '/PO',
            event: 'Reworked interface',
        },
        {
            key: '47',
            date: '2024-01-30',
            page: '/PO',
            event: 'Exporting purchased items option',
        },
        {
            key: '48',
            date: '2024-02-27',
            page: '/asset-adjustment/',
            event: 'A mechanism for manually removing the products was built.',
        },
        {
            key: '49',
            date: '2024-02-27',
            page: 'general',
            event: 'Item searcher: now searches among names and numbers, not only numbers',
        },
        {
            key: '50',
            date: '2024-03-01',
            page: '/transactions-stock/',
            event: 'Users were updated: now all the users have departments. Stock logs can be now filtered by users departments',
        },
        {
            key: '51',
            date: '2024-03-22',
            page: '/in-stock',
            event: 'Items are now grouped by the receiving date',
        },
        {
            key: '52',
            date: '2024-04-23',
            page: '/in-stock',
            event: 'Exporting to CSV added',
        },
        {
            key: '53',
            date: '2024-04-30',
            page: '/asset-adjustment/',
            event: 'Uncheck a barcode feature added',
        },
        {
            key: '54',
            date: '2024-05-13',
            page: '/reports/stock/items-usage',
            event: 'Amount of items ordered column added',
        },
        {
            key: '55',
            date: '2024-05-14',
            page: '/PO',
            event: 'Adding new items to an existing PO added',
        },
        {
            key: '54',
            date: '2024-05-14',
            page: '/PO',
            event: 'Search by PO notes added',
        },
    ];

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '150px'
        },
        {
            title: 'Page',
            dataIndex: 'page',
            key: 'page',
            width: '300px'
        },
        {
            title: 'Event',
            dataIndex: 'event',
            key: 'event',
        },
    ];

    dataSource = dataSource.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      });

    return (
        <Table dataSource={dataSource} columns={columns} size="small" pagination={{ defaultPageSize: 1000, showSizeChanger: true, pageSizeOptions: ['10', '50','100', '1000']}}        />
    )
}

export default InventoryUpdates