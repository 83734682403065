import  { React } from "react";
import { Table } from "antd";

import {TableRow} from "./row"

export function OrdersTable(props) {
    const dataSource = [...props.dataSource]

    dataSource.forEach((item)=>{
        item.key = item.id;
        return item
    })

    const columns = [
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
          },
        {
          title: 'REF',
          dataIndex: 'ref',
          key: 'ref',
        },
        
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
     
    ];

    
    // const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    return (
        <>
            <div className="scrolledBox">
                <Table
                    columns={columns}
                    expandRowByClick={true}
                    expandable={{ expandedRowRender: (record) => (<div style={{ margin: 0, }} > <TableRow record={record} customComponent={props.customComponent}/> </div>), rowExpandable: (record) => record.name !== 'Not Expandable', }}
                    dataSource={dataSource}
                    pagination={false}
                    scroll={{ x: "100%"}}
                />
            </div>
        </>
    );
}
/*
Order Date	Department	PO #	Ref #	Company	Title	Quantity	Unit of Measure	Status
*/

/*
{
      id: "58a364e22df6083edf9e2cf6",
      item: "58a35c7e2df6083edf9e2cf1",
      isDeleted: false,
      department: "Molecular",
      trackingInformation: null,
      orderDate: "2017-02-14T20:13:22.412Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: null,
      receivedDate: "2017-01-24T05:00:00.000Z",
      testedDate: "2017-02-03T05:00:00.000Z",
      testResult: "PASS",
      testMethod: "Lot to Lot",
      location: null,
      quantity: 0,
      expirationDate: "2017-11-15T05:00:00.000Z",
      lot: "179470AH",
      barcode: "011542004550547610179470AH17171115",
      orderQuantity: 16,
      company: "Hologic",
      title: "Aptima Trichomonas vaginalis 100",
      ref: "303065",
      price: null,
    },
*/
