import { Space } from 'antd';
import { useState, useEffect, React, useContext } from "react";
import axioss from '../../services/restAPI';
import { AuthContext } from "../../components/Auth/AuthContext";
import FormattedLocation from '../Warehouses/Locations/FormattedLocation';


const TableRowLocationLog = (props) => {
    const {record} = props
    const oldLocation = record.location_log.old_location
    const newLocation = record.location_log.new_location


   return (
        <Space direction='vertical' style={{paddingLeft:"20px"}}>
            <p ><b>Location Changed: </b></p>
            <Space><b>from: </b><LocationByID locationID={oldLocation}/></Space>
            <Space><b>to: </b><LocationByID locationID={newLocation}/></Space>
        </Space>
		
   );
}

export default TableRowLocationLog

const LocationByID = ({locationID}) => {
    const [loading, setLoading] = useState(false)
    const { token } = useContext(AuthContext)
    const [location, setLocation] = useState(null)

    console.log(loading)
    const getData = () => {
        setLoading(true)
        axioss.get(`/locations/${locationID}`, { headers: { Authorization: token } })
            .then(response => {
                setLocation(response.data)
                console.log(response.data)
            })
            .catch(error => console.error('Error fetching data:', error))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
            <>
                {location&&(
                    <>{loading?<>loading...</>:<FormattedLocation location={location} hideOnChangeLocation/>}</>
                )}
            </>
    )
}
