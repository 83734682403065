import { Input,  } from "antd"
import {useState,useEffect} from 'react'
import dayjs from 'dayjs'
const RocheItemsQRreader = (props) => {
  const {setExpirationDate, setItemNumber, setLotNumber,  setIsComponentInUse } = props
  const [rocheQR, setRocheQR] = useState("")

  useEffect(() => {
    if(rocheQR!==""&&rocheQR.length === 56){
      const itemNumber = rocheQR.substring(38, 48);
      const lotNumber = rocheQR.substring(18, 26);

      const expDateYear = rocheQR.substring(28, 30);
      const expDateMonth = rocheQR.substring(30, 32);
      const expDateDay = rocheQR.substring(32, 34);
      const expDate = `20${expDateYear}-${expDateMonth}-${expDateDay}`


      setItemNumber(itemNumber)
      setLotNumber(lotNumber)
      setExpirationDate(dayjs(expDate, 'YYYY-MM-DD'))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[rocheQR])

  useEffect(() => {
    if(rocheQR){
      if(setIsComponentInUse){ setIsComponentInUse(true) }
    }
    else{
      if(setIsComponentInUse){ setIsComponentInUse(false) }
      setItemNumber("")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rocheQR])

  return (
    <>
      <Input placeholder="Scan Roche QR code" value={rocheQR} onChange={(e) => {setRocheQR(e.target.value)}}/>
    </>
  )
}
export default RocheItemsQRreader