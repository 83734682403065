import { TableRow } from "./Row/index";
import { React, useEffect, useContext, useState } from "react";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { WarehouseContext } from "../WarehouseContext";
import { App,Table } from "antd";
import { LoadingSpinLocal } from "../../../components/LoadingSpinLocal";
import { getWarehousesService } from "../../../services/APIcalls/getWarehouses";

export function WarehousesTable(props) {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const { authConfig } = useContext(AuthContext);
  const { modal } = App.useApp();
  const {reloadParent, setReloadParent } = useContext(WarehouseContext)

  const getWarehouses = async () => {
    try {
      setLoading(true);
      const response = await getWarehousesService(authConfig)
      setDataSource(response);
    } catch (error) {
      modal.warning({ title: "Warehouses error:", content: error.message, });
      console.error(error);
    } finally {
      setLoading(false);
      setReloadParent(false)
    }
  };

  useEffect(() => {
    getWarehouses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    if(reloadParent){
        getWarehouses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadParent]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
  ];

  // const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

  return (
    <>
      <LoadingSpinLocal loading={loading}>
        <div className="scrolledBox">
          <Table
            columns={columns}
            expandRowByClick={true}
            expandable={{
              expandedRowRender: (record) => (
                <div style={{ margin: 0 }}>
                  <TableRow
                    record={record}
                    customComponent={props.customComponent}
                  />
                </div>
              ),
              rowExpandable: (record) => record.name !== "Not Expandable",
            }}
            dataSource={dataSource}
            pagination={false}
            scroll={{ x: "100%" }}
          />
        </div>
      </LoadingSpinLocal>
    </>
  );
}
