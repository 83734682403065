import { Checkbox, Collapse, Input, Space,Tooltip, Button } from 'antd';
import {useState,useMemo} from 'react';
import Locations from '../../Warehouses/Locations';
import { DepartmentsSelector } from '../../../components/Departments';
import VendorsSelect from '../../Vendors/VendorsSelect';

const SearchFilters = (props) => {
    const {filters, getData} = props
    return (
        <Collapse items={
            [
                {
                    key: '1',
                    label: 'Search',
                    children: <SearchFields filters={filters} getData={getData}/>,
                },
            ]
        } 
        defaultActiveKey={['1']}
        bordered={false}
        />
    )
}

export default SearchFilters

const SearchFields = (props) => {
    const [arrow, ] = useState('Show');
    const mergedArrow = useMemo(() => {
      if (arrow === 'Hide') {
        return false;
      }
      if (arrow === 'Show') {
        return true;
      }
      return {
        pointAtCenter: true,
      };
    }, [arrow]);


    const {filters, getData} = props

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            getData();
        }
    };


    return(
        <Space size='large' wrap>
            <Input placeholder='Search by Item Number' onKeyPress={handleKeyPress}  value={filters.searchByItemNumber} onChange={(e) => {filters.setSearchByItemNumber(e.target.value)}} allowClear/>
            <Input placeholder='Search by Item Name' onKeyPress={handleKeyPress} value={filters.searchByItemName} onChange={(e) => {filters.setSearchByItemName(e.target.value)}} allowClear/>
            <Input placeholder='Search by LOT' onKeyPress={handleKeyPress} value={filters.searchByLOT} onChange={(e) => {filters.setSearchByLOT(e.target.value)}} allowClear/>

            <Tooltip placement="topLeft" title={`Expires in ${filters.searchByExpiresIn?filters.searchByExpiresIn:"N"} days or sooner`} arrow={mergedArrow}>
                <Input placeholder='Search by Expires In' onKeyPress={handleKeyPress} value={filters.searchByExpiresIn} onChange={(e) => {filters.setSearchByExpiresIn(e.target.value)}} allowClear/>
            </Tooltip >

            <DepartmentsSelector selectedDepartment={filters.searchByLocationDepartment} setSelectedDepartment={filters.setSearchByLocationDepartment} />

            <VendorsSelect selectedVendor={filters.searchByVender} setSelectedVendor={filters.setSearchByVender}/>

            <Locations selectedWarehouse={1}location={filters.searchByLocation} setLocation={filters.setSearchByLocation}/>
            
            {/* <Input onKeyPress={handleKeyPress} placeholder='Search by PO number' allowClear disabled/> */}


            {/* <MyCollapse title="Search By Vendor" collapsed child={
                <Space direction='vertical'>
                     <Input placeholder='Search by Vendor' onKeyPress={handleKeyPress} value={filters.searchByVender} onChange={(e) => {filters.setSearchByVender(e.target.value)}} allowClear/>
                    <VendorsSelect selectedVendor={filters.searchByVender} setSelectedVendor={filters.setSearchByVender}/>
                </Space>
            } /> */}


            <Space>
                Low in stock
                <Checkbox onChange={(e) => {filters.setSearchByLowInStock(e.target.checked)}}/>
            </Space>
            <Space>
                Expires soon
                <Checkbox onChange={(e) => {if (e.target.checked){filters.setSearchByExpiresIn(90)}else{filters.setSearchByExpiresIn("")}}}/>
            </Space>



            <Button onClick = {() => {getData()}}>Search</Button>
        </Space>
    )
}