import { useState, useEffect, useContext } from "react";
import axioss from "../../../services/restAPI";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { LoadingSpinLocal } from "../../../components/LoadingSpinLocal";
import { useParams } from "react-router-dom";
import DateFormatter from "../../../components/DateFormatter";
import PONotes from "../PONotes";
import AddNewItems from "./AddNewItems";
import MyCollapse from "../../../components/Collapse";
import {Space, Tabs, App} from 'antd'
import { PurchasedItems } from "../PurchasedItems";
import RemoveItems from "./RemoveItems";

const EditPO = () => {
    const { poID } = useParams();
    const [loading, setLoading] = useState(false);
    const { authConfig } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [getDataTrigger, setGetDataTrigger] = useState("")
    const { modal } = App.useApp()


    const getData = () => {
        // const params = {};
        setLoading(true);
        axioss
            .get(`/po/${poID}/`, authConfig)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) =>{
                modal.warning({
                    title: 'Error while loading the PO:',
                    content: error.response.data.message
                });

                console.error("Error fetching data:", error)
            })
            .finally(() => setLoading(false)); // Correctly set the loading state to false
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getDataTrigger]);


    const editingOption = [
        {
          key: '1',
          label: 'Add Items',
          children: <AddNewItems PO={data} setGetDataTrigger={setGetDataTrigger}key={`add items ${getDataTrigger}`}/>
        },
        {
          key: '2',
          label: 'Remove Items',
          children: <RemoveItems record={data} setGetDataTrigger={setGetDataTrigger}  key={`remove items ${getDataTrigger}`}/>
        },
    ];


    return (
        <>
            <h1>{poID} editing</h1>
            <LoadingSpinLocal loading={loading}>
                {data.barcode && (
                    <>
                        <div> Created: <DateFormatter dateString={data.date_created} hideTime /> </div>
                        <div> Notes: <PONotes record={data} setLoading={setLoading} /> </div>
                        <br />
                        <Space size={'large'} wrap align="top">
                            
                            <MyCollapse child={<PurchasedItems record={data} />} title={poID} key={`current PO ${getDataTrigger}`}/>

                            <MyCollapse child={<Tabs defaultActiveKey="1" items={editingOption} />} title={'Edit PO'} />
                            
                        </Space>
                    </>
                )}
            </LoadingSpinLocal>
        </>
    );
};

export default EditPO;
