import { Table,  } from 'antd'
import TableRow from './TableRow';
import JsonToCSV from '../../../../../components/JsonToCSV'

const MainTable = (props) => {
  const { inStockProducts, expandable, hideVender, hideType, hideAmountToOrder, tableSize } = props

  // function convertTimestampToDate(timestamp) {
  //   var date = new Date(timestamp);
  //   return date.toISOString().split('T')[0];
  // }


  function transformData(items) {
    const groups = {};

    items.forEach(item => {
      const key = `${item.item_id}-${item.name}`;
      if (!groups[key]) {
        groups[key] = {
          actualItem: [item.actual_item[0]],
          total_amount_of_products: 0,
          group_name: item.name,
          key: item.name,
          products: []
        };
      }
      groups[key].total_amount_of_products++;
      groups[key].products.push(item);
    });

    return Object.values(groups);
  }

  const dataSource = transformData(inStockProducts)

  // const dataToExport = dataSource.map(item=>{
  //   item.received =  convertTimestampToDate(parseInt(item.products[0].barcode))
  //   item.itemNumber =  item.actualItem[0].item_number
  //   item.name = item.actualItem[0].name
  //   item.vendor = item.actualItem[0].vendor
  //   item.type = item.actualItem[0].type
  //   item.threshold = item.actualItem[0].threshold
  //   item.threshold = item.actualItem[0].threshold
  //   item.inStock = item.products.length
  //   return item
  // })


  let columns = [
    {
      title: '#',
      dataIndex: 'item_number',
      key: 'item_number',
      render: (_,record) => (<>{record.actualItem[0].item_number}</>)
    },
    {
      title: 'Name',
      dataIndex: 'group_name',
      key: 'group_name',
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor',
      render: (_, record) => (<>{record.actualItem[0].vendor}</>)
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => (<>{record.actualItem[0].type}</>)
    },
    {
      title: 'Quantity',
      dataIndex: 'total_amount_of_products',
      key: 'total_amount_of_products',
    },
  ];

  if (hideVender) {
    columns = columns.filter(col => col.key !== 'vender')
  }
  if (hideType) {
    columns = columns.filter(col => col.key !== 'type')
  }
  if (hideAmountToOrder) {
    columns = columns.filter(col => col.key !== 'amount_to_order')
  }


  return (
    <>
      <JsonToCSV data={dataSource} />
      <Table
        size={tableSize?tableSize:null}
        dataSource={dataSource}
        columns={columns}
        expandable={expandable ? (
          {
            expandedRowRender: (record) => (<TableRow record={record} />),
            rowExpandable: (record) => record.name !== 'Not Expandable',
          }
        ) : null}
      />
    </>
  )
}

export default MainTable

