
import { ItemInformation } from "../../Items/ItemInformation"

export const PurchasedItemsRow = (props) => {
  const {record} = props
  // console.log(record.actual_item)
  return(
    <> 
      <h3>{record.actual_item.name}</h3>
      <ItemInformation selectedItem={record.actual_item}/>
    </>
  )
}