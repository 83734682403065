import { Select } from 'antd'
import { useState, useEffect, useContext } from "react";
import axioss from '../../services/restAPI';
import { AuthContext } from "../../components/Auth/AuthContext";
import { App } from 'antd'
import { LoadingSpinLocal } from "../../components/LoadingSpinLocal";


const DepartmentsSelector = (props) => {
    // const departments = ["Chemistry","Cytology","Hematology","Histology","Immunology","MassSpec","Microbiology","Molecular","Warehouse","IT","Phlebotomy"]
    const optionWeight = 300

    const [loading, setLoading] = useState(false)
    const { authConfig } = useContext(AuthContext)
    const { modal } = App.useApp()
    const { selectedDepartment, setSelectedDepartment } = props
    const [departments, setDepartments] = useState([])
    const selectedDepartmentIsNotEmptyInitially = selectedDepartment!=="" && departments.length>0  && isNaN(selectedDepartment)
    
    useEffect(() => {
        if(departments.length===0){
            getDepartments()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        //this function sets an ID instead of a Name.
        //use case: select initial value is not empty (for example,in an item edit mode)

    // const selectedDepartmentIsNotEmptyInitially = selectedDepartment!=="" && departments.length>0  && isNaN(selectedDepartment)

        
        if (selectedDepartmentIsNotEmptyInitially) {
            const convertLabelToValue = () => {
                const value = ((departments.filter(i => i.name === selectedDepartment))[0]).id
                return value
            }
            
            setSelectedDepartment(convertLabelToValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDepartmentIsNotEmptyInitially])


    const getDepartments = async () => {
        try {
            setLoading(true)
            const response = await axioss.get("/departments/", authConfig)
            setDepartments(response.data)
        }
        catch (err) {
            console.log(err)
            modal.warning({
                title: 'Error while loading departments',
                content: err.message,
            });
        }
        finally {
            setLoading(false)
        }
    }

    const departmentsOptions = departments.map(department => {
        return {
            value: department.id,
            label: department.name,
        }
    })

    return (
        <LoadingSpinLocal loading={loading}>
            <Select
                value={selectedDepartment === "" ? null : selectedDepartment}
                placeholder="Select Department"
                options={departmentsOptions}
                onSelect={(value) => { setSelectedDepartment(value) }}
                style={{minWidth:optionWeight}}
            />
        </LoadingSpinLocal>
    )
}

export { DepartmentsSelector }