import { React, useState, useContext,useEffect } from 'react'
import { AuthContext } from "../../../components/Auth/AuthContext";
import { App, Select } from "antd";
import { LoadingSpinLocal } from "../../../components/LoadingSpinLocal";
import { getWarehousesService } from '../../../services/APIcalls/getWarehouses';

const WarehouseDropdown = (props) => {
    const { warehouse, setWarehouse, allowAllOption } = props;
    const [loading, setLoading] = useState(false);
    const optionsInitValue = allowAllOption?([{label:"All", value:""}]):[]
    const [options, setOptions] = useState(optionsInitValue);
    const { modal } = App.useApp();
    const { authConfig } = useContext(AuthContext);

    useEffect(()=>{
        getWarehouses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const getWarehouses = async () => {
        try {
            setLoading(true);
            const response = await getWarehousesService(authConfig);

            const tempArr = response.map((warehouse) => {
                warehouse.label = warehouse.name;
                warehouse.value = warehouse.id;
                return warehouse
            })

            tempArr.sort((a, b) => a.id - b.id);

            setOptions([...tempArr, ...options]); //option may contain "All"
        } catch (error) {
            modal.warning({
                title: "Warehouses error:",
                content: error.message,
            });
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <LoadingSpinLocal loading={loading}>
            <Select
                value={warehouse}
                onSelect={(value)=>{setWarehouse(value)}}
                placeholder="Select Warehouse"
                style={{
                    width: 200,
                }}
                options={options}
            />
        </LoadingSpinLocal>
    )

}

export {WarehouseDropdown}