import { Result } from "antd";

export const Page401 = () => {
  return (
    <Result
      status="warning"
      title="401"
      subTitle="Access Denied"
    />
  );
};
