import { useState, useContext, useEffect } from "react";

import axioss from '../../services/restAPI';
import { AuthContext } from "../Auth/AuthContext";
import { App } from 'antd'
import { LoadingSpinLocal } from "../LoadingSpinLocal";

const DepartmentByID = (params) => {
    const { departmentID } = params
    const [loading, setLoading] = useState(false)
    const { authConfig } = useContext(AuthContext)
    const { modal } = App.useApp()
    const [departments, setDepartments] = useState([])
    const [department, setDepartment] = useState(null)

    const getDepartments = async () => {
        try {
            setLoading(true)
            const response = await axioss.get("/departments/", authConfig)
            setDepartments(response.data)
            console.log(response)
        }
        catch (err) {
            console.log(err)
            modal.warning({
                title: 'Error while loading departments',
                content: err.message,
            });
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (departments.length === 0) {
            getDepartments()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (departments.length> 0) {
            const d  = departments.find(department=>(department.id===departmentID))
            setDepartment(d.name)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departments])


    return (
        <LoadingSpinLocal loading={loading}>
            <>{department}</>
        </LoadingSpinLocal>
    )
}
export default DepartmentByID