import { Button, Card,  Descriptions } from 'antd';

const StockProduct = (props) => {
    const { product, products, setProducts, counter,  } = props

    const onRemove = () => {
        const tempArr = [...products]
        tempArr.splice(counter, 1)
        setProducts(tempArr)
    }

    const items = [
        {
          key: '1',
          label: 'Barcode',
          children: product.barcode,
        },
        {
          key: '2',
          label: 'Vendor',
          children: product.vendor,
        },
        {
          key: '3',
          label: 'Type',
          children: product.type,
        },
      ];


    return (
        <Card
            title={`${counter + 1}. ${product.name}`}
            bordered={true}
            size='small'
            style={{ width: 300, }}
            extra={<Button type="link" onClick={onRemove}>Remove</Button>}
        >
            <Descriptions items={items} column={1} size='small'/>
        </Card>
    )

}


export default StockProduct

// const AmountTaken = (props) => {
//     const { product, products, setProducts, } = props

//     const [value, setValue] = useState("")

//     const onBlur = () => {
//         const tempArr = [...products].map( (addedProduct) => {
//             if (addedProduct.stock_id===product.stock_id){
//                 addedProduct.amountTaken = value;
//             }
//             return addedProduct
//         })
//         setProducts(tempArr)
//     }

//     const onReset = () => {
//         const tempArr = [...products].map( (addedProduct) => {
//             if (addedProduct.stock_id===product.stock_id){
//                 addedProduct.amountTaken = null;
//             }
//             return addedProduct
//         })
//         setProducts(tempArr)
//     }

//     const onChange = (e) => {
//         let value = e.target.value
//         value = value.replace(/\D/g, '');
//         setValue(value)
//     }

//     return (
//         <>
//             {product.amountTaken?(
//                 <Space>
//                     <div>Amount is taking: <b>{product.amountTaken}</b></div> 
//                     <Button onClick={onReset} type='link'> edit</Button>
//                 </Space>
//             ):(
//                 <Space>
//                     <Input placeholder='Amount Taken' value={value} onChange={(e) => {onChange(e)}} onBlur={onBlur} onPressEnter={onBlur} allowClear/>
//                     <Button onClick={onBlur} disabled={value===""}>Submit</Button>
//                 </Space>
//             )}
//         </>
//     )
// }


/*
{
    "stock_id": 4603,
    "quantity_in_stock": 1,
    "section_id": 1,
    "barcode": "1689342983496",
    "department": "Department 1",
    "warehouseId": 1,
    "description": "This is a section in warehouse",
    "item_id": 1,
    "company": "Test Company",
    "isdeleted": false,
    "memo": "This is a test item",
    "packsize": "20",
    "ref": "TestRef",
    "threshold": 10,
    "type": "TestType",
    "packageconfiguration": "TestPackage",
    "title": "TestItem"
}
*/