import { useState, useEffect, React, useContext } from "react";
import axioss from "../../../../services/restAPI";
import { AuthContext } from "../../../../components/Auth/AuthContext";
import { LoadingSpinLocal } from "../../../../components/LoadingSpinLocal";
import PossiblePOsRadioGroup from "./PossiblePOsRadioGroup";

const PossiblePOs = (props) => {
    const { selectedItem, selectedPO, setSelectedPO, setPossiblePOsCompleted } = props;
    const [loading, setLoading] = useState(false);
    const { authConfig } = useContext(AuthContext);
    const [data, setData] = useState([]);



    const getData = () => {
        const params = { selectedItem: selectedItem.id };
        setLoading(true);
        axioss
            .get(`/po/get-possible-po/`, { params }, authConfig)
            .then((response) => {
                setData(response.data);
                // console.log(response.data)
            })
            .catch((error) => {
                setData([]);
                console.error("Error fetching data:", error)
            })
            .finally(setLoading(false));
    };


    useEffect(() => {
        if (selectedItem) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem]);

    useEffect(() => {
        if (selectedPO) {
            setPossiblePOsCompleted(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPO]);


    return (
        <>
            {selectedItem && (
                <LoadingSpinLocal loading={loading}>
                    {data.length > 0 && (
                        <PossiblePOsRadioGroup data={data} setSelectedPO={setSelectedPO} selectedPO={selectedPO} />
                    )}
                </LoadingSpinLocal>
            )}
        </>

    );
};


export default PossiblePOs;





