
const MockItems = [
    {   
        id:1,
        name: 'PAS with Diastase Digestion 1',
        department: 'Histology',
        type: 'Reagent',
        vender: 'Siemens',
        itemNumber: 'CY0009',
        refNumber: 'CY0009',
        packSize: 1,
        unitOfMeasure: 'box',
    },
    {
      id:2,
        name: 'Panel Neg Urine Combo 103 2',
        department: 'Microbiology',
        type: 'Control Materials',
        vender: 'BeckmanCoulter',
        itemNumber: 'C89644',
        refNumber: 'C89644',
        packSize: 1,
        unitOfMeasure: 'Box of 20',
    },
    { 
      id:3,
        name: 'Hematoxylin and Eosin Stain Kit 3',
        department: 'Histology',
        type: 'Reagent',
        vender: 'Thermo Fisher Scientific',
        itemNumber: 'HS1234',
        refNumber: 'HS1234',
        packSize: 1,
        unitOfMeasure: 'box',
    },
    {
      id:4,
        name: 'Blood Culture Bottle 4',
        department: 'Microbiology',
        type: 'Consumable',
        vender: 'BD',
        itemNumber: 'BC5678',
        refNumber: 'BC5678',
        packSize: 1,
        unitOfMeasure: 'pack',
    },
    {
      id:5,
        name: 'Giemsa Stain 5',
        department: 'Microbiology',
        type: 'Reagent',
        vender: 'Merck',
        itemNumber: 'GS9876',
        refNumber: 'GS9876',
        packSize: 1,
        unitOfMeasure: 'bottle',
    },
    {
      id:6,
        name: 'Paraffin Wax 6',
        department: 'Histology',
        type: 'Consumable',
        vender: 'Sigma-Aldrich',
        itemNumber: 'PW3456',
        refNumber: 'PW3456',
        packSize: 1,
        unitOfMeasure: 'pack',
    },
    {
      id:7,
        name: 'Water',
        department: 'IT',
        type: 'Reagent',
        vender: 'VWR',
        itemNumber: 'FO8910',
        refNumber: 'FO8910',
        packSize: 1,
        unitOfMeasure: 'bottle',
    },
    {
      id:234323223,
        name: 'Blood Agar Plates',
        department: 'Microbiology',
        type: 'Consumable',
        vender: 'Thermo Fisher Scientific',
        itemNumber: 'BA8765',
        refNumber: 'BA8765',
        packSize: 1,
        unitOfMeasure: 'pack',
    },
    {
      id:234211134,
        name: 'Xylene',
        department: 'Histology',
        type: 'Reagent',
        vender: 'Sigma-Aldrich',
        itemNumber: 'XY1234',
        refNumber: 'XY1234',
        packSize: 1,
        unitOfMeasure: 'bottle',
    },
    {
      id:21213423,
        name: 'Urine Test Strips',
        department: 'Microbiology',
        type: 'Consumable',
        vender: 'Beckman Coulter',
        itemNumber: 'UT6789',
        refNumber: 'UT6789',
        packSize: 1,
        unitOfMeasure: 'pack',
    },
    {
      id:2342321,
        name: 'Eosin Y Stain',
        department: 'Histology',
        type: 'Reagent',
        vender: 'Thermo Fisher Scientific',
        itemNumber: 'ES5678',
        refNumber: 'ES5678',
        packSize: 1,
        unitOfMeasure: 'bottle',
    },
    
];

const MockOrders = [
    {
      id: "58a364e22df6083edf9e2cf6",
      item: "58a35c7e2df6083edf9e2cf1",
      isDeleted: false,
      department: "Molecular",
      trackingInformation: null,
      orderDate: "2017-02-14T20:13:22.412Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: null,
      receivedDate: "2017-01-24T05:00:00.000Z",
      testedDate: "2017-02-03T05:00:00.000Z",
      testResult: "PASS",
      testMethod: "Lot to Lot",
      location: null,
      quantity: 0,
      expirationDate: "2017-11-15T05:00:00.000Z",
      lot: "179470AH",
      barcode: "011542004550547610179470AH17171115",
      orderQuantity: 16,
      company: "Hologic",
      title: "Aptima Trichomonas vaginalis 100",
      ref: "303065",
      price: null,
    },
    {
      id: "58a3666566ecdc02f293c11b",
      item: "58a35cee2df6083edf9e2cf2",
      isDeleted: false,
      department: "Molecular",
      trackingInformation: null,
      orderDate: "2017-02-14T20:19:49.019Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-01-07T05:00:00.000Z",
      receivedDate: "2016-12-27T05:00:00.000Z",
      testedDate: "2017-01-07T05:00:00.000Z",
      testResult: "PASS",
      testMethod: "Lot to Lot",
      location: null,
      quantity: 0,
      expirationDate: "2018-08-15T04:00:00.000Z",
      lot: "187295AH",
      barcode: "011542004550005110187295AH17180815",
      orderQuantity: 3,
      company: "Hologic",
      title: "aptima HPV Assay",
      ref: "303012",
      price: null,
    },
    {
      id: "58a366bdd49a9302f84f678f",
      item: "58a35cee2df6083edf9e2cf2",
      isDeleted: false,
      department: "Molecular",
      trackingInformation: null,
      orderDate: "2017-02-14T20:21:17.722Z",
      orderNumber: null,
      purchaseOrder: "MO000001",
      status: "In Use",
      dateInService: "2017-03-27T00:00:00.000Z",
      receivedDate: null,
      testedDate: "2017-03-27T00:00:00.000Z",
      testResult: "PASS",
      testMethod: "Lot to Lot",
      location: "Molecular",
      quantity: 0,
      expirationDate: "2018-10-15T00:00:00.000Z",
      lot: "189719AH",
      barcode: "011542004550005110189719AH17181015",
      orderQuantity: 20,
      company: "Hologic",
      title: "aptima HPV Assay",
      ref: "303012",
      price: null,
    },
    {
      id: "58a3685066ecdc02f293c11c",
      item: "58a35d5240327d3ed99bb547",
      isDeleted: false,
      department: "Molecular",
      trackingInformation: null,
      orderDate: "2017-02-14T20:28:00.886Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-01-20T05:00:00.000Z",
      receivedDate: "2017-02-12T05:00:00.000Z",
      testedDate: "2017-01-20T05:00:00.000Z",
      testResult: "PASS",
      testMethod: "Lot to Lot",
      location: null,
      quantity: 0,
      expirationDate: "2018-03-15T04:00:00.000Z",
      lot: "186696AH",
      barcode: "011542004550571110186696BH17180315",
      orderQuantity: 4,
      company: "Hologic",
      title: "Aptima Combo 2",
      ref: "301130",
      price: null,
    },
    {
      id: "58a3692dd49a9302f84f6790",
      item: "58a35cb740327d3ed99bb546",
      isDeleted: false,
      department: "Molecular",
      trackingInformation: null,
      orderDate: "2017-02-14T20:31:41.087Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2016-10-29T04:00:00.000Z",
      receivedDate: "2016-10-04T04:00:00.000Z",
      testedDate: "2016-10-29T04:00:00.000Z",
      testResult: "PASS",
      testMethod: "Lot to Lot",
      location: null,
      quantity: 0,
      expirationDate: "2018-07-15T04:00:00.000Z",
      lot: "179697AH",
      barcode: "011542004550011210179697AH17180715",
      orderQuantity: 1,
      company: "Hologic",
      title: "Aptiva HPV 16 18/45 Genotype Assay",
      ref: "303234",
      price: null,
    },
    {
      id: "58a3699066ecdc02f293c11d",
      item: "58a35cb740327d3ed99bb546",
      isDeleted: false,
      department: "Molecular",
      trackingInformation: null,
      orderDate: "2017-02-14T20:33:20.685Z",
      orderNumber: null,
      purchaseOrder: "MO000002",
      status: "In Use",
      dateInService: "2017-03-17T18:35:04.287Z",
      receivedDate: null,
      testedDate: "2017-03-03T00:00:00.000Z",
      testResult: "PASS",
      testMethod: "Lot to Lot",
      location: "Molecular",
      quantity: 0,
      expirationDate: "2018-07-15T00:00:00.000Z",
      lot: "182327AH",
      barcode: "011542004550011210182327AH17180715",
      orderQuantity: 10,
      company: "Hologic",
      title: "Aptiva HPV 16 18/45 Genotype Assay",
      ref: "303234",
      price: null,
    },
    {
      id: "58a36b56d49a9302f84f6792",
      item: "58a35e2340327d3ed99bb548",
      isDeleted: false,
      department: "Molecular",
      trackingInformation: null,
      orderDate: "2017-02-14T20:40:54.406Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2016-11-16T05:00:00.000Z",
      receivedDate: "2016-11-16T05:00:00.000Z",
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      quantity: 0,
      expirationDate: "2018-07-15T04:00:00.000Z",
      lot: "177627H",
      barcode: "011542004550025910177627H17180715",
      orderQuantity: 2,
      company: "Hologic",
      title: "Aptima Auto Detect Kit 301048",
      ref: "301048",
      price: null,
    },
    {
      id: "58a36bec66ecdc02f293c11e",
      item: "58a35dd52df6083edf9e2cf3",
      isDeleted: false,
      department: "Molecular",
      trackingInformation: null,
      orderDate: "2017-02-14T20:43:24.441Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-01-24T05:00:00.000Z",
      receivedDate: "2017-01-24T05:00:00.000Z",
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: "902986",
      orderQuantity: 11,
      company: "Hologic",
      title: "Endozime",
      ref: "902986",
      price: null,
    },
    {
      id: "58a36c9f66ecdc02f293c11f",
      item: "58a362c940327d3ed99bb54b",
      isDeleted: false,
      department: "Molecular",
      trackingInformation: null,
      orderDate: "2017-02-14T20:46:23.124Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-28T19:50:52.786Z",
      receivedDate: "2017-02-28T19:50:22.556Z",
      testedDate: "2017-02-27T15:38:03.152Z",
      testResult: "PASS",
      testMethod: "Lot to Lot",
      location: null,
      quantity: 0,
      expirationDate: "2017-10-15T04:00:00.000Z",
      lot: "175709AH",
      barcode: "011542004550545210175709AH17171015",
      orderQuantity: 2,
      company: "Hologic",
      title: "Aptima Trichomonas vaginalis 250",
      ref: "302806",
      price: null,
    },
    {
      id: "58a46ead66ecdc02f293c120",
      item: "58a35e2340327d3ed99bb548",
      isDeleted: false,
      department: "Molecular",
      trackingInformation: null,
      orderDate: "2017-02-15T15:07:25.465Z",
      orderNumber: "3741774",
      purchaseOrder: "MO000003",
      status: "In Use",
      dateInService: "2017-02-27T20:20:24.266Z",
      receivedDate: "2017-02-21T17:09:34.840Z",
      testedDate: "2017-02-27T20:20:18.959Z",
      testResult: "N/A",
      testMethod: "N/A",
      location: null,
      quantity: 0,
      expirationDate: "2018-11-15T00:00:00.000Z",
      lot: "187276H",
      barcode: "011542004550025910187276H17181115",
      orderQuantity: 30,
      company: "Hologic",
      title: "Aptima Auto Detect Kit 301048",
      ref: "301048",
      price: null,
    },
    {
      id: "58a5c2c8062bf504c76ade40",
      item: "58a35d5240327d3ed99bb547",
      isDeleted: false,
      department: "Molecular",
      trackingInformation: null,
      orderDate: "2017-02-16T15:18:32.651Z",
      orderNumber: "3743113",
      purchaseOrder: "MO000004",
      status: "In Use",
      dateInService: "2017-03-10T19:25:13.591Z",
      receivedDate: "2017-02-21T17:05:56.080Z",
      testedDate: "2017-08-03T00:00:00.000Z",
      testResult: "PASS",
      testMethod: "Lot to Lot",
      location: "Molecular",
      quantity: 0,
      expirationDate: "2018-05-15T00:00:00.000Z",
      lot: "189205BH",
      barcode: "011542004550571110189205BH17180515",
      orderQuantity: 30,
      company: "Hologic",
      title: "Aptima Combo 2",
      ref: "301130",
      price: null,
    },
    {
      id: "58a5de80062bf504c76ade47",
      item: "58a5c868089ca904cd210750",
      isDeleted: false,
      department: "Cytology",
      trackingInformation: null,
      orderDate: "2017-02-16T17:16:48.535Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-16T17:16:48.541Z",
      receivedDate: "2016-09-30T04:00:00.000Z",
      testedDate: "2016-09-30T04:00:00.000Z",
      testResult: "PASS",
      testMethod: "Lot to Lot",
      location: "Cyto-FC",
      quantity: 0,
      expirationDate: "2020-09-13T04:00:00.000Z",
      lot: "1625324",
      barcode: "3533361625324",
      orderQuantity: 5,
      company: "CEN-MED",
      title: "100% Reagent Alcohol",
      ref: "353336",
      price: null,
    },
    {
      id: "58a5e135062bf504c76ade48",
      item: "58a5d08e062bf504c76ade41",
      isDeleted: false,
      department: "Cytology",
      trackingInformation: null,
      orderDate: "2017-02-16T17:28:21.118Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-16T17:28:21.119Z",
      receivedDate: "2017-01-26T05:00:00.000Z",
      testedDate: "2017-01-26T05:00:00.000Z",
      testResult: "PASS",
      testMethod: "Lot to Lot",
      location: "Cyto-FC",
      quantity: 0,
      expirationDate: "2020-05-23T04:00:00.000Z",
      lot: "1614401",
      barcode: "3533371614401",
      orderQuantity: 16,
      company: "CEN-MED",
      title: "95% Reagent Alcohol",
      ref: "353337",
      price: null,
    },
    {
      id: "58a5e2ec089ca904cd210751",
      item: "58a5d08e062bf504c76ade41",
      isDeleted: false,
      department: "Cytology",
      trackingInformation: null,
      orderDate: "2017-02-16T17:35:40.354Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-16T17:35:40.357Z",
      receivedDate: "2017-02-15T05:00:00.000Z",
      testedDate: "2017-01-26T05:00:00.000Z",
      testResult: "PASS",
      testMethod: "Lot to Lot",
      location: "Cyto-FC",
      quantity: 0,
      expirationDate: "2020-12-18T05:00:00.000Z",
      lot: "1635111",
      barcode: "3533371635111",
      orderQuantity: 6,
      company: "CEN-MED",
      title: "95% Reagent Alcohol",
      ref: "353337",
      price: null,
    },
    {
      id: "58a5ecedfd44fd2c9c629b7e",
      item: "58a5c868089ca904cd210750",
      isDeleted: false,
      department: "Cytology",
      trackingInformation: null,
      orderDate: "2017-02-16T18:18:21.320Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-23T17:02:20.455Z",
      receivedDate: "2017-02-16T18:21:28.528Z",
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      quantity: 0,
      expirationDate: "2020-12-15T00:00:00.000Z",
      lot: "1634710",
      barcode: "3533361634710",
      orderQuantity: 12,
      company: "CEN-MED",
      title: "100% Reagent Alcohol",
      ref: "353336",
      price: null,
    },
    {
      id: "58a5ed2efd44fd2c9c629b7f",
      item: "58a5d0fe062bf504c76ade43",
      isDeleted: false,
      department: "Cytology",
      trackingInformation: null,
      orderDate: "2017-02-16T18:19:26.087Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-16T18:19:26.088Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      quantity: 0,
      expirationDate: "2019-01-01T00:00:00.000Z",
      lot: "360243",
      barcode: "6901360243",
      orderQuantity: 3,
      company: "Richard Allan Sci.",
      title: "ClearRite 3",
      ref: "6901",
      price: null,
    },
    {
      id: "58a6066afd44fd2c9c629b80",
      item: "58a5d0ce062bf504c76ade42",
      isDeleted: false,
      department: "Cytology",
      trackingInformation: null,
      orderDate: "2017-02-16T20:07:06.301Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-16T20:07:06.303Z",
      receivedDate: "2017-01-20T05:00:00.000Z",
      testedDate: "2017-01-20T05:00:00.000Z",
      testResult: "PASS",
      testMethod: "Lot to Lot",
      location: "Histo-SF",
      quantity: 0,
      expirationDate: "2020-08-23T04:00:00.000Z",
      lot: "1623509",
      barcode: null,
      orderQuantity: 9,
      company: "CEN-MED",
      title: "Xylene",
      ref: "353346",
      price: null,
    },
    {
      id: "58a74e5afd44fd2c9c629b81",
      item: "58a363cb40327d3ed99bb54c",
      isDeleted: false,
      department: "Molecular",
      trackingInformation: null,
      orderDate: "2017-02-17T19:26:18.565Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-17T19:26:18.568Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      quantity: 0,
      expirationDate: null,
      lot: "51891120",
      barcode: "021627163",
      orderQuantity: 12,
      company: "Hologic",
      title: "MTU",
      ref: "104772-02",
      price: null,
    },
    {
      id: "58a76384089ca904cd210758",
      item: "58a76320089ca904cd210757",
      isDeleted: false,
      department: "Molecular",
      trackingInformation: null,
      orderDate: "2017-02-17T20:56:36.289Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-17T20:56:36.290Z",
      receivedDate: null,
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: null,
      quantity: 0,
      expirationDate: null,
      lot: "16410559",
      barcode: "904-013",
      orderQuantity: 11,
      company: "Fisher Scientific",
      title: "MBP BioRobotix Tips-1000 ul",
      ref: "904-013",
      price: null,
    },
    {
      id: "58ac8e2e089ca904cd21075a",
      item: "58ac8c2f089ca904cd210759",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-21T18:59:58.284Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-21T18:59:58.285Z",
      receivedDate: "2016-12-20T05:00:00.000Z",
      testedDate: null,
      testResult: "PASS",
      testMethod: "Lot to Lot",
      location: "Room 107",
      quantity: 0,
      expirationDate: "2017-11-14T05:00:00.000Z",
      lot: "2017-11-14",
      barcodes: ["0115099590657529", "17171114102017-11-14"],
      barcode: null,
      orderQuantity: 71,
      company: "Beckman Coulter",
      title: "Pos Combo Panel Type 33",
      ref: "B1017-211",
      price: null,
    },
    {
      id: "58ac8ee3089ca904cd21075b",
      item: "58ac8c2f089ca904cd210759",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-21T19:02:59.105Z",
      orderNumber: null,
      purchaseOrder: "Mi000001",
      status: "In Use",
      dateInService: "2017-02-21T19:15:10.616Z",
      receivedDate: "2017-02-21T19:14:31.641Z",
      testedDate: "2017-02-21T19:14:59.837Z",
      testResult: "PASS",
      testMethod: "Lot to Lot",
      location: null,
      quantity: 0,
      expirationDate: "2020-05-10T00:00:00.000Z",
      lot: "12345",
      barcode: "0115099590657529",
      orderQuantity: 0,
      company: "Beckman Coulter",
      title: "Pos Combo Panel Type 33",
      ref: "B1017-211",
      price: null,
    },
    {
      id: "58ac95eb089ca904cd21075d",
      item: "58ac9519089ca904cd21075c",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-21T19:32:59.728Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-21T19:32:59.731Z",
      receivedDate: "2016-12-22T05:00:00.000Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: "Room 107",
      quantity: 0,
      expirationDate: "2018-03-01T05:00:00.000Z",
      lot: "6288785",
      barcode: "171803011062887853020",
      orderQuantity: 3,
      company: "Becton, Dickinson and Company",
      title: "GasPak EZ Anaerobe Gas Generating Pouch System with Indicator",
      ref: "260683",
      price: null,
    },
    {
      id: "58ac9639fd44fd2c9c629b82",
      item: "58ac9519089ca904cd21075c",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-21T19:34:17.957Z",
      orderNumber: null,
      purchaseOrder: "MI000002",
      status: "In Use",
      dateInService: "2017-03-17T17:27:37.444Z",
      receivedDate: "2017-02-21T19:35:05.777Z",
      testedDate: "2017-03-17T00:00:00.000Z",
      testResult: "N/A",
      testMethod: "N/A",
      location: "Room 107",
      quantity: 0,
      expirationDate: "2018-06-01T00:00:00.000Z",
      lot: "6348754",
      barcode: "171806011063487543020",
      orderQuantity: 10,
      company: "Becton, Dickinson and Company",
      title: "GasPak EZ Anaerobe Gas Generating Pouch System with Indicator",
      ref: "260683",
      price: null,
    },
    {
      id: "58ac9caafd44fd2c9c629b84",
      item: "58ac9c22fd44fd2c9c629b83",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-21T20:01:46.730Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-21T20:01:46.732Z",
      receivedDate: "2017-02-07T05:00:00.000Z",
      testedDate: "2017-02-07T05:00:00.000Z",
      testResult: "PASS",
      testMethod: "Lot to Lot",
      location: "Jose",
      quantity: 0,
      expirationDate: "2019-09-20T04:00:00.000Z",
      lot: "554014510",
      barcode: "010405322800210917190920105540145102400594-0201-NA",
      orderQuantity: 25,
      company: "Qiagen",
      title: "QuantiFERON-TB Gold",
      ref: "0594-0201-NA",
      price: null,
    },
    {
      id: "58ac9e64fd44fd2c9c629b86",
      item: "58ac9debfd44fd2c9c629b85",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-21T20:09:08.129Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-21T20:09:08.131Z",
      receivedDate: "2016-10-14T04:00:00.000Z",
      testedDate: "2016-10-14T04:00:00.000Z",
      testResult: "PASS",
      testMethod: "Lot to Lot",
      location: "Jose",
      quantity: 0,
      expirationDate: "2017-04-04T04:00:00.000Z",
      lot: "0490640AA",
      barcode: "010805677110234617170404100490640AA240P001929",
      orderQuantity: 0,
      company: "DiaSorin",
      title: "ETI-AB-EBK Plus",
      ref: "P001929",
      price: null,
    },
    {
      id: "58aca062fd44fd2c9c629b88",
      item: "58ac9f9efd44fd2c9c629b87",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-21T20:17:38.258Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-21T20:17:38.259Z",
      receivedDate: "2017-01-25T05:00:00.000Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: "Room 107",
      quantity: 0,
      expirationDate: "2018-01-13T00:00:00.000Z",
      lot: "2018-01-13",
      barcode: "01150995906552111117011317180113102018-01-13",
      orderQuantity: 4,
      company: "Beckman Coulter",
      title: "Microscan Alpha Naphthol",
      ref: "B1010-42A",
      price: null,
    },
    {
      id: "58aca0eb089ca904cd21075f",
      item: "58aca098089ca904cd21075e",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-21T20:19:55.118Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-21T20:19:55.119Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: "Jose",
      quantity: 0,
      expirationDate: "2017-12-31T05:00:00.000Z",
      lot: "16120031",
      barcode: null,
      orderQuantity: 11,
      company: "ZEUS",
      title: "FTA-ABS IFA Test System",
      ref: "FA7001",
      price: null,
    },
    {
      id: "58aca198089ca904cd210761",
      item: "58ac9debfd44fd2c9c629b85",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-21T20:22:48.454Z",
      orderNumber: null,
      purchaseOrder: "IM000001",
      status: "In Use",
      dateInService: "2017-02-27T19:18:45.959Z",
      receivedDate: "2017-02-21T20:25:28.618Z",
      testedDate: "2017-02-27T19:18:51.031Z",
      testResult: "PASS",
      testMethod: "Lot to Lot",
      location: "Jose",
      quantity: 0,
      expirationDate: "2017-06-30T00:00:00.000Z",
      lot: "0490650AA",
      barcode: "010805677110234617170630100490650AA240P001929",
      orderQuantity: 28,
      company: "DiaSorin",
      title: "ETI-AB-EBK Plus",
      ref: "P001929",
      price: null,
    },
    {
      id: "58aca1f3fd44fd2c9c629b89",
      item: "58aca155089ca904cd210760",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-21T20:24:19.119Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2018-09-26T20:04:01.440Z",
      receivedDate: "2017-01-25T05:00:00.000Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: "Room 107",
      quantity: 0,
      expirationDate: "2017-12-01T05:00:00.000Z",
      lot: "2017-12-01",
      barcode: "17171201102017-12-01",
      orderQuantity: 4,
      company: "Beckman Coulter",
      title: "Microscan 0.05N Sodium Hydroxide",
      ref: "B1015-3",
      price: null,
      comment: "",
    },
    {
      id: "58aca312089ca904cd210762",
      item: "58aca27bfd44fd2c9c629b8a",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-21T20:29:06.635Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-21T20:29:06.637Z",
      receivedDate: "2016-09-02T04:00:00.000Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: "Room 107",
      quantity: 0,
      expirationDate: "2017-07-20T04:00:00.000Z",
      lot: "2017-07-20",
      barcode: "17170720102017-07-20",
      orderQuantity: 4,
      company: "Beckman Coulter",
      title: "Microscan 0.8% Sulfanilic Acid",
      ref: "B1015-44",
      price: null,
    },
    {
      id: "58aca378089ca904cd210763",
      item: "58aca27bfd44fd2c9c629b8a",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-21T20:30:48.360Z",
      orderNumber: null,
      purchaseOrder: "MI000003",
      status: "In Use",
      dateInService: "2017-05-12T00:00:00.000Z",
      receivedDate: "2017-02-21T20:33:44.728Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: null,
      quantity: 0,
      expirationDate: "2017-12-01T00:00:00.000Z",
      lot: "2017-12-01",
      barcode: "01150995906558531116120217171201102017-12-01",
      orderQuantity: 4,
      company: "Beckman Coulter",
      title: "Microscan 0.8% Sulfanilic Acid",
      ref: "B1015-44",
      price: null,
    },
    {
      id: "58aca5ce6317d867c8cb85b5",
      item: "58aca56e78165867c2ddcbc9",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-21T20:40:46.309Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-21T20:40:46.316Z",
      receivedDate: "2016-08-16T04:00:00.000Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: "Room 107",
      quantity: 0,
      expirationDate: "2017-07-11T04:00:00.000Z",
      lot: "2017-07-11",
      barcode: "17170711102017-07-11",
      orderQuantity: 4,
      company: "Beckman Coulter",
      title: "Microscan 10% Ferric Chloride",
      ref: "B1015-48",
      price: null,
    },
    {
      id: "58aca5e06317d867c8cb85b6",
      item: "58aca56e78165867c2ddcbc9",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-21T20:41:04.813Z",
      orderNumber: null,
      purchaseOrder: "MI000004",
      status: "In Use",
      dateInService: "2017-05-12T00:00:00.000Z",
      receivedDate: "2017-02-21T20:41:11.023Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: null,
      quantity: 0,
      expirationDate: "2017-11-30T00:00:00.000Z",
      lot: "2017-11-30",
      barcode: "01150995906558841116113017171130102017-11-30",
      orderQuantity: 4,
      company: "Beckman Coulter",
      title: "Microscan 10% Ferric Chloride",
      ref: "B1015-48",
      price: null,
    },
    {
      id: "58aca79678165867c2ddcbca",
      item: "58aca71a6317d867c8cb85b7",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-21T20:48:22.344Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-21T20:48:22.346Z",
      receivedDate: "2017-02-02T05:00:00.000Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: "Room 107",
      quantity: 0,
      expirationDate: "2017-12-24T05:00:00.000Z",
      lot: "2017-12-24",
      barcode: "01150995906557231116122717171224102017-12-24",
      orderQuantity: 1,
      company: "Beckman Coulter",
      title: "Microscan 3mL Inoculum Water",
      ref: "B1015-2",
      price: null,
    },
    {
      id: "58aca86e78165867c2ddcbcb",
      item: "58aca8196317d867c8cb85b8",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-21T20:51:58.912Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-21T20:51:58.913Z",
      receivedDate: "2016-09-02T04:00:00.000Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: "Room 107",
      quantity: 0,
      expirationDate: "2017-07-15T04:00:00.000Z",
      lot: "2017-07-15",
      barcode: "17170715102017-07-15",
      orderQuantity: 3,
      company: "Beckman Coulter",
      title: "Microscan 40% Potassium Hydroxide",
      ref: "B1015-43",
      price: null,
    },
    {
      id: "58aca89878165867c2ddcbcc",
      item: "58aca8196317d867c8cb85b8",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-21T20:52:40.862Z",
      orderNumber: null,
      purchaseOrder: "MI000005",
      status: "In Use",
      dateInService: "2017-05-12T00:00:00.000Z",
      receivedDate: "2017-02-21T20:52:46.146Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: null,
      quantity: 0,
      expirationDate: "2017-12-22T00:00:00.000Z",
      lot: "2017-12-22",
      barcode: "01150995906558461116122217171222102017-12-22",
      orderQuantity: 4,
      company: "Beckman Coulter",
      title: "Microscan 40% Potassium Hydroxide",
      ref: "B1015-43",
      price: null,
    },
    {
      id: "58aca9af6317d867c8cb85ba",
      item: "58aca95a6317d867c8cb85b9",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-21T20:57:19.429Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-21T20:57:19.430Z",
      receivedDate: "2016-05-13T04:00:00.000Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: "Room 107",
      quantity: 0,
      expirationDate: "2017-03-21T04:00:00.000Z",
      lot: "2017-03-21",
      barcode: "17170321102017-03-21",
      orderQuantity: 4,
      company: "Beckman Coulter",
      title: "Microscan 0.5% N,N-Dimethylalphanaphthylamine",
      ref: "B1015-45",
      price: null,
    },
    {
      id: "58aca9c56317d867c8cb85bb",
      item: "58aca95a6317d867c8cb85b9",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-21T20:57:41.701Z",
      orderNumber: null,
      purchaseOrder: "MI000006",
      status: "In Use",
      dateInService: "2017-05-12T00:00:00.000Z",
      receivedDate: "2017-02-21T20:57:47.716Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: null,
      quantity: 0,
      expirationDate: "2017-12-27T00:00:00.000Z",
      lot: "2017-12-27",
      barcode: "01150995906558601116122717171227102017-12-27",
      orderQuantity: 4,
      company: "Beckman Coulter",
      title: "Microscan 0.5% N,N-Dimethylalphanaphthylamine",
      ref: "B1015-45",
      price: null,
    },
    {
      id: "58acaab778165867c2ddcbce",
      item: "58acaa5c78165867c2ddcbcd",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-21T21:01:43.807Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-21T21:01:43.809Z",
      receivedDate: "2016-08-15T04:00:00.000Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: "Room 107",
      quantity: 0,
      expirationDate: "2017-06-07T04:00:00.000Z",
      lot: "2017-06-07",
      barcode: "17170607102017-06-07",
      orderQuantity: 3,
      company: "Beckman Coulter",
      title: "Microscan Kovac's Reagent",
      ref: "B1015-41",
      price: null,
    },
    {
      id: "58acaac36317d867c8cb85bc",
      item: "58acaa5c78165867c2ddcbcd",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-21T21:01:55.767Z",
      orderNumber: null,
      purchaseOrder: "MI000007",
      status: "In Use",
      dateInService: "2017-05-12T00:00:00.000Z",
      receivedDate: "2017-02-21T21:02:00.777Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: null,
      quantity: 0,
      expirationDate: "2017-12-30T00:00:00.000Z",
      lot: "2017-12-30",
      barcode: "01150995906558221116123017171230102017-12-30",
      orderQuantity: 4,
      company: "Beckman Coulter",
      title: "Microscan Kovac's Reagent",
      ref: "B1015-41",
      price: null,
    },
    {
      id: "58adc674c46dea0cf35706d4",
      item: "58adc457e265d10cf99f58b4",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-22T17:12:20.451Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T17:12:20.455Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: "Refregerator #106",
      price: null,
      quantity: 0,
      expirationDate: "2017-05-01T04:00:00.000Z",
      lot: "392BF",
      barcode: "392CGBF",
      orderQuantity: 2,
      company: "PHADIA",
      title: "Allergen t2, Grey alder",
      ref: "14-4146-01",
    },
    {
      id: "58adc8d8e265d10cf99f58b5",
      item: "58adc796c46dea0cf35706d5",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-22T17:22:32.943Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T17:22:32.945Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: "Refregerator #106",
      price: null,
      quantity: 0,
      expirationDate: "2018-09-30T04:00:00.000Z",
      lot: "467C2",
      barcode: "01073330660022311718093010467C2",
      orderQuantity: 1,
      company: "PHADIA",
      title: "T8",
      ref: "14-4226-01",
    },
    {
      id: "58add07bc46dea0cf35706d7",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58adc457e265d10cf99f58b4",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-22T17:55:07.145Z",
      orderNumber: "12345",
      purchaseOrder: "Im000002",
      status: "In Use",
      dateInService: "2017-02-28T21:39:26.258Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: null,
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen t2, Grey alder",
      ref: "14-4146-01",
    },
    {
      id: "58add988e265d10cf99f58b7",
      item: "58adc9c4c46dea0cf35706d6",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-22T18:33:44.134Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T18:33:44.135Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: "Refrigerator #106",
      price: null,
      quantity: 0,
      expirationDate: "2018-06-30T04:00:00.000Z",
      lot: "599GC",
      barcode: null,
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen t73, Australian pine, Casuarina equisetifolia",
      ref: "14-4374-01",
    },
    {
      id: "58addd1ac46dea0cf35706d9",
      item: "58addcbfc46dea0cf35706d8",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-22T18:48:58.329Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T18:48:58.331Z",
      receivedDate: "2016-05-20T04:00:00.000Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: "Room 107",
      price: null,
      quantity: 0,
      expirationDate: "2017-08-13T04:00:00.000Z",
      lot: "749908",
      barcode: "01008488380237741717081310749908",
      orderQuantity: 1,
      company: "Remel",
      title: "BactiDrop Dobell & O'connor Iodine",
      ref: "R21510",
    },
    {
      id: "58addd42c46dea0cf35706da",
      user: "588f7207877ac43f2b662bbf",
      item: "58addcbfc46dea0cf35706d8",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-22T18:49:38.745Z",
      orderNumber: null,
      purchaseOrder: "MI000008",
      status: "In Use",
      dateInService: "2017-05-12T00:00:00.000Z",
      receivedDate: "2017-02-22T19:10:08.867Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-01-27T00:00:00.000Z",
      lot: "864786",
      barcode: "01008488380237741718012710864786",
      orderQuantity: 2,
      company: "Remel",
      title: "BactiDrop Dobell & O'connor Iodine",
      ref: "R21510",
    },
    {
      id: "58addddfc46dea0cf35706dc",
      item: "58adc457e265d10cf99f58b4",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-22T18:52:15.802Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T18:52:15.803Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: "Refrigerator #106",
      price: null,
      quantity: 0,
      expirationDate: "2018-10-31T04:00:00.000Z",
      lot: "406CV",
      barcode: "01073330660016091718103110406CV",
      orderQuantity: 0,
      company: "PHADIA",
      title: "Allergen t2, Grey alder",
      ref: "14-4146-01",
    },
    {
      id: "58addec7e265d10cf99f58b8",
      item: "58addd88c46dea0cf35706db",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-22T18:56:07.665Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T18:56:07.669Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: "Refrigerator #106",
      price: null,
      quantity: 0,
      expirationDate: "2018-10-31T04:00:00.000Z",
      lot: "406CV",
      barcode: "01073330660016091718103110406CV",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen t1, Box-elder, Acer negundo",
      ref: "14-4161-01",
    },
    {
      id: "58ade13ce265d10cf99f58ba",
      item: "58addf96e265d10cf99f58b9",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-22T19:06:36.719Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T19:06:36.723Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: "Refrigerator #106",
      price: null,
      quantity: 0,
      expirationDate: "2018-09-30T04:00:00.000Z",
      lot: "466BV",
      barcode: "01073330660022241718093010466BV",
      orderQuantity: null,
      company: "PHADIA",
      title: "T6",
      ref: "14-4225-01",
    },
    {
      id: "58ade250c596060a261920b8",
      item: "58addf96e265d10cf99f58b9",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-22T19:11:12.480Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T19:11:12.489Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: "Refrigerator #106",
      price: null,
      quantity: 0,
      expirationDate: "2018-09-30T04:00:00.000Z",
      lot: "406BV",
      barcode: "01073330660022241718093010466BV",
      orderQuantity: 1,
      company: "PHADIA",
      title: "T6",
      ref: "14-4225-01",
    },
    {
      id: "58ade382c596060a261920ba",
      item: "58ade2fdc596060a261920b9",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-22T19:16:18.002Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T19:16:18.003Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: "Refrigerator #106",
      price: null,
      quantity: 0,
      expirationDate: "2018-10-31T04:00:00.000Z",
      lot: "352CP",
      barcode: "01073330660010981718103110352CP",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergy t3, Common silver birch, Betula verrucosa",
      ref: "14-4102-01",
    },
    {
      id: "58ade4aa398ded0a2077f8ac",
      item: "58ade3f7c596060a261920bb",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-22T19:21:14.328Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T19:21:14.330Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: "Refrigerator #106",
      price: null,
      quantity: 0,
      expirationDate: "2018-09-30T04:00:00.000Z",
      lot: "359CF",
      barcode: "01073330660014941718093010395CF",
      orderQuantity: 1,
      company: "PHADIA",
      title: "T7",
      ref: "14-4149-01",
    },
    {
      id: "58ade57e398ded0a2077f8af",
      item: "58ade51f398ded0a2077f8ad",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-22T19:24:46.581Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T19:24:46.583Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: "Refrigerator #106",
      price: null,
      quantity: 0,
      expirationDate: "2018-09-30T04:00:00.000Z",
      lot: "553JK",
      barcode: "01073330660030471718093010553JK",
      orderQuantity: 1,
      company: "PHADIA",
      title: "T70",
      ref: "14-4325-01",
    },
    {
      id: "58ade68ec596060a261920bd",
      item: "58c03fc20f3a511b83e5d242",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-22T19:29:18.834Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T19:29:18.835Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: "Refrigerator #106",
      price: null,
      quantity: 0,
      expirationDate: "2018-10-31T04:00:00.000Z",
      lot: "BJNPX",
      barcode: "01073330660040371718103110BJNPX",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Total IgE, Anti-IgE",
      ref: "14-4509-01",
    },
    {
      id: "58ade7b2398ded0a2077f8b1",
      item: "58ade72c398ded0a2077f8b0",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-22T19:34:10.681Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T19:34:10.683Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: "Refrigerator #106",
      price: null,
      quantity: 0,
      expirationDate: "2018-06-30T04:00:00.000Z",
      lot: "371BB",
      barcode: "371CTBB",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen e3, Horse dander",
      ref: "14-4122-01",
    },
    {
      id: "58ade80bc596060a261920be",
      item: "58ade72c398ded0a2077f8b0",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-22T19:35:39.181Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T19:35:39.183Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: "Refrigerator #106",
      price: null,
      quantity: 0,
      expirationDate: "2018-06-30T04:00:00.000Z",
      lot: "371BB",
      barcode: "371CTBB",
      orderQuantity: 0,
      company: "PHADIA",
      title: "Allergen e3, Horse dander",
      ref: "14-4122-01",
    },
    {
      id: "58ade973398ded0a2077f8b3",
      item: "58ade8da398ded0a2077f8b2",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-22T19:41:39.931Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T19:41:39.933Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: "Refrigerator #106",
      price: null,
      quantity: 0,
      expirationDate: "2018-09-30T04:00:00.000Z",
      lot: "360E5",
      barcode: "01073330660011731718093010360E5",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen e5, Dog dander",
      ref: "14-4110-01",
    },
    {
      id: "58adec4fc596060a261920c0",
      item: "58adebefc596060a261920bf",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-22T19:53:51.375Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T19:53:51.377Z",
      receivedDate: "2017-01-31T05:00:00.000Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: "Room 107",
      price: null,
      quantity: 0,
      expirationDate: "2018-08-03T04:00:00.000Z",
      lot: "948537",
      barcode: "01008488380238661718080310948537",
      orderQuantity: 1,
      company: "Remel",
      title: "BactiDrop Nitrate A",
      ref: "R21536",
    },
    {
      id: "58aded40c596060a261920c1",
      item: "58adecb8398ded0a2077f8b5",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-22T19:57:52.833Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T19:57:52.834Z",
      receivedDate: "2016-10-05T04:00:00.000Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: "Room 107",
      price: null,
      quantity: 0,
      expirationDate: "2018-03-29T04:00:00.000Z",
      lot: "920180",
      barcode: "01008488380238801718032910920180",
      orderQuantity: 2,
      company: "Remel",
      title: "BactiDrop Oxidase",
      ref: "R21540",
    },
    {
      id: "58adee57398ded0a2077f8b6",
      item: "58adedbfc596060a261920c2",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-22T20:02:31.164Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T20:02:31.166Z",
      receivedDate: "2016-10-18T04:00:00.000Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: "Room 107",
      price: null,
      quantity: 0,
      expirationDate: "2018-06-30T04:00:00.000Z",
      lot: "936799",
      barcode: "01008488380239031718063010936799",
      orderQuantity: 3,
      company: "Remel",
      title: "BactiDrop Spot Indole",
      ref: "R21550",
    },
    {
      id: "58adef9dc596060a261920c4",
      item: "58adeeecc596060a261920c3",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-22T20:07:57.485Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T20:07:57.487Z",
      receivedDate: null,
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: "Room 107",
      price: null,
      quantity: 0,
      expirationDate: "2017-04-28T04:00:00.000Z",
      lot: "688318",
      barcode: "01008488380239101717042810688318",
      orderQuantity: 4,
      company: "Remel",
      title: "BactiDrop Voges-Proskauer A",
      ref: "R21560",
    },
    {
      id: "58adf000c596060a261920c5",
      item: "58adef43398ded0a2077f8b7",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-22T20:09:36.496Z",
      orderNumber: null,
      purchaseOrder: null,
      status: "In Use",
      dateInService: "2017-02-22T20:09:36.497Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: "Refrigerator #106",
      price: null,
      quantity: 0,
      expirationDate: "2018-09-30T04:00:00.000Z",
      lot: "407D6",
      barcode: "01073330660016161718093010407D6",
      orderQuantity: 1,
      company: "PHADIA",
      title: "W1",
      ref: "14-4162-01",
    },
    {
      id: "58af126065bff95855d62b6b",
      user: "588f77b58a54955129d060ed",
      item: "58af123097de60585491d65a",
      isDeleted: false,
      department: "Warehouse",
      trackingInformation: null,
      orderDate: "2017-02-23T16:48:32.459Z",
      orderNumber: null,
      purchaseOrder: "Wa000001",
      status: "In Use",
      dateInService: "2017-03-01T17:41:49.558Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: null,
      orderQuantity: 0,
      company: "PHADIA",
      title: "Allergen e6, Guinea pig epithelium",
      ref: "14-4167-01",
    },
    {
      id: "58af129b97de60585491d65b",
      user: "588f77b58a54955129d060ed",
      item: "58af123097de60585491d65a",
      isDeleted: false,
      department: "Warehouse",
      trackingInformation: null,
      orderDate: "2017-02-23T16:49:31.678Z",
      orderNumber: null,
      purchaseOrder: "Wa000002",
      status: "In Use",
      dateInService: "2017-03-01T17:41:54.738Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: null,
      orderQuantity: 0,
      company: "PHADIA",
      title: "Allergen e6, Guinea pig epithelium",
      ref: "14-4167-01",
    },
    {
      id: "58af12ab97de60585491d65c",
      user: "588f77b58a54955129d060ed",
      item: "58af123097de60585491d65a",
      isDeleted: false,
      department: "Warehouse",
      trackingInformation: null,
      orderDate: "2017-02-23T16:49:47.277Z",
      orderNumber: null,
      purchaseOrder: "Wa000003",
      status: "In Use",
      dateInService: "2017-03-01T17:41:58.295Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: null,
      orderQuantity: 0,
      company: "PHADIA",
      title: "Allergen e6, Guinea pig epithelium",
      ref: "14-4167-01",
    },
    {
      id: "58af12b497de60585491d65d",
      user: "588f77b58a54955129d060ed",
      item: "58af123097de60585491d65a",
      isDeleted: false,
      department: "Warehouse",
      trackingInformation: null,
      orderDate: "2017-02-23T16:49:56.964Z",
      orderNumber: null,
      purchaseOrder: "Wa000004",
      status: "In Use",
      dateInService: "2017-03-01T17:42:02.440Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: null,
      orderQuantity: 0,
      company: "PHADIA",
      title: "Allergen e6, Guinea pig epithelium",
      ref: "14-4167-01",
    },
    {
      id: "58af12b965bff95855d62b6c",
      user: "588f77b58a54955129d060ed",
      item: "58af123097de60585491d65a",
      isDeleted: false,
      department: "Warehouse",
      trackingInformation: null,
      orderDate: "2017-02-23T16:50:01.365Z",
      orderNumber: null,
      purchaseOrder: "Wa000005",
      status: "In Use",
      dateInService: "2017-03-01T17:42:06.677Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: null,
      orderQuantity: 0,
      company: "PHADIA",
      title: "Allergen e6, Guinea pig epithelium",
      ref: "14-4167-01",
    },
    {
      id: "58af151a97de60585491d65e",
      user: "588f77b58a54955129d060ed",
      item: "58af123097de60585491d65a",
      isDeleted: false,
      department: "Warehouse",
      trackingInformation: null,
      orderDate: "2017-02-23T17:00:10.826Z",
      orderNumber: null,
      purchaseOrder: "WA000006",
      status: "In Use",
      dateInService: "2017-02-23T17:13:59.547Z",
      receivedDate: "2017-02-23T17:03:18.076Z",
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-07-31T00:00:00.000Z",
      lot: "412AW",
      barcode: "01073330660016611718073110412AW",
      orderQuantity: 20,
      company: "PHADIA",
      title: "Allergen e6, Guinea pig epithelium",
      ref: "14-4167-01",
    },
    {
      id: "58af15fa97de60585491d65f",
      user: "588f7673191b9d4dce1d8d5d",
      item: "58a5d14e062bf504c76ade44",
      isDeleted: false,
      department: "Cytology",
      trackingInformation: null,
      orderDate: "2017-02-23T17:03:54.463Z",
      orderNumber: null,
      purchaseOrder: "CY000001",
      status: "In Use",
      dateInService: "2017-02-23T17:11:16.536Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-02-18T00:00:00.000Z",
      lot: "373943",
      barcode: null,
      orderQuantity: 4,
      company: "Richard Allan Sci.",
      title: "Bluing Reagent",
      ref: "7301",
      comment: "",
    },
    {
      id: "58af1a3365bff95855d62b6d",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58ade9e1398ded0a2077f8b4",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T17:21:55.198Z",
      orderNumber: null,
      purchaseOrder: "IM000003",
      status: "In Use",
      dateInService: "2017-02-23T17:27:01.556Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-10-31T00:00:00.000Z",
      lot: "359DX",
      barcode: "01073330660011661718103110359DX",
      orderQuantity: 26,
      company: "PHADIA",
      title: "Allergen e1, Cat dander",
      ref: "14-4109-01",
    },
    {
      id: "58af295b97de60585491d660",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af26de65bff95855d62b6e",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T18:26:35.803Z",
      orderNumber: null,
      purchaseOrder: "Im000004",
      status: "In Use",
      dateInService: "2017-02-28T21:12:26.389Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: null,
      orderQuantity: null,
      company: "PHADIA",
      title: "Allergen w14, Common pigweed, Amaranthus retroflexus",
      ref: "14-4252-01",
    },
    {
      id: "58af296165bff95855d62b6f",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af26de65bff95855d62b6e",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T18:26:41.185Z",
      orderNumber: null,
      purchaseOrder: "Im000005",
      status: "In Use",
      dateInService: "2017-02-23T18:29:02.798Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-09-30T00:00:00.000Z",
      lot: "490DR",
      barcode: "01073330660024601718093010490DR",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen w14, Common pigweed, Amaranthus retroflexus",
      ref: "14-4252-01",
    },
    {
      id: "58af2ac197de60585491d661",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af2aac65bff95855d62b70",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T18:32:33.337Z",
      orderNumber: null,
      purchaseOrder: "IM000006",
      status: "In Use",
      dateInService: "2017-02-23T18:33:31.698Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-08-31T00:00:00.000Z",
      lot: "353CJ",
      barcode: "01073330660011041718083110353CJ",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen w6, Mugwort, Artemisia vulgaris",
      ref: "14-4103-01",
    },
    {
      id: "58af2c7e65bff95855d62b71",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af2c6097de60585491d662",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T18:39:58.788Z",
      orderNumber: null,
      purchaseOrder: "IM000007",
      status: "In Use",
      dateInService: "2017-02-23T18:40:44.009Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-10-31T00:00:00.000Z",
      lot: "368HR",
      barcode: "01073330660012581718103110368HR",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen m3, Aspergillius fumigatus",
      ref: "14-4119-01",
    },
    {
      id: "58af2d4b97de60585491d664",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af2d2b97de60585491d663",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T18:43:23.130Z",
      orderNumber: null,
      purchaseOrder: "Im000008",
      status: "In Use",
      dateInService: "2017-02-23T18:44:07.560Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-09-30T00:00:00.000Z",
      lot: "357GZ",
      barcode: "01073330660011421718093010357GZ",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen d1, House dust mite, Dermatophagoides pteronyssinus",
      ref: "14-4107-01",
    },
    {
      id: "58af2f2797de60585491d666",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af2f0b97de60585491d665",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T18:51:19.156Z",
      orderNumber: null,
      purchaseOrder: "IM000009",
      status: "In Use",
      dateInService: "2017-02-23T18:52:15.477Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-09-30T00:00:00.000Z",
      lot: "906H7",
      barcode: "01073330660042041718093010906H7",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen f201, Pecan nut, Carya illinoensis",
      ref: "14-4807-01",
    },
    {
      id: "58af2fe797de60585491d668",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af2fd197de60585491d667",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T18:54:31.743Z",
      orderNumber: null,
      purchaseOrder: "IM000010",
      status: "In Use",
      dateInService: "2017-02-23T18:55:13.896Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-09-30T00:00:00.000Z",
      lot: "997HW",
      barcode: "01073330660044711718093010997HW",
      orderQuantity: 1,
      company: "PHADIA",
      title: "F202",
      ref: "14-4835-01",
    },
    {
      id: "58af309697de60585491d669",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af308465bff95855d62b72",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T18:57:26.094Z",
      orderNumber: null,
      purchaseOrder: "IM000011",
      status: "In Use",
      dateInService: "2017-02-23T18:58:01.134Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-09-30T00:00:00.000Z",
      lot: "CMEB3",
      barcode: "01073330660040991718093010CMEB3",
      orderQuantity: 1,
      company: "PHADIA",
      title: "F256",
      ref: "14-4521-01",
    },
    {
      id: "58af313297de60585491d66a",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af311e65bff95855d62b73",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T19:00:02.225Z",
      orderNumber: null,
      purchaseOrder: "IM000012",
      status: "In Use",
      dateInService: "2017-02-23T19:01:02.183Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-09-30T00:00:00.000Z",
      lot: "BHNJA",
      barcode: "BHNCWJA",
      orderQuantity: 1,
      company: "PHADIA",
      title: "F338",
      ref: "14-4895-01",
    },
    {
      id: "58af320e97de60585491d66c",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af31fa97de60585491d66b",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T19:03:42.644Z",
      orderNumber: null,
      purchaseOrder: "IM000013",
      status: "In Use",
      dateInService: "2017-02-23T19:04:33.804Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-03-31T00:00:00.000Z",
      lot: "419AY",
      barcode: "419CQAY",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen f12, Pea, Pisum sativum",
      ref: "14-4176-01",
    },
    {
      id: "58af32a865bff95855d62b75",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af329165bff95855d62b74",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T19:06:16.901Z",
      orderNumber: null,
      purchaseOrder: "IM000014",
      status: "In Use",
      dateInService: "2017-02-23T19:06:49.533Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-10-31T00:00:00.000Z",
      lot: "525HY",
      barcode: "01073330660027741718103110525HY",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen f79, Gluten",
      ref: "14-4291-01",
    },
    {
      id: "58af336e97de60585491d66e",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af335c97de60585491d66d",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T19:09:34.421Z",
      orderNumber: null,
      purchaseOrder: "Im000015",
      status: "In Use",
      dateInService: "2017-02-23T19:10:20.501Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: "421BA",
      barcode: "421CJBA",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen f18, Brazil nut, Berthollethia excelsa",
      ref: "14-4178-01",
    },
    {
      id: "58af344197de60585491d670",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af341397de60585491d66f",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T19:13:05.203Z",
      orderNumber: null,
      purchaseOrder: "IM000016",
      status: "In Use",
      dateInService: "2017-02-23T19:13:38.761Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-04-30T00:00:00.000Z",
      lot: "456C4",
      barcode: "01073330660021251718043010456C4",
      orderQuantity: 1,
      company: "PHADIA",
      title: "F41",
      ref: "14-4215-01",
    },
    {
      id: "58af34d165bff95855d62b77",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af34c565bff95855d62b76",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T19:15:29.420Z",
      orderNumber: null,
      purchaseOrder: "IM000017",
      status: "In Use",
      dateInService: "2017-02-23T19:16:00.824Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-10-31T00:00:00.000Z",
      lot: "364D4",
      barcode: "01073330660012101718103110364D4",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen f14, Soybean, Glycine max",
      ref: "14-4115-01",
    },
    {
      id: "58af357065bff95855d62b79",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af355f65bff95855d62b78",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T19:18:08.870Z",
      orderNumber: null,
      purchaseOrder: "IM000018",
      status: "In Use",
      dateInService: "2017-02-23T19:18:41.306Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-11-30T00:00:00.000Z",
      lot: "418CN",
      barcode: "01073330660017461718113010418CN",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen f10, Sesame seed, Sesamum indicum",
      ref: "14-4175-01",
    },
    {
      id: "58af361065bff95855d62b7a",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af360197de60585491d671",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T19:20:48.815Z",
      orderNumber: null,
      purchaseOrder: "IM000019",
      status: "In Use",
      dateInService: "2017-02-23T19:21:52.953Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-10-31T00:00:00.000Z",
      lot: "375KX",
      barcode: "375CXKX",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen f13, Peanut, Arachis hypogaea",
      ref: "14-4126-01",
    },
    {
      id: "58af36cf97de60585491d672",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58c03e6a14bf7f1b54ca8917",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T19:23:59.908Z",
      orderNumber: null,
      purchaseOrder: "IM000020",
      status: "In Use",
      dateInService: "2017-02-23T19:24:32.802Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-10-31T00:00:00.000Z",
      lot: "362G1",
      barcode: "01073330660011971718103110362G1",
      orderQuantity: 1,
      company: "PHADIA",
      title: "ALLERGEN F2 MILK",
      ref: "14-4112-01",
    },
    {
      id: "58af376365bff95855d62b7c",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af375997de60585491d673",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T19:26:27.931Z",
      orderNumber: null,
      purchaseOrder: "IM000021",
      status: "In Use",
      dateInService: "2017-02-23T19:27:03.949Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-10-31T00:00:00.000Z",
      lot: "404C5",
      barcode: "01073330660015861718103110404C5",
      orderQuantity: 1,
      company: "PHADIA",
      title: "F3",
      ref: "14-4159-01",
    },
    {
      id: "58af37fd97de60585491d674",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af37f465bff95855d62b7d",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T19:29:01.214Z",
      orderNumber: null,
      purchaseOrder: "IM000022",
      status: "In Use",
      dateInService: "2017-02-23T19:30:11.623Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-10-31T00:00:00.000Z",
      lot: "363E6",
      barcode: "01073330660012031718103110363E6",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen f4, Wheat, Triticum aestivum",
      ref: "14-4113-01",
    },
    {
      id: "58af38a297de60585491d676",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af389597de60585491d675",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T19:31:46.015Z",
      orderNumber: null,
      purchaseOrder: "IM000023",
      status: "In Use",
      dateInService: "2017-02-23T19:32:17.493Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-10-31T00:00:00.000Z",
      lot: "361E3",
      barcode: "01073330660011801718103110361E3",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen f1, Egg white",
      ref: "14-4111-01",
    },
    {
      id: "58af395d65bff95855d62b7e",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af395297de60585491d677",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T19:34:53.755Z",
      orderNumber: null,
      purchaseOrder: "Im000024",
      status: "In Use",
      dateInService: "2017-02-23T19:35:32.346Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-04-30T00:00:00.000Z",
      lot: "416B5",
      barcode: "416CRB5",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen f7, Oat, Avena sativa ",
      ref: "14-4173-01",
    },
    {
      id: "58af39f965bff95855d62b7f",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af39ea97de60585491d678",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T19:37:29.204Z",
      orderNumber: null,
      purchaseOrder: "IM000025",
      status: "In Use",
      dateInService: "2017-02-23T19:38:00.546Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-10-31T00:00:00.000Z",
      lot: "424DP",
      barcode: "01073330660018071718103110424DP",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen f24, Shrimp Pandalus birealis...",
      ref: "14-4181-01",
    },
    {
      id: "58af3a8665bff95855d62b81",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58c03ded9214ce1d134d85c2",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T19:39:50.653Z",
      orderNumber: null,
      purchaseOrder: "IM000026",
      status: "In Use",
      dateInService: "2017-02-23T19:40:21.975Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-11-30T00:00:00.000Z",
      lot: "422CD",
      barcode: "01073330660017841718113010422CD",
      orderQuantity: 1,
      company: "PHADIA",
      title: "Allergen f20, Almond, Amygdalus communis",
      ref: "14-4179-01",
    },
    {
      id: "58af3afd97de60585491d67a",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af3af097de60585491d679",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T19:41:49.896Z",
      orderNumber: null,
      purchaseOrder: "IM000027",
      status: "In Use",
      dateInService: "2017-02-23T19:42:30.258Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-06-30T00:00:00.000Z",
      lot: "373BU",
      barcode: "01073330660012961718063010373BU",
      orderQuantity: 1,
      company: "PHADIA",
      title: "F8",
      ref: "14-4124-01",
    },
    {
      id: "58af3bc397de60585491d67b",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af3baa65bff95855d62b82",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T19:45:07.740Z",
      orderNumber: null,
      purchaseOrder: "IM000028",
      status: "In Use",
      dateInService: "2017-02-23T19:45:49.949Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-10-31T00:00:00.000Z",
      lot: "D2FB7",
      barcode: "01073330660041671718103110D2FB7",
      orderQuantity: 20,
      company: "PHADIA",
      title: "Allergen f17, Hazel nut, Corylus avellana",
      ref: "14-4528-01",
    },
    {
      id: "58af3d6865bff95855d62b84",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af3d4865bff95855d62b83",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-23T19:52:08.068Z",
      orderNumber: null,
      purchaseOrder: "IM000029",
      status: "In Use",
      dateInService: "2017-02-23T19:52:49.618Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-11-30T00:00:00.000Z",
      lot: "JXXM",
      barcode: "01073330660000081718113010JXXM",
      orderQuantity: 16,
      company: "Thermo Scientific",
      title: "Washing Solution ",
      ref: "10-9202-01",
    },
    {
      id: "58b053ef65bff95855d62b85",
      user: "588f7673191b9d4dce1d8d5d",
      item: "58a5d1ee062bf504c76ade46",
      isDeleted: false,
      department: "Cytology",
      trackingInformation: null,
      orderDate: "2017-02-24T15:40:31.119Z",
      orderNumber: null,
      purchaseOrder: "CY000002",
      status: "In Use",
      dateInService: "2017-02-24T15:45:00.361Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-10-17T00:00:00.000Z",
      lot: "6299EA",
      barcode: "2340046299EA",
      orderQuantity: 7,
      company: "Hologic",
      title: "TP PreservCyt Solution",
      ref: "234004",
      comment: "",
    },
    {
      id: "58b460df97de60585491d67d",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b45f7797de60585491d67c",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T17:24:47.160Z",
      orderNumber: null,
      purchaseOrder: "IM000030",
      status: "In Use",
      dateInService: "2017-02-27T17:28:01.126Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2019-10-01T00:00:00.000Z",
      lot: "F168833P",
      barcode: "01080567711024141719100010F168833P240X0015",
      orderQuantity: 12,
      company: "DiaSorin",
      title: "Disposable Tips",
      ref: "X0015",
    },
    {
      id: "58b4636297de60585491d67f",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b4634797de60585491d67e",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T17:35:30.402Z",
      orderNumber: null,
      purchaseOrder: "IM000031",
      status: "In Use",
      dateInService: "2017-02-27T17:53:26.110Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2019-06-01T00:00:00.000Z",
      lot: "60101159711002",
      barcode: "+$$061960101159711002JG",
      orderQuantity: 4,
      company: "DiaSorin",
      title: "Cuvettes",
      ref: "X0016",
    },
    {
      id: "58b46a5b65bff95855d62b87",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b46a2265bff95855d62b86",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T18:05:15.197Z",
      orderNumber: null,
      purchaseOrder: "Im000032",
      status: "In Use",
      dateInService: "2017-02-27T18:06:17.997Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2017-10-17T00:00:00.000Z",
      lot: "133386XA",
      barcode: "01080567716020061717101710133386XA240310985",
      orderQuantity: 1,
      company: "DiaSorin",
      title: "1.25 Dihydroxyvitamin D",
      ref: "310985",
    },
    {
      id: "58b46bf365bff95855d62b88",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b46be597de60585491d681",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T18:12:03.720Z",
      orderNumber: null,
      purchaseOrder: "Im000033",
      status: "In Use",
      dateInService: "2017-02-27T18:14:17.697Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-02-25T00:00:00.000Z",
      lot: "163017X",
      barcode: "01080567711013871718022510163017X240318840",
      orderQuantity: 6,
      company: "DiaSorin",
      title: "Mumps IgG",
      ref: "318840",
    },
    {
      id: "58b46c0497de60585491d682",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b46b9e97de60585491d680",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T18:12:20.123Z",
      orderNumber: null,
      purchaseOrder: "Im000034",
      status: "In Use",
      dateInService: "2017-02-27T18:13:16.175Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2017-07-16T00:00:00.000Z",
      lot: "159014XC",
      barcode: "01080567711013251717071610159014XC240318810",
      orderQuantity: 5,
      company: "DiaSorin",
      title: "Measles IgG",
      ref: "318810",
    },
    {
      id: "58b474fd97de60585491d684",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b474b165bff95855d62b8d",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T18:50:37.954Z",
      orderNumber: null,
      purchaseOrder: "IM000035",
      status: "In Use",
      dateInService: null,
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: "660483123216",
      orderQuantity: 1,
      company: "Alconox",
      title: "Liquinox",
      ref: "21943",
    },
    {
      id: "58b4759a65bff95855d62b8e",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b4745e65bff95855d62b8c",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T18:53:14.114Z",
      orderNumber: null,
      purchaseOrder: "IM000036",
      status: "In Use",
      dateInService: "2017-02-27T18:55:31.742Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2017-11-30T00:00:00.000Z",
      lot: "177025x",
      barcode: "01080567711011341717113010177025X240310995",
      orderQuantity: 1,
      company: "DiaSorin",
      title: "Cleaning Tool",
      ref: "310995",
    },
    {
      id: "58b4769665bff95855d62b8f",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b473c865bff95855d62b8b",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T18:57:26.899Z",
      orderNumber: null,
      purchaseOrder: "IM000037",
      status: "In Use",
      dateInService: "2017-02-27T18:58:19.073Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2017-07-22T00:00:00.000Z",
      lot: "133218xb",
      barcode: "01080567716002621717072210133218XB240310870",
      orderQuantity: 7,
      company: "DiaSorin",
      title: "Borrelia burgdorferi",
      ref: "310870",
    },
    {
      id: "58b476e665bff95855d62b90",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b4738765bff95855d62b8a",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T18:58:46.467Z",
      orderNumber: null,
      purchaseOrder: "Im000038",
      status: "In Use",
      dateInService: "2017-02-27T18:59:23.914Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2017-08-26T00:00:00.000Z",
      lot: "133301xc",
      barcode: "01080567716001011717082610133301XC240310620",
      orderQuantity: 3,
      company: "DiaSorin",
      title: "HSV-2 Type Specific IgG",
      ref: "310620",
    },
    {
      id: "58b4772965bff95855d62b91",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b4734f65bff95855d62b89",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T18:59:53.831Z",
      orderNumber: null,
      purchaseOrder: "Im000039",
      status: "In Use",
      dateInService: "2017-02-27T19:00:34.363Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2017-04-30T00:00:00.000Z",
      lot: "133409xb",
      barcode: "01080567716002311717043010133409XB240310830",
      orderQuantity: 4,
      company: "DiaSorin",
      title: "HSV-1 Type Specific IgG",
      ref: "310830",
    },
    {
      id: "58b4778765bff95855d62b92",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b472ff97de60585491d683",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T19:01:27.417Z",
      orderNumber: null,
      purchaseOrder: "Im000040",
      status: "In Use",
      dateInService: "2017-02-27T19:02:10.140Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2017-07-04T00:00:00.000Z",
      lot: "019040x",
      barcode: "01080567711005711717070410019040X240310460",
      orderQuantity: 4,
      company: "DiaSorin",
      title: "Rubella IgG",
      ref: "310460",
    },
    {
      id: "58b4785f97de60585491d686",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b4784897de60585491d685",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T19:05:03.126Z",
      orderNumber: null,
      purchaseOrder: "Im000041",
      status: "In Use",
      dateInService: "2017-02-27T19:05:39.267Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2017-09-12T00:00:00.000Z",
      lot: "243007xa",
      barcode: "01080567711006251717091210243007XA240310495",
      orderQuantity: 5,
      company: "DiaSorin",
      title: "VZV IgG",
      ref: "310495",
    },
    {
      id: "58b478f797de60585491d687",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b4634797de60585491d67e",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T19:07:35.062Z",
      orderNumber: null,
      purchaseOrder: "IM000042",
      status: "In Use",
      dateInService: null,
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: null,
      orderQuantity: 10,
      company: "DiaSorin",
      title: "Cuvettes",
      ref: "X0016",
    },
    {
      id: "58b4791b65bff95855d62b93",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b46a2265bff95855d62b86",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T19:08:11.591Z",
      orderNumber: null,
      purchaseOrder: "IM000043",
      status: "In Use",
      dateInService: null,
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: null,
      orderQuantity: 15,
      company: "DiaSorin",
      title: "1.25 Dihydroxyvitamin D",
      ref: "310985",
    },
    {
      id: "58b4793365bff95855d62b94",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b46b9e97de60585491d680",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T19:08:35.734Z",
      orderNumber: null,
      purchaseOrder: "IM000044",
      status: "In Use",
      dateInService: null,
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: null,
      orderQuantity: 15,
      company: "DiaSorin",
      title: "Measles IgG",
      ref: "318810",
    },
    {
      id: "58b4794a97de60585491d688",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b46be597de60585491d681",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T19:08:58.006Z",
      orderNumber: null,
      purchaseOrder: "IM000045",
      status: "In Use",
      dateInService: null,
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: null,
      orderQuantity: 15,
      company: "DiaSorin",
      title: "Mumps IgG",
      ref: "318840",
    },
    {
      id: "58b4795f97de60585491d689",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b4784897de60585491d685",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T19:09:19.768Z",
      orderNumber: null,
      purchaseOrder: "IM000046",
      status: "In Use",
      dateInService: null,
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: null,
      orderQuantity: 15,
      company: "DiaSorin",
      title: "VZV IgG",
      ref: "310495",
    },
    {
      id: "58b4796f65bff95855d62b95",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b472ff97de60585491d683",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T19:09:35.370Z",
      orderNumber: null,
      purchaseOrder: "IM000047",
      status: "In Use",
      dateInService: null,
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: null,
      orderQuantity: 15,
      company: "DiaSorin",
      title: "Rubella IgG",
      ref: "310460",
    },
    {
      id: "58b4798265bff95855d62b96",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b4734f65bff95855d62b89",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T19:09:54.982Z",
      orderNumber: null,
      purchaseOrder: "IM000048",
      status: "In Use",
      dateInService: null,
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: null,
      orderQuantity: 15,
      company: "DiaSorin",
      title: "HSV-1 Type Specific IgG",
      ref: "310830",
    },
    {
      id: "58b4799697de60585491d68a",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b4738765bff95855d62b8a",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T19:10:14.146Z",
      orderNumber: null,
      purchaseOrder: "IM000049",
      status: "In Use",
      dateInService: null,
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: null,
      orderQuantity: 15,
      company: "DiaSorin",
      title: "HSV-2 Type Specific IgG",
      ref: "310620",
    },
    {
      id: "58b479a565bff95855d62b97",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b473c865bff95855d62b8b",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T19:10:29.255Z",
      orderNumber: null,
      purchaseOrder: "IM000050",
      status: "In Use",
      dateInService: null,
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: null,
      orderQuantity: 8,
      company: "DiaSorin",
      title: "Borrelia burgdorferi",
      ref: "310870",
    },
    {
      id: "58b479fb65bff95855d62b98",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b45f7797de60585491d67c",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T19:11:55.201Z",
      orderNumber: null,
      purchaseOrder: "IM000051",
      status: "In Use",
      dateInService: null,
      receivedDate: "2017-05-18T21:10:29.888Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: "IMMUNOLOGY",
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: null,
      orderQuantity: 48,
      company: "DiaSorin",
      title: "Disposable Tips",
      ref: "X0015",
    },
    {
      id: "58b47a2465bff95855d62b99",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b4745e65bff95855d62b8c",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-27T19:12:36.995Z",
      orderNumber: null,
      purchaseOrder: "IM000052",
      status: "In Use",
      dateInService: null,
      receivedDate: "2017-05-18T21:10:33.621Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: "IMMUNOLOGY",
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: null,
      orderQuantity: 4,
      company: "DiaSorin",
      title: "Cleaning Tool",
      ref: "310995",
    },
    {
      id: "58b47eb065bff95855d62b9b",
      user: "588f7207877ac43f2b662bbf",
      item: "58b47e7065bff95855d62b9a",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-27T19:32:00.445Z",
      orderNumber: null,
      purchaseOrder: "Mi000009",
      status: "In Use",
      dateInService: "2017-02-27T19:35:24.627Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-01-18T00:00:00.000Z",
      lot: "414017",
      barcode: "01008153810200621718011810414017",
      orderQuantity: 2,
      company: "BioFire Diagnostics, LLC.",
      title: "FilmArray Respiratory Panel",
      ref: "RFIT-ASY-0124",
    },
    {
      id: "58b47fc197de60585491d68b",
      user: "588f7207877ac43f2b662bbf",
      item: "58b47e7065bff95855d62b9a",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-27T19:36:33.959Z",
      orderNumber: "250228",
      purchaseOrder: "Mi000010",
      status: "In Use",
      dateInService: "2017-03-07T16:58:20.446Z",
      receivedDate: "2017-02-28T18:10:19.470Z",
      testedDate: "2017-03-02T00:00:00.000Z",
      testResult: "PASS",
      testMethod: "Lot to Lot",
      location: "Room 107",
      price: null,
      quantity: 0,
      expirationDate: "2018-01-25T00:00:00.000Z",
      lot: "433517",
      barcode: "01008153810200621718012510433517",
      orderQuantity: 2,
      company: "BioFire Diagnostics, LLC.",
      title: "FilmArray Respiratory Panel",
      ref: "RFIT-ASY-0124",
    },
    {
      id: "58b48f5167f8900cbf641af0",
      user: "588f74b96a480845a7938fab",
      item: "58a36a57d49a9302f84f6791",
      isDeleted: false,
      department: "Molecular",
      trackingInformation: null,
      orderDate: "2017-02-27T20:42:57.476Z",
      orderNumber: null,
      purchaseOrder: "Mo000005",
      status: "In Use",
      dateInService: "2017-02-27T20:44:48.766Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-11-15T00:00:00.000Z",
      lot: "188629H",
      barcode: "011542004550024210188629H",
      orderQuantity: 4,
      company: "Hologic",
      title: "Aptima Assay Fluids Kit",
      ref: "302382",
    },
    {
      id: "58b499ec8d5afc0cc526b6ce",
      user: "588f7207877ac43f2b662bbf",
      item: "58b499dd67f8900cbf641af1",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-27T21:28:12.681Z",
      orderNumber: "A70595168 ",
      purchaseOrder: "Mi000011",
      status: "In Use",
      dateInService: "2017-03-17T19:15:38.034Z",
      receivedDate: "2017-03-01T18:31:29.454Z",
      testedDate: "2017-03-01T00:00:00.000Z",
      testResult: "PASS",
      testMethod: "MICQC",
      location: "Room 107",
      price: null,
      quantity: 0,
      expirationDate: "2017-09-30T00:00:00.000Z",
      lot: "1933505",
      barcode: "010084883802915817170930101933505",
      orderQuantity: 3,
      company: "Remel Inc.",
      title: "Staphaurex",
      ref: "30859901",
    },
    {
      id: "58b49a678d5afc0cc526b6d0",
      user: "588f7207877ac43f2b662bbf",
      item: "58b49a5c8d5afc0cc526b6cf",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-27T21:30:15.975Z",
      orderNumber: "A70595168 ",
      purchaseOrder: "Mi000012",
      status: "In Use",
      dateInService: "2017-03-17T19:15:12.173Z",
      receivedDate: "2017-03-01T18:33:10.030Z",
      testedDate: "2017-03-01T00:00:00.000Z",
      testResult: "PASS",
      testMethod: "MICQC",
      location: "Room 107",
      price: null,
      quantity: 0,
      expirationDate: "2017-12-15T00:00:00.000Z",
      lot: "966497",
      barcode: "01008488380225311717121510966497",
      orderQuantity: 5,
      company: "Remel Inc.",
      title: "PYRDisc",
      ref: "R211172",
    },
    {
      id: "58b49ad88d5afc0cc526b6d1",
      user: "588f7207877ac43f2b662bbf",
      item: "58b49acd67f8900cbf641af2",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-27T21:32:08.470Z",
      orderNumber: "1562184",
      purchaseOrder: "MI000013",
      status: "In Use",
      dateInService: null,
      receivedDate: "2017-03-07T17:11:39.776Z",
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-02-01T00:00:00.000Z",
      lot: "0117381",
      barcode: null,
      orderQuantity: 1,
      company: "Alere",
      title: "C. Diff Quik Chek Complete",
      ref: "30550C",
    },
    {
      id: "58b49b3567f8900cbf641af3",
      user: "588f7207877ac43f2b662bbf",
      item: "58b49b2b8d5afc0cc526b6d2",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-27T21:33:41.857Z",
      orderNumber: "A70595168 ",
      purchaseOrder: "Mi000014",
      status: "In Use",
      dateInService: "2017-03-17T19:14:02.632Z",
      receivedDate: "2017-03-01T16:33:01.191Z",
      testedDate: "2017-03-01T00:00:00.000Z",
      testResult: "PASS",
      testMethod: "MICQC",
      location: "Room 107",
      price: null,
      quantity: 0,
      expirationDate: "2017-04-24T00:00:00.000Z",
      lot: "106953",
      barcode: "010084883800064517170424101069539126",
      orderQuantity: 5,
      company: "remel",
      title: "Blood Agar Plate",
      ref: "R01202",
    },
    {
      id: "58b59c848d5afc0cc526b6d4",
      user: "588f7207877ac43f2b662bbf",
      item: "58b59c568d5afc0cc526b6d3",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T15:51:32.098Z",
      orderNumber: null,
      purchaseOrder: "MI000015",
      status: "In Use",
      dateInService: "2017-02-28T15:51:45.966Z",
      receivedDate: "2017-02-28T15:51:43.798Z",
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2017-05-14T00:00:00.000Z",
      lot: "712141",
      barcode: "01008488380239271717051410712141",
      orderQuantity: 2,
      company: "Remel",
      title: "BactiDrop Voges-Proskauer B",
      ref: "R21562",
    },
    {
      id: "58b59e288d5afc0cc526b6d6",
      user: "588f7207877ac43f2b662bbf",
      item: "58b59dfc8d5afc0cc526b6d5",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T15:58:32.067Z",
      orderNumber: null,
      purchaseOrder: "Mi000016",
      status: "In Use",
      dateInService: "2017-02-28T15:58:45.113Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-04-05T00:00:00.000Z",
      lot: "6281522",
      barcode: "17180405106281522300100",
      orderQuantity: 1,
      company: "Becton, Dickinson and Company",
      title: "BBL Tryticase Soy Broth",
      ref: "221815",
    },
    {
      id: "58b59fdf8d5afc0cc526b6d7",
      user: "588f7207877ac43f2b662bbf",
      item: "58b59fc167f8900cbf641af4",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T16:05:51.101Z",
      orderNumber: null,
      purchaseOrder: "MI000017",
      status: "In Use",
      dateInService: "2017-02-28T16:06:02.826Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2017-05-01T00:00:00.000Z",
      lot: "52231S",
      barcode: "010084883805671017170501105223IS",
      orderQuantity: 3,
      company: "Mitsubishi Gas Chemical Company, Inc. ",
      title: "AnaeroPouch System Pouch-CO2",
      ref: "R682007",
    },
    {
      id: "58b5a1258d5afc0cc526b6d9",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5a1168d5afc0cc526b6d8",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T16:11:17.832Z",
      orderNumber: null,
      purchaseOrder: "Mi000018",
      status: "In Use",
      dateInService: "2017-02-28T16:11:27.549Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 1,
      expirationDate: "2021-05-16T00:00:00.000Z",
      lot: "002121",
      barcode: "4014200212121136",
      orderQuantity: 1,
      company: "Remel",
      title: "QC- Slide Gram Stain Control",
      ref: "R40142",
    },
    {
      id: "58b5a31f67f8900cbf641af6",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5a29c67f8900cbf641af5",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T16:19:43.900Z",
      orderNumber: null,
      purchaseOrder: "Mi000019",
      status: "In Use",
      dateInService: "2017-02-28T16:23:24.325Z",
      receivedDate: "2017-02-28T16:20:20.142Z",
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: null,
      lot: null,
      barcode: null,
      orderQuantity: 0,
      company: "FISHERbrand",
      title: "Control and Test Slide for Gram Stain Procedures",
      ref: "08-801",
    },
    {
      id: "58b5a32667f8900cbf641af7",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5a29c67f8900cbf641af5",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T16:19:50.467Z",
      orderNumber: null,
      purchaseOrder: "Mi000020",
      status: "In Use",
      dateInService: "2017-02-28T16:20:02.508Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 1,
      expirationDate: "2017-07-31T00:00:00.000Z",
      lot: "471",
      barcode: "+H1380880134",
      orderQuantity: 1,
      company: "FISHERbrand",
      title: "Control and Test Slide for Gram Stain Procedures",
      ref: "08-801",
    },
    {
      id: "58b5a50c8d5afc0cc526b6da",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5a4f867f8900cbf641af8",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T16:27:56.642Z",
      orderNumber: null,
      purchaseOrder: "Mi000021",
      status: "In Use",
      dateInService: "2017-02-28T16:28:08.293Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-03-01T00:00:00.000Z",
      lot: "6272532",
      barcode: "171803011062725323020",
      orderQuantity: 1,
      company: "Becton, Dickinson and Company",
      title: "GasPak EZ CO2 Container System",
      ref: "260679",
    },
    {
      id: "58b5a5598d5afc0cc526b6db",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5a4f867f8900cbf641af8",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T16:29:13.979Z",
      orderNumber: null,
      purchaseOrder: "Mi000022",
      status: "In Use",
      dateInService: "2017-03-17T17:25:15.222Z",
      receivedDate: "2017-02-28T16:29:20.012Z",
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-05-01T00:00:00.000Z",
      lot: "6301944",
      barcode: "171805011063019443020",
      orderQuantity: 9,
      company: "Becton, Dickinson and Company",
      title: "GasPak EZ CO2 Container System",
      ref: "260679",
    },
    {
      id: "58b5a6e167f8900cbf641af9",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5a6c18d5afc0cc526b6dc",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T16:35:45.406Z",
      orderNumber: null,
      purchaseOrder: "MI000023",
      status: "In Use",
      dateInService: "2017-02-28T16:35:54.382Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-03-01T00:00:00.000Z",
      lot: "6288783",
      barcode: "171803011062887833020",
      orderQuantity: 9,
      company: "Becton, Dickinson and Company",
      title: "GasPak EZ Campy Gas Generating Pouch System",
      ref: "260685",
    },
    {
      id: "58b5a8d667f8900cbf641afa",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5a89b8d5afc0cc526b6dd",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T16:44:06.197Z",
      orderNumber: null,
      purchaseOrder: "MI000024",
      status: "In Use",
      dateInService: "2017-02-28T16:44:13.404Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2018-08-05T00:00:00.000Z",
      lot: "2018-08-05",
      barcode: "17180805102018-08-05",
      orderQuantity: 9,
      company: "Beckman Coulter",
      title: "Microscan Mineral Oil",
      ref: "B1010-40A",
    },
    {
      id: "58b5a90e67f8900cbf641afb",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5a89b8d5afc0cc526b6dd",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T16:45:02.524Z",
      orderNumber: null,
      purchaseOrder: "MI000025",
      status: "In Use",
      dateInService: "2017-05-12T00:00:00.000Z",
      receivedDate: "2017-02-28T16:45:08.731Z",
      testedDate: null,
      testResult: "N/A",
      testMethod: "N/A",
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2019-01-06T00:00:00.000Z",
      lot: "2019-01-06",
      barcode: "01150995906551981117010617190106102019-01-06",
      orderQuantity: 5,
      company: "Beckman Coulter",
      title: "Microscan Mineral Oil",
      ref: "B1010-40A",
    },
    {
      id: "58b5aa7e8d5afc0cc526b6de",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5aa6c67f8900cbf641afc",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T16:51:10.273Z",
      orderNumber: null,
      purchaseOrder: "Mi000026",
      status: "In Use",
      dateInService: "2017-02-28T16:51:16.690Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2017-05-16T00:00:00.000Z",
      lot: "945022",
      barcode: "01008488380049951717051610945022",
      orderQuantity: 1,
      company: "Remel",
      title: "Enriched Thiogylcollate Medium",
      ref: "R05152",
    },
    {
      id: "58b5afc08d5afc0cc526b6df",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5afaa67f8900cbf641afd",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T17:13:36.753Z",
      orderNumber: null,
      purchaseOrder: "MI000027",
      status: "In Use",
      dateInService: "2017-02-28T17:13:42.200Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2017-04-10T00:00:00.000Z",
      lot: "7023648",
      barcode: "107023648171704103020",
      orderQuantity: 3,
      company: "Becton, Dickinson and Company",
      title: "Anaerobe CNA/ Laked KV Agar",
      ref: "297041",
    },
    {
      id: "58b5c08a67f8900cbf641afe",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5c0038d5afc0cc526b6e0",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T18:25:14.616Z",
      orderNumber: null,
      purchaseOrder: "MI000028",
      status: "In Use",
      dateInService: "2017-02-28T18:25:20.539Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      expirationDate: "2017-04-06T00:00:00.000Z",
      lot: "997982",
      barcode: "010084883800064517170406109979829119",
      orderQuantity: 3,
      company: "Remel",
      title: "RapID Yeast Plus System",
      ref: "R8311007",
      comment: "",
    },
    {
      id: "58b5d0adda571d717b1ebec9",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b5ccecd274995eb27b310b",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T19:34:05.609Z",
      orderNumber: null,
      purchaseOrder: "Im000053",
      status: "In Use",
      dateInService: "2017-02-28T19:36:28.139Z",
      receivedDate: "2017-02-28T19:36:08.582Z",
      testedDate: "2017-02-28T19:36:24.431Z",
      testResult: "N/A",
      testMethod: "N/A",
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 10,
      expirationDate: "2018-04-30T00:00:00.000Z",
      lot: "0055",
      barcode: "010733306601085417180430100055",
      company: "Thermo",
      title: "Elia RF IgM Well",
      ref: "14-5600-01",
    },
    {
      id: "58b5d101da571d717b1ebecc",
      user: "588f74b96a480845a7938fab",
      item: "58a35f752df6083edf9e2cf4",
      isDeleted: false,
      department: "Molecular",
      trackingInformation: null,
      orderDate: "2017-02-28T19:35:29.681Z",
      orderNumber: null,
      purchaseOrder: "MO000008",
      status: "In Use",
      dateInService: "2017-02-28T19:37:26.355Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 17,
      expirationDate: "2017-09-15T00:00:00.000Z",
      lot: "182465",
      barcode: "302380",
      company: "Hologic",
      title: "Aptima System Fluid Preservative TIGRIS",
      ref: "302380",
    },
    {
      id: "58b5d1fd2f47aa71757fdde0",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5cf9b0e2c9d5eac7fc5a3",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T19:39:41.190Z",
      orderNumber: null,
      purchaseOrder: "Mi000029",
      status: "In Use",
      dateInService: "2017-02-28T19:39:54.471Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 4,
      expirationDate: "2017-03-16T00:00:00.000Z",
      lot: "986646",
      barcode: "010084883800108617170316109866469101",
      company: "Remel",
      title: "Campy Blood Agar",
      ref: "R01280",
    },
    {
      id: "58b5d315da571d717b1ebece",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b5d2ca2f47aa71757fdde1",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T19:44:21.833Z",
      orderNumber: null,
      purchaseOrder: "Im000054",
      status: "In Use",
      dateInService: "2017-02-28T19:45:54.982Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 10,
      expirationDate: "2018-01-31T00:00:00.000Z",
      lot: "0030",
      barcode: "010733306601083017180131100030",
      company: "Thermo",
      title: "Elia Gliadin IgA",
      ref: "14-5538-01",
    },
    {
      id: "58b5d334da571d717b1ebecf",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5d3242f47aa71757fdde2",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T19:44:52.330Z",
      orderNumber: null,
      purchaseOrder: "Mi000030",
      status: "In Use",
      dateInService: "2017-02-28T19:44:59.214Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 3,
      expirationDate: "2017-04-18T00:00:00.000Z",
      lot: "999778",
      barcode: "010084883800122217170418109997789112",
      company: "Remel",
      title: "Chocolate Agar",
      ref: "R01302",
    },
    {
      id: "58b5d4722f47aa71757fdde3",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5d465da571d717b1ebed0",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T19:50:10.436Z",
      orderNumber: null,
      purchaseOrder: "Mi000031",
      status: "In Use",
      dateInService: "2017-02-28T19:50:20.222Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 3,
      expirationDate: "2017-04-07T00:00:00.000Z",
      lot: "999065",
      barcode: "010084883800134517170407109990659102",
      company: "Remel",
      title: "Columbia CNA Agar W/ 5% Sheep Blood",
      ref: "R01322",
    },
    {
      id: "58b5d55dda571d717b1ebed2",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5d547da571d717b1ebed1",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T19:54:05.869Z",
      orderNumber: null,
      purchaseOrder: "Mi000032",
      status: "In Use",
      dateInService: "2017-02-28T19:54:39.795Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 1,
      expirationDate: "2017-03-03T00:00:00.000Z",
      lot: "978718",
      barcode: "010084883800187117170303109787189101",
      company: "Remel",
      title: "Hektoen Enteric Agar",
      ref: "R01483",
    },
    {
      id: "58b5d5a52f47aa71757fdde4",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5d547da571d717b1ebed1",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T19:55:17.868Z",
      orderNumber: "A70612649",
      purchaseOrder: "Mi000033",
      status: "In Use",
      dateInService: "2017-03-10T21:24:17.608Z",
      receivedDate: "2017-03-07T17:12:24.393Z",
      testedDate: "2017-03-02T00:00:00.000Z",
      testResult: "PASS",
      testMethod: "MICQC",
      location: "Room 107",
      price: null,
      quantity: 0,
      orderQuantity: 1,
      expirationDate: "2017-05-01T00:00:00.000Z",
      lot: "107010",
      barcode: "010084883800187117170501101070109102",
      company: "Remel",
      title: "Hektoen Enteric Agar",
      ref: "R01483",
    },
    {
      id: "58b5d6719fb4797b66fce698",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b5d6639fb4797b66fce697",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T19:58:41.743Z",
      orderNumber: null,
      purchaseOrder: "Im000055",
      status: "In Use",
      dateInService: "2017-02-28T19:59:24.782Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 10,
      expirationDate: "2018-03-31T00:00:00.000Z",
      lot: "0032",
      barcode: "010733306601084717180331100032",
      company: "Thermo",
      title: "EliA Gliadin IgG Well",
      ref: "14-5539-01",
    },
    {
      id: "58b5d67e9fb4797b66fce699",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5d6589fb4797b66fce696",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T19:58:54.116Z",
      orderNumber: null,
      purchaseOrder: "Mi000034",
      status: "In Use",
      dateInService: "2017-02-28T19:59:02.020Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 3,
      expirationDate: "2017-04-04T00:00:00.000Z",
      lot: "991969",
      barcode: "010084883800209017170404109919699119",
      company: "Remel",
      title: "MacCONKEY Agar",
      ref: "R01552",
    },
    {
      id: "58b5d79692f3117b6c24e5c7",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b5d78992f3117b6c24e5c6",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T20:03:34.883Z",
      orderNumber: null,
      purchaseOrder: "IM000056",
      status: "In Use",
      dateInService: "2017-02-28T20:04:24.380Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 10,
      expirationDate: "2018-01-31T00:00:00.000Z",
      lot: "0056",
      barcode: "010733306601082317180131100056",
      company: "Thermo",
      title: "Elia MPO Well",
      ref: "14-5537-01",
    },
    {
      id: "58b5d7e592f3117b6c24e5c8",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5d7349fb4797b66fce69a",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T20:04:53.131Z",
      orderNumber: null,
      purchaseOrder: "Mi000035",
      status: "In Use",
      dateInService: "2017-02-28T20:05:03.196Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 3,
      expirationDate: "2017-03-21T00:00:00.000Z",
      lot: "983383",
      barcode: "010084883800212017170321109833839102",
      company: "Remel",
      title: "MacCONKEY Agar W/ Sorbitol",
      ref: "R01556",
    },
    {
      id: "58b5d8709fb4797b66fce69b",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5d86692f3117b6c24e5c9",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T20:07:12.279Z",
      orderNumber: null,
      purchaseOrder: "Mi000036",
      status: "In Use",
      dateInService: "2017-02-28T20:07:35.369Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 4,
      expirationDate: "2017-03-15T00:00:00.000Z",
      lot: "985602",
      barcode: "010084883800329517170315109856029101",
      company: "Remel",
      title: "Thayer Martin Agar (Improved)",
      ref: "R01884",
    },
    {
      id: "58b5d9079fb4797b66fce69c",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5d8f992f3117b6c24e5ca",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T20:09:43.235Z",
      orderNumber: null,
      purchaseOrder: "Mi000037",
      status: "In Use",
      dateInService: "2017-02-28T20:09:50.875Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 1,
      expirationDate: "2017-03-15T00:00:00.000Z",
      lot: "980503",
      barcode: "010084883800449017170315109805039103",
      company: "Remel",
      title: "Mueller Hinton Agar",
      ref: "R04050",
    },
    {
      id: "58b5d9469fb4797b66fce69d",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b5d92592f3117b6c24e5cb",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T20:10:46.083Z",
      orderNumber: null,
      purchaseOrder: "Im000057",
      status: "In Use",
      dateInService: "2017-02-28T20:11:37.024Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 5,
      expirationDate: "2018-03-31T00:00:00.000Z",
      lot: "0064",
      barcode: "010733306601062517180331100064",
      company: "Thermo",
      title: "EliA jo-1 Well",
      ref: "14-5507-01",
    },
    {
      id: "58b5d9f19fb4797b66fce6a0",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5d99192f3117b6c24e5cc",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T20:13:37.069Z",
      orderNumber: null,
      purchaseOrder: "Mi000038",
      status: "In Use",
      dateInService: "2017-02-28T20:13:43.387Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 5,
      expirationDate: "2017-03-29T00:00:00.000Z",
      lot: "985622",
      barcode: "010084883800283017170329109856229102",
      company: "Remel",
      title: "Sabouraud Dextrose Agar (Emmons)",
      ref: "R01760",
    },
    {
      id: "58b5da069fb4797b66fce6a1",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b5d9e49fb4797b66fce69f",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T20:13:58.012Z",
      orderNumber: null,
      purchaseOrder: "IM000058",
      status: "In Use",
      dateInService: "2017-02-28T20:32:19.013Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 5,
      expirationDate: "2017-12-01T00:00:00.000Z",
      lot: "0018",
      barcode: "CJZCN800I",
      company: "Thermo",
      title: "EliA RF IgA Well",
      ref: "14-5601-01",
    },
    {
      id: "58b5da1a92f3117b6c24e5cd",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5d9e39fb4797b66fce69e",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T20:14:18.808Z",
      orderNumber: null,
      purchaseOrder: "Mi000039",
      status: "In Use",
      dateInService: "2017-02-28T20:14:26.978Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 3,
      expirationDate: "2017-03-22T00:00:00.000Z",
      lot: "983206",
      barcode: "01008488380029081717032210983206",
      company: "Remel",
      title: "Sabouraud Dextrose Agar W/ Chloramphenicol W/ Gentamicin",
      ref: "R01772",
    },
    {
      id: "58b5decc92f3117b6c24e5cf",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b5debf92f3117b6c24e5ce",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T20:34:20.251Z",
      orderNumber: null,
      purchaseOrder: "IM000059",
      status: "In Use",
      dateInService: "2017-02-28T20:35:17.206Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 5,
      expirationDate: "2018-01-01T00:00:00.000Z",
      lot: "0017",
      barcode: "CBBCO7Y0H",
      company: "Thermo",
      title: "EliA B2-Glycoprotein I IgA Well",
      ref: "14-5531-01",
    },
    {
      id: "58b5df529fb4797b66fce6a2",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5df4592f3117b6c24e5d0",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T20:36:34.363Z",
      orderNumber: null,
      purchaseOrder: "Mi000040",
      status: "In Use",
      dateInService: "2017-02-28T20:36:39.560Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 4,
      expirationDate: "2017-10-26T00:00:00.000Z",
      lot: "6340915",
      barcode: "17171026106340915",
      company: "Becton, Dickinson and Company",
      title: "Affirm VPIII",
      ref: "446252",
    },
    {
      id: "58b5e06092f3117b6c24e5d3",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b5e05092f3117b6c24e5d2",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T20:41:04.379Z",
      orderNumber: null,
      purchaseOrder: "IM000060",
      status: "In Use",
      dateInService: "2017-02-28T20:41:42.066Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 5,
      expirationDate: "2018-01-01T00:00:00.000Z",
      lot: "0063",
      barcode: "BFHCO1V1R",
      company: "Thermo",
      title: "EliA La Well",
      ref: "14-5504-01",
    },
    {
      id: "58b5e0bd92f3117b6c24e5d4",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5dff09fb4797b66fce6a3",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T20:42:37.560Z",
      orderNumber: null,
      purchaseOrder: "Mi000041",
      status: "In Use",
      dateInService: "2017-02-28T20:42:43.490Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 1,
      expirationDate: "2017-11-15T00:00:00.000Z",
      lot: "951003",
      barcode: "01008488380580351717111510951003",
      company: "Remel",
      title: "RapID ANA II System",
      ref: "R8311002",
    },
    {
      id: "58b5e0f392f3117b6c24e5d5",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b5e0ea9fb4797b66fce6a4",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T20:43:31.549Z",
      orderNumber: null,
      purchaseOrder: "Im000061",
      status: "In Use",
      dateInService: "2017-02-28T20:44:00.825Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 5,
      expirationDate: "2018-04-30T00:00:00.000Z",
      lot: "0059",
      barcode: "010733306601077917180430100059",
      company: "Thermo",
      title: "EIia Cardiolipin IgM Well",
      ref: "14-5530-01",
    },
    {
      id: "58b5e17b92f3117b6c24e5d7",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b5e17292f3117b6c24e5d6",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T20:45:47.691Z",
      orderNumber: null,
      purchaseOrder: "IM000062",
      status: "In Use",
      dateInService: "2017-02-28T20:46:11.044Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 5,
      expirationDate: "2018-01-31T00:00:00.000Z",
      lot: "0072",
      barcode: "010733306601058817180131100072",
      company: "Thermo",
      title: "EliA Ro Well",
      ref: "14-5503-01",
    },
    {
      id: "58b5e1d59fb4797b66fce6a5",
      user: "588f7207877ac43f2b662bbf",
      item: "58b49b2b8d5afc0cc526b6d2",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T20:47:17.565Z",
      orderNumber: null,
      purchaseOrder: "Mi000042",
      status: "In Use",
      dateInService: "2017-02-28T20:47:24.500Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 3,
      expirationDate: "2017-04-06T00:00:00.000Z",
      lot: "997982",
      barcode: "010084883800064517170406109979829119",
      company: "remel",
      title: "Blood Agar Plate",
      ref: "R01202",
    },
    {
      id: "58b5e2909fb4797b66fce6a6",
      user: "588f7207877ac43f2b662bbf",
      item: "58b5c0038d5afc0cc526b6e0",
      isDeleted: false,
      department: "Microbiology",
      trackingInformation: null,
      orderDate: "2017-02-28T20:50:24.187Z",
      orderNumber: null,
      purchaseOrder: "Mi000043",
      status: "In Use",
      dateInService: "2017-02-28T20:50:30.403Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 1,
      expirationDate: "2017-09-15T00:00:00.000Z",
      lot: "918590",
      barcode: "01008488380582331717091510918590",
      company: "Remel",
      title: "RapID Yeast Plus System",
      ref: "R8311007",
    },
    {
      id: "58b5e3109fb4797b66fce6a7",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58ade8da398ded0a2077f8b2",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T20:52:32.627Z",
      orderNumber: null,
      purchaseOrder: "IM000063",
      status: "In Use",
      dateInService: "2017-02-28T20:53:42.220Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 20,
      expirationDate: "2018-11-30T00:00:00.000Z",
      lot: "360E6",
      barcode: "01073330660011731718113010360E6",
      company: "PHADIA",
      title: "Allergen e5, Dog dander",
      ref: "14-4110-01",
    },
    {
      id: "58b5e57392f3117b6c24e5d8",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b5e53b9fb4797b66fce6a8",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T21:02:43.716Z",
      orderNumber: null,
      purchaseOrder: "IM000064",
      status: "In Use",
      dateInService: "2017-02-28T21:03:48.193Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 10,
      expirationDate: "2017-08-31T00:00:00.000Z",
      lot: "C7NGB",
      barcode: "01073330660049761717083110C7NGB",
      company: "PHADIA",
      title: "Rf147",
      ref: "14-4928-10",
    },
    {
      id: "58b5e61092f3117b6c24e5d9",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af26de65bff95855d62b6e",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T21:05:20.930Z",
      orderNumber: null,
      purchaseOrder: "IM000065",
      status: "In Use",
      dateInService: "2017-02-28T21:05:49.873Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 20,
      expirationDate: "2018-09-30T00:00:00.000Z",
      lot: "490DR",
      barcode: "01073330660024601718093010490DR",
      company: "PHADIA",
      title: "Allergen w14, Common pigweed, Amaranthus retroflexus",
      ref: "14-4252-01",
    },
    {
      id: "58b5e81d9fb4797b66fce6a9",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b5e81092f3117b6c24e5da",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T21:14:05.781Z",
      orderNumber: null,
      purchaseOrder: "IM000066",
      status: "In Use",
      dateInService: "2017-02-28T21:15:05.221Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 13,
      expirationDate: "2018-09-30T00:00:00.000Z",
      lot: "356EE",
      barcode: "01073330660011351718093010356EE",
      company: "PHADIA",
      title: "m6",
      ref: "14-4106-01",
    },
    {
      id: "58b5e8929fb4797b66fce6aa",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b5e81092f3117b6c24e5da",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T21:16:02.391Z",
      orderNumber: null,
      purchaseOrder: "IM000067",
      status: "In Use",
      dateInService: "2017-02-28T21:16:49.158Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 20,
      expirationDate: "2018-11-30T00:00:00.000Z",
      lot: "356EG",
      barcode: "01073330660011351718113010356EG",
      company: "PHADIA",
      title: "m6",
      ref: "14-4106-01",
    },
    {
      id: "58b5e9b092f3117b6c24e5dc",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b5e9a592f3117b6c24e5db",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T21:20:48.445Z",
      orderNumber: null,
      purchaseOrder: "IM000068",
      status: "In Use",
      dateInService: "2017-02-28T21:21:46.427Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 22,
      expirationDate: "2018-09-30T00:00:00.000Z",
      lot: "BHPHF",
      barcode: "01073330660047611718093010BHPHF",
      company: "PHADIA",
      title: "d201",
      ref: "14-4896-01",
    },
    {
      id: "58b5ea5f92f3117b6c24e5dd",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af2d2b97de60585491d663",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T21:23:43.739Z",
      orderNumber: null,
      purchaseOrder: "IM000069",
      status: "In Use",
      dateInService: "2017-02-28T21:24:35.368Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 21,
      expirationDate: "2018-09-30T00:00:00.000Z",
      lot: "357GZ",
      barcode: "01073330660011421718093010357GZ",
      company: "PHADIA",
      title: "Allergen d1, House dust mite, Dermatophagoides pteronyssinus",
      ref: "14-4107-01",
    },
    {
      id: "58b5eab392f3117b6c24e5de",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58af2d2b97de60585491d663",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T21:25:07.257Z",
      orderNumber: null,
      purchaseOrder: "IM000070",
      status: "In Use",
      dateInService: "2017-02-28T21:25:46.058Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 20,
      expirationDate: "2018-11-30T00:00:00.000Z",
      lot: "357H2",
      barcode: "01073330660011421718113010357H2",
      company: "PHADIA",
      title: "Allergen d1, House dust mite, Dermatophagoides pteronyssinus",
      ref: "14-4107-01",
    },
    {
      id: "58b5ebf192f3117b6c24e5e0",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b5ebd792f3117b6c24e5df",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T21:30:25.533Z",
      orderNumber: null,
      purchaseOrder: "IM000071",
      status: "In Use",
      dateInService: "2017-02-28T21:32:04.445Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 20,
      expirationDate: "2018-10-31T00:00:00.000Z",
      lot: "432HG",
      barcode: "01073330660018831718103110432HG",
      company: "PHADIA",
      title: "g17",
      ref: "14-4189-01",
    },
    {
      id: "58b5ed709fb4797b66fce6ac",
      user: "588f763e191b9d4dce1d8d5c",
      item: "58b5ed699fb4797b66fce6ab",
      isDeleted: false,
      department: "Immunology",
      trackingInformation: null,
      orderDate: "2017-02-28T21:36:48.991Z",
      orderNumber: null,
      purchaseOrder: "IM000072",
      status: "In Use",
      dateInService: "2017-02-28T21:38:03.771Z",
      receivedDate: null,
      testedDate: null,
      testResult: null,
      testMethod: null,
      location: null,
      price: null,
      quantity: 0,
      orderQuantity: 2,
      expirationDate: "2018-07-31T00:00:00.000Z",
      lot: "742G5",
      barcode: "01073330660075331718073110742G5",
      company: "PHADIA",
      title: "Rf258",
      ref: "14-5142-10",
    },
];
  

async function getItemsFromBackend (query) {
    return new Promise((resolve) => {
        setTimeout(() => {
            const results = MockItems.filter((item) =>
                item.name.toLowerCase().includes(query.toLowerCase())
            ).slice(0, 5);
            resolve(results);
        }, Math.random() * 3000 + 100); // Random delay between 0.1 and 2 seconds
    });


}



export { MockItems, getItemsFromBackend, MockOrders }