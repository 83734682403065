import {useState, useEffect} from 'react'
import {App} from 'antd'
import RocheItemsQRreader from "../../../Items/RocheItemsQRreader"
const InputRocheQR = (props) => {
    const { record, dataSource, setDataSource } = props
    const [expirationDate, setExpirationDate] = useState(null)
    const [itemNumber, setItemNumber] = useState(null)
    const [lotNumber, setLotNumber] = useState(null)
    const { message } = App.useApp()

    useEffect(() => {
        //fool proof function to make sure that a user is scanning a box related to the item from the table
        if (itemNumber !== null && itemNumber !== record.actual_item.item_number) {
            if (itemNumber.length > 0) {
                message.error("Wrong QR code was scanned")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemNumber])


    useEffect(() => {

        if (itemNumber !== null && itemNumber === record.actual_item.item_number) {
            const tempArr = [...dataSource];
            const updatedArr = tempArr.map((item) => {
                if (item.purchase_order_item_id === record.purchase_order_item_id) {
                    item.lotNumber = lotNumber;
                    item.expirationDate = expirationDate;
                }
                return item;
            });
            setDataSource(updatedArr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemNumber]);


    return (
        <RocheItemsQRreader setExpirationDate={setExpirationDate} setItemNumber={setItemNumber} setLotNumber={setLotNumber} />
    )

}

export default InputRocheQR