import React from "react";
import { Layout } from "antd";
import InventoryFooter from "../InventoryFooter";

const InventoryLayout = ({children}) => {

  const { Footer, Content } = Layout;
  return (
    <Layout>
      <Layout style={{ minHeight: "100vh" }}>

        <Content>
          <div style={{ padding: "20px", backgroundColor: "#F5F5F5", height: "100%", }} >
            {children}
          </div>
        </Content>

        <Footer className="footerStyle" >
          <InventoryFooter/>
        </Footer>

      </Layout>
    </Layout>
  );
};

export default InventoryLayout 