import { Space } from 'antd';
import TableRowBarcodes from './TableRowBarcodes';
import TableRowLocationLog from './TableRowLocationLog';
import TableRowDiscardedNotes from './TableRowDiscardedNotes';

const TableRow = (props) => {
    const {record} = props

   return (
        <Space size={'large'} wrap>
            {record.barcodes&&(<TableRowBarcodes record={record}/>)}
            {record.location_log&&(<TableRowLocationLog record={record}/>)}
            {record.discarded_notes&&(<TableRowDiscardedNotes record={record}/>)}
        </Space>
		
   );
};


export default TableRow;




