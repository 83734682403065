import axios from "axios";

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8001"
      : "https://inv.linkingapimid.com",
  headers: { "Content-Type": "application/json" },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 && !(error.response.config.url === '/signin')) {
      window.location.href = '/login';
    }
    console.log(error)
    return Promise.reject(error);
  }
);

export default api;
