import { Space } from 'antd';
import JsonToDescription from './JsonToDescription';

const TableRow = (props) => {
    const { record } = props
    console.log(record)

    function isNonEmptyObject(obj) {
        return obj && typeof obj === 'object' && Object.keys(obj).length > 0;
    }

    function compareAndFilterJsons(json1, json2) {
        /*
            The function compareAndFilterJsons takes two JSON objects as inputs,
            both having the same keys but possibly different values.
            It compares the values of corresponding keys in both JSONs.
            If the values are different, the function stores these key-value
            pairs into two new JSON objects. These new JSONs only include
            the keys where the values differed between the original JSONs.
            The function then returns these two new JSON objects.
            This is useful for identifying and isolating
            differences between two similar data structures.
        */
        let result1 = {};
        let result2 = {};

        for (let key in json1) {
            if (json1.hasOwnProperty(key) && json2.hasOwnProperty(key) && json1[key] !== json2[key]) {
                result1[key] = json1[key];
                result2[key] = json2[key];
            }
        }

        return { compared_old_value: result1, compared_new_value: result2 };
    }

    const { compared_old_value, compared_new_value } = compareAndFilterJsons(record.old_value, record.new_value)

    const before = record.transaction_type === "editing" ? compared_old_value : record.old_value;
    const after = record.transaction_type === "editing" ? compared_new_value : record.new_value;


    return (
        <Space size={'large'} wrap>
            {isNonEmptyObject(before) && (
                <div>
                    <h3>Before</h3>
                    <JsonToDescription data={before} />
                </div>
            )}
            {isNonEmptyObject(after) && (
                <div>
                    <h3>After</h3>
                    <JsonToDescription data={after} />
                </div>
            )}
        </Space>

    );
};


export default TableRow;




