import dayjs from "dayjs";
import { Tag,Space } from "antd";

const ExpirationDate = (props) => {
    const { date } = props;

    const expirationDate = dayjs(date).format('DD MMM YYYY');

    const currentDate = dayjs(); // Current date as Day.js object
    const endDate = dayjs(date); // Expiration date as Day.js object
    const expiresInDays = parseInt(endDate.diff(currentDate, 'day')); // Calculate the difference


    return expiresInDays <0 ? (
        <Tag color='red' bordered={false}>
            <Space direction="vertical">
                <small>Expired</small>
                <small>{date.split("T")[0]}</small>
            </Space>
        </Tag>
    ) : expiresInDays < 40 ? (
        <Tag color='volcano' bordered={false}>
            <Space direction="vertical">
                <small>{expiresInDays} days left</small>
                <small>{date.split("T")[0]}</small>
            </Space>
        </Tag>
    ) : (
        <>{expirationDate}</>
    );

    // return (
    //     <>
    //         {parseInt(expiresInDays)<30?(
    //             <Tag color='red'  bordered={false}>
    //                 <small>{expiresInDays} days left</small>
    //             </Tag>
    //         ):(<>{expirationDate}</>)}
    //     </>
    // );
}

export default ExpirationDate;
