import { Table, Tag, Space, Descriptions, Collapse } from "antd";
import ExpirationDate from "../../../components/ExpirationDate";
import FormattedLocation from "../../Warehouses/Locations/FormattedLocation";
import LabelRePrint from "../../../components/LabelRePrint";
import DateFormatter from "../../../components/DateFormatter";

//barcodes, itemName, lotNumber, expirationDate
const GroupProducts = (props) => {
  const {
    products,
    bordered,
    scrolled,
    hideLot,
    hideBarcode,
    expandable,
    hideExpiredProducts,
  } = props;

  console.log(products)

  function groupJsons(jsonArray) {
    const grouped = {};

    jsonArray.forEach((json) => {
      //   const key = `${json.item_id}-${json.expiration_date}-${json.section_id}-${json.lot}-${json.name}`;
      // const key = `${json.date_received}`;//expiration_date
      // const key = `${json.actual_item.item_name}`;//expiration_date
      const key = `${json.lot}`;//expiration_date

      if (!grouped[key]) {
        grouped[key] = {
          ...json,
          total_amount: 0,
          barcodes: [], //remove
          products: [],
        };
      }

      grouped[key].total_amount++;
      grouped[key].barcodes.push(json.barcode); //remove
      grouped[key].products.push(json);
    });

    return Object.values(grouped);
  }
  function filterExpiredProducts(products) {
    const today = new Date();
    return products.filter(
      (product) => new Date(product.expiration_date) > today
    );
  }

  let groupedProducts = groupJsons(products);

  groupedProducts = [...groupedProducts].map((product, index) => {
    product.key = `${index}`;
    return product;
  });

  if (hideExpiredProducts) {
    groupedProducts = filterExpiredProducts(groupedProducts);
  }


  let columns = [
    {
      title: "Item Number",
      dataIndex: "item_number",
      key: "item_number",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Received",
      dataIndex: "received",
      key: "received",
      width: 100,
      render: (_, record) => (
        // <>{convertTimestampToDate(parseInt(record.products[0].barcode))}</>
        <DateFormatter dateString={record.products[0].date_received} />
      ),
    },
    {
      title: "Expiration date",
      dataIndex: "expiration_date",
      key: "expiration_date",
      render: (text, record) =>
        record.actual_item[0].expirationDateRequired === true && record.expiration_date ? (
          <ExpirationDate date={text} />
        ) : (
          <Tag>Not Applicable</Tag>
        ),
    },
    {
      title: "LOT number",
      dataIndex: "lot",
      key: "lot",
      width: 150,
      render: (text, record) => record.actual_item[0].lotRequired === true ? (<>{text}</>) : (<Tag>Not Applicable</Tag>),
    },
    {
      title: "Amount",
      dataIndex: "total_amount",
      key: "total_amount",
      width: 10,
    },
    {
      title: "Labels",
      dataIndex: "labels",
      key: "labels",
      width: 10,
      render: (_, record) => (
        <LabelRePrint
          barcodes={record.barcodes}
          itemName={record.name}
          lotNumber={record.lot ? record.lot : "N/A"}
          expirationDate={
            record.expiration_date
              ? record.expiration_date.split("T")[0]
              : "N/A"
          }
        />
      ),
    },
  ];

  if (hideLot) {
    columns = columns.filter((col) => col.key !== "lot");
  }
  if (hideBarcode) {
    columns = columns.filter((col) => col.key !== "barcode");
  }


  return (
    <Table
      dataSource={groupedProducts}
      columns={columns}
      size="small"
      bordered={bordered}
      expandable={
        expandable
          ? {
            expandedRowRender: (record) => <ActualProducts record={record} />,
            // rowExpandable: (record) => record.barcodes.length > 1,
            rowExpandable: (record) => {
              return true;
            },
          }
          : null
      }
      scroll={scrolled ? { x: "750px" } : null}
      pagination={{
        pageSizeOptions: ["5", "10", "100", "1000"],
        showSizeChanger: true,
      }}
    />
  );
};

export default GroupProducts;

const ActualProducts = (props) => {
  const { record } = props;

  const actualProducts = [...record.products];
  return (
    <div className="rowStyle">
      <Space direction="vertical">
        {actualProducts.map((product) => {
          const location = {
            department: product.location_department,
            type: product.location_type,
            number: product.location_number,
            locationID: product.location_id,
          };
          const descriptionItems = [
            {
              key: "1",
              label: "barcode",
              children: product.barcode,
            },
            {
              key: "2",
              label: "location",
              children: (
                <FormattedLocation location={location} hideOnChangeLocation />
              ),
            },
          ];
          return (
            <Collapse
              items={[
                {
                  key: product.barcode,
                  label: product.barcode,
                  children: (
                    <Descriptions items={descriptionItems} column={1} />
                  ),
                },
              ]}
              defaultActiveKey={["1"]}
              bordered={false}
              style={{ width: 500 }}
            />
          );
        })}
      </Space>
    </div>
  );
};
