import { useState, useEffect } from 'react'
import { Input, Space, Button, App } from 'antd'

const BarcodesCheckList = (props) => {
    const { message } = App.useApp()

    const { barcodes, setBarcodes, amount, setItemBarcodesCompleted } = props
    const [barcode, setBarcode] = useState('')

    const submitBarcode = () => {
        if (barcodes.length === amount) {
            message.error(`All the barcodes were scanned`)
            return
        }

        if (!barcodes.includes(barcode)) {
            setBarcodes([...barcodes, barcode.trim()])
            setBarcode('') 
            message.success(`${barcode} added.`)
        } else {
            message.error(`${barcode} already scanned.`)
        }
    }

    useEffect(() => {
      if(barcodes.length===amount){
        setItemBarcodesCompleted(true)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [barcodes])

    return (
        <>
            {amount && (
                <Space direction='vertical' wrap>
                    <Space wrap>
                        <Input placeholder='Scan a barcode' value={barcode} onChange={(e) => setBarcode(e.target.value)} onPressEnter={submitBarcode} />
                        <Button onClick={submitBarcode} disabled={!barcode}>Add</Button>
                        <>Scanned: {barcodes.length} of {amount}</>

                    </Space>

                    {barcodes.map((barcode, index) => (<div key={index}>{index + 1}. {barcode}</div>))}
                </Space>
            )}
        </>
    )
}

export default BarcodesCheckList
