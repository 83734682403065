
import BadgeAuthorization from "../../../components/BadgeAuthorization"

const DistributingAuthorizationPage = () => {


    return (
        <BadgeAuthorization targetPage={"products-distributing"}/>
    );
};

export default DistributingAuthorizationPage;



