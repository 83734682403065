// import React from 'react';
// import { Button } from 'antd';
// import { saveAs } from 'file-saver';

// const JsonToCSV = ({ data, includeFields, excludeFields }) => {
//   const handleDownload = () => {
//     let headers;
//     if (includeFields) {
//       headers = includeFields;
//     } else if (excludeFields) {
//       headers = Object.keys(data[0]).filter(key => !excludeFields.includes(key));
//     } else {
//       headers = Object.keys(data[0]);
//     }

//     const csvRows = [headers.join(',')];

//     // Loop over the rows
//     for (const row of data) {
//       const values = headers.map(header => {
//         const escaped = ('' + (row[header] || '')).replace(/"/g, '\\"');
//         return `"${escaped}"`;
//       });
//       csvRows.push(values.join(','));
//     }

//     // Create Blob and save file
//     const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
//     saveAs(blob, 'report.csv');
//   };

//   return <Button type='link' onClick={handleDownload} disabled={data.length===0}>Export as CSV</Button>;
// };

// export default JsonToCSV;


import React from 'react';
import { Button } from 'antd';
import { saveAs } from 'file-saver';

const JsonToCSV = ({ data, includeFields, excludeFields }) => {
  const handleDownload = () => {
    let headers;
    if (includeFields) {
      headers = includeFields;
    } else if (excludeFields) {
      headers = Object.keys(data[0]).filter(key => !excludeFields.includes(key));
    } else {
      headers = Object.keys(data[0]);
    }

    const csvRows = [headers.join(',')];

    // Loop over the rows
    for (const row of data) {
      const values = headers.map(header => {
        const value = row[header];
        const escaped = (value !== null && value !== undefined ? value : '').toString().replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }

    // Create Blob and save file
    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    saveAs(blob, 'report.csv');
  };

  return <Button type='link' onClick={handleDownload} disabled={data.length===0}>Export as CSV</Button>;
};

export default JsonToCSV;
