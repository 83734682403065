import { Table, Space } from "antd";
import { useContext, useEffect, useState } from 'react';
import axios from '../../../services/restAPI';
import { AuthContext } from "../../../components/Auth/AuthContext";
import { LoadingSpinLocal } from "../../../components/LoadingSpinLocal";
import ExportToCSV from "./ExportToCSV";

const PurchasedItems = (props) => {
    const { authConfig } = useContext(AuthContext);
    const [PO, setPO] = useState(null);
    const [loading, setLoading] = useState(false);
    const { record, hidePrice, hideAmount, hideReceived, showHeader, adjustHeight } = props;
    let purchaseItems = PO ? [...PO.purchase_items] : [];


    function groupItemsByNumber(arr) {
        const grouped = {};
        arr.forEach(item => {
            const itemNumber = item.actual_item[0].item_number;
            if (!grouped[itemNumber]) {
                grouped[itemNumber] = { ...item };
                grouped[itemNumber].quantity_purchased = 0;
            }
            grouped[itemNumber].quantity_purchased += item.quantity_purchased;
        });
        return Object.values(grouped);
    }

    function addAmountOfReceived(arr) {
        if (!PO) return arr;
        for (const arrItem of arr) {
            const POitems = PO.purchase_items.filter((item) => {
                return item.item_id === arrItem.item_id;
            });
            arrItem.POitems = POitems;
        }
        return arr;
    }

    function AddCalculateAmountItemsReceived(arr) {
        for (const arrItem of arr) {
            arrItem.amountOfReceived = arrItem.POitems.reduce((acc, item) => {
                return item.received === true ? acc + 1 : acc;
            }, 0);
        }
        return arr;
    }

    if (PO) {
        purchaseItems = groupItemsByNumber(purchaseItems);
        purchaseItems = addAmountOfReceived(purchaseItems);
        purchaseItems = AddCalculateAmountItemsReceived(purchaseItems);
    }

    const getPO = async () => {
        setLoading(true);
        await axios.get('/po/get-po-items/', {
            ...authConfig,
            params: { POid: record.purchase_order_id }
        })
            .then((response) => {
                setPO(response.data[0]);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getPO();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    let columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 30
        },
        {
            title: 'Item Number',
            dataIndex: 'item_number',
            key: 'purchase_order_id',
            render: (_, PO) => <>{PO.actual_item[0].item_number}</>,
            width: 100
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, PO) => <>{PO.actual_item[0].name}</>,
            width: 200
        },
        {
            title: 'Vendor',
            dataIndex: 'vendor',
            key: 'vendor',
            render: (_, PO) => <>{PO.actual_item[0].vendor}</>,
            width: 200
        },
        {
            title: 'Amount',
            dataIndex: 'quantity_purchased',
            key: 'quantity_purchased',
            render: (_, PO) => <>received: {PO.amountOfReceived}/{PO.quantity_purchased}</>
        },
        {
            title: 'Cost Per Item',
            dataIndex: 'purchase_price',
            key: 'purchase_price',
            render: (_, PO) => <>$ {PO.purchase_price}</>
        },
    ];

    if (hidePrice) {
        columns = columns.filter(column => column.key !== "purchase_price");
    }
    if (hideAmount) {
        columns = columns.filter(column => column.key !== "quantity_purchased");
    }
    if (hideReceived) {
        columns = columns.filter(column => column.key !== "Received");
    }

    const dataSource = purchaseItems;

    dataSource.map((item, index) => {
        item.key = item.purchase_order_item_id;
        item.index = index + 1;
        return item;
    });

    // console.log(PO)
    return (
        <LoadingSpinLocal loading={loading}>

            {PO !== null && (
                <Space direction='vertical'>

                    <ExportToCSV record={PO} />


                    <div className={adjustHeight ? "scrolledBox" : null} style={adjustHeight ? { maxHeight: '150px' } : null}>

                        <Table
                            style={{ pointerEvents: 'none' }}
                            rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
                            showHeader={showHeader}
                            columns={columns}
                            dataSource={dataSource}
                            pagination={false}
                            scroll={{ x: "100%" }}
                            size="small"
                        />
                    </div>
                </Space>
            )}
        </LoadingSpinLocal>
    );
}

export { PurchasedItems };

