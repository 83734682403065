import { useState, useEffect } from "react";
import { Modal, Button, Space } from "antd";
import { PrintLabels } from "./PrintLabels";
const AfterSubmitModal = (props) => {
  const { showAfterSubmitModal, setShowAfterSubmitModal, form, setRerenderTrigger } = props; //setShowAfterSubmitModal
  const [printingDone, setPrintingDone] = useState(false)


  const addNew = () => {
    setShowAfterSubmitModal(false);
    setPrintingDone(false)

    form.setSelectedItem(null);
    form.setAmount(null);
    form.setLotNumber(null);
    form.setExpirationDate(null);
    form.setWarehouse(1);
    form.setLocation(null);
    setRerenderTrigger(Date.now())
    // window.location.reload();
  };

  useEffect(() => {
    if (printingDone) {
      addNew()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printingDone])

  return (
    <Modal
          title="New product was submitted successfully"
          open={showAfterSubmitModal}
          closable={false}
          keyboard={false}
          footer={null}
        //   onCancel={() => { setShowAfterSubmitModal(false); }}
        >
          <Space direction="vertical" size="large" >
            <PrintLabels amountOfItems={form.amount} form={form} setPrintingDone={setPrintingDone} />

            <Space size='large' >
              {/* <Button onClick={addSame}>Add Analogous</Button> */}
              <Button onClick={addNew}>Add New</Button>
            </Space>
          </Space>
        </Modal>

  );
};

export { AfterSubmitModal };
