import { Select } from 'antd'
import { useState, useEffect, useContext } from "react";
import axioss from '../../../services/restAPI';
import { AuthContext } from "../../../components/Auth/AuthContext";
import { App } from 'antd'
import { LoadingSpinLocal } from "../../../components/LoadingSpinLocal";


const ItemTypeSelect = (props) => {
    const selectWidth = 200

    const [loading, setLoading] = useState(false)
    const { authConfig } = useContext(AuthContext)
    const { modal } = App.useApp()
    const { selectedItemType, setSelectedItemType } = props
    const [itemTypes, setItemTypes] = useState([])

    const selectedItemTypeIsNotEmptyInitially = selectedItemType!=="" && itemTypes.length>0  && isNaN(selectedItemType)
    
    useEffect(() => {
        if(itemTypes.length===0){
            getItemTypes()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        //this function sets an ID instead of a Name.
        //use case: select initial value is not empty (for example,in an item edit mode)

        if (selectedItemTypeIsNotEmptyInitially) {
            const convertLabelToValue = () => {
                const value = ((itemTypes.filter(i => i.type === selectedItemType))[0]).id
                return value
            }
            
            setSelectedItemType(convertLabelToValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItemTypeIsNotEmptyInitially])
   
    const getItemTypes = async () => {
        try {
            setLoading(true)
            const response = await axioss.get("/items/item-types/", authConfig)
            setItemTypes(response.data)
        }
        catch (err) {
            console.log(err)
            modal.warning({
                title: 'Error while loading item types',
                content: err.message,
            });
        }
        finally {
            setLoading(false)
        }
    }


    const itemTypesOptions = itemTypes.map(type => {
        return {
            value: type.id,
            label: type.type,
        }
    })

    return (
        <LoadingSpinLocal loading={loading}>
            <Select
                value={selectedItemType === "" ? null : selectedItemType}
                placeholder="Select Type"
                options={itemTypesOptions}
                onSelect={(value) => { setSelectedItemType(value) }}
                style={{minWidth:selectWidth}}
            />
        </LoadingSpinLocal>
    )
}

export { ItemTypeSelect }