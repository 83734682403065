import { React, useState, useEffect, useContext } from "react";
import { AuthContext } from "../../components/Auth/AuthContext";
import { Table, App,  } from "antd";
import dayjs from 'dayjs';
import axioss from '../../services/restAPI';
import { LoadingSpinLocal } from "../../components/LoadingSpinLocal";
// import { PurchasedItems } from "./PurchasedItems";
// import ToPrintButton from "./ToPrintButton";
import buildQueryParams from "../../services/utilites/buildQueryParams";
import POStatuses from "./POStatuses";
import PONotes from "./PONotes";
import MyPagination from "../../components/Pagination";
import { POrow } from "./row";
// import CopyButton from "../../components/CopyButton";
import { Link } from 'react-router-dom'

export function POtable(props) {
    const {searchParams} = props
    const { authConfig } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const [createdPOs, setCreatedPOs] = useState([])
    const { modal } = App.useApp()
    let dataSource = [...createdPOs]


    dataSource.forEach((item) => {
        item.key = item.purchase_order_id;
        item.date_created = dayjs(item.date_created).format('YYYY-MM-DD');

        return item
    })

    dataSource.sort(function(a, b) {
        return a["date_created"] - b["date_created"];
    });


    const getPOs = async () => {

        const params = {
            limit,
            offset,
            searchByBarcode:searchParams.searchByBarcode,
            searchByNotes:searchParams.searchByNotes,
        };
        const url = `/po?${buildQueryParams(params)}`;

        try {
            setLoading(true)
            const response = await axioss.get(url, authConfig)
            let tempArr = response.data.items
            // console.log(tempArr)
            tempArr.sort((a,b) => {return a-b})
            setCreatedPOs(tempArr)
            setTotalItems(response.data.count)
            // console.log(response.data)
        } catch (error) {
            console.error(error)
            modal.warning({
                title: 'Error creating new PO:',
                content: error.message
            })
        }
        finally {
            setLoading(false)
            searchParams.setPerformSearch(false)
        }
    }

    useEffect(() => {
        if (createdPOs.length === 0) {
            getPOs()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(searchParams.performSearch){
            getPOs()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams.performSearch])

    //* pagination
    const [totalItems, setTotalItems] = useState(0)
    const [limit, setLimit] = useState(5)
    const [offset, setOffset] = useState(0)
    useEffect(() => {
        getPOs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset, limit])
    //* pagination
    

    const columns = [
        {
            title: 'ID',
            dataIndex: 'purchase_order_id',
            key: 'purchase_order_id',
            width:100,

        },
        {
            title: 'Date',
            dataIndex: 'date_created',
            key: 'date_created',
            width:200,

        },
        // {
        //     title: 'Purchased Items',
        //     dataIndex: 'purchasedItems',
        //     key: 'purchasedItems',
        //     render: (_, record, index) => (
        //       <PurchasedItems record = {record} hidePrice  rowNumber={index} showHeader={false} adjustHeight/>
        //     ),
        // },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width:200,
            render: (_, record) => ( <POStatuses record={record} setLoading={setLoading}/> ),
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
            width:'300px',
            render: (_, record) => ( <PONotes style={{width:"100%"}}record={record} setLoading={setLoading}/> ),
        },
        // {
        //     title: 'Print',
        //     dataIndex: 'print',
        //     key: 'print',
        //     render: (_, record) => ( <ToPrintButton record={record}/> ),
        // },
        {
            title: 'Barcode',
            dataIndex: 'barcode',
            key: 'barcode',
            // render: (_) => (<>{_}<CopyButton textToCopy={_}/></>)
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            render: (_,record)=>(<Link to={`/PO/edit-PO/${record.barcode}`} style={{ paddingTop: 8 }}>Edit</Link>)
        },

    ];



    // const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    return (
        <LoadingSpinLocal loading={loading}>
            <div className="scrolledBox">
                <Table
                    size="small"
                    // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                    columns={columns}
                    // expandRowByClick={true}
                    expandable={{ expandedRowRender: (record) => (<div style={{ margin: 0, }} > <POrow record={record} customComponent={props.customComponent} /> </div>), rowExpandable: (record) => record.name !== 'Not Expandable', }}
                    dataSource={dataSource}
                    pagination={false}
                    scroll={{ x: "100%" }}
                />
                <MyPagination totalItems={totalItems} limit={limit} offset={offset} setLimit={setLimit} setOffset={setOffset} />
            </div>
        </LoadingSpinLocal>
    );
}

