import { useState, useContext, useEffect } from 'react';
import { LoadingSpinLocal } from '../../../components/LoadingSpinLocal';
import axioss from '../../../services/restAPI';
import { AuthContext } from "../../../components/Auth/AuthContext";
import { useParams } from "react-router-dom";
import {Button, Table,App} from 'antd'

const RemoveItems = (props) => {
    const {setGetDataTrigger} = props
    const { poID } = useParams();
    const [loading, setLoading] = useState(false);
    const { authConfig } = useContext(AuthContext);
    const [PO, setPO] = useState([]);
    const [purchaseItems, setPurchaseItems] = useState([]);
    const { message,modal } = App.useApp()

    function extractUniqueItems(inputArray) {
        const itemMap = {};
    
        inputArray.forEach(item => {
            const { actual_item } = item;
            const { id, name, item_number } = actual_item;
    
            if (!itemMap[item_number]) {
                itemMap[item_number] = {
                    quantity: 1,
                    actual_item_name: name,
                    actual_item_id: id,
                    actual_item_number: item_number
                };
            } else {
                itemMap[item_number].quantity++;
            }
        });
    
        return Object.values(itemMap);
    }

    useEffect(() => {
        if (PO.items) {
            setPurchaseItems(extractUniqueItems(PO.items));
        }
    }, [PO]);

    const getData = () => {
        setLoading(true);
        axioss.get(`/po/${poID}/`, authConfig)
            .then((response) => {
                setPO(response.data);
            })
            .catch((error) => console.error("Error fetching data:", error))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    console.log()

    const submitItemRemoval = (record) => {
        const params = {
            purchase_order_id: PO.purchase_order_id,
            actual_item_id: record.actual_item_id
        };
    
        try {
            setLoading(true);
            axioss.delete('/po/remove-item-from-po', { ...authConfig, params })
                .then(() => {
                    message.success('The PO was updated');
                    // window.location.reload();
                    setGetDataTrigger(Date.now());
                    getData()
                })
                .catch((error) => {
                    console.error(error);
                    modal.warning({
                        title: 'Error while updating the PO:',
                        content: error.response.data.message
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (error) {
            console.error(error);
            modal.warning({
                title: 'Error while updating the PO:',
                content: error.message
            });
            setLoading(false);
        }
    };
    



    return (
        <LoadingSpinLocal loading={loading}>
           <Table
            dataSource={purchaseItems}
            size='small'
            columns={[
                {
                    title: 'Item Number',
                    dataIndex: 'actual_item_number',
                    key: 'actual_item_number',
                },
                {
                    title: 'Item Name',
                    dataIndex: 'actual_item_name',
                    key: 'actual_item_name',
                },
                {
                    title: 'Remove',
                    dataIndex: 'remove',
                    key: 'remove',
                    render: (_,record)=>( <Button danger size='small' onClick={() => {submitItemRemoval(record)}}>Remove</Button> )
                },
            ]}
            />
        </LoadingSpinLocal>
    );
}

export default RemoveItems;
