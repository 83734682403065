import {React, } from 'react';
import FAQ from '../FAQ'
import InventoryUpdates from '../InventoryUpdates';
import { Tabs } from 'antd';
import GoHome from '../../components/GoHome';
import PrintZebraLabel from '../../components/PrintZebraLabel';
import TimeStampToDate from '../../components/TimeStampToDate';
const About = () => {
    const tabs = [
        {
          key: '1',
          label: 'How To',
          children: <FAQ/>
        },
        {
          key: '2',
          label: 'Updates',
          children: <InventoryUpdates/>,
        },
        {
          key: '3',
          label: 'Print a Label',
          children: <PrintZebraLabel />,
        },
        {
          key: '4',
          label: 'Timestamp to Date',
          children: <TimeStampToDate />,
        },
      ];

    return (
        <div style={{padding:'20px'}}>
            <Tabs defaultActiveKey="1" items={tabs}   tabBarExtraContent={{left:<GoHome/>}}/>
        </div>
    );
}

export default About;