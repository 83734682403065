import React from 'react';
import { Select } from 'antd';

const ReceivingLocations = (props) => {
    const { setLocation } = props;

    const handleChange = (value) => {
        const selectedOption = options.find(option => option.value === value);
        const label = selectedOption ? selectedOption.label : '';
        setLocation({ locationID: value, locationReceivingNotes: label });
    };

    const options = [
        { value: 45, label: 'Walk-in Fridge' },
        { value: 69, label: 'Chemistry ' },
        { value: 67, label: 'Warehouse ' },
        { value: 61, label: 'Cytology ' },
        { value: 62, label: 'Hematology ' },
        { value: 63, label: 'Immunology ' },
        { value: 64, label: 'MassSpec ' },
        { value: 65, label: 'Microbiology ' },
        { value: 66, label: 'Molecular ' },
        { value: 70, label: 'Histology ' },
        // { value: 60, label: 'IT ' },
        // { value: 68, label: 'Phlebotomy ' },
        // { value: 71, label: 'Inventory ' },
        // { value: 72, label: 'Accessioning ' },
    ];

    return (
        <Select
            placeholder="Location"
            style={{ width: 200 }}
            onChange={handleChange}
            options={options}
        />
    );
}

export default ReceivingLocations;
