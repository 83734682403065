// import TableRow from "./TableRow"
// import { WarehouseSectionsDropdown } from "../Warehouses/WarehouseSectionsDropdown";
import { Button, Table, App } from 'antd'
import { useState, } from "react";
import LocationForRows from '../Warehouses/Locations/LocationForRows';

const MultipleLocation = (props) => {
    const { record, dataSource, setDataSource } = props
    console.log(dataSource)
    const apply = () => {
        let tempArr= [...dataSource]
        console.log(tempArr)
        tempArr = tempArr.map((product) => {
          product.newLocation = record.newLocation
          return product
        })
        setDataSource(tempArr)
    }
    return (
        <>
            {record.newLocation && (
                <Button type={"link"} onClick={apply}>Multiply Location</Button>
            )}
        </>
    )
}
const MainTable = (props) => {
    const { productList, setProductList } = props
    const { message } = App.useApp()
    const [warehouseID,] = useState(1)

    const columns = [{
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        render: (_, record) => (<LocationForRows record={record} dataSource={productList} setDataSource={setProductList} warehouseID={warehouseID}/>),

    },
    {
        title: 'Barcode',
        dataIndex: 'barcode',
        key: 'barcode',

    },
    {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (_, record) => (<><RemoveRow record={record} /><MultipleLocation record={record} dataSource={productList} setDataSource={setProductList}/> </>),
    },]

    const RemoveRow = (props) => {
        const { record } = props
        let tempArr = [...productList];
        tempArr = tempArr.filter(item => item.key !== record.key)
        return (
            <Button type="link" onClick={() => { setProductList(tempArr); message.success(`${record.name.slice(0, 10)}...  removed`) }}>Remove</Button>
        )
    }

    return (
        <>
            <Table
                columns={columns}
                // expandable={{
                //     expandedRowRender: (record) => (<TableRow record={record} productList={productList} setProductList={setProductList} />),
                //     rowExpandable: (record) => record.name !== 'Not Expandable',
                // }}
                dataSource={[...productList]}
            />
        </>
    )
}

export default MainTable