const generateBarcode = (barcodeValue, barcodeHeight = 70, barcodePaddingLeft = 15, barcodePaddingTop = 30, fontSize = 30) => {
    const amountOfLabels = 1;

    let labelTextEncoding = "";
    labelTextEncoding += "^XA";
    labelTextEncoding += `^CWZ,E:TT0003M_.FNT^FS^A0N,${fontSize},${fontSize}`;
    labelTextEncoding += `^FO${barcodePaddingLeft},${barcodePaddingTop}^BCN,${barcodeHeight},Y,N,N^FD${barcodeValue}^FS^FO30`;
    labelTextEncoding += `^PQ${amountOfLabels}`;
    labelTextEncoding += "^XZ";

    return labelTextEncoding;
}

export default generateBarcode;
