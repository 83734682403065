import { useState, useEffect, React, useContext } from "react";
import axioss from '../../services/restAPI';
import { AuthContext } from "../../components/Auth/AuthContext";
import { App, Tag } from 'antd'
import { LoadingSpinLocal } from "../../components/LoadingSpinLocal";
import TransactionsTable from "./TransactionsTable";
import TableFilters from "./TableFilters";
import buildQueryParams from "../../services/utilites/buildQueryParams";

const TransactionsStock = () => {
    const [loading, setLoading] = useState(false)
    const { authConfig } = useContext(AuthContext)
    const [transactions, setTransactions] = useState([])
    const { modal } = App.useApp()

    // console.log(transactions)

    const [searchByTransactionType, setSearchByTransactionType] = useState('')
    const [searchByUser, setSearchByUser] = useState('')
    const [searchByItem, setSearchByItem] = useState('')
    const [searchByDateRange, setSearchByDateRange] = useState('')
    const [searchByBarcode, setSearchByBarcode] = useState('')
    const [selectedDepartment, setSelectedDepartment] = useState('')
    const [searching, setSearching] = useState(false)



    const searchFilters = {
        searchByTransactionType, setSearchByTransactionType,
        searchByUser, setSearchByUser,
        searchByItem, setSearchByItem,
        searchByDateRange, setSearchByDateRange,
        searchByBarcode, setSearchByBarcode,
        selectedDepartment, setSelectedDepartment,
        searching, setSearching
    }

    useEffect(() => {
        if(transactions.length===0){
            getTransactions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(searching){
            getTransactions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searching])

    useEffect(() => {
        getTransactions();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchByTransactionType, searchByItem, selectedDepartment, searchByUser, searchByDateRange])

    const params = {
        searchByTransactionType,
        searchByUser:searchByUser!==undefined?searchByUser.id:"", 
        searchByItem:searchByItem!==null?searchByItem.id:"",
        startDate:searchByDateRange[0],
        endDate:searchByDateRange[1],
        searchByBarcode,
        searchByUserDepartment:selectedDepartment
    }

    const getTransactions = async () => {
        try {
            setLoading(true)
            const url = `/transactions-stock?${buildQueryParams(params)}`;
            const response = await axioss.get(url, authConfig);
            const temp = response.data.map((item, index)=>{ item.key = index ; return item })
            setTransactions(temp)
            // console.log(response.data)
        }
        catch (err) {
            console.log(err)
            modal.warning({
                title: 'Something went wrong...',
                content: err.message,
            });
        }
        finally {
            setLoading(false)
            setSearching(false)
        }
    }
    return (
        <LoadingSpinLocal loading={loading}>
            <h1>Stock Logs</h1>
            { Object.values(params).every(value => !value )?( <Tag>Only the 100 most recent records are shown. Please specify at least one search filter to view more records.</Tag>):(null)}
            <TableFilters searchFilters={searchFilters}/>
            <TransactionsTable data={transactions} />
        </LoadingSpinLocal>
    )
}
export default TransactionsStock
