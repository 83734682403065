import {React, useState, useContext} from 'react'
import {Space} from 'antd'
import { PrintLabelZebra } from '../../../../components/PrintLabelZebra';
import { GeneralContext } from '../../../../components/GeneralContext/GeneralContext';
import dayjs from 'dayjs';

const PrintLabel = (props) => {
    const {connectedZebraPrinter, setConnectedZebraPrinter} = useContext(GeneralContext)
    const { record } = props
    const [amountOfLabels] = useState(record.amount)

    // console.log(record)
   
    const printedDate = dayjs(Date.now()).format('DD MMM YYYY') //YYYY-MM-DD
    const expDate = record.expirationDate?(dayjs(record.expirationDate).format('DD MMM YY')):("N/A")
    const lot = record.lotNumber?record.lotNumber:"N/A"
    let labels = []
    let barcodes = [...record.barcodes]
    
    barcodes.forEach((barcode) => {
      const labelText = {
        line1:`Item     : ${record.actual_item.name}`,
        line2:`Printed ${printedDate}`,
        line3:`LOT     : ${lot}, Exps.:${expDate}`,
        line4:`Notes   : `,
      }
      labels.push({ barcode:barcode.barcode, labelText });
    });



    return (
      <Space direction='horizontal'>
        <Space.Compact>
            <PrintLabelZebra key={record.barcode} connectedZebraPrinter={connectedZebraPrinter} setConnectedZebraPrinter={setConnectedZebraPrinter} amountOfLabels={amountOfLabels} labels={labels}/>
        </Space.Compact>
      </Space>
    )
  }

  export {PrintLabel}

  //