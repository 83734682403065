import {Space, Button, DatePicker, Tag} from 'antd'
const InputExpirationDate = (props) => {
    const { record, dataSource, setDataSource } = props
    const onSave = (value) => {
        const tempArr = [...dataSource]
        tempArr.map((item) => {
            if (item.purchase_order_item_id === record.purchase_order_item_id) {
                item.expirationDate = value
            }
            return item
        })
        setDataSource(tempArr)
    }
    const onClear = () => {
        const tempArr = [...dataSource]
        tempArr.map((item) => {
            if (item.purchase_order_item_id === record.purchase_order_item_id) {
                item.expirationDate = undefined
            }
            return item
        })
        setDataSource(tempArr)
    }

    let plainStringDate;
    if (typeof record.expirationDate === 'object' && record.expirationDate !== null) {
        const obj = record.expirationDate
        const year = obj.$y;
        const month = obj.$M + 1; // getMonth returns 0-11, so add 1 for 1-12
        const day = obj.$d.getDate();
        plainStringDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    }
    return (
        <>
            {record.actual_item.expirationDateRequired === true ? (
                <>
                    {record.expirationDate ?
                        (
                            <Space>
                                <>{plainStringDate ? plainStringDate : record.expirationDate}</>
                                <Button onClick={onClear} type='text'>change</Button>
                            </Space>
                        ) : (
                            <DatePicker
                                onChange={(date, dateString) => { onSave(dateString); }}
                                allowClear
                                changeOnBlur
                                placeholder="Expires"
                                disabledDate={(current) => current && current.valueOf() < Date.now()}
                            />
                        )}
                </>
            ) : (<Tag>Not Applicable</Tag>)}
        </>
    )
}

export default InputExpirationDate