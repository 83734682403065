import React, { useState, useEffect } from "react";
import { Button, Typography, App, Modal, } from "antd";
import axioss from '../../../services/restAPI';
import { useParams } from "react-router-dom";
import ProductScanning from "../../../components/ProductScanning";
import InventoryLayout from "../../../components/PageLayout/InventoryLayout";
import { LoadingSpinLocal } from "../../../components/LoadingSpinLocal";
import GoHome from "../../../components/GoHome";

const DecreaseStockApp = () => {
    // const {doLogin} = useContext(AuthContext)
    const { message } = App.useApp();
    const [loading, setLoading] = useState(false)
    const [currentUser, setCurrentUser] = useState([]);
    const [productList, setProductList] = useState([]);
    const { barcodeValue } = useParams();
    // const formIsNotCompleted = productList.length === 0 || productList.some(product => product.amountTaken === "" || product.amountTaken === undefined)
    const formIsNotCompleted = productList.length < 1

    const getUserByBarcode = async () => {
        try {
            setLoading(true)
            const response = await axioss.get(`/users/user-by/${barcodeValue}/`)
            setCurrentUser(response.data.user)
        } catch (error) {
            console.log(error)
        }
        finally{
            setLoading(false)
        }
    }


    const handleSubmit = async () => {
        Modal.confirm({
            title: 'Confirm',
            content: `Are you sure you want to decrease ${productList.length} products from warehouse stock?`,
            onOk: async () => {
                try {
                    setLoading(true)
                    const data = productList.map(item => {
                        return {
                            user:{...currentUser},
                            barcode: item.barcode,
                            quantity: 1,
                            stockID: item.stock_id,
                            item_id:item.item_id
                        };
                    });


                    await axioss.post('/action/decreaseStock/', data);
                    // console.log(response.data);

                    setProductList([]); // Clear product list after transaction

                    message.success("Transaction Successful");
                } catch (error) {
                    console.error("Error:", error);
                    message.error(error.response.data.message)
                }
                finally{
                    setLoading(false)
                }
            },
            onCancel() {
            },
        });
    };


    useEffect(() => {
        (async () => {
            await getUserByBarcode();
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <InventoryLayout>
            <LoadingSpinLocal loading={loading}>
                <div style={{ paddingLeft: 100 }}>
                    <div style={{marginLeft:"-17px"}}><GoHome/></div>
                    <h1>Assets Withdrawal</h1>
                    <Typography.Title level={3} > Hi, {currentUser.first_name} {currentUser.last_name} </Typography.Title>
                    <ProductScanning productList={productList} setProductList={setProductList} />
                    <br />
                    <Button type="primary" onClick={handleSubmit} disabled={formIsNotCompleted}> Withdraw products </Button>
                </div>
            </LoadingSpinLocal>
        </InventoryLayout>
    );
};

export default DecreaseStockApp;
