import { Descriptions, Input, Button, Space, App } from "antd";
import { useState, useContext } from 'react'
import { UserRoles } from "../UserRoles";
import { LoadingSpinLocal } from '../../../components/LoadingSpinLocal'
import { AuthContext } from "../../../components/Auth/AuthContext"
import axioss from '../../../services/restAPI';
import DepartmentByID from "../../../components/Departments/DepartmentByID";
import { DepartmentsSelector } from "../../../components/Departments";
// import { useNavigate } from "react-router-dom";


const UserInformation = (props) => {
    const { authConfig } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const { editUser, setEditUser, record } = props;
    const [email, setEmail] = useState(record.email)
    const [firstName, setFirstName] = useState(record.first_name)
    const [lastName, setLastName] = useState(record.last_name)
    const [role, setRole] = useState(record.role);
    const [barcode, setBarcode] = useState(record.key_barcode);
    const [password, setPassword] = useState("");
    const [department, setDepartment] = useState("");
    const { message, modal } = App.useApp();
    // const navigate = useNavigate();


    const onSubmit = async () => {
        const updatedUser = {
            firstName,
            lastName,
            email,
            role,
            barcode,
            password,
            userID: record.id,
            department
        }

        try {
            setLoading(true)
            await axioss.put(`/users/${record.id}/`, { ...updatedUser },
                authConfig
            )
            
            message.success("Record updated successfully")

            window.location.reload();
            setEditUser(false)
        } catch (error) {
            console.error(error)
            modal.warning({
                title: 'Something went wrong',
                content: error.message,
            });
        }
        finally {
            setLoading(false)
        }
    }


    // console.log(record)
    return (
        <LoadingSpinLocal loading={loading}>
            <Descriptions bordered size='small' layout='horizontal' column={1} >

                <Descriptions.Item label="Name">
                    {editUser ? (
                        <Space size='large'>
                            <Input placeholder='Edit first name' value={firstName} onChange={(e) => { setFirstName(e.target.value) }} />
                            <Input placeholder='Edit last name' value={lastName} onChange={(e) => { setLastName(e.target.value) }} />
                        </Space>
                    ) : (record.name)}
                </Descriptions.Item>


                <Descriptions.Item label="Email">
                    {editUser ? (
                        <Input placeholder='Edit name' value={email} onChange={(e) => { setEmail(e.target.value) }} />
                    ) : (record.email)}
                </Descriptions.Item>

                <Descriptions.Item label="Department">
                    {editUser ? (
                        <DepartmentsSelector selectedDepartment = {department} setSelectedDepartment = {setDepartment}/>
                    ) : (<DepartmentByID departmentID = {record.department}/>)}
                </Descriptions.Item>



                <Descriptions.Item label="Role">
                    {editUser ?
                        (
                            <UserRoles role={role} setRole={setRole} />
                        ) :

                        (
                            <>
                                {record.role === 1 && ('Administrator')}
                                {record.role === 2 && ('Supervisor')}
                                {record.role === 3 && ('Labtech')}
                            </>
                        )
                    }
                </Descriptions.Item>

                <Descriptions.Item label="Barcode">
                    {editUser ? (
                        <Input placeholder='Edit barcode' value={barcode} onChange={(e) => { setBarcode(e.target.value) }} />
                    ) : (record.key_barcode)}
                </Descriptions.Item>


                {editUser ? (
                    <Descriptions.Item label="New Password">
                        <Input placeholder='Set new password' value={password} onChange={(e) => { setPassword(e.target.value) }} />
                    </Descriptions.Item>
                ) : (null)}

            </Descriptions>

            <br />

            {editUser ? (
                <Space size='large'>
                    <Button type='primary' onClick={() => { onSubmit() }}>Submit</Button>
                    <Button onClick={() => { setEditUser(false) }}>Cancel</Button>
                </Space>
            ) : null}

        </LoadingSpinLocal>
    );
};

export { UserInformation }