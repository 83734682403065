import React from 'react';
import { Descriptions } from 'antd';

const JsonToDescription = ({ data }) => {
  return (
    <Descriptions bordered column={1} size='small'>
      {Object.entries(data).map(([key, value]) => (
        <Descriptions.Item label={key} key={key}>
          {value}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
};

export default JsonToDescription;






// column={1} size='small'