import { Space } from 'antd'
const dayjs = require('dayjs');

function parseISOdateString(isoDateString) {

  const formattedDate = dayjs(isoDateString).utc('z').local().tz('America/New_York').format('YYYY-MM-DD HH:mm:ss')

  const year = formattedDate.split('-')[0]
  const month = formattedDate.split('-')[1]
  const day = formattedDate.split('-')[2].split(' ')[0]

  const time = formattedDate.split(' ')[1]
  const hours = time.split(':')[0]
  const minutes = time.split(':')[1]
  const seconds = time.split(':')[2]

  return {
    date: `${year}-${month}-${day}`,
    time: `${hours}:${minutes}:${seconds}`
  };
}

function parseTimestamp(timestamp) {
  try {
    var date = new Date(parseInt(timestamp));
    return date.toISOString().split('T')[0]
  } catch (error) {
    return 'Invalid timestamp'
  }
}


const DateFormatter = (props) => {

  const { dateString, hideTime, hideDate, plainText } = props
  

  if (plainText) {
    return parseISOdateString(dateString).date
  }

  return (
    <Space direction='vertical' size={'s'}>
      {!hideDate && (<div>{parseISOdateString(dateString).date}</div>)}
      {!hideTime && (<small>{parseISOdateString(dateString).time}</small>)}
    </Space>
  )
}

export default DateFormatter

export { parseISOdateString, parseTimestamp };

// render: (_) => (<>{dayjs(_).utc('z').local().tz('America/New_York').format('YYYY-MM-DD HH:mm:ss')}</>)
