import { Select, Tag } from "antd";

// const [role, setRole] = useState('Select role');

const UserRoles = (props) => {
    const {role, setRole} = props
    return (
        <Select
            defaultValue={role}
            style={{
                width: '100%',
            }}
            onChange={(value) => { setRole(value); }}
            options={[
                {
                    value: 1,
                    label: 'Administrator',
                },
                {
                    value: 2,
                    label: 'Supervisor',
                },
                {
                    value: 3,
                    label: 'Labtech',
                },
            ]}
        />
    )
}

const UserRoleInterpreter= (props) =>(
    <>
        {props.role===1&&(
            <Tag color="red">Administrator</Tag>
        )}
        {props.role===2&&(
            <Tag color="green">Supervisor</Tag>
        )}
        {props.role===3&&(
            <Tag>Labtech</Tag>
        )}
    </>
)

export { UserRoles, UserRoleInterpreter }
