import React, { useState, useEffect, useContext } from "react";
import { Select, Spin, Space, Tag } from "antd";
import axioss from "../../../services/restAPI";
import buildQueryParams from "../../../services/utilites/buildQueryParams";
import { AuthContext } from "../../../components/Auth/AuthContext";

const ItemsSearcher = (props) => {
  const { authConfig } = useContext(AuthContext)
  const { Option } = Select;
  const { selectedItem, setSelectedItem, predefinedItemNumber } = props;
  const [options, setOptions] = useState([]);
  const [localLoading, setLocalLoading] = useState(false);
  const [currentOption, setCurrentOption] = useState(null); //the purpose of having this option is to be able to clear the search bar if itemSelected was set to Null


  const getItemByNumber = async (searchQuery) => {
    if (searchQuery !== "" && searchQuery !== undefined) {
      setLocalLoading(true);
      try {
        const params = {
          // limit,
          // offset,
          // searchByNumber: searchQuery,
          searchByNameOrNumber:searchQuery
        };

        const url = `/items/items-old?${buildQueryParams(params)}`;
        const response = await axioss.get(url, params, authConfig);
        // console.log('items searcher',response.data)
        const tempArr = response.data.items.map((item) => {
          return {
            ...item,
            key: item.id,
          };
        });

        setOptions(tempArr);
      }
      catch (error) {
        console.error(error);
      }
      finally {
        setLocalLoading(false);
      }
    }
  }

  useEffect(() => {
    if (predefinedItemNumber) {
      getItemByNumber(predefinedItemNumber)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predefinedItemNumber])

  useEffect(() => {
    if (options.length > 0 && predefinedItemNumber) {
      console.log(predefinedItemNumber, options)
      // const selectedOption = options.find((option) => option.item_number === predefinedItemNumber);
      const selectedOption = options.find((option) => option.item_number.includes(predefinedItemNumber));
      setSelectedItem(selectedOption);
      setCurrentOption(predefinedItemNumber);
      setOptions([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, predefinedItemNumber])

  const handleSelect = (value) => {
    const selectedOption = options.find((option) => option.item_number === value);
    setSelectedItem(selectedOption);
    setCurrentOption(value);
    setOptions([])
  };

  return (
    <Select
      autoFocus
      allowClear
      showSearch
      placeholder="Search an Item by Number"
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onClear={() => {
        setSelectedItem(null);
        setCurrentOption(null);
      }}
      onSearch={getItemByNumber}
      onSelect={handleSelect}
      style={{ minWidth: '400px', width: "100%" }}
      notFoundContent={
        localLoading ? <Spin className="localSpinnerPosition" /> : null
      }
      value={selectedItem === null ? null : currentOption}
    >
      {options.map((option) => (
        <Option style={{ height: 50 }} key={option.item_number} value={option.item_number}>
          <SearchOption option={option} />
        </Option>
      ))}
    </Select>
  );
};

export { ItemsSearcher };

const SearchOption = (props) => {
  const { option } = props;
  return (
    <Space direction="horizontal" size="x-small">
      <Tag color="gold">{option.item_number}</Tag>
      <div style={{ marginRight: "15px" }}>{option.name}</div>
      <Tag color="cyan">{option.vendor}</Tag>
    </Space>
  );
};
