import { React } from 'react'
import { Collapse, Space, Select, DatePicker, Input, Button } from 'antd'
import { ItemsSearcher } from '../Items/ItemsSearcher'
import UserSelect from '../Users/UserSelect'
import { DepartmentsSelector } from '../../components/Departments'

const { RangePicker } = DatePicker;

const TableFilters = (props) => {
  const { Panel } = Collapse;
  const { searchFilters } = props
  const onPressEnter = () => {
    searchFilters.setSearching(true)
  }

  // const onClear = () => {
  //   searchFilters.setSearchByName("")
  // }
  return (
    <Collapse defaultActiveKey={["1"]} bordered={false}>
      <Panel header="Search" key="1">
        <Space size="large" wrap>

          <RangePicker onChange={(dates) => {
            const dateString = dates ? [dates[0].format('YYYY-MM-DD'), dates[1].format('YYYY-MM-DD')] : [];
            searchFilters.setSearchByDateRange(dateString);
          }}/>

          <UserSelect selectedUser={searchFilters.searchByUser} setSelectedUser={searchFilters.setSearchByUser} />

          <DepartmentsSelector selectedDepartment = {searchFilters.selectedDepartment} setSelectedDepartment = {searchFilters.setSelectedDepartment} />

          <ItemsSearcher selectedItem={searchFilters.searchByItem} setSelectedItem={searchFilters.setSearchByItem}/>

          <Select showSearch placeholder='Search by Type' onChange={(value) => searchFilters.setSearchByTransactionType(value)} allowClear style={{ width: "150px" }} >
            <Select.Option value="receiving">Receiving</Select.Option>
            <Select.Option value="withdrawing">Withdrawing</Select.Option>
            <Select.Option value="distributing">Distributing</Select.Option>
            <Select.Option value="discarding">Discarding</Select.Option>
            <Select.Option value="manually removed">Manually Removed</Select.Option>
          </Select>

          <Input placeholder='Search by Barcode' value={searchFilters.searchByBarcode} onChange={(e) => {searchFilters.setSearchByBarcode(e.target.value)}}  onPressEnter={onPressEnter} allowClear/>
        
          <Button onClick={onPressEnter} disabled={!searchFilters.searchByBarcode}>Search</Button>
        </Space>
      </Panel>
    </Collapse>
  )
}

export default TableFilters

// value={searchFilters.searchByTransactionType}
