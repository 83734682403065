import { GeneralContext } from "../GeneralContext";
import { React, useState } from "react";
import { IsSmallScreen } from "./IsSmallScreen";

const GeneralProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [connectedZebraPrinter, setConnectedZebraPrinter] = useState(false)
  const isSmallScreen = IsSmallScreen();

  return (
    <GeneralContext.Provider value={{ loading, setLoading,isSmallScreen, connectedZebraPrinter, setConnectedZebraPrinter }}>
      {children}
    </GeneralContext.Provider>
  );
};

export { GeneralProvider };
