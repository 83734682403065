import { React, useState, useEffect } from "react";
import { Button, App } from "antd";
import { PrinterFilled } from '@ant-design/icons';


const PrintLabelZebra = (props) => {
  const { connectedZebraPrinter, setConnectedZebraPrinter, labels, printRightAway, setPrintingDone } = props
  const { message, modal } = App.useApp()
  const [loading, setLoading] = useState(false)
  const [labelPrinted, setLabelPrinted] = useState(false)

  const connectToPrinter = async () => {
    try {
      const device = await navigator.usb.requestDevice({
        filters: [{ vendorId: 0x0a5f }],
      }); // Replace with your printer's vendorId
      await device.open();
      // Select configuration if it hasn't been automatically. First configuration is usually 1.
      if (device.configuration === null) {
        await device.selectConfiguration(1);
      }
      // Claim first interface (index starts from 0). You may need to adjust this for your device.
      await device.claimInterface(0);
      setConnectedZebraPrinter(device);
    } catch (error) {
      console.error("Could not connect to printer:", error);
    }
  };

  const printBarcode = async (label) => {
    const encoder = new TextEncoder();
    const barcodeHeight = 70
    const barcodePaddingLeft = 15
    const barcodePaddingTop = 30
    // const barcodeLinesWidth = 2
    const amountOfLabels = 1
    const line1 = label.labelText.line1
    const line2 = label.labelText.line2
    const line3 = label.labelText.line3
    const line4 = label.labelText.line4
    const labelTextHeight = 20
    const labelTextWidth = 20
    const labelTextFontSize = `${labelTextHeight}, ${labelTextWidth}'`
    const labelTextStarts = 160

    let labelTextEncoding = "";
    labelTextEncoding += "^XA";
    labelTextEncoding += "^CWZ,E:TT0003M_.FNT^FS";
    labelTextEncoding += `^FO${barcodePaddingLeft},${barcodePaddingTop}^BCN,${barcodeHeight},Y,N,N^FD${label.barcode}^FS^FO30`
    // labelTextEncoding += `^FO${barcodePaddingLeft},${barcodePaddingTop}^BY${barcodeLinesWidth}^B3N,,${barcodeHeight}^FD${barcodeText}^FS`
    labelTextEncoding += `^FO010,${labelTextStarts}^CI28^AZN,${labelTextFontSize}^FD ${line1}^FS`;
    labelTextEncoding += `^FO010,${labelTextStarts + labelTextHeight}^CI28^AZN,${labelTextFontSize}^FD ${line2}^FS`;
    labelTextEncoding += `^FO010,${labelTextStarts + labelTextHeight * 2}^CI28^AZN,${labelTextFontSize}^FD ${line3}^FS`;
    labelTextEncoding += `^FO010,${labelTextStarts + labelTextHeight * 3}^CI28^AZN,${labelTextFontSize}^FD ${line4}^FS`;
    labelTextEncoding += `^PQ${amountOfLabels}`;
    labelTextEncoding += "^XZ";

    const data = encoder.encode(labelTextEncoding);





    if (!connectedZebraPrinter) {
      modal.warning({
        title: "something went wrong..",
        content: "Printer not connected"
      })
      setConnectedZebraPrinter(false)
      return;
    }

    try {
      await connectedZebraPrinter.transferOut(1, data);
    } catch (error) {
      throw new Error(error)
    }
  };

  const printBarcodes = () => {
    try {
      setLoading(true);

      labels.forEach(label => {
        printBarcode(label)
      });
      message.info('Printing...')
      setLabelPrinted(true)
      if (setPrintingDone !== undefined) {
        setPrintingDone(true)
      }
    }
    catch (err) {
      console.log(err)
      setConnectedZebraPrinter(false)
      modal.warning({ title: "An error occurred while printing the label:", content: err.message })
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (printRightAway && labels.length > 0 && connectedZebraPrinter && !labelPrinted) {
      printBarcodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printRightAway, labels, connectedZebraPrinter]);

  return (
    <>
      {connectedZebraPrinter === false ? (
        <Button onClick={connectToPrinter}>Connect to Printer</Button>
      ) : (
        <Button onClick={printBarcodes} type={labelPrinted ? "link" : "primary"} icon={<PrinterFilled />} loading={loading}>Print</Button>
      )}
    </>
  );
};

export { PrintLabelZebra };
