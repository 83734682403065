import { React, useEffect, useState } from "react";
import { Table, Tag,  } from "antd";
import { PurchasedItemsRow } from "./PurchasedItemsRow";

import InputLOT from "./TableInputs/InputLOT";
import InputAmount from "./TableInputs/InputAmount";
import InputExpirationDate from "./TableInputs/InputExpirationDate";
import InputLocation from "./TableInputs/InputLocation";
import InputRocheQR from "./TableInputs/InputRocheQR";
import TableFilterByNumber from "./TableFilterByNumber";

export function PurchasedItemsTable(props) {
    const { foundPO, selectedItems, setSelectedItems } = props
    const [dataSource, setDataSource] = useState([])
    const [filteredDataSource, setFilteredDataSource] = useState([])



    console.log(dataSource)

    function groupByItemNumber(arr) {
        const grouped = {};

        for (const obj of arr) {
            const itemNumber = obj.actual_item.item_number;
            if (!grouped[itemNumber]) {
                grouped[itemNumber] = {
                    ...obj,
                    count: 0,
                };
            }

            grouped[itemNumber].count += 1;
        }

        return Object.values(grouped);
    }

    const updateSelectedRowsArr = () => {
        const updatedSelectedItems = dataSource.filter((item) => {
            return selectedItems.some((selectedItem) => selectedItem.purchase_order_item_id === item.purchase_order_item_id);
        });

        setSelectedItems(updatedSelectedItems);
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedItems([...selectedRows])
        },
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'item_id',
            width: '50px'
        },
        {
            title: 'Ordered',
            dataIndex: 'count',
            width: '50px'
        },
        {
            title: 'QR',
            dataIndex: 'QR',
            width: '200px',
            render: (text, record) => { return record.actual_item.vendor === "Roche" ? <InputRocheQR dataSource={dataSource} setDataSource={setDataSource} record={record} /> : <Tag>N/A</Tag> }
        },
        {
            title: 'Item Number',
            dataIndex: 'itemNumber',
            render: (text, record) => { return record.actual_item.item_number }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => { return record.actual_item.name }
        },

        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (text, record) => <InputAmount record={record} dataSource={dataSource} setDataSource={setDataSource} />
        },

        {
            title: 'Location',
            dataIndex: 'location',
            width: '200px',
            // render: (text, record) => <LocationForRows record={record} dataSource={dataSource} setDataSource={setDataSource} warehouseID={1} key={record.warehouse} />
            render: (text, record) => <InputLocation record={record} dataSource={dataSource} setDataSource={setDataSource} warehouseID={1} key={record.warehouse} />
        },
        {
            title: 'LOT',
            dataIndex: 'lotNumber',
            // render: (text,record) => <a>{record.item_id}</a>,
            render: (text, record) => <InputLOT record={record} dataSource={dataSource} setDataSource={setDataSource} />
        },
        {
            title: 'Expiration Date',
            dataIndex: 'expirationDate',
            render: (text, record) => <InputExpirationDate record={record} dataSource={dataSource} setDataSource={setDataSource} />
        },
    ];

    useEffect(() => {
        if (dataSource.length === 0) {
            let tempArr = [...foundPO.items]
            tempArr = groupByItemNumber(tempArr)
            tempArr.map((item) => {
                item.key = item.purchase_order_item_id
                return item
            })
            setDataSource(tempArr)
            setFilteredDataSource(tempArr)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        updateSelectedRowsArr()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource])

    return (
        <>
        <TableFilterByNumber setFilteredDataSource={setFilteredDataSource} dataSource={dataSource} />

        <Table
            bordered={true}
            columns={columns}
            expandable={{ expandedRowRender: (record) => (<PurchasedItemsRow record={record} />), rowExpandable: (record) => record.name !== 'Not Expandable', }}
            dataSource={filteredDataSource}
            rowSelection={{ type: 'checkbox', ...rowSelection, }}
            pagination={false}
        />
        </>
    );
}