import {React,useState} from "react";
import { Collapse, Button, Space, Col, Row,  Tabs } from "antd";
import { WarehouseAccess } from "./WarehouseAccess";
import { UserInformation } from "./UserInformation";

const { Panel } = Collapse;


export function TableRow(props) {
  const {record} = props;
  const [editUser, setEditUser] = useState(false)

  return (
    <div className="rowStyle">
      <Row>
        <Col xs={{ span: 23 }} lg={{ span: 11 }}>
          <Collapse defaultActiveKey={["1"]} bordered={false}>
            <Panel header="User Information" key="1">
              <UserInformation record={record} editUser={editUser} setEditUser={setEditUser}/>
            </Panel>
          </Collapse>
        </Col>
        <Col span={1} />
        <Col xs={{ span: 23 }} lg={{ span: 11 }}>
          <Collapse defaultActiveKey={["0"]} bordered={false}>
            <Panel header="Manage user" key="1">
              <ManageUser record={record} editUser={editUser} setEditUser={setEditUser} />
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
}


const ManageUser = (props) => {
  const {record,editUser,setEditUser} = props

  const Demo = ()=>{


    return(
      <Space direction="horizontal" size='large'>

        {editUser?
          <Button onClick={()=>{setEditUser(false)}}>Cancel Editing</Button>
        :
          <Button onClick={()=>{setEditUser(true)}}>Edit User</Button>
        }

      </Space>
    )
  }

  

  const tabs = [
    {
      key: '1',
      label: `General actions`,
      children: <Demo/>,
    },
    {
      key: '2',
      label: `Warehouse access`,
      children: <WarehouseAccess user={record} />,
    },
  ];
  
  return (
    <div >
      <Tabs defaultActiveKey="1" items={tabs} />
    </div>
  );
};
