import { useState, React, useContext} from 'react';
import { Input, Button, Space, App } from 'antd';
import { Link } from 'react-router-dom'
import axioss from '../../../services/restAPI';
import { UserRoles } from '../UserRoles';
// import { checkIfLettersOnlyString } from '../../../components/Utilites';
import { GeneralContext } from '../../../components/GeneralContext/GeneralContext';
import { DepartmentsSelector } from '../../../components/Departments';


const AddNewUser = () => {
    const {loading, setLoading} = useContext(GeneralContext)
    const {message,modal} = App.useApp();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('Select role');
    const [IDBarcode, setIDBarcode] = useState('');
    const [department, setDepartment] = useState('')
    
    const allFieldsAreFilled = (firstName !== '' && lastName !== '' && email !== '' && password !== '' && role !== 'Select role' && role !== '' && IDBarcode!=='' && department !=='')

    const generatePassword = () => {
        // Generate a random password
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; //@!#$%^&*()_+
        const length = 6
        let generatedPassword = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            generatedPassword += characters[randomIndex];
        }
        setPassword(generatedPassword);
    };

    const handleSubmit = async () => {
        // console.log('Submitted:', { firstName, lastName, email, password, role });
        await submit()
    };

    const submit = async () => {
        const params = { 
            password, 
            firstName, 
            lastName, 
            email, 
            role, 
            IDBarcode,
            department
        }
        try {
            setLoading(true)
            const response = await axioss.post('/register', params)
            console.log(response.data)
            handleClear()
        }
        catch (error) {
            console.error(error.response.data)
            modal.warning({
                title: 'Something went wrong',
                content: error.message,
            });
        }
        finally {
            setLoading(false)
            message.success('A new user was created');

        }
    }

    const handleClear = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPassword('');
        setRole('Select role');
        setIDBarcode('')
        setDepartment('')
    };


    return (
        <div>
            <Space direction="vertical" size='middle'>
                <Space direction='horizontal' size='large'>
                    <h2>Add a New User</h2><Link to="/users">Back</Link>
                </Space>
                <Input placeholder='First Name' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                <Input placeholder='Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                <Input placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())} />
                <DepartmentsSelector selectedDepartment = {department} setSelectedDepartment ={setDepartment}/>
                <Space direction='horizontal'>
                    <Input placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
                    <Button onClick={generatePassword}> Generate Password </Button>
                </Space>
                <UserRoles role={role} setRole={setRole}/>
                <Input placeholder='User ID barcode' value={IDBarcode} onChange={(e) => setIDBarcode(e.target.value)} />
                <Space direction='horizontal'>
                    <Button type="primary" onClick={handleSubmit} disabled={!allFieldsAreFilled || loading}> Submit </Button>
                    <Button onClick={handleClear} type='link'>Clear</Button>
                </Space>
            </Space>
        </div>
    );
};

export default AddNewUser;