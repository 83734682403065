//* Returns a PO by ID
import { React, useState, useContext, useEffect } from "react";
import { Input, App, Space,Tag,Button } from "antd";
import axioss from '../../../services/restAPI';
import { AuthContext } from "../../../components/Auth/AuthContext";
import { LoadingSpinLocal } from "../../../components/LoadingSpinLocal";

const PObyBarcode = (props) => {
  const [loading, setLoading] = useState(false)
  const { message, modal } = App.useApp()
  const { authConfig } = useContext(AuthContext)
  const { foundPO, setFoundPO } = props
  const [inputBarcode, setInputBarcode] = useState("")

  useEffect(() => {
    if (inputBarcode.length===8) {
      getPObyBarcode(inputBarcode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputBarcode])

  const getPObyBarcode = async (barcode) => {
    try {
      setLoading(true)
      const response = await axioss.get(`/po/${barcode}/`, authConfig)
      setFoundPO(response.data)
      // console.log(response.data)

    } catch (error) {
      console.error(error)

      if (error.response.data.statusCode === 500) {
        message.error('PO not found')
        return
      }

      modal.warning({
        title: 'Error while searching PO:',
        content: error.response.data.message
      })
    }

    finally {
      setInputBarcode("")
      setLoading(false)
    }
  }

  return (
    <>
      {foundPO === null ? (
        <LoadingSpinLocal loading={loading}>
          <Input placeholder="Search PO by a Barcode" value={inputBarcode} onChange={(e) => { setInputBarcode(e.target.value.toLocaleUpperCase()) }} style={{width:300}}/>
        </LoadingSpinLocal>
      ) : (
        <FoundPO foundPO={foundPO} setFoundPO={setFoundPO}/>
      )}
    </>

  );
};

export { PObyBarcode };

const FoundPO = (props) => {
  const { foundPO, setFoundPO } = props

  return (
    <Space >
      Selected PO:
      <Tag>{foundPO.barcode}</Tag> 
      <Button type="link" size='small' onClick={() => {setFoundPO(null)}}>cancel</Button>
    </Space>
  )
}
