


import FormattedLocation from '../../../Warehouses/Locations/FormattedLocation'
import Locations from '../../../Warehouses/Locations'
import { useEffect, useState } from 'react'

const InputLocation = (props) => {
    const { record, dataSource, setDataSource } = props;
    const [location, setLocation] = useState(null);
    const [existingLocation, setExistingLocation]=useState(null);
    const [dataSourceItem, setDataSourceItem] = useState(null);

    useEffect(() => {
        let tempArr = [...dataSource];
        tempArr = tempArr.filter((item) => {
            return item.purchase_order_item_id === record.purchase_order_item_id
        });
        setDataSourceItem(tempArr[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if(dataSourceItem && dataSourceItem.newLocation){
        setExistingLocation(dataSourceItem.newLocation)
      }
    },[dataSourceItem])

    useEffect(() => {
        // console.log(location);

        if (location !== null) {
            const tempArr = [...dataSource];
            const updatedArr = tempArr.map((item) => {
                if (item.purchase_order_item_id === record.purchase_order_item_id) {
                    item.newLocation = location;
                }
                return item;
            });
            setDataSource(updatedArr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const changeLocation = () =>{
        setLocation(null)
        setExistingLocation(null)

        const tempArr = [...dataSource];
            const updatedArr = tempArr.map((item) => {
                if (item.purchase_order_item_id === record.purchase_order_item_id) {
                    delete item.newLocation;
                }
                return item;
            });
        setDataSource(updatedArr);
    }

    return (
        <>

            {existingLocation ? (
                <FormattedLocation location = {existingLocation} onChangeLocation={changeLocation}/>
            ) : (
                <Locations record={record} initialLocation={location} setLocation={setLocation} selectedWarehouse={1} key={record.actual_item.item_number} />
            )}
        </>
    )

}

export default InputLocation


// import FormattedLocation from '../../../Warehouses/Locations/FormattedLocation'
// import Locations from '../../../Warehouses/Locations'
// import { useEffect, useState } from 'react'

// const InputLocation = (props) => {
//     const { record, dataSource, setDataSource } = props
//     const [location, setLocation] = useState(null)

//     useEffect(() => {
//         // console.log(location);

//         if (location !== null) {
//             const tempArr = [...dataSource];
//             const updatedArr = tempArr.map((item) => {
//                 if (item.purchase_order_item_id === record.purchase_order_item_id) {
//                     item.newLocation = location;
//                 }
//                 return item;
//             });
//             setDataSource(updatedArr);
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [location]);

//     const changeLocation = () =>{
//         setLocation(null)

//         const tempArr = [...dataSource];
//             const updatedArr = tempArr.map((item) => {
//                 if (item.purchase_order_item_id === record.purchase_order_item_id) {
//                     delete item.newLocation;
//                 }
//                 return item;
//             });
//         setDataSource(updatedArr);
//     }

//     return (
//         <>

//             {location ? (
//                 <FormattedLocation location = {location} onChangeLocation={changeLocation}/>
//             ) : (
//                 <Locations record={record} initialLocation={location} setLocation={setLocation} selectedWarehouse={1} key={record.actual_item.item_number} />
//             )}
//         </>
//     )

// }

// export default InputLocation