import { useState,useContext } from "react";
import { Space, Input, Button, App } from "antd";
import { useParams, Link } from "react-router-dom";
import { DepartmentsSelector } from "../../../components/Departments";
import { LoadingSpinLocal } from "../../../components/LoadingSpinLocal";
import axioss from "../../../services/restAPI";
import { LocationTypesDropdown } from "./LocationTypesDropdown";
import { AuthContext } from '../../../components/Auth/AuthContext';

const AddNewLocation = () => {
  const { authConfig } = useContext(AuthContext)

  const { warehouseID, warehouseName } = useParams();
  const { message, modal } = App.useApp();
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [department, setDepartment] = useState("");
  const [selectedType, setSelectedType] = useState("")

  const [key, setKey] = useState("")

  const locationData={
    department,
    type:selectedType,
    location:name,
    warehouse_id:warehouseID
  }

  const formNotCompleted = name === "" || department === "" || selectedType === ""
  const onClear = () => {
    setName('')
    setDepartment('')
    setSelectedType('')

    setKey(Date.now())
  }

  const submit = async () => {
    try {
      setLoading(true)
      await axioss.post('/locations', { ...locationData }, authConfig)
      // console.log(response.data)
      message.success("New item was added successfully")
      onClear()
    } catch (error) {
      console.error(error)
      modal.warning({
        title: 'Error while adding the new item',
        content: error.message,
      });
    }
    finally {
      setLoading(false)
    }
  }

  return (
    <LoadingSpinLocal loading={loading}>
      <Space direction='vertical'>

        <Space size='large'>
          <h1>Add {warehouseName} location</h1>
          <Link to="/warehouses">Back</Link>
        </Space>

        <DepartmentsSelector selectedDepartment={department} setSelectedDepartment={setDepartment} key={key+"department"}/>
        <LocationTypesDropdown selectedType={selectedType} setSelectedType={setSelectedType} warehouseID={warehouseID} key={key+"location"}/>
        <Input placeholder="Name" value={name} onChange={(e) => { setName(e.target.value) }} style={{ width: 300 }} key={key+"input"}/>

        <br />

        <Space>
          <Button disabled={formNotCompleted} type="primary" onClick={submit} > Submit </Button>
          <Button type="link" onClick={onClear} > Clear </Button>
        </Space>

      </Space>
    </LoadingSpinLocal>
  );
};

export { AddNewLocation };
