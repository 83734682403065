import { Table, } from 'antd';
// import JsonToCSV from '../../components/JsonToCSV'
import TableRow from './TableRow';
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc); dayjs.extend(timezone);

// function groupJsons(jsonArray) {
//     const grouped = {};

//     jsonArray.forEach(jsonObj => {
//         // Create a unique key based on fields except 'item_amount' and 'id'
//         const key = JSON.stringify({
//             item_number: jsonObj.item_number,
//             item_id: jsonObj.item_id,
//             item_name: jsonObj.item_name,
//             transaction_type: jsonObj.transaction_type,
//             // date: jsonObj.date.split('T')[0]
//         });

//         // Initialize a new group if key is not present
//         if (!grouped[key]) {
//             grouped[key] = {
//                 ...JSON.parse(key),
//                 item_amount: 0,
//                 elements: []
//             };
//         }

//         // Add current object to the group and update total_amount
//         grouped[key].elements.push(jsonObj);
//         grouped[key].item_amount += jsonObj.item_amount;
//     });

//     // Convert grouped objects back to an array
//     return Object.values(grouped);
// }

const TransactionsTable = (props) => {
    const { data } = props

    // console.log(data)

    // const [groupDataByItem, setGroupDataByItem] = useState(false);
    // const [dataSource, setDataSource] = useState([]);
    
    // const groupedData = useMemo(() => {
    //     return groupJsons([...data]);
    // }, [data]);


    // useEffect(() => {
    //     setDataSource(groupDataByItem ? groupedData : data);
    // }, [groupDataByItem, data, groupedData]);


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (_) => (<>{dayjs(_).utc('z').local().tz('America/New_York').format('YYYY-MM-DD HH:mm:ss')}</>)
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => `${record.user_first_name} ${record.user_last_name}`,
        },
        {
            title: 'Type',
            dataIndex: 'transaction_type',
            key: 'transaction_type',
        },
        {
            title: 'Data',
            dataIndex: 'table_name',
            key: 'table_name',
        },
    ];



    return (
        <>
            {/* <Space>
                <JsonToCSV data={dataSource} includeFields={['date', 'transaction_type', 'user_first_name', 'user_last_name', 'item_number', 'item_name', 'item_amount', 'barcodes']} />

                <Space>
                    Group by Item
                    <Tooltip title="Switch this to group or ungroup the displayed transactions. When on, transactions are grouped by item. When off, all transactions are listed individually and contain more details.">
                        <Switch checked={groupDataByItem} onChange={() => setGroupDataByItem(!groupDataByItem)} />
                    </Tooltip>
                </Space>
            </Space> */}

            <Table
                dataSource={data}
                columns={columns}
                expandable={{
                    expandedRowRender: (record) => (<TableRow record={record} />),
                    rowExpandable: (record) => record.old_value||record.new_value,
                }}
            />
        </>
    );
};

export default TransactionsTable;
