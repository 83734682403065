import { Select } from 'antd';

import { useState, useEffect, React, useContext } from "react";
import axioss from '../../services/restAPI';
import { AuthContext } from "../../components/Auth/AuthContext";
import { LoadingSpinLocal } from "../../components/LoadingSpinLocal";


const UserSelect = ({ setSelectedUser }) => {
    const [loading, setLoading] = useState(false)
    const { token } = useContext(AuthContext)
    const [users, setUsers] = useState([])

    const getUsers = () => {
        setLoading(true)
        axioss.get(`/users?limit=${1000}&offset=${0}&userRole=1`, { headers: { Authorization: token } })
            .then(response => {
                setUsers(response.data.users);
                // console.log(response.data)
            })
            .catch(error => console.error('Error fetching users:', error))
            .finally(setLoading(false))
    }

    useEffect(() => {
        getUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = value => {
        const user = users.filter((user) => (user.id === value))[0]
        setSelectedUser(user);
    };

    const filterOption = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    return (
        <LoadingSpinLocal loading={loading}>
            <Select
                allowClear
                showSearch
                style={{ width: 200 }}
                onChange={handleChange}
                placeholder="Search by User"
                filterOption={filterOption}
            >
                {users.map(user => (
                    <Select.Option key={user.id} value={user.id}>
                        {user.first_name + ' ' + user.last_name}
                    </Select.Option>
                ))}
            </Select>
        </LoadingSpinLocal>

    );
};

export default UserSelect;
