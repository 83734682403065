import FormattedLocation from "../../Warehouses/Locations/FormattedLocation";
import {Space} from 'antd'

const ProductLocations = (props) => {
    const { record } = props

    function extractUniqueLocations(data) {
        const uniqueLocations = {};
    
        data.forEach(item => {
            const locationKey = `${item.location_id}-${item.location_department}-${item.location_number}-${item.location_type}-${item.locations_warehouse_id}`;
            
            if (!uniqueLocations[locationKey]) {
                uniqueLocations[locationKey] = {
                    location_department: item.location_department,
                    location_id: item.location_id,
                    location_number: item.location_number,
                    location_type: item.location_type,
                    location_warehouse_id: item.locations_warehouse_id,
                    count: 0
                };
            }
    
            uniqueLocations[locationKey].count++;
        });
    
        return Object.values(uniqueLocations);
    }
    

    const products = extractUniqueLocations(record.products)
    return (
        <Space direction="vertical">
            {products.map((product) => {
                const location = {
                    "department": product.location_department,
                    "type": product.location_type,
                    "number": product.location_number,
                    "locationID": product.location_id
                }
                return(
                    <Space>
                        <b>{product.count} </b>in
                         <FormattedLocation key={product.location_number} location={location} hideOnChangeLocation />
                    </Space>
                )
            })}
        </Space>
    )
}

export default ProductLocations