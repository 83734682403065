import React, { useState, useEffect, useContext } from 'react';
import { Table, Typography } from 'antd';
import axioss from '../../../../services/restAPI';
import { AuthContext } from "../../../../components/Auth/AuthContext";
import { LoadingSpinLocal } from "../../../../components/LoadingSpinLocal";
import JsonToCSV from '../../../../components/JsonToCSV'
import dayjs from 'dayjs';
import TableFilters from './TableFilters';
import buildQueryParams from '../../../../services/utilites/buildQueryParams';

const { Title } = Typography;

const ItemsUsage = () => {
  const [loading, setLoading] = useState(false)
  const { authConfig } = useContext(AuthContext)
  const [data, setData] = useState([])
  const [searchByDateRange, setSearchByDateRange]= useState([dayjs().subtract(30, 'day'), dayjs()])
  const [searchByName, setSearchByName]= useState("")
  const [searchByVendor, setSearchByVendor]= useState("")
  const [searchByDepartment, setSearchByDepartment]= useState("")
  const [searchByType, setSearchByType]= useState("")
  const [searchByNumber, setSearchByNumber] = useState('')

  const searchFilters = {

    searchByDateRange, setSearchByDateRange,

    searchByName,setSearchByName,

    searchByVendor,setSearchByVendor,

    searchByDepartment,setSearchByDepartment,

    searchByType,setSearchByType,

    searchByNumber, setSearchByNumber,
  }


  const startDate = searchByDateRange[0] 
  const endDate = searchByDateRange[1]

  useEffect(() => {
      fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchByDateRange, searchByName,searchByVendor,searchByDepartment,searchByType, searchByNumber]);

  const fetchData = async () => {
    try {
      setLoading(true)

      const params = {
        startDate,
        endDate,
        searchByName,
        searchByVendor,
        searchByDepartment,
        searchByType,
        searchByNumber,
        // offset,
        // limit
    };
      const url = `/reports/stock/items-usage/items-usage?${buildQueryParams(params)}`;
      const response = await axioss.get(url, authConfig);
      setData(response.data);
      console.log(response.data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    finally {
      setLoading(false)
    }
  };

  const columns = [
    {
      title: 'Item Number',
      dataIndex: 'item_number',
      key: 'item_number',
    },
    {
      title: 'Item Name',
      dataIndex: 'item_name',
      key: 'item_name',
    },
    {
      title: 'Ordered',
      dataIndex: 'amount_ordered',
      key: 'amount_ordered',
    },
    {
      title: 'Received',
      dataIndex: 'amount_received',
      key: 'amount_received',
    },

    {
      title: 'Withdrawn',
      dataIndex: 'amount_withdrawn',
      key: 'amount_withdrawn',
      render: (text) => `${text}`,
    },
  ];

  return (
    <LoadingSpinLocal loading={loading}>
      <Title level={4}>Items Usage Report</Title>
      <br />
      <TableFilters searchFilters={searchFilters}/>
      <JsonToCSV data={[...data]} includeFields={['item_number', 'item_name', 'item_vendor','amount_ordered', 'amount_received', 'amount_withdrawn']} />
      <Table dataSource={data} columns={columns} rowKey="item_id" />
    </LoadingSpinLocal>
  );
};

export default ItemsUsage;
