import MyCollapse from "../../../../components/Collapse"
import { PurchasedItems } from "../../../PO/PurchasedItems"
import { Tag } from "antd"

const PossiblePO = (props) => {
    const { PO,isSelected } = props

    const PObarcode = () => (<Tag color={isSelected?'green':null}>{PO.barcode}</Tag>)
    const title = (
        <span>
            {PObarcode()} created {PO.date_created.split('T')[0]}
        </span>
    )
    return (
        <MyCollapse title={title} child={<PurchasedItems record={PO} hidePrice />} collapsed style={{ width: 800 }} />
    )
}

export default PossiblePO
