import { Select,  } from 'antd';
import { useState, useEffect, useContext } from "react";
import axioss from '../../../services/restAPI';
import { AuthContext } from "../../../components/Auth/AuthContext";
import { App } from 'antd'
import { LoadingSpinLocal } from "../../../components/LoadingSpinLocal";



const ItemUnitOfMeasureSelect = (props) => {
    const selectWidth = 200

    const [loading, setLoading] = useState(false)
    const { authConfig } = useContext(AuthContext)
    const { modal } = App.useApp()
    const [unitsOfMeasure, setUnitsOfMeasure] = useState([])
    const {selectedUnitOfMeasure, setSelectedUnitOfMeasure} = props
    const selectedUnitOfMeasureIsNotEmptyInitially = selectedUnitOfMeasure!=="" && isNaN(selectedUnitOfMeasure) && unitsOfMeasure.length>0
   
   
    useEffect(() => {
        if(unitsOfMeasure.length===0){
            getUnitsOfMeasure()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    useEffect(() => {
        
        if (selectedUnitOfMeasureIsNotEmptyInitially) {
            const convertLabelToValue = () => {

                
                const value = ((unitsOfMeasure.filter(i => i.unit === selectedUnitOfMeasure))[0]).id
                // console.log(selectedUnitOfMeasure,value)

                return value
            }
            
            setSelectedUnitOfMeasure(convertLabelToValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUnitOfMeasureIsNotEmptyInitially])


    const getUnitsOfMeasure = async () => {
        try {
            setLoading(true)
            const response = await axioss.get("/items/item-units-of-measure/", authConfig)
            setUnitsOfMeasure(response.data)
        }
        catch (err) {
            console.log(err)
            modal.warning({
                title: 'Error while loading item units of measure',
                content: err.message,
            });
        }
        finally {
            setLoading(false)
        }
    }

    const options = unitsOfMeasure.map(unit => {
        return {
            value: unit.id,
            label: unit.unit,
        }
    })



    return (
        <LoadingSpinLocal loading={loading}>
            <Select
            value={selectedUnitOfMeasure === "" ? null : selectedUnitOfMeasure}
            loading={loading}
            placeholder='Unit of Measure'
            options={options}
            onSelect={(option) => {setSelectedUnitOfMeasure(option)}}
            style={{minWidth:selectWidth}}
        />
        </LoadingSpinLocal>
    )
};
export default ItemUnitOfMeasureSelect;