import {React} from "react";
import { Collapse, Button, Space, Col, Row, App } from "antd";

const { Panel } = Collapse;

export function TableRow(props) {
  const record = { ...props.record };
  return (
    <div className="rowStyle">
      <Row>
        <Col xs={{ span: 23 }} lg={{ span: 11 }}>
          <Collapse defaultActiveKey={["1"]} bordered={false}>
            <Panel header="Order Information" key="1">
              information
            </Panel>
          </Collapse>
        </Col>
        <Col span={1} />
        <Col xs={{ span: 23 }} lg={{ span: 11 }}>
          <Collapse defaultActiveKey={["0"]} bordered={false}>
            <Panel header="Actions" key="1">
              <Actions record={record} />
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
}


const Actions = () => {
  // const record = {...props.record}
  const { message } = App.useApp();

  const handleOnEdit = ()=>{
    message.info('Action performed');
  }

  return (
    <div >
      <Space direction="horizontal">
        <Button type="primary" onClick={()=>{handleOnEdit()}}>Test action</Button>
      </Space>
    </div>
  );
};
