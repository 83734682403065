import { PObyBarcode } from "../../PO/PObyBarcode"
import {useEffect, useState, useContext} from 'react'
import {PurchasedItemsTable} from "./PurchasedItemsTable"
import { Button, Divider,App } from 'antd';
import axioss from '../../../services/restAPI';
import { AuthContext } from "../../../components/Auth/AuthContext";
import { LoadingSpinLocal } from "../../../components/LoadingSpinLocal";
import { AfterSubmitModal } from "./AfterSubmitModal";

const ReceiveByPO = () => {
  const [foundPO, setFoundPO] = useState(null)
  const [selectedItems, setSelectedItems] = useState([])
  const [readyToSubmit, setReadyToSubmit] = useState(false)
  const {authConfig} = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const {message,modal} = App.useApp()
  const [showModal, setShowModal] = useState(false)
  const [afterSubmitResponse, setAfterSubmitResponse] = useState(null)

  useEffect(() => {
    // console.log('%cindex.jsx line:11 selectedItems', 'color: #007acc;', selectedItems);
    checkIfAllRequiredItemFieldsFilled()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedItems])


  const onSubmit = async () => {
    try {
      setLoading(true)
      // console.log('selectedItems',selectedItems)
      const response = await axioss.post('/action/create-stocks-by-PO/', {items:selectedItems}, authConfig)
      // console.log(response.data)
      message.success('Submitted successfully')
      setAfterSubmitResponse(response.data)
      setFoundPO(null)
      setShowModal(true)
    } catch (error) {
      console.error(error)
      modal.warning({ title: "Error while submitting:", content: error.response.data.error?error.response.data.error:error.response.data })
    }
    finally {
      setLoading(false)
    }
  }


  const checkIfAllRequiredItemFieldsFilled = () => {

    const isReadyToSubmit = selectedItems.every((item) =>
      item.amount &&
      (item.actual_item.expirationDateRequired ? item.expirationDate : true) &&
      (item.actual_item.lotRequired ? item.lotNumber : true) &&
      item.newLocation
    );

    setReadyToSubmit(isReadyToSubmit);
  };

  return (
    <>
      <LoadingSpinLocal loading={loading}>
        {afterSubmitResponse !== null && (
          <AfterSubmitModal showModal={showModal} setShowModal={setShowModal} barcodes={afterSubmitResponse.barcodes} selectedItems={selectedItems} />
        )}
        <h2 style={{color:'red'}}>This page is legacy. Avoid using it </h2>
        <PObyBarcode foundPO={foundPO} setFoundPO={setFoundPO} />
        <Divider />

        {foundPO && (
          <PurchasedItemsTable foundPO={foundPO} selectedItems={selectedItems} setSelectedItems={setSelectedItems} />
        )}

        {selectedItems !== null && selectedItems.length > 0 && (
          <Button type='primary' disabled={!readyToSubmit} onClick={onSubmit}>Submit</Button>
        )}
      </LoadingSpinLocal>
    </>
  )
}

export {ReceiveByPO}

