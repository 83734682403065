import {React,useState, useContext} from "react";
import { Button, App } from "antd";
import { PrinterFilled } from '@ant-design/icons';
import { GeneralContext } from "../GeneralContext/GeneralContext";

const Processor = (props) => {
  const { connectedZebraPrinter, setConnectedZebraPrinter } = useContext(GeneralContext)

  const { labels, printOnce } = props
  const { message, modal } = App.useApp()
  const [loading, setLoading] = useState(false)
  const [labelPrinted, setLabelPrinted] = useState(false)
  const [disableButtons, setDisableButtons] = useState(false)

  const connectToPrinter = async () => {
    try {
      const device = await navigator.usb.requestDevice({
        filters: [{ vendorId: 0x0a5f }],
      }); // Replace with your printer's vendorId
      await device.open();
      // Select configuration if it hasn't been automatically. First configuration is usually 1.
      if (device.configuration === null) {
        await device.selectConfiguration(1);
      }
      // Claim first interface (index starts from 0). You may need to adjust this for your device.
      await device.claimInterface(0);
      setConnectedZebraPrinter(device);
    } catch (error) {
      console.error("Could not connect to printer:", error);
    }
  };

  const printLabel = async (label) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(label);


    if (!connectedZebraPrinter) {
      modal.warning({
        title: "something went wrong..",
        content: "Printer not connected"
      })
      setConnectedZebraPrinter(false)
      return;
    }

    try {
      await connectedZebraPrinter.transferOut(1, data);
    } catch (error) {
      throw new Error(error)
    }
  };

  const printLabels = () => {
    try {
      setLoading(true);

      labels.forEach(label => {
        printLabel(label)
      });
      message.info('Printing...')
      setLabelPrinted(true)
    }
    catch (err) {
      console.log(err)
      setConnectedZebraPrinter(false)
      modal.warning({ title: "An error occurred while printing the label:", content: err.message })
    }
    finally {
      setLoading(false)
      if (printOnce){
        setDisableButtons(true)
      }
    }
  }

  return (
    <>
      {connectedZebraPrinter === false ? (
        <Button onClick={connectToPrinter} disabled={disableButtons}>Connect to Printer</Button>
      ) : (
        <Button onClick={printLabels} type={labelPrinted?"link":"primary"} icon={<PrinterFilled />} loading={loading} disabled={disableButtons}>Print</Button>
      )}
    </>
  );
};

export default Processor;


/*
^XA: This is the start of the ZPL label format command.
^FO50,50: This command positions the starting point of the barcode at coordinates (50, 50) on the label.
^BCN,70,Y,N,N: This command specifies the barcode type (BCN), sets the bar height to 70 units, and configures the barcode appearance (Y for printing human-readable text, N for no interpretation line, and N for no interpretation line above the barcode).
^FD${barcodeText}^FS: This command specifies the barcode data to be encoded. ${barcodeText} is a placeholder that likely gets replaced with the actual barcode data.
^FO50,150: This command positions the starting point of the label text at coordinates (50, 150) on the label.
^ADN,36,10: This command sets the font type (ADN) and specifies the font size (36 points) and print orientation (10 for normal).
^FD${labelText}^FS: This command specifies the label text data to be printed. ${labelText} is a placeholder that likely gets replaced with the actual label text.
^XZ: This is the end of the ZPL label format command.
*/


/*
            cmds += "^FO010,160^CI28^AZN,50,40^FD- Roman: ABCDEFGHIJKLMNOPQRSTUVWXYZ^FS";
            cmds += "^FO010,230^CI28^AZN,50,40^FD- Cyrillic: ЁЂЃЄЅІЇЈЉЊЋЌЎЏАБВГДЕЖЗИЙКЛМН^FS";
            cmds += "^FO010,300^CI28^AZN,50,40^FD- Eastern: ŠŚŤŽŹŁĄŞŻĽľŔÁÂĂÄĹĆÇČÉĘËĚÍÎĎ^FS";
            cmds += "^FO010,370^CI28^AZN,50,40^FD- Greek: ΆΈΉΊΌΐΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩΪΫ^FS";
            cmds += "^FO010,440^CI28^AZN,50,40^FD- Turkish: ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎĞÑÒÓÔÕÖ×ØÙÚÛÜİŞ^FS";
            cmds += "^PA1,1,1,1^FS"; //^PA command is mandatory for RTL Languages like Arabic & Hebrew
            cmds += "^FO010,510^CI28^AZN,50,40^FD- Arabic: زيبرة  تكنوليجيز اوربا المحدودة^FS";
*/