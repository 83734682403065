import {useEffect, useState, useContext, } from 'react'
import { GeneralContext } from '../../components/GeneralContext/GeneralContext';
import { ItemsTable } from './table';
import {App,Space} from 'antd'
import { ItemsSearchBars } from './ItemsSearchBars';
import { AuthContext } from '../../components/Auth/AuthContext';
import { Link } from "react-router-dom";
import buildQueryParams from "../../services/utilites/buildQueryParams";
import axioss from "../../services/restAPI";
import MyPagination from '../../components/Pagination';

const Items = () => {
    const [repeatGetItems, setRepeatGetItems] = useState(false);
    const {authConfig} = useContext(AuthContext)
    const {setLoading} = useContext(GeneralContext)
    const [items, setItems] = useState([])
    const [fistLoading, setFirstLoading] = useState(true)
    const {modal} = App.useApp();

    const [searchByName, setSearchByName]= useState("")
    const [searchByVendor, setSearchByVendor]= useState("")
    const [searchByDepartment, setSearchByDepartment]= useState("")
    const [searchByType, setSearchByType]= useState("")
    const [searchByNumber, setSearchByNumber] = useState('')


    //* pagination
    const [totalItems, setTotalItems] = useState(0)
    const [limit, setLimit] = useState(10)
    const [offset, setOffset] = useState(0)
    useEffect(() => {
        getItems()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset, limit])
    //* pagination
 
    const searchFilters = {
        searchByName:searchByName,
        setSearchByName:setSearchByName,

        searchByVendor:searchByVendor,
        setSearchByVendor:setSearchByVendor,

        searchByDepartment:searchByDepartment,
        setSearchByDepartment:setSearchByDepartment,

        searchByType:searchByType,
        setSearchByType:setSearchByType,

        searchByNumber,
        setSearchByNumber,
    }

    useEffect(()=>{
        if(fistLoading){
            getItems();
            setFirstLoading(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchByName,searchByVendor,searchByDepartment,searchByType, searchByNumber])

    useEffect(() => {
        if (repeatGetItems){
            getItems()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[repeatGetItems])

    const getItems = async () => {
        try {
            setLoading(true)

            const params = {
                searchByName,
                searchByVendor,
                searchByDepartment,
                searchByType,
                searchByNumber,
                offset,
                limit
            };

            const url = `/items/items-old?${buildQueryParams(params)}`;
            const response = await axioss.get(url, authConfig);

            // const result = await getItemsService(authConfig, searchFilters)
            console.log(response.data)
            setItems(response.data.items)
            setTotalItems(response.data.count)
        }
        catch (err) {
            modal.warning({
                title: 'Items loading error:',
                content: err.message
            })
            console.log(err)
        }
        finally {
            setLoading(false)
            setRepeatGetItems(false)
        }
    }

    return (
        <>
            <Space size={'large'}>
                <h2>Items</h2>
                <Link to="add-new" style={{ paddingTop: 8 }}>Add a New Item</Link>
            </Space>
            <ItemsSearchBars searchFilters={searchFilters}/>
            <ItemsTable dataSource={items} setRepeatGetItems={setRepeatGetItems}/>
            <MyPagination totalItems={totalItems} limit={limit} offset={offset} setLimit={setLimit} setOffset={setOffset} />
        </>
    )
}

export {Items}
