import { useState, useEffect, React, useContext } from "react";
import { AuthContext } from "../../components/Auth/AuthContext"
import axioss from '../../services/restAPI';
import buildQueryParams from "../../services/utilites/buildQueryParams";
import { LoadingSpinLocal } from '../../components/LoadingSpinLocal'
import { Table, Tag, Space, Descriptions, Collapse, Switch } from "antd";
import FormattedLocation from "../Warehouses/Locations/FormattedLocation";
import LabelRePrint from "../../components/LabelRePrint";
import DateFormatter from "../../components/DateFormatter";
const dayjs = require('dayjs');

const ExpiresSoon = () => {
    const [hideExpiredProducts, setHideExpiredProducts] = useState(true)
    const [loading, setLoading] = useState(false)
    const { authConfig } = useContext(AuthContext)
    const [inStockProducts, setInStockProducts] = useState([])
    const { Panel } = Collapse
    console.log(hideExpiredProducts)

    useEffect(() => {
        getProductsInStock()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getProductsInStock = async () => {
        try {
            setLoading(true)

            const params = {
                searchByExpiresIn: '90'
            };
            const url = `/in-stock?${buildQueryParams(params)}`;

            const response = await axioss.get(url, authConfig);
            setInStockProducts(response.data)

            // console.log(response.data)

        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
        }
    }

    const HideExpired = () => {
        return (
            <><small>Hide expired products</small> <Switch size="small" defaultChecked={hideExpiredProducts} onChange={(checked) => { setHideExpiredProducts(checked) }} /></>
        )
    }

    return (
        <Collapse defaultActiveKey={[0]} >
            <Panel header="Expires Soon" key={0}>
                <HideExpired />
                <br />
                <br />
                <LoadingSpinLocal loading={loading}>
                    <GroupProducts products={inStockProducts} scrolled expandable hideExpiredProducts={hideExpiredProducts}  />
                </LoadingSpinLocal>
            </Panel>
        </Collapse>
    )

}

export default ExpiresSoon





const GroupProducts = (props) => {
    const {
        products,
        bordered,
        scrolled,
        hideLot,
        hideBarcode,
        expandable,
        hideExpiredProducts,
        hideShelfLife,
    } = props;

    function groupJsons(jsonArray) {
        const grouped = {};

        jsonArray.forEach((json) => {
            // const key = `${json.item_id}-${json.expiration_date}-${json.section_id}-${json.lot}-${json.name}`;
            // const key = `${json.date_received}`;//expiration_date
            // const key = `${json.actual_item.item_name}`;//expiration_date
            const key = `${json.lot}`;//expiration_date

            if (!grouped[key]) {
                grouped[key] = {
                    ...json,
                    total_amount: 0,
                    barcodes: [], //remove
                    products: [],
                };
            }

            grouped[key].total_amount++;
            grouped[key].barcodes.push(json.barcode); //remove
            grouped[key].products.push(json);
        });

        return Object.values(grouped);
    }

    function filterExpiredProducts(products) {
        const today = new Date();
        return products.filter(
            (product) => new Date(product.expiration_date) > today
        );
    }

    let groupedProducts = groupJsons(products);

    groupedProducts = [...groupedProducts].map((product, index) => {
        product.key = `${index}`;
        return product;
    });

    if (hideExpiredProducts) {
        groupedProducts = filterExpiredProducts(groupedProducts);
    }

    function addShelfLife(items) {
        items.forEach(item => {
            const dateReceived = dayjs(item.date_received);
            const expirationDate = dayjs(item.expiration_date);
            const shelfLife = expirationDate.diff(dateReceived, 'day');
            item.shelf_life = shelfLife;
        });
        return items;
    }

    function addExpiresInDays(items) {
        const currentDate = dayjs();
        items.forEach(item => {
            const expirationDate = dayjs(item.expiration_date);
            const daysLeft = expirationDate.diff(currentDate, 'day');
            item.expires_in_days = daysLeft;
        });
        return items;
    }

    function getUniqueVendors(items) {
        const vendors = items.map(item => item.vendor);
        let uniqueVendors = [...new Set(vendors)]
        uniqueVendors.sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        return uniqueVendors.map(vendor => ({
            text: vendor,
            value: vendor
        }));
    }
    

    groupedProducts = addExpiresInDays(groupedProducts)
    groupedProducts = addShelfLife(groupedProducts)
    groupedProducts = groupedProducts.sort((a, b) => a.expires_in_days - b.expires_in_days)
    const vendors = getUniqueVendors(groupedProducts)

    console.log('groupedProducts', groupedProducts)

    let columns = [
        {
            title: "Item Number",
            dataIndex: "item_number",
            key: "item_number",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Vendor",
            dataIndex: "vendor",
            key: "vendor",
            width: 100,
            render: (_, record) => <>{record.actual_item[0].vendor}</>,
            filters: vendors,
            onFilter: (value, record) => record.actual_item[0].vendor.startsWith(value),
            filterSearch: true,
        },
        {
            title: "Received",
            dataIndex: "received",
            key: "received",
            render: (_, record) => (
                <DateFormatter dateString={record.products[0].date_received} />
            ),
            width: 120
        },
        {
            title: "Expiration date",
            dataIndex: "expiration_date",
            key: "expiration_date",
            render: (text, record) =>
                record.actual_item[0].expirationDateRequired === true ? (
                    <DateFormatter dateString={text} hideTime />
                ) : (
                    <Tag>Not Applicable</Tag>
                ),
            width: 120
        },
        {
            title: "Days before expires",
            dataIndex: "expires_in_days",
            key: "expires_in_days",
            sorter: (a, b) => a.expires_in_days - b.expires_in_days,
        },
        {
            title: "Shelf Life",
            dataIndex: "shelf_life",
            key: "shelf_life",
            sorter: (a, b) => a.shelf_life - b.shelf_life,
        },
        {
            title: "Lot number",
            dataIndex: "lot",
            key: "lot",
            width: 150,
            render: (text, record) => record.actual_item[0].lotRequired === true ? (<>{text}</>) : (<Tag>Not Applicable</Tag>),
        },
        {
            title: "Amount",
            dataIndex: "total_amount",
            key: "total_amount",
            width: 10,
        },
        // {
        //   title: "Labels",
        //   dataIndex: "labels",
        //   key: "labels",
        //   width: 10,
        //   render: (_, record) => (
        //     <LabelRePrint
        //       barcodes={record.barcodes}
        //       itemName={record.name}
        //       lotNumber={record.lot ? record.lot : "N/A"}
        //       expirationDate={
        //         record.expiration_date
        //           ? record.expiration_date.split("T")[0]
        //           : "N/A"
        //       }
        //     />
        //   ),
        // },
    ];

    if (hideLot) {
        columns = columns.filter((col) => col.key !== "lot");
    }
    if (hideBarcode) {
        columns = columns.filter((col) => col.key !== "barcode");
    }
    if (hideShelfLife) {
        columns = columns.filter((col) => col.key !== "shelfLife");
    }



    return (
        <Table
            dataSource={groupedProducts}
            columns={columns}
            size="small"
            bordered={bordered}
            expandable={
                expandable
                    ? {
                        expandedRowRender: (record) => <ActualProducts record={record} />,
                        // rowExpandable: (record) => record.barcodes.length > 1,
                        rowExpandable: (record) => {
                            return true;
                        },
                    }
                    : null
            }
            scroll={scrolled ? { x: "750px" } : null}
            pagination={{
                pageSizeOptions: ["5", "10", "100", "1000"],
                showSizeChanger: true,
            }}
        />
    );
};


const ActualProducts = (props) => {
    const { record } = props;

    const actualProducts = [...record.products];
    return (
        <div className="rowStyle">
            <Space direction="vertical">

                {actualProducts.map((product) => {
                    const location = {
                        department: product.location_department,
                        type: product.location_type,
                        number: product.location_number,
                        locationID: product.location_id,
                    };
                    const descriptionItems = [
                        {
                            key: "1",
                            label: "barcode",
                            children: product.barcode,
                        },
                        {
                            key: "2",
                            label: "location",
                            children: (
                                <FormattedLocation location={location} hideOnChangeLocation />
                            ),
                        },
                    ];
                    return (
                        <Collapse
                            items={[
                                {
                                    key: product.barcode,
                                    label: product.barcode,
                                    children: (
                                        <Descriptions items={descriptionItems} column={1} />
                                    ),
                                },
                            ]}
                            defaultActiveKey={["1"]}
                            bordered={false}
                            style={{ width: 500 }}
                        />
                    );
                })}

                <LabelRePrint
                    barcodes={record.barcodes}
                    itemName={record.name}
                    lotNumber={record.lot ? record.lot : "N/A"}
                    expirationDate={
                        record.expiration_date
                            ? record.expiration_date.split("T")[0]
                            : "N/A"
                    }
                />
            </Space>
        </div>
    );
};
