import {Space, Button, InputNumber} from 'antd'
const InputAmount = (props) => {
    const { record, dataSource, setDataSource } = props
    const onSave = (value) => {
        value = value.replace(/\D/g, '');
        if (value !== "") {
            const tempArr = [...dataSource]
            tempArr.map((item) => {
                if (item.purchase_order_item_id === record.purchase_order_item_id) {
                    item.amount = value
                }
                return item
            })
            setDataSource(tempArr)
        }
    }
    const onClear = () => {
        const tempArr = [...dataSource]
        tempArr.map((item) => {
            if (item.purchase_order_item_id === record.purchase_order_item_id) {
                item.amount = undefined
            }
            return item
        })
        setDataSource(tempArr)
    }

    return (
        <>
            {record.amount ?
                (
                    <Space>
                        <>{record.amount}</>
                        <Button onClick={onClear} type='text'>change</Button>
                    </Space>
                ) : (
                    <InputNumber placeholder='Amount' min={1} onPressEnter={(e) => { onSave(e.target.value) }} onBlur={(e) => { onSave(e.target.value) }} />
                )}
        </>
    )
}

export default InputAmount