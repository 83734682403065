const TableRowBarcodes = (props) => {
    const { record } = props
    return (
        <>
            <b>Barcodes:</b>

            {record.barcodes.map(barcode=>(
                <div>{barcode}</div>
            ))}
        </>
    )
}

export default TableRowBarcodes