import { Tag,  } from "antd"

const LocationTypeFormatted = (props) => {
  const {type} = props
  let color;
  if(type==="Flammable Yellow"){color="#d4b106"}
  if(type==="Freezer"){color="cyan"}
  if(type==="Corrosive Blue"){color="#0958d9"}
  if(type==="Room Temp Cabinet"){color="#5b8c00"}
  if(type==="Refrigerator"){color="#08979c"}
    return(
      <Tag color={color}>{type}</Tag>
    )
}

export default LocationTypeFormatted