import { React } from 'react'
import { Input, Collapse, Space, DatePicker, Button } from 'antd'
import dayjs from 'dayjs';
import { DepartmentsSelector } from '../../../../components/Departments';
import { ItemTypeSelect } from '../../../Items/ItemTypeSelect';

const { RangePicker } = DatePicker;

const TableFilters = (props) => {
  const { Panel } = Collapse;
  const { searchFilters } = props

  const onClear = () => {
    searchFilters.setSearchByName("")
    searchFilters.setSearchByVendor("")
    searchFilters.setSearchByDepartment("")
    searchFilters.setSearchByType("")
    searchFilters.setSearchByNumber("")
  }

  return (
    <Collapse defaultActiveKey={["1"]} bordered={false}>
      <Panel header="Search" key="1">
        <Space size="large" wrap>

          <RangePicker
            defaultValue={[dayjs().subtract(30, 'day'), dayjs()]}
            onChange={(dates) => {
              const dateString = dates ? [dates[0].format('YYYY-MM-DD'), dates[1].format('YYYY-MM-DD')] : [];
              searchFilters.setSearchByDateRange(dateString);
            }}
          />

          <Input placeholder='Search by Number' value={searchFilters.searchByNumber} onChange={(e) => { searchFilters.setSearchByNumber(e.target.value) }} allowClear />
          <Input placeholder='Search by Name' value={searchFilters.searchByName} onChange={(e) => { searchFilters.setSearchByName(e.target.value) }} allowClear />
          <Input placeholder='Search by Vendor' value={searchFilters.searchByVendor} onChange={(e) => { searchFilters.setSearchByVendor(e.target.value) }} allowClear />
          <DepartmentsSelector selectedDepartment={searchFilters.searchByDepartment} setSelectedDepartment={searchFilters.setSearchByDepartment} />
          <ItemTypeSelect selectedItemType={searchFilters.searchByType} setSelectedItemType={searchFilters.setSearchByType} />

          <Button type="link" onClick={onClear}>clear</Button>

        </Space>
      </Panel>
    </Collapse>
  )
}

export default TableFilters

