import React, { useState, useContext, useEffect } from "react";
import GetBarcodes from './getBarcodes';
import TableAdjustment from './table';
import { Button, App, Modal, Input, Space } from "antd";
import axioss from '../../../services/restAPI';
import { LoadingSpinLocal } from "../../../components/LoadingSpinLocal";
import { AuthContext } from "../../../components/Auth/AuthContext";
import UncheckExistingItems from "./UncheckExistingItems";

const BarcodesRemoval = () => {
    const { authConfig } = useContext(AuthContext)
    const { message, } = App.useApp();
    const [loading, setLoading] = useState(false)
    const { TextArea } = Input;
    const [data, setData] = useState([])
    const [reason, setReason] = useState("")
    const [rKey, setRKey] = useState("rKey")
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedBarcodes, setSelectedBarcodes] = useState([]) //can be replaced with selectedRowKeys


    const formIsNotReady = selectedBarcodes.length === 0 || reason.length === 0


    const handleSubmit = async () => {
        Modal.confirm({
            title: 'Confirm',
            content: `Are you sure you want to manually remove ${selectedBarcodes.length} products?`,
            onOk: async () => {
                try {
                    setLoading(true)
                    const params = { scannedProducts: selectedBarcodes, notes:reason }
                    await axioss.post('/action/remove-manually/', params, authConfig);
                    setSelectedBarcodes([]); 
                    setData([]); 
                    setReason([]); 
                    setRKey(Date.now()); 
                    setSelectedRowKeys([])
                    message.success("Items were removed successfully");
                } catch (error) {
                    console.error("Error:", error);
                    message.error("Error removing the items")
                }
                finally {
                    setLoading(false)
                }
            },
            onCancel() {
            },
        });
    };

    useEffect(() => {
        let tempArr = selectedRowKeys.map((item) => {
            return item.value;
        });
        setSelectedBarcodes(tempArr);
    }, [selectedRowKeys]);

    return (
        <Space size={'large'}>
            <LoadingSpinLocal loading={loading}>
                <div style={{ width: "700px" }}>
                    <GetBarcodes setData={setData} key={`${rKey} GetBarcodes`}/>

                    <TableAdjustment
                        key={`${rKey} TableAdjustment`}
                        itemBarcodes={data}
                        setSelectedBarcodes={setSelectedBarcodes}
                        selectedRowKeys={selectedRowKeys}
                        setSelectedRowKeys={setSelectedRowKeys}
                    />

                    <TextArea value={reason} onChange={(e) => setReason(e.target.value)} placeholder="Reason of removal" autoSize={{ minRows: 3, maxRows: 5, }} maxLength={999} showCount key={`${rKey} TextArea`}/>
                    <br />
                    <br />
                    <Button danger type='primary' disabled={formIsNotReady} onClick={handleSubmit}>Remove</Button>
                </div>
            </LoadingSpinLocal>

            {selectedRowKeys.length>0&&(<UncheckExistingItems selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} key={`${rKey} UncheckExistingItems`}/>)}
        </Space>
        
    );
};


export default BarcodesRemoval;



