import { Space } from 'antd'
import Logo from '../Logo';
import { GeneralContext } from "../../../components/GeneralContext/GeneralContext";
import { useContext } from 'react'

const InventoryBanner = ({ children }) => {
    const { isSmallScreen } = useContext(GeneralContext)

    return (
        <Space direction='horizontal' wrap style={{ display: 'flex', justifyContent: 'center', height: '50vh', padding: 30 }}>

            <Logo />

            {!isSmallScreen && (
                <div style={{ width: 0.5, height: 180, backgroundColor: '#e1e1e1', margin: 10 }}>{' '}</div>
            )}

            {children}

        </Space>
    )
}

export default InventoryBanner 