import React from 'react';
import { Collapse, Row, Col } from "antd";
import ExpiresSoon from './ExpiresSoon';
import LowInStock from './LowInStock';

const Home = () => {
    const { Panel } = Collapse;

    return (
        <div>
            <h2>Dashboard</h2>

            <Row>
                <Col xs={{ span: 23 }} lg={{ span: 8 }} >
                    <Collapse defaultActiveKey={[0]} >
                        <Panel header='Low in Stock' key={0}>
                            <LowInStock />
                        </Panel>
                    </Collapse>
                    <br />
                </Col>
                <Col span={1} />
                <Col xs={{ span: 23 }} lg={{ span: 15 }} >
                    <ExpiresSoon />
                </Col>
            </Row>

            <br />
            <Row>
                <Col xs={{ span: 23 }} lg={{ span: 8 }} >

                </Col>
                <Col span={1} />
                <Col xs={{ span: 23 }} lg={{ span: 15 }} >

                </Col>
            </Row>

            <br />

        </div>
    );
}

export default Home;
