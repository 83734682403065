import { React } from "react";
import { Modal, Button, Space } from "antd";
import { PrintLabels } from "./PrintLabels";

const AfterSubmitModal = (props) => {
  const { showAfterSubmitModal, setShowAfterSubmitModal, form,setRerenderTrigger } = props;
  // const addSame = () => {
  //   // form.setSelectedDepartment(form.selectedDepartment);
  //   form.setWarehouse(form.warehouse);
  //   // form.setSection(form.section);
  //   // form.setLocation(form.location)

  //   form.setSelectedItem(null);
  //   form.setAmount(null);
  //   form.setLotNumber(null);
  //   form.setExpirationDate(null);
  //   setShowAfterSubmitModal(false);
  // };

  const addNew = () => {
    form.setSelectedItem(null);
    form.setAmount(null);
    form.setLotNumber(null);
    form.setExpirationDate(null);
    setShowAfterSubmitModal(false);
    form.setWarehouse(1);
    form.setLocation(null)
    setRerenderTrigger(Date.now())
    // form.setSelectedDepartment(null);
    // form.setSection(null);
    // form.setLocation(null)
  };

  return (
    <Modal
      title="New product was submitted successfully"
      open={showAfterSubmitModal}
      closable={false}
      keyboard={false}
      footer={null}
      //   onCancel={() => { setShowAfterSubmitModal(false); }}
    >
      <Space direction="vertical" size="large" >
        <PrintLabels amountOfItems={form.amount} form={form}/>

        <Space size='large' >
          {/* <Button onClick={addSame}>Add Analogous</Button> */}
          <Button onClick={addNew}>Add New</Button>
        </Space>
      </Space>
    </Modal>
  );
};

export { AfterSubmitModal };
