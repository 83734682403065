

import BadgeAuthorization from "../../../components/BadgeAuthorization"

const TakeFromStorage = () => {


    return (
        <BadgeAuthorization targetPage={"withdraw-an-asset"}/>

    );
};

export default TakeFromStorage;




