import { Table, Tag, Space, Descriptions, Collapse } from "antd";
// import ExpirationDate from "../../../../../components/ExpirationDate";
import FormattedLocation from "../../../../Warehouses/Locations/FormattedLocation";
// import LabelRePrint from "../../../../../components/LabelRePrint";
import DateFormatter from "../../../../../components/DateFormatter";
import JsonToCSV from "../../../../../components/JsonToCSV";

const GroupProducts = (props) => {
  const { products, bordered, scrolled, hideLot, hideBarcode, expandable, hideExpiredProducts, hideShelfLife, } = props;
  function groupJsons(jsonArray) {
    const grouped = {};

    jsonArray.forEach((json) => {
      const key = `${json.lot}`;//expiration_date

      if (!grouped[key]) {
        grouped[key] = {
          ...json,
          total_amount: 0,
          barcodes: [], //remove
          products: [],
        };
      }

      grouped[key].total_amount++;
      grouped[key].barcodes.push(json.barcode); //remove
      grouped[key].products.push(json);
    });

    return Object.values(grouped);
  }
  function filterExpiredProducts(products) {
    const today = new Date();
    return products.filter(
      (product) => new Date(product.expiration_date) > today
    );
  }

  let groupedProducts = groupJsons(products);

  groupedProducts = [...groupedProducts].map((product, index) => {
    product.key = `${index}`;
    return product;
  });

  if (hideExpiredProducts) {
    groupedProducts = filterExpiredProducts(groupedProducts);
  }

  function convertTimestampToDate(timestamp) {
    var date = new Date(timestamp);
    return date.toISOString().split("T")[0];
  }

  function calculateShelfLife(receivedDate, expirationDate) {
    const received = new Date(receivedDate);
    const expiration = new Date(expirationDate);
    const timeDifference = expiration.getTime() - received.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    return Math.round(daysDifference);
  }

  let columns = [
    {
      title: "Item Number",
      dataIndex: "item_number",
      key: "item_number",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Received",
      dataIndex: "received",
      width: 100,
      render: (_, record) => (
        <DateFormatter dateString={record.products[0].date_received} />
      ),
    },
    {
      title: "Removed",
      dataIndex: "removed",
      key: "removed",
      width: 100,
      render: (_, record) => (
        <DateFormatter dateString={record.products[0].date_removed} />
      ),
    },
    {
      title: "Expiration date",
      dataIndex: "expiration_date",
      key: "expiration_date",
      render: (text, record) =>
        record.actual_item[0].expirationDateRequired === true ? (
          <>{text.split('T')[0]}</>
        ) : (
          <Tag>Not Applicable</Tag>
        ),
    },
    {
      title: "Shelf Life",
      dataIndex: "shelfLife",
      key: "shelfLife",
      render: (text, record) =>
        record.actual_item[0].expirationDateRequired === true ? (
          <>
            {calculateShelfLife(
              convertTimestampToDate(parseInt(record.products[0].barcode)),
              record.expiration_date.split("T")[0]
            )}
          </>
        ) : (
          <Tag>Not Applicable</Tag>
        ),
    },
    {
      title: "Lot number",
      dataIndex: "lot",
      key: "lot",
      width: 150,
      render: (text, record) => record.actual_item[0].lotRequired === true ? (<>{text}</>) : (<Tag>Not Applicable</Tag>),
    },
    {
      title: "Amount",
      dataIndex: "total_amount",
      key: "total_amount",
      width: 10,
    },
  ];

  if (hideLot) {
    columns = columns.filter((col) => col.key !== "lot");
  }
  if (hideBarcode) {
    columns = columns.filter((col) => col.key !== "barcode");
  }
  if (hideShelfLife) {
    columns = columns.filter((col) => col.key !== "shelfLife");
  }

  let dataToExportAsCSV = [];
  if (groupedProducts.length > 0) {
    dataToExportAsCSV = groupedProducts.map((item) => {
      let newItem = {};
      newItem.item_number = item.item_number;
      newItem.name = item.name;
      newItem.date_received = item.date_received.split('T')[0];
      newItem.date_removed = item.date_removed.split('T')[0];
      newItem.expiration_date =item.expiration_date?(item.expiration_date.split('T')[0]):('N/A') ;
      newItem.lot = item.lot;
      newItem.quantity = item.total_amount;

      return newItem;
    });
  }

  return (
    <>
    {dataToExportAsCSV.length>0&&(<JsonToCSV data={dataToExportAsCSV} />)}
      <Table
        dataSource={groupedProducts}
        columns={columns}
        size="small"
        bordered={bordered}
        expandable={
          expandable
            ? {
              expandedRowRender: (record) => <ActualProducts record={record} />,
              rowExpandable: (record) => true,
            }
            : null
        }
        scroll={scrolled ? { x: "750px" } : null}
        pagination={{
          pageSizeOptions: ["5", "10", "100", "1000"],
          showSizeChanger: true,
        }}
      />
    </>
  );
};

export default GroupProducts;

const ActualProducts = (props) => {
  const { record } = props;

  const actualProducts = [...record.products];
  return (
    <div className="rowStyle">
      <Space direction="vertical">
        {actualProducts.map((product) => {
          const location = {
            department: product.location_department,
            type: product.location_type,
            number: product.location_number,
            locationID: product.location_id,
          };
          const descriptionItems = [
            {
              key: "1",
              label: "barcode",
              children: product.barcode,
            },
            {
              key: "2",
              label: "location",
              children: (
                <FormattedLocation location={location} hideOnChangeLocation />
              ),
            },
          ];
          return (
            <Collapse
              items={[
                {
                  key: product.barcode,
                  label: product.barcode,
                  children: (
                    <Descriptions items={descriptionItems} column={1} />
                  ),
                },
              ]}
              defaultActiveKey={["1"]}
              bordered={false}
              style={{ width: 500 }}
            />
          );
        })}
      </Space>
    </div>
  );
};
