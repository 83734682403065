import { AuthContext } from "../AuthContext";
import {useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserAccessWatcher } from "../UserAccessWatcher/UserAccessWatcher";

export const AuthRequired = ({ children }) => {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);

  useEffect(() => {
    if (token===null) {
      console.log('token expired')
      navigate("/login");
    }
  }, [token,navigate]);

  return (
    <UserAccessWatcher>{children}</UserAccessWatcher>
    );
};

