import { Space, Input, Collapse, Button } from 'antd'
import { Link } from 'react-router-dom'
import { POtable } from './table';
import { useState } from 'react'

const PO = () => {
    const [searchByBarcode, setSearchByBarcode] = useState("")
    const [searchByNotes, setSearchByNotes] = useState("")
    const [performSearch, setPerformSearch] = useState(false)

    const searchParams = {
        searchByBarcode, setSearchByBarcode,
        searchByNotes, setSearchByNotes,
        performSearch, setPerformSearch
    }
    // console.log(searchByBarcode)
    return (
        <>
            <Space direction='horizontal' size='large' >
                <h2>PO</h2>
                <Link to="create-new-PO" style={{ paddingTop: 8 }}>Create a new PO</Link>
            </Space>
            <POtableFilters searchParams={searchParams} />
            <POtable searchParams={searchParams} />
        </>
    )
}

export { PO }

const POtableFilters = (props) => {
    const { Panel } = Collapse;
    const { searchParams } = props

    return (
        <Collapse defaultActiveKey={["1"]} bordered={false}>
            <Panel header="Search" key="1">
                <Space size="large" wrap>
                    <Input placeholder='Search by Date' disabled allowClear onPressEnter={() => {searchParams.setPerformSearch(true)}}/>
                    <Input placeholder='Search by Status' disabled allowClear onPressEnter={() => {searchParams.setPerformSearch(true)}}/>
                    <Input placeholder='Search by Barcode'  allowClear value={searchParams.searchByBarcode} onChange={(e) => {searchParams.setSearchByBarcode(e.target.value)}} onPressEnter={() => {searchParams.setPerformSearch(true)}}/>
                    <Input placeholder='Search by Notes'  allowClear value={searchParams.searchByNotes} onChange={(e) => {searchParams.setSearchByNotes(e.target.value)}} onPressEnter={() => {searchParams.setPerformSearch(true)}}/>
                    <Button onClick={() => {searchParams.setPerformSearch(true)}}>Search</Button>
                </Space>
            </Panel>
        </Collapse>
    )
}