import {React, useState, useContext} from 'react'
import {Space} from 'antd'
import {PrintLabelZebra} from '../../../../../components/PrintLabelZebra'
import { GeneralContext } from '../../../../../components/GeneralContext/GeneralContext'
import dayjs from 'dayjs';

const PrintLabels = (props) => {
    const {connectedZebraPrinter, setConnectedZebraPrinter} = useContext(GeneralContext)
    const { amountOfItems, form } = props
    const [amountOfLabels] = useState(amountOfItems)

    // console.log(form)
   
    const printedDate = dayjs(Date.now()).format('DD MMM YYYY') //YYYY-MM-DD
    const expDate = dayjs(form.expirationDate).format('DD MMM YY')

    const barcodes = form.afterSubmitServerResponse.barcodes // MAX 14
    let labels = []

    barcodes.forEach((barcode) => {
      const labelText = {
        line1: `Item     : ${form.selectedItem.name}`,
        line2: `Printed ${printedDate}`,
        line3: `LOT     : ${form.lotNumber}, Exps.: ${expDate}`,
        line4: `Notes   :`
      };

      labels.push({ barcode, labelText });
    });

    // console.log(labels)

    return (
      <Space direction='horizontal'>
        <p>Print labels</p>
        <Space.Compact>
            {/* <InputNumber min={1} value={amountOfLabels} onChange={(value) => { setAmountOfLabels(value) }} placeholder="Amount of Labels"  /> */}
            <PrintLabelZebra connectedZebraPrinter={connectedZebraPrinter} setConnectedZebraPrinter={setConnectedZebraPrinter} amountOfLabels={amountOfLabels} labels={labels}/>
        </Space.Compact>
      </Space>
    )
  }

  export {PrintLabels}

  //