import { useState, useEffect } from 'react';
import ProductScanning from '../../components/ProductScanning';
import InventoryLayout from '../../components/PageLayout/InventoryLayout';
import MainTable from './MainTable';
import { Button } from 'antd/es/radio';
import { Divider, Space, App } from 'antd';
import axioss from '../../services/restAPI';
import { useParams } from "react-router-dom";
import { LoadingSpinLocal } from '../../components/LoadingSpinLocal';
import GoHome from '../../components/GoHome';

const ProductsDistributing = () => {
  const [loading, setLoading] = useState(false)
  const [productList, setProductList] = useState([])
  const { message, modal } = App.useApp()
  const [currentUser, setCurrentUser] = useState([]);
  const { barcodeValue } = useParams();
  const formReady = productList.every(product => product.newLocation!==undefined)

  const getUserByBarcode = async () => {
    try {
      setLoading(true)
      const response = await axioss.get(`/users/user-by/${barcodeValue}/`)
      setCurrentUser(response.data.user)
    } catch (error) {
      console.log(error)
    }
    finally{
      setLoading(false)
    }
  }


  useEffect(() => {
    (async () => {
      await getUserByBarcode();
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onDistribute = async () => {

    try {
      setLoading(true)
      await axioss.post('/action/change-products-locations/', { scannedProducts: productList, user:currentUser })
      message.success("Products locations were updated")
      setProductList([])
    } catch (error) {
      modal.error({
        title: "An error occurred while updating product locations",
        content: error.message
      })
      console.log(error)
    }
    finally{
      setLoading(false)
    }
  }
  return (
    <LoadingSpinLocal loading={loading}>
      <InventoryLayout>
        <Space><h1>Product distributing</h1><GoHome/></Space>
        <h3>Welcome {currentUser.first_name}</h3>
        <Space size={'large'} style={{ width: "100%" }} wrap align='top'>

          <Space direction='vertical'>
            <ProductScanning productList={productList} setProductList={setProductList} showScannedProducts={false} />
            <br />
            {productList.length > 0 && (
              <Button type="primary" onClick={onDistribute} disabled={!formReady} > Distribute </Button>
            )}
          </Space>

          <Divider type='vertical' />

          {productList.length > 0 && (
            <MainTable productList={productList} setProductList={setProductList} />
          )}


        </Space>
      </InventoryLayout>
    </LoadingSpinLocal>
  );
}

export default ProductsDistributing;