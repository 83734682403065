import { Space, Tag, Button } from "antd"
import LocationTypeFormatted from "./LocationTypeFormatted"

const FormattedLocation = (props) => {
    const { location, onChangeLocation, hideOnChangeLocation } = props
    
    return (
        <Space>
            {location.department}/
            <LocationTypeFormatted type={location.type}/>/

            {location.location&&(
              <Tag>{location.location}</Tag>
            )}
            {/* or */}
            {location.number&&(
              <Tag>{location.number}</Tag>
            )}

            {!hideOnChangeLocation && (
                <Button type="text" onClick={onChangeLocation}>change</Button>
            )}
        </Space>
    )
}

export default FormattedLocation