
import { useState, useRef, useEffect } from "react";
import { Input, App, Form, Collapse, Space, Descriptions } from "antd";
import axioss from '../../services/restAPI';
import StockProduct from "./StockProduct";
import { LoadingSpinLocal } from "../LoadingSpinLocal";


const ProductScanning = (props) => {
    // const {doLogin} = useContext(AuthContext)
    const { message } = App.useApp();
    const { productList, setProductList, showScannedProducts, showScannedSummary, allowZeroQuantityProducts} = props
    const [barcode, setBarcode] = useState("");
    const inputRef = useRef(null);
    const [loading, setLoading] =useState(false)

    const getProductFromStock = async () => {
        if (barcode === "") {
            message.error("Please input your barcode!");
        } else {
            try {
                setLoading(true)
                const response = await axioss.get(`/action/getProductFromStock/${barcode}/`);

                // check if a barcode has already been added
                if ([...productList].some(product => product.stock_id === response.data.item.stock_id)) {
                    message.error("The scanned item has already been added")
                    setBarcode(''); 
                    return
                }

                //check if quantity_in_stock===0
                if (allowZeroQuantityProducts!==true && response.data.item.quantity_in_stock===0){
                    message.error("this item is not allowed");
                    setBarcode(''); 
                    return
                }

                let tempArr = [...productList, response.data.item]
                tempArr.map((product, index) => {return product.key = index})
                setProductList(tempArr); 

                message.success(`${response.data.item.name}`);
                setBarcode(''); 
            } catch (error) {
                console.error("Error:", error);
                if (error.response && error.response.status === 404) {
                    message.error("Product not found in stock.");
                    setBarcode(''); // clear barcode
                }
            }
            finally{setLoading(false)}
        }
    };

    const Summary = () => {

        const summarizeInformationAboutScannedProducts = () => {
            let summaryMap = new Map();

            // Iterate through each item in the array
            productList.forEach(item => {
                let name = item.name;
                if (summaryMap.has(name)) {
                    // Increment the count if name is already in the map
                    summaryMap.set(name, summaryMap.get(name) + 1);
                } else {
                    // Otherwise, initialize the count to 1
                    summaryMap.set(name, 1);
                }
            });

            // Convert the map to the desired format
            let result = [];
            summaryMap.forEach((value, key) => {
                result.push({
                    key: key,
                    label: key,
                    children: value
                });
            });

            return result;
        }


        return (
            <Space direction='vertical'>
                <Descriptions items={summarizeInformationAboutScannedProducts()} column={1} />
                <p>Total: {productList.length}</p>
            </Space>
        )
    }

    useEffect(() => {
        inputRef.current.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ScannedProducts = () => (
        <Space wrap size={'middle'}>
            {productList.map((product, index) => (
                <StockProduct
                    counter={index}
                    key={index}
                    product={product}
                    products={productList}
                    setProducts={setProductList}
                />
            ))}
        </Space>
    )

    return (
        <LoadingSpinLocal loading={loading}>

            <Form.Item label={'Scan an asset'} autoComplete={'off'}>
                <Input
                    ref={inputRef}
                    value={barcode}
                    // onBlur={() => setTimeout(() => inputRef.current.focus(), 0)}
                    onChange={({ target: { value } }) => setBarcode(value)}
                    onPressEnter={getProductFromStock}
                    placeholder={'barcode'}
                    style={{ width: "80%" }}
                />
            </Form.Item>

            {productList.length > 0 && (
                <div className="scrolledBox">
                    <Space size={'large'} align="top" wrap>
                        
                        {showScannedProducts !== false && (
                            <Collapse items={[{ key: '1', label: 'Scanned Products', children: <ScannedProducts /> }]} defaultActiveKey={['1']} bordered={false} style={{ width: 650 }} />
                        )}


                        {showScannedSummary !== false && (
                            <Collapse items={[{ key: '1', label: 'Summary', children: <Summary /> }]} defaultActiveKey={['1']} bordered={false} style={{ width: 350 }} />

                        )}

                    </Space>
                </div>
            )}

        </LoadingSpinLocal>
    )
}

export default ProductScanning

