import { React, useState } from "react";
import { Collapse, Row, Col, Space, Button} from "antd";
import { WarehouseInformation } from "./WarehouseInformation";
import {useNavigate} from 'react-router-dom'
import Locations from "../../Locations";
const { Panel } = Collapse;

export function TableRow(props) {
  const record = { ...props.record };
  const [editMode, setEditMode] = useState(false)
  const [location, setLocation] = useState(false)

  return (
    <div className="rowStyle">
      <Row>
        <Col xs={{ span: 23 }} lg={{ span: 11 }}>
          <Collapse defaultActiveKey={["1"]} bordered={false}>
            <Panel header="Warehouse Information" key="1">
              <WarehouseInformation record={record}  editMode={editMode} setEditMode={setEditMode}/>
            </Panel>
          </Collapse>
        </Col>
        <Col span={1} />
        <Col xs={{ span: 23 }} lg={{ span: 11 }}>
          <Collapse defaultActiveKey={["1"]} bordered={false}>
            <Panel header="Manage warehouse" key="1">
              <ManageWarehouse  editMode={editMode} setEditMode={setEditMode} warehouseID={record.id} warehouseName={record.name}/>
            </Panel>

            <Panel header="Locations" key="3">
              <Locations selectedWarehouse={record.id} location={location} setLocation={setLocation}/>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
}


const ManageWarehouse = (props) => {
  const navigate = useNavigate()
  const {editMode, setEditMode, warehouseID, warehouseName} = props
  return (
    <>
    <Space>
      {editMode?(<Button onClick={()=>{setEditMode(false)}}>Cancel Editing</Button>):(<Button onClick={()=>{setEditMode(true)}}>Edit Warehouse</Button>)}
      <Button  onClick={()=>{navigate(`add-new-location/${warehouseID}/${warehouseName}`)}}>Add Location</Button>
    </Space>
  </>
  );
};
