import { useState, useEffect } from 'react'
import { InputNumber, Space, Input } from "antd"
import Processor from './Processor'
import generatePlainText from './utilites/generatePlainText';
import MyCollapse from '../Collapse'
const { TextArea } = Input;

const LABELplainText = () => {
    const [quantity, setQuantity] = useState(0)
    const [labels, setLabels] = useState([])
    const [value, setValue] = useState("")
    const [fontSize, setFontSize] = useState(30)
    const [topPadding, setTopPadding] = useState(30)
    const [linesSpacing, setLineSpacing] = useState(1.3)

    const generateBarcodes = () => {
        const newLabels = []
        for (let index = 0; index < quantity; index++) {
            const label = generatePlainText(value, fontSize, topPadding, linesSpacing)
            newLabels.push(label)
        }
        setLabels(newLabels)
    }

    useEffect(() => {
        generateBarcodes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, quantity, fontSize, topPadding, linesSpacing])


    return (
        <Space direction='vertical'>
            <TextArea rows={4} placeholder="Value"value={value} onChange={(e) => {setValue(e.target.value)}}/>
            <Space>
                Quantity of labels:
                <InputNumber min={1} value={quantity} onChange={(value) => { setQuantity(value) }} style={{width:'50px'}} />
                {quantity>0&&( <Processor labels={labels}/> )}
            </Space>

            <MyCollapse title='Label settings' collapsed child={
                <Space direction='vertical'>
                    <div>Font Size: <InputNumber value={fontSize} onChange={(value) => { setFontSize(value) }} style={{width:'70px'}} /></div>
                    <div>Lines Spacing: <InputNumber value={linesSpacing} onChange={(value) => { setLineSpacing(value) }} style={{width:'70px'}} /></div>
                    <div>Top Padding: <InputNumber value={topPadding} onChange={(value) => { setTopPadding(value) }} style={{width:'70px'}} /></div>
                </Space>
            }/>
            
        </Space>
    )
}

export default LABELplainText
