import { Space } from 'antd';
import MyCollapse from '../../components/Collapse';
import BarcodesRemoval from './BarcodesRemoval';


const AssetsAdjustment = () => {

   return (
        <Space direction='vertical'>
            <h1>Adjust an Asset</h1>
            <Space align='top' size={'large'} wrap>

                <MyCollapse title={"Remove Barcodes"} child={<BarcodesRemoval/>} />

                {/* <MyCollapse title={"Add Barcodes"} child={<>Add Barcodes</>} /> */}

            </Space>
        </Space>
   );
};


export default AssetsAdjustment;



