
import React, { useState, useRef, useEffect } from "react";
import { Input, App, Form,  } from "antd";
import axioss from '../../services/restAPI';
import { useNavigate } from "react-router-dom";
import InventoryLayout from "../../components/PageLayout/InventoryLayout";
import InventoryBanner from "../../components/PageLayout/InventoryBanner";
import {LoadingSpinLocal} from "../LoadingSpinLocal"
import GoHome from "../GoHome";

const BadgeAuthorization = (props) => {
    const { message, modal } = App.useApp();
    const [loading, setLoading] = useState(false)
    const [barcode, setBarcode] = useState("");
    const inputRef = useRef(null);
    const navigate = useNavigate();

    const {targetPage} = props


    const onFinishHandler = async () => {
        try {
            setLoading(true)
            const response = await axioss.post('/users/check-barcode/', { barcode })
            message.success('Welcome to the Inventory')
            // console.log(response.data.user)

            navigate(`/${targetPage}/${response.data.user.key_barcode}`);
        } catch (error) {
            if (error.response.status === 404) {
                modal.error({
                    title: "User not found",
                    content: error.message
                })
            }
            if (error.response.status === 403) {
                modal.error({
                    title: "Access forbidden. Ask an Inventory's administrator for assistance",
                    content: error.message
                })
            }
            console.log(error)
        }
        finally{
            setLoading(false)
        }
    }

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    return (
        <InventoryLayout>
            <InventoryBanner>
                <LoadingSpinLocal loading={loading}>
                    <Form onFinish={async () => { if (barcode !== "") { await onFinishHandler() } else { message.error("Please input your barcode!"); } }} >
                        <Form.Item label={'Scan your badge'} autoComplete={'off'} >
                            <Input.Password
                                width={'100%'}
                                ref={inputRef}
                                onChange={({ target: { value } }) => setBarcode(value)}
                                placeholder={'barcode'}
                                rules={[{ required: true, message: 'Please input your barcode!' }]}
                            />
                        </Form.Item>
                    </Form>
                    <div style={{marginLeft:"-17px", marginTop:"-30px"}}>
                        <GoHome />
                    </div >
                </LoadingSpinLocal>   
            </InventoryBanner>
        </InventoryLayout>
    );
};

export default BadgeAuthorization;
