import { Descriptions, Input, Button, Space, Checkbox, App } from 'antd'
import { useState, useContext } from 'react'
import { GeneralContext } from '../../../components/GeneralContext/GeneralContext'
import { LoadingSpinLocal } from '../../../components/LoadingSpinLocal'
import { AuthContext } from '../../../components/Auth/AuthContext'
import axioss from '../../../services/restAPI/'
import {DepartmentsSelector} from '../../../components/Departments'
import {ItemTypeSelect} from '../ItemTypeSelect'
import ItemUnitOfMeasureSelect from '../ItemUnitOfMeasureSelect'

const ItemInformation = (props) => {
    const { isSmallScreen } = useContext(GeneralContext)
    const { selectedItem, editItem, setEditItem, size, setRepeatGetItems } = props
    const amountOfColumns = (isSmallScreen || editItem || size==="small" ? (1) : (2))
    const [loading, setLoading] = useState(false)
    const {authConfig} = useContext(AuthContext)
    const {message, modal} = App.useApp()
    
    const [name, setName] = useState(selectedItem.name)
    const [department, setDepartment] = useState(selectedItem.department)
    const [type, setType] = useState(selectedItem.type)
    const [vendor, setVendor] = useState(selectedItem.vendor)
    const [unitOfMeasure, setUnitOfMeasure] = useState(selectedItem.unitOfMeasure)
    const [memo, setMemo] = useState(selectedItem.memo)
    const [barcode, setBarcode] = useState(selectedItem.barcode)
    const [threshold, setThreshold] = useState(selectedItem.threshold)
    const [amountOfAtomsInABox, setAmountOfAtomsInABox] = useState(selectedItem.amountOfAtomsInABox)
    const [lotRequired, setLotRequired] = useState(selectedItem.lotRequired)
    const [expirationDateRequired, setExpirationDateRequired] = useState(selectedItem.expirationDateRequired)
    const [price, setPrice] = useState(selectedItem.price)
    const [itemNumber, setItemNumber] = useState(selectedItem.item_number)
    // console.log(selectedItem)


    const onSubmit = async () => {
        try {
          setLoading(true)
          await axioss.put(`/items/items-old/${selectedItem.id}/`, {
            itemNumber,
            name,
            vendor,
            barcode,
            memo,
            price,
            threshold,
            type,
            unitOfMeasure,
            department,
            amountOfAtomsInABox,
            lotRequired,
            expirationDateRequired,
          },
            authConfig
          )
        //   console.log(response.data)
          message.success("Record updated successfully")
          setRepeatGetItems(true)
        } catch (error) {
          console.error(error)
          modal.warning({
            title: 'Something went wrong',
            content: error.message,
          });
        }
        finally {
          setLoading(false)
        }
      }

      

    return (
        <LoadingSpinLocal loading={loading}>
            <Descriptions bordered size='small' layout='horizontal' column={amountOfColumns} >

                <Descriptions.Item label="Item Number">
                    {editItem ? (
                        <Input placeholder='Edit Item Number' value={itemNumber} onChange={(e) => { setItemNumber(e.target.value) }} />
                    ) : (selectedItem.item_number)}
                </Descriptions.Item>
                

                <Descriptions.Item label="Name">
                    {editItem ? (
                        <Input placeholder='Edit name' value={name} onChange={(e) => { setName(e.target.value) }} />
                    ) : (selectedItem.name)}
                </Descriptions.Item>


                <Descriptions.Item label="Department">
                    {editItem ? (
                        // <Input placeholder='Edit department' value={department} onChange={(e) => { setDepartment(e.target.value) }} />
                        <DepartmentsSelector selectedDepartment = {department} setSelectedDepartment={setDepartment}/>
                    ) : (selectedItem.department)}
                </Descriptions.Item>


                <Descriptions.Item label="Type">
                    {editItem ? (
                        // <Input placeholder='Edit type' value={type} onChange={(e) => { setType(e.target.value) }} />
                        <ItemTypeSelect selectedItemType={type} setSelectedItemType={setType} />
                    ) : (selectedItem.type)}
                </Descriptions.Item>


                <Descriptions.Item label="Vendor">
                    {editItem ? (
                        <Input placeholder='Edit vendor' value={vendor} onChange={(e) => { setVendor(e.target.value) }} />
                    ) : (selectedItem.vendor)}
                </Descriptions.Item>


                {/* TODO predefine item units */}
                <Descriptions.Item label="Unit Of Measure">
                    {editItem ? (
                        // <Input placeholder='Edit units' value={unitOfMeasure} onChange={(e) => { setUnitOfMeasure(e.target.value) }} />
                        <ItemUnitOfMeasureSelect selectedUnitOfMeasure={unitOfMeasure} setSelectedUnitOfMeasure={setUnitOfMeasure}/>
                    ) : (selectedItem.unitOfMeasure)}
                </Descriptions.Item>


                <Descriptions.Item label="Memo">
                    {editItem ? (
                        <Input placeholder='Edit memo' value={memo} onChange={(e) => { setMemo(e.target.value) }} />
                    ) : (selectedItem.memo)}
                </Descriptions.Item>


                <Descriptions.Item label="Barcode">
                    {editItem ? (
                        <Input placeholder='Edit barcode' value={barcode} onChange={(e) => { setBarcode(e.target.value) }} />
                    ) : (selectedItem.barcode)}
                </Descriptions.Item>


                <Descriptions.Item label="Threshold">
                    {editItem ? (
                        <Input placeholder='Edit threshold' value={threshold} onChange={(e) => { setThreshold(e.target.value) }} />
                    ) : (selectedItem.threshold)}
                </Descriptions.Item>


                <Descriptions.Item label="Amount of items in a box">
                    {editItem ? (
                        <Input placeholder='Edit amount of items in a box' value={amountOfAtomsInABox} onChange={(e) => { setAmountOfAtomsInABox(e.target.value) }} />
                    ) : (selectedItem.amountOfAtomsInABox)}
                </Descriptions.Item>


                <Descriptions.Item label="Price">
                    {editItem ? (
                        <Input placeholder='Edit cost' value={price} onChange={(e) => { setPrice(e.target.value) }} />
                    ) : (<>${selectedItem.price}</>)}
                </Descriptions.Item>


                <Descriptions.Item label="Is LOT required">
                    {editItem ? (
                        <Checkbox checked={lotRequired} onChange={(e) => setLotRequired(e.target.checked)} />
                    ) : (<>{selectedItem.lotRequired ? 'yes' : 'no'}</>)}
                </Descriptions.Item>


                <Descriptions.Item label="Is expiration date required">
                    {editItem ? (
                        <Checkbox checked={expirationDateRequired} onChange={(e) => setExpirationDateRequired(e.target.checked)} />
                    ) : (<>{selectedItem.expirationDateRequired ? 'yes' : 'no'}</>)}
                </Descriptions.Item>


            </Descriptions>

            <br />

            {editItem ? (
                <Space size='large'>
                    <Button onClick={onSubmit} type='primary'>Submit</Button>
                    <Button onClick={() => { setEditItem(false) }}>Cancel</Button>
                </Space>
            ) : null}
        </LoadingSpinLocal>
    )
}

export { ItemInformation }

