import React, { useEffect, useState, useContext } from "react";
import { Button, App, Modal, Space, Input } from "antd";
import axioss from '../../services/restAPI';
import ProductScanning from "../../components/ProductScanning";
import { LoadingSpinLocal } from "../../components/LoadingSpinLocal";
import { AuthContext } from "../../components/Auth/AuthContext";

const AssetDiscarding = () => {
  const { authConfig } = useContext(AuthContext)

  const { message,  } = App.useApp();
  const [loading, setLoading] = useState(false)
  const [productList, setProductList] = useState([]);
  const formIsNotCompleted = productList.length < 1 || (productList.some(product=>!product.discardedNotes))

  console.log(productList)

  const handleSubmit = async () => {
    Modal.confirm({
      title: 'Confirm',
      content: `Are you sure you want to discard ${productList.length} products?`,
      onOk: async () => {
        try {
          setLoading(true)

          await axioss.post('/action/discard-stock/', { scannedProducts: productList }, authConfig);
          // console.log(response.data);

          setProductList([]); // Clear product list after transaction

          message.success("Transaction Successful");
        } catch (error) {
          console.error("Error:", error);
          message.error("Error discarding the items")
        }
        finally {
          setLoading(false)
        }
      },
      onCancel() {
      },
    });
  };

  return (
    <LoadingSpinLocal loading={loading}>
      <div style={{ paddingLeft: 100 }}>
        <h1>Assets Discarding</h1>
        <ProductScanning productList={productList} setProductList={setProductList} />
        <br />
        <DiscardingNotes data={productList} setData={setProductList}/>
        <Button type="primary" danger onClick={handleSubmit} disabled={formIsNotCompleted} > Discard </Button>
      </div>
    </LoadingSpinLocal>
  )
}

export default AssetDiscarding

const DiscardingNotes = (params) => {
  const { data, setData } = params
  const [inputDiscardingNotes, setInputDiscardingNotes] = useState("")

  useEffect(() => {
    const tempArr = [...data].map(obj => ({ ...obj, discardedNotes: inputDiscardingNotes }))
    setData(tempArr)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDiscardingNotes])

  return (
    <>
      {data.length > 0 && (
        <Space>
          Discarding reason:
          <Input placeholder='' value={inputDiscardingNotes} onChange={(e) => { setInputDiscardingNotes(e.target.value) }} />
        </Space>
      )}
    </>
  )
}