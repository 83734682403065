import { React, useState, createContext } from 'react'

const WarehouseContext = createContext({})

const WarehouseContextProvider = ({ children }) => {
    const [reloadParent, setReloadParent] = useState(false)

    return (
        <WarehouseContext.Provider value={{ reloadParent, setReloadParent }}>
            {children}
        </WarehouseContext.Provider>
    );
};

export { WarehouseContext, WarehouseContextProvider };
