import { useState, useContext } from "react";
import { Space, Input, Button, App } from "antd";
import axioss from '../../services/restAPI';
import { AuthContext } from "../../components/Auth/AuthContext";
import { GeneralContext } from "../../components/GeneralContext/GeneralContext";
import InventoryBanner from "../../components/PageLayout/InventoryBanner";
import InventoryLayout from "../../components/PageLayout/InventoryLayout";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const { setLoading, isSmallScreen } = useContext(GeneralContext)
  const { doLogin } = useContext(AuthContext)
  const { message, modal } = App.useApp();
  const [email, setEmail] = useState("");//anatoly@empirecitylabs.com
  const [password, setPassword] = useState("");

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const response = await axioss.post('/signin', { email, password })
      doLogin(response.data)
      message.success('Welcome to the Inventory')
    }
    catch (error) {
      if (error.request.status === 401) {
        message.error('Incorrect email or password')
      }
      else {
        modal.warning({
          title: 'Something went wrong',
          content: error.message,
        });
      }
      console.log(error);
    }
    finally {
      setLoading(false)
    }
  };

  return (
  
    <InventoryLayout>

      <Space size={"large"} wrap>
        <Button type="link" onClick={() => {navigate("/distributing-authorization")}}>Distribute products</Button>
        <Button type="link" onClick={() => {navigate("/withdraw-an-asset")}}>Withdraw product</Button>
        <Button type="link" onClick={() => {navigate("/about")}}>About</Button>
      </Space>

      <InventoryBanner>
        <Space direction="vertical" style={{ width: 300 }} size='middle' align={isSmallScreen ? ('center') : ('left')}>
          <Input type="email" placeholder="email" value={email} onChange={(e) => { setEmail(e.target.value.toLocaleLowerCase()); }} />
          <Input onKeyPress={handleKeyPress}  type="password" placeholder="password" value={password} onChange={(e) => { setPassword(e.target.value); }} />
          <Button type="primary" disabled={email===""||password===""} onClick={handleSubmit}>Submit</Button>
        </Space>
      </InventoryBanner>
    </InventoryLayout>
  );
};

export default Login;




// 2020Empire229  anatoly@empirecitylabs.com