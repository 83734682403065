import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Root from "./Root";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { AuthProvider } from "./components/Auth/AuthProvider";
import { GeneralProvider } from "./components/GeneralContext/GeneralProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter basename={"/"}>
      <AuthProvider>
        <GeneralProvider>
          <ConfigProvider theme={{ token: { colorPrimary: "#2FB394" } }}>
            <Root />
          </ConfigProvider>
        </GeneralProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
