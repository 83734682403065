import { React } from "react";
import { Spin } from "antd";

const LoadingSpinLocal = ({ children, loading }) => {
  return (
    <Spin spinning={loading} tip="loading">
      {children}
    </Spin>
  );
};

export { LoadingSpinLocal };
