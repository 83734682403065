import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../../components/Auth/AuthContext';
import axioss from '../../../../services/restAPI'; // corrected import
import { LoadingSpinLocal } from '../../../../components/LoadingSpinLocal';
import buildQueryParams from "../../../../services/utilites/buildQueryParams";
import SearchFilters from './SearchFilters';
import MainTable from "./MainTable";


const UsedItemsReport = () => {
  const [loading, setLoading] = useState(false);
  const { authConfig } = useContext(AuthContext);
  const [data, setData] = useState([]);

  const [searchByItemNumber, setSearchByItemNumber] = useState("")
  const [searchByItemName, setSearchByItemName] = useState("")
  const [searchByWarehouseID, setSearchByWarehouseID] = useState("")
  const [searchByVender, setSearchByVender] = useState("")
  const [searchByExpiresIn, setSearchByExpiresIn] = useState("")
  const [searchByLowInStock, setSearchByLowInStock] = useState(null)
  const [searchByLocation, setSearchByLocation] = useState("")
  const [searchByLOT, setSearchByLOT] = useState("")

  // const triggers = [searchByItemName, searchByWarehouseID, searchByVender, searchByExpiresIn, searchByLowInStock, searchByLocation, searchByItemNumber, searchByLOT]

  const filters = {
    searchByItemNumber, setSearchByItemNumber,

    searchByItemName, setSearchByItemName,

    searchByWarehouseID, setSearchByWarehouseID,

    searchByVender, setSearchByVender,

    searchByExpiresIn, setSearchByExpiresIn,

    searchByLowInStock, setSearchByLowInStock,

    searchByLocation, setSearchByLocation,

    searchByLOT, setSearchByLOT
  }


  const getProductsInStock = async () => {
    try {
      setLoading(true)

      const params = {
        // limit,
        // offset,
        searchByWarehouseID,
        searchByItemName,
        searchByVender,
        searchByExpiresIn,
        searchByLowInStock,
        searchByLocation: searchByLocation.locationID,
        searchByItemNumber,
        searchByLOT,
        searchByUsed: true
      };
      const url = `/in-stock?${buildQueryParams(params)}`;

      const response = await axioss.get(url, authConfig);
      setData(response.data)

      console.log(response.data)

    }
    catch (err) {
      console.log(err)
      if (err.response.status === 403) {
        alert("access forbidden")
      }
    }
    finally {
      setLoading(false)
    }
  }




  // const getData = () => {
  //   const params = {};
  //   setLoading(true);
  //   axioss
  //     .get(`/data/`, { params }, authConfig)
  //     .then((response) => {
  //       setData(response.data.users);
  //       // console.log(response.data)
  //     })
  //     .catch((error) => console.error("Error fetching data:", error))
  //     .finally(setLoading(false));
  // };


  // useEffect(() => {
  //   // getProductsInStock();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);


  return (
    <>
      <h1>Used Items Report</h1>
      <SearchFilters filters={filters} getData={getProductsInStock} />

      <LoadingSpinLocal loading={loading}>
        <MainTable inStockProducts={data} setInStockProducts={setData} expandable hideAmountToOrder />
      </LoadingSpinLocal>
    </>
  );
};


export default UsedItemsReport;





