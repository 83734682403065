
import { Collapse,  Col, Row, } from "antd";
import { ItemInformation } from "../../Items/ItemInformation"
import GroupProducts from "./GroupProducts";
import ProductLocations from "./ProductLocations";

const TableRow = (props) => {
    const { record } = props
    const { Panel } = Collapse;

    return (
        <div className="rowStyle">
            <Row>
                <Col xs={{ span: 23 }} lg={{ span: 14 }}>
                    <Collapse defaultActiveKey={["1"]} bordered={false}>
                        <Panel header={<>Products in stock <small>(grouped by <b>LOT</b>)</small></>} key="1">

                            <GroupProducts products={record.products} bordered expandable />
                            <div>Total amount of products in stock: <b>{record.total_amount_of_products}</b></div>

                        </Panel>
                    </Collapse>
                </Col>


                <Col span={1} />


                <Col xs={{ span: 23 }} lg={{ span: 8 }}>
                    <Collapse defaultActiveKey={["1"]} bordered={false}>
                        <Panel header="Locations" key="1">
                            <ProductLocations record={record}/>
                        </Panel>

                        <Panel header="Item information" key="2">
                         <ItemInformation selectedItem={record.actualItem[0]} size={'small'}/>
                        </Panel>

                    </Collapse>
                </Col>
            </Row>
        </div>
    )
}

export default TableRow
