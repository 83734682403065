import PossiblePO from './PossiblePO';
import { Radio, Space } from 'antd'; 


const PossiblePOsRadioGroup = (props) => {
    const { data, setSelectedPO, selectedPO } = props;

    const onChange = (e) => {
    //   console.log('radio checked', e.target.value);
    //   setValue(e.target.value);
      setSelectedPO(e.target.value)
    };


    return (
        <>
            <Radio.Group onChange={onChange} value={selectedPO}>
                <Space direction="vertical" size={'small'} >
                    {data.map((PO) => (
                        <Radio value={PO}>{<PossiblePO PO={PO} key={PO.barcode} isSelected = {selectedPO === PO}/>}</Radio>
                    ))}
                </Space>
          
             </Radio.Group>
        </>
    );
}

export default PossiblePOsRadioGroup;
