import { useState, useRef, useEffect } from 'react';
import { Input, App, Space } from "antd";

const UncheckExistingItems = (props) => {
    const { selectedRowKeys, setSelectedRowKeys } = props
    const [inputScannedBarcode, setInputScannedBarcode] = useState("");
    const { message } = App.useApp()
    const inputRef = useRef(null);
    const [uncheckedBarcodes, setUnchekedBarcodes] = useState([])

    function removeItemFromSelectedRowsByBarcode(barcode) {
        let tempArr = [...selectedRowKeys]
        let tempUncheckedBarcodes = [...uncheckedBarcodes]

        const barcodeIsInList = tempArr.some(item => item.value.barcode === barcode)

        if (barcodeIsInList) {
            tempArr = tempArr.filter(item => item.value.barcode !== barcode);
            setSelectedRowKeys(tempArr)
            message.success(`${barcode} was unchecked`)

            if (tempUncheckedBarcodes.every(item => item !== barcode)) {
                const newBarcodes = [...tempUncheckedBarcodes, barcode];
                setUnchekedBarcodes(newBarcodes);
            }
        }
        else {
            message.error(`${barcode} has not been selected yet.`)
        }


    }

    //   useEffect(() => {
    //     // Automatically focus the input on mount and keep focus
    //     const focusInput = () => {
    //       if (inputRef.current) {
    //         inputRef.current.focus();
    //       }
    //     };

    //     focusInput();
    //     // Re-focus input every time the value changes
    //     const interval = setInterval(focusInput, 100);

    //     return () => clearInterval(interval);
    //   }, []);
    const handleScannedValue = () => {
        // console.log("Enter pressed with value:", inputScannedBarcode);

        removeItemFromSelectedRowsByBarcode(inputScannedBarcode)

        setInputScannedBarcode("");
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleScannedValue()
        }
    };
    useEffect(() => {
        if (inputScannedBarcode.length >= 13) {
            handleScannedValue()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputScannedBarcode])

    return (
        <Space direction='vertical'>
            <Input
                placeholder='Uncheck a barcode'
                ref={inputRef}
                value={inputScannedBarcode}
                onChange={(e) => setInputScannedBarcode(e.target.value)}
                onKeyDown={handleKeyDown}
            />

            {uncheckedBarcodes.length > 0 && (
                <Space direction='vertical'>
                    <br />
                    {uncheckedBarcodes.map(item => <div key={item}>{item}</div>)}

                    <div>Unchecked: <b>{uncheckedBarcodes.length}</b></div>
                </Space>
            )}
        </Space>
    )
};

export default UncheckExistingItems;
