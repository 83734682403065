import  { React } from "react";
import { Table } from "antd";

import {TableRow} from "./row"

export function ItemsTable(props) {
    const dataSource = [...props.dataSource]
    const {setRepeatGetItems} = props

    dataSource.forEach((item)=>{
        const itemKey =`${item.item_number}  ${item.vendor} ${item.barcode} ${item.memo} ${item.price} ${item.threshold} ${item.type} ${item.unitOfMeasure} ${item.department} ${item.amountOfAtomsInABox} ${item.lotRequired} ${item.expirationDateRequired}`
        item.key = itemKey
        return item
    })

    const columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Item Number',
          dataIndex: 'item_number',
          key: 'item_number',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Department',
          dataIndex: 'department',
          key: 'department',
        },
        {
          title: 'Vendor',
          dataIndex: 'vendor',
          key: 'vendor',
        },
        {
          title: 'Department',
          dataIndex: 'department',
          key: 'department',
        },
    ];

    
    // const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    return (
        <>
            <div className="scrolledBox">
                <Table
                    columns={columns}
                    expandRowByClick={true}
                    expandable={{ expandedRowRender: (record) => (<div style={{ margin: 0, }} > <TableRow record={record} setRepeatGetItems={setRepeatGetItems} customComponent={props.customComponent}/> </div>), rowExpandable: (record) => record.name !== 'Not Expandable', }}
                    dataSource={dataSource}
                    pagination={false}
                    scroll={{ x: "100%"}}
                />
            </div>
        </>
    );
}

