import { ItemInformation } from "../../../Items/ItemInformation";


export function CreateNewPOrow(props) {
  const {record} = props;

  return (
    <div className="rowStyle">
      <ItemInformation selectedItem={record}/>
    </div>
  )
}



