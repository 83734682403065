import React, { useState } from 'react';
import { Input, Button, Typography, Space } from 'antd';
import {parseTimestamp} from '../DateFormatter'

const { Title } = Typography;

const TimeStampToDate = () => {
  const [timestamp, setTimestamp] = useState('');
  const [convertedDate, setConvertedDate] = useState(null);



  const handleConvert = () => {
    const date = parseTimestamp(timestamp)
    setConvertedDate(date);
  };

  return (
    <Space direction="vertical" size="large" style={{ padding: '20px' }}>
      <Title level={4}>Convert a timestamp to a date</Title>
      
      <Input 
        placeholder="Enter timestamp"
        value={timestamp}
        onChange={(e) => setTimestamp(e.target.value)}
        addonAfter={<Button type='link' onClick={handleConvert}>Convert</Button>}
      />

      {convertedDate&&(
        <div>Converted date: <b>{convertedDate}</b></div>
      )}

    </Space>
  );
};

export default TimeStampToDate;
