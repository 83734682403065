import { React } from "react";
import { Modal, Space } from "antd";
import { SubmittedItems } from "./SubmittedItems";

const AfterSubmitModal = (props) => {
  const { showModal, setShowModal, barcodes, selectedItems  } = props;

  return (
    <Modal
      title="New products were submitted successfully"
      open={showModal}
      closable={false}
      keyboard={false}
      footer={null}
      width={'70%'}
      onCancel ={() => {setShowModal(false)}}
    >
      <Space direction="vertical" size="large" >

        <SubmittedItems selectedItems={selectedItems} barcodes={barcodes} />

      </Space>
    </Modal>
  );
};

export { AfterSubmitModal };

