

const InventoryFooter = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    return (
        <>
            <p> 2008-{currentYear} Empire City Laboratories, Inc. </p>
            <small> All Rights Reserved. ECL and Empire City Laboratories are registered trademarks of Empire City Laboratories®, Inc. </small>

        </>
    )
}

export default InventoryFooter