import { useNavigate } from "react-router-dom";
import {Button} from 'antd'

const GoHome = () => {
  const navigate = useNavigate();

  return(
    <Button type="link" size="xs" onClick={() => {navigate("/home")}}>Go Home</Button>
  )
}

export default GoHome