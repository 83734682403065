
import JsonToCSV from "../../../components/JsonToCSV";

const ExportToCSV = (props) => {
    const { record } = props

    // console.log(record.POitems)

    let dataToExport = record.purchase_items.map((purchasedItem) => {

        let newItem = {}
        newItem.number = purchasedItem.actual_item[0].item_number
        newItem.name = purchasedItem.actual_item[0].name
        newItem.quantity = purchasedItem.quantity_purchased
        return newItem
    })

    function groupDataItemsToExport(jsonArray) {
        const result = {};
        jsonArray.forEach(item => {
            const key = `${item.number}-${item.name}`;
            if (result[key]) {
                result[key].quantity += item.quantity;
            } else {
                result[key] = { ...item };
            }
        });
        return Object.values(result);
    }

    dataToExport = groupDataItemsToExport(dataToExport)

    return (
        <JsonToCSV data={dataToExport} />
    )

}

export default ExportToCSV