import { useEffect } from 'react';
import { Space, InputNumber, DatePicker, Input } from 'antd';
import { ItemsSearcher } from '../../../Items/ItemsSearcher';
import { WarehouseDropdown } from '../../../Warehouses/WarehousesSelect';
import Locations from '../../../Warehouses/Locations';
import RocheItemsQRreader from '../../../Items/RocheItemsQRreader';

const ItemData = (props) => {
    const {
        warehouse,
        setWarehouse,
        location,
        setLocation,
        isRocheItemsQRreaderInUse,
        setIsRocheItemsQRreaderInUse,
        setExpirationDate,
        setLotNumber,
        setItemNumber,
        // rerenderTrigger,
        setSelectedItem,
        selectedItem,
        itemNumber,
        amount,
        setAmount,
        lotNumber,
        expirationDate,
    } = props.itemDataFields;

    const { setItemDataCompleted } = props;

    useEffect(() => {
        const essentialDataCompleted = warehouse && location && selectedItem && amount;
        const lotCompleted = selectedItem?.lotRequired ? lotNumber : true;
        const expirationDateCompleted = selectedItem?.expirationDateRequired ? expirationDate : true;

        if (essentialDataCompleted && lotCompleted && expirationDateCompleted) {
            setItemDataCompleted(true);
        }
    }, [warehouse, location, selectedItem, amount, lotNumber, expirationDate, setItemDataCompleted]);

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Space>
                <Space>Warehouse: <WarehouseDropdown warehouse={warehouse} setWarehouse={setWarehouse} /></Space>
                <Locations key={`${warehouse}`} selectedWarehouse={warehouse} location={location} setLocation={setLocation} />
            </Space>

            <Space>
                <div style={isRocheItemsQRreaderInUse ? { pointerEvents: 'none', opacity: '0.4' } : null}>
                    <ItemsSearcher
                        setSelectedItem={setSelectedItem}
                        selectedItem={selectedItem}
                        predefinedItemNumber={itemNumber}
                        // key={`ItemsSearcher ${rerenderTrigger}`}
                    />
                </div>
                <>or</>
                <RocheItemsQRreader
                    isComponentInUse={isRocheItemsQRreaderInUse}
                    setIsComponentInUse={setIsRocheItemsQRreaderInUse}
                    setExpirationDate={setExpirationDate}
                    setLotNumber={setLotNumber}
                    setItemNumber={setItemNumber}
                    // key={`RocheItemsQRreader ${rerenderTrigger}`}
                />
            </Space>

            <Space>
                <InputNumber
                    min={1}
                    value={amount}
                    onChange={(value) => setAmount(value)}
                    placeholder="Amount of Items"
                    // key={`InputAmount ${rerenderTrigger}`}
                />
                {selectedItem?.lotRequired && (
                    <Input
                        value={lotNumber}
                        onChange={(e) => setLotNumber(e.target.value)}
                        allowClear
                        placeholder="Lot Number"
                        // key={`InputLotNumber ${rerenderTrigger}`}
                    />
                )}
                {selectedItem?.expirationDateRequired && (
                    <DatePicker
                        value={expirationDate}
                        placeholder="Expiration Date"
                        onChange={(date) => setExpirationDate(date)}
                        disabledDate={(current) => current && current.valueOf() < Date.now()}
                        // key={`ExpirationDate ${rerenderTrigger}`}
                    />
                )}
            </Space>
        </Space>
    );
};

export default ItemData;
