import {Tag, Space, Button, Input} from 'antd'

const InputLOT = (props) => {
    const { record, dataSource, setDataSource } = props
    const onSave = (value) => {
        const tempArr = [...dataSource]
        tempArr.map((item) => {
            if (item.purchase_order_item_id === record.purchase_order_item_id) {
                item.lotNumber = value
            }
            return item
        })
        setDataSource(tempArr)
    }
    const onClear = () => {
        const tempArr = [...dataSource]
        tempArr.map((item) => {
            if (item.purchase_order_item_id === record.purchase_order_item_id) {
                item.lotNumber = undefined
            }
            return item
        })
        setDataSource(tempArr)
    }

    return (
        <>
            {record.actual_item.lotRequired === true ? (
                <>
                    {record.lotNumber ?
                        (
                            <Space>
                                <>{record.lotNumber}</>
                                <Button onClick={onClear} type='text'>change</Button>
                            </Space>
                        ) : (
                            <Input placeholder='LOT' onPressEnter={(e) => { onSave(e.target.value) }} onBlur={(e) => { onSave(e.target.value) }} />
                        )}
                </>
            ) : (<Tag>Not Applicable</Tag>)}
        </>
    )
}

export default InputLOT