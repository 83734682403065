import { useState, useEffect, React, useContext } from "react";
import axioss from '../../services/restAPI';
import { AuthContext } from "../../components/Auth/AuthContext";
import { Space, App } from 'antd'
import { UsersTable } from "./table";
import MyPagination from "../../components/Pagination"
import { Link } from "react-router-dom";
import { LoadingSpinLocal } from "../../components/LoadingSpinLocal";

export const Users = () => {
    const [loading, setLoading] = useState(false)
    const { token } = useContext(AuthContext)
    const [firstLoading, setFirstLoading] = useState(true)
    const [users, setUsers] = useState([])
    const { modal } = App.useApp()

    useEffect(() => {
        if (firstLoading) {
            getUsers();
            setFirstLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //* pagination
    const [totalItems, setTotalItems] = useState(0)
    const [limit, setLimit] = useState(100)
    const [offset, setOffset] = useState(0)
    useEffect(() => {
        if (!firstLoading) {
            getUsers()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset, limit])
    //* pagination
 

    const getUsers = async () => {
        try {
            setLoading(true)
            const response = await axioss.get(`/users?limit=${limit}&offset=${offset}&userRole=1`, { headers: { Authorization: token } })
            console.log(response)
            setUsers(response.data.users)
            setTotalItems(response.data.count)
        }
        catch (err) {
            console.log(err)
            modal.warning({
                title: 'Something went wrong...',
                content: err.message,
            });
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <div>
            <Space direction='horizontal' size='large' >
                <h2>Users</h2>
                <Link to="add-new-user" style={{ paddingTop: 8 }}>Add a New User</Link>
            </Space>

            <LoadingSpinLocal loading={loading}>
                <UsersTable dataSource={users} />
                <MyPagination totalItems={totalItems} limit={limit} offset={offset} setLimit={setLimit} setOffset={setOffset} />
            </LoadingSpinLocal >

        </div>
    )
}