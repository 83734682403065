const generatePlainText = (labelText, fontSize = 60, labelTextStarts = 30, linesSpacing=1.3) => {
    const amountOfLabels = 1;
    const labelTextFontSize = `${fontSize}, ${fontSize}`;

    let labelTextEncoding = "^XA^CWZ,E:TT0003M_.FNT^FS";

    const lines = labelText.split('\n');
    
    lines.forEach((line, index) => {
        const linePosition = labelTextStarts + fontSize * index * linesSpacing;
        labelTextEncoding += `^FO010,${linePosition}^CI28^AZN,${labelTextFontSize}^FD ${line}^FS`;
    });

    labelTextEncoding += `^PQ${amountOfLabels}^XZ`;

    return labelTextEncoding;
};

export default generatePlainText;
