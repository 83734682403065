
import { useState, useContext, useEffect } from 'react';
import { WarehouseDropdown } from '../../Warehouses/WarehousesSelect';
import { Space, Switch, App } from 'antd'
import { AuthContext } from '../../../components/Auth/AuthContext';
import axioss from '../../../services/restAPI';

const WarehouseAccess = (props) => {
    const { modal } = App.useApp();
    const { authConfig } = useContext(AuthContext)
    const { user } = props
    const [warehouse, setWarehouse] = useState(null)
    const [loading, setLoading] = useState(false)
    const [userHasAccess, setUserHasAccess] = useState(false)

    useEffect(() => {
        //reads user access each time a new warehouse is selected 
        if (warehouse !== null) {
            readAccess()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouse])


    const readAccess = async () => {
        try {
            const response = await axioss.get(`user-warehouse/${warehouse}/users/`, authConfig)
            const isUserAllowed = response.data.some(allowedUser => { return allowedUser.id === user.id })
            setUserHasAccess(isUserAllowed)
        } catch (error) {
            modal.warning({
                title: 'Error reading user access:',
                content: error.message
            })
            console.error(error.response.data)
        }
    }

    const createAccess = async () => {
        try {
            setLoading(true)
            await axioss.post('/user-warehouse/', {
                userId: user.id,
                warehouseId: warehouse
            }, authConfig)
            setUserHasAccess(!userHasAccess)
        } catch (error) {
            modal.warning({
                title: 'Grant user access error',
                content: error.message
            })
            console.error(`Error: ${error}`)
        } finally {
            setLoading(false)
        }
    }

    const removeAccess = async () => {
        try {
            setLoading(true)
            await axioss.delete(`/user-warehouse/?userId=${user.id}&warehouseId=${warehouse}`, authConfig)
            // const baseUrl = "/user-warehouse";
            // const url = new URL(baseUrl);
            // const params = { userId:user.id, warehouseId:warehouse };
            // url.search = new URLSearchParams(params).toString();
            // await axioss.delete(url.toString(), authConfig)

            setUserHasAccess(!userHasAccess)
        } catch (error) {
            modal.warning({
                title: 'Remove user access error:',
                content: error.message
            })
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const onChangeAccess = () => {
        if (userHasAccess) {
            removeAccess()
        }
        else {
            createAccess()
        }
    }

    return (
        <>
            <Space>
                <WarehouseDropdown warehouse={warehouse} setWarehouse={setWarehouse} />
                <Switch
                    checkedChildren="Access allowed"
                    unCheckedChildren="Access forbidden"
                    checked={userHasAccess}
                    disabled={warehouse === null}
                    loading={loading}
                    onClick={onChangeAccess}
                />
            </Space>
        </>
    )
}

export { WarehouseAccess }