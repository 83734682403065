import { useParams } from "react-router-dom";
import { Row, Col, QRCode, Descriptions } from "antd";
import { PurchasedItems } from "../PurchasedItems";
import { PrintableComponentWrapper } from "../../../components/PrintableComponentWrapper";

const PrintDocPO = () => {
  let { POId } = useParams(); // Assuming the URL parameter is named POId

  const storedPO = localStorage.getItem(`PO_${POId}`);
  if (!storedPO) {
    console.error('Record not found in local storage');
    return null; // Or handle this scenario appropriately
  }
  // localStorage.removeItem(`PO_${POId}`); // Clear the record from local storage

  const PO = JSON.parse(storedPO);


  return (
    <PrintableComponentWrapper>
      <div style={{ padding: '20px' }}>
        <Header PO={PO} />
        <PurchasedItems record={{ purchase_items: PO.purchase_items, products_in_stock: [] }} hideReceived hidePrice />
      </div>
    </PrintableComponentWrapper>
  )
}
export { PrintDocPO }


const Header = (props) => {
  const { PO } = props
  return (
    <>
      <Row>
        <Col xs={{ span: 11 }} >
          <Descriptions bordered column={1} size="small">
            <Descriptions.Item label="Status">{PO.status?"completed":"pending"}</Descriptions.Item>
            <Descriptions.Item label="Created">{PO.date_created}</Descriptions.Item>
          </Descriptions>
        </Col>

        <Col xs={{ span: 1, offset: 7 }}>
          <div style={{ marginTop: 0 }} >
            <QRCode value={PO.barcode} bordered={false} size={110} />
            <div style={{ paddingLeft: 10 }}>{PO.barcode}</div>
          </div>
        </Col>
      </Row>
      <Row style={{width:'100%'}}>
        <h1 style={{textAlign:'center'}} className="mainColor">Purchase Order #{PO.purchase_order_id}</h1>
      </Row>
    </>
  )
}

