import { React, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Space, Input, Button, App, InputNumber } from 'antd'
import axioss from '../../../services/restAPI';
import { LoadingSpinLocal } from '../../../components/LoadingSpinLocal';
import { AuthContext } from '../../../components/Auth/AuthContext';
import { USAstates } from '../../../components/USAstates';

const AddNewWarehouse = () => {
    const { authConfig } = useContext(AuthContext)
    const { message, modal } = App.useApp();
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    // const [country, setCountry] = useState('');
    const [fax, setFax] = useState('');
    const [memo, setMemo] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');


    const formNotCompleted = name === "" || phone === "" || email === "" || address === "" || city === "" || contactPerson === "" || fax === "" || memo === "" || state === "" || zip === ""

    const submit = async () => {
        try {
            setLoading(true)
            await axioss.post('/warehouse/', {
                name,
                phone,
                email,
                address,
                city,
                contactPerson,
                // country,
                fax,
                isDeleted: false,
                memo,
                state,
                zip,
            }, authConfig )
            // console.log(response.data)
            message.success("New warehouse was added successfully")
            onClear()
        } catch (error) {
            console.error(error)
            modal.warning({
                title: 'Something went wrong',
                content: error.message,
            });
        }
        finally {
            setLoading(false)
        }
    }

    const onClear = () => {
        setName('');
        setPhone('');
        setEmail('');
        setAddress('');
        setCity('');
        setContactPerson('');
        // setCountry('');
        setFax('');
        setMemo('');
        setState('');
        setZip('');
    };

    return (
        <LoadingSpinLocal loading={loading}>
            <Space direction='vertical'>
                <Space direction='horizontal' size='large'>
                    <h2>Add a New Warehouse</h2><Link to="/warehouses">Back</Link>
                </Space>

                <Input placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
                <Input placeholder="Contact Person" value={contactPerson} onChange={e => setContactPerson(e.target.value)} />
                <Input placeholder="Phone" value={phone} onChange={e => setPhone(e.target.value)} />
                <Input placeholder="Fax" value={fax} onChange={e => setFax(e.target.value)} />
                <Input placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                <Input placeholder="Address" value={address} onChange={e => setAddress(e.target.value)} />
                <Input placeholder="City" value={city} onChange={e => setCity(e.target.value)} />
                <USAstates setState={setState}/>
                <InputNumber value={zip} onChange={(value) => { setZip(value) }} placeholder="ZIP code" style={{ width: '100%' }} />
                {/* <Input placeholder="Country" value={country} onChange={e => setCountry(e.target.value)} /> */}
                <Input placeholder="Memo" value={memo} onChange={e => setMemo(e.target.value)} />

                <Space direction='horizontal' size='large'>
                    <Button type="primary" disabled={formNotCompleted} onClick={submit}> Create Warehouse </Button>
                    <Button type="link" onClick={onClear}> Clear </Button>
                </Space>
            </Space>
        </LoadingSpinLocal>

    )
}

export { AddNewWarehouse }