import { Cascader } from 'antd';
import { useState, useEffect, useContext } from "react";
import axioss from '../../../services/restAPI';
import { AuthContext } from "../../../components/Auth/AuthContext";
import { App } from 'antd'
import { LoadingSpinLocal } from "../../../components/LoadingSpinLocal";
import buildQueryParams from '../../../services/utilites/buildQueryParams';

const Locations = (props) => {
    const {selectedWarehouse, setLocation, initialLocation} = props
    const [loading, setLoading] = useState(false)
    const { authConfig } = useContext(AuthContext)
    const { modal } = App.useApp()
    const [locations, setLocations] = useState([])

    // console.log(locationRowID)
    const onChange = (value) => {
        const location = {
          department:value[0],
          type:value[1],
          number:value[2][0],
          locationID:value[2][1]
        }
        // console.log(location);
        setLocation(location)
    };

    function buildTree(data) {
        const tree = [];
      
        data.forEach((item) => {
          let departmentNode = tree.find(
            (node) => node.value === item.department
          );
          if (!departmentNode) {
            departmentNode = {
              value: item.department,
              label: item.department,
              children: [],
            };
            tree.push(departmentNode);
          }
      
          let typeNode = departmentNode.children.find(
            (node) => node.value === item.type
          );
          if (!typeNode) {
            typeNode = {
              value: item.type,
              label: item.type,
              children: [],
            };
            departmentNode.children.push(typeNode);
          }
      
          const locationNode = {
            value: [item.location,item.id],
            label: item.location,
          };
      
          typeNode.children.push(locationNode);
        });
      
        return tree;
    }

    const getLocations = async () => {
        const params = {warehouseID:selectedWarehouse}
        try {
            setLoading(true)
            const response = await axioss.get(`/locations/?${buildQueryParams(params)}`, authConfig)
            if(initialLocation){
              const tempArr = response.data.filter(location=>location.type==="Initial")
              setLocations(buildTree(tempArr))
              return
            } 
            setLocations(buildTree(response.data))
            // console.log(response.data)
            
        }
        catch (err) {
            console.log(err)
            modal.warning({
                title: 'Error while loading departments',
                content: err.message,
            });
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
      if(locations.length===0){
        getLocations()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <LoadingSpinLocal loading ={loading}>
            <Cascader options={locations} onChange={onChange} placeholder="Select location" style={{width:300}} allowClear={false}/>
        </LoadingSpinLocal>
    )
}

export default Locations