import { AuthContext } from "../AuthContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {Button, App} from 'antd'

const Logout = () => {
  const {message} = App.useApp()
  const { setToken,setUser} = useContext(AuthContext); //setUser 
  const navigate = useNavigate();
  const handleLogout = () => {
    setToken(null);
    setUser(null);
    navigate("/login");
    message.info('see you 👋 ')
  };

  return <Button type='link' onClick={handleLogout} >Logout</Button>;
};

export default Logout;
