import { useState, useEffect, React, useContext } from "react";
import { AuthContext } from "../../components/Auth/AuthContext"
import axioss from '../../services/restAPI';
import buildQueryParams from "../../services/utilites/buildQueryParams";
import { LoadingSpinLocal } from '../../components/LoadingSpinLocal'
import MainTable from "../InStock/MainTable";

const LowInStock = () => {
    const [loading, setLoading] = useState(false)
    const { authConfig } = useContext(AuthContext)
    const [inStockProducts, setInStockProducts] = useState([])

    useEffect(() => {
        getProductsInStock()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getProductsInStock = async () => {
        try {
            setLoading(true)

            const params = {
                searchByLowInStock:true
            };
            const url = `/in-stock?${buildQueryParams(params)}`;
            
            const response = await axioss.get(url, authConfig);
            setInStockProducts(response.data)

            // console.log(response.data)

        }
        catch (err) {
            console.log(err)
        }
        finally {
            setLoading(false)
        }
    }

    return(
        <LoadingSpinLocal loading={loading}>
            <MainTable inStockProducts={inStockProducts} setInStockProducts={setInStockProducts} hideVender hideType tableSize={"small"}/>
        </LoadingSpinLocal>
    )

}

export default LowInStock