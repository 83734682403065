//* It wraps around its children components 
//* and allows you to perform specific actions before
//* and after the print operation.


import { useEffect } from "react";

const PrintableComponentWrapper = ({ children }) => {
  useEffect(() => {
    const handleBeforePrint = () => {
      // console.log('Print button was clicked.');
    };

    const handleAfterPrint = () => {
      // Check if the print menu was canceled
      if (window.matchMedia) {
        const mediaQueryList = window.matchMedia('print');
        if (!mediaQueryList.matches) {
          // console.log('Print menu was canceled.');
          window.close()
        }
      }

    };

    const sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    };

    const printDocument = async () => {
      await sleep(500);
      window.print();
    };

    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);

    printDocument();

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, []);
  return (
    <>
      {children}
    </>
  )
}

export { PrintableComponentWrapper }