import { Table, Tag } from 'antd'
import TableRow from './TableRow';
import JsonToCSV from '../../../components/JsonToCSV'

const MainTable = (props) => {
  const { inStockProducts, expandable, hideVender, hideType, hideAmountToOrder, tableSize } = props



  function transformData(items) {
    const groups = {};

    items.forEach(item => {
      const key = `${item.item_id}-${item.name}`;
      if (!groups[key]) {
        groups[key] = {
          actualItem: [item.actual_item[0]],
          total_amount_of_products: 0,
          group_name: item.name,
          key: item.name,
          products: []
        };
      }
      groups[key].total_amount_of_products++;
      groups[key].products.push(item);
    });

    return Object.values(groups);
  }

  const dataSource = transformData(inStockProducts)

  const dataToExport = dataSource.map(item=>{
    item.itemNumber =  item.actualItem[0].item_number
    item.name = item.actualItem[0].name
    item.vendor = item.actualItem[0].vendor
    item.type = item.actualItem[0].type
    item.threshold = item.actualItem[0].threshold
    item.threshold = item.actualItem[0].threshold
    item.inStock = item.products.length
    return item
  })


  let columns = [
    {
      title: '#',
      dataIndex: 'item_number',
      key: 'item_number',
      render: (_,record) => (<>{record.actualItem[0].item_number}</>)
    },
    {
      title: 'Name',
      dataIndex: 'group_name',
      key: 'group_name',
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor',
      render: (_, record) => (<>{record.actualItem[0].vendor}</>)
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => (<>{record.actualItem[0].type}</>)
    },
    {
      title: 'Threshold',
      dataIndex: 'threshold',
      key: 'threshold',
      render: (_, record) => (<>{record.actualItem[0].threshold}</>)

    },
    {
      title: 'In Stock',
      dataIndex: 'total_amount_of_products',
      key: 'total_amount_of_products',
      render: (_, record) => (<TotalAmountInStock record={record} />)

    },
    {
      title: 'To Order',
      dataIndex: 'amount_to_order',
      key: 'amount_to_order',
      render: (_, record) => (<>{Math.round(( record.actualItem[0].threshold * 1.5 ) )} </>)

    },
  ];

  if (hideVender) {
    columns = columns.filter(col => col.key !== 'vender')
  }
  if (hideType) {
    columns = columns.filter(col => col.key !== 'type')
  }
  if (hideAmountToOrder) {
    columns = columns.filter(col => col.key !== 'amount_to_order')
  }


  return (
    <>
      <JsonToCSV data={dataToExport} includeFields={['received', 'itemNumber', 'name', 'vendor', 'type', 'threshold', 'inStock']}/>
      <Table
        size={tableSize?tableSize:null}
        dataSource={dataSource}
        columns={columns}
        expandable={expandable ? (
          {
            expandedRowRender: (record) => (<TableRow record={record} />),
            rowExpandable: (record) => record.name !== 'Not Expandable',
          }
        ) : null}
      />
    </>
  )
}

export default MainTable

const TotalAmountInStock = (props) => {
  const { record } = props
  const total_amount_of_products = record.total_amount_of_products
  const LowLeft = total_amount_of_products <= record.actualItem[0].threshold
  return (
    <>
      {LowLeft ? (
        <> <Tag color='red'>{total_amount_of_products}</Tag></>
      ) : (
        <>{total_amount_of_products}</>
      )}
    </>
  )
}