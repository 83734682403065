import { useState, useContext } from 'react'
import { ItemsSearcher } from '../../Items/ItemsSearcher'
import { Space, InputNumber, Button, Tag, Table, App } from 'antd'
import { LoadingSpinLocal } from '../../../components/LoadingSpinLocal'
import axioss from '../../../services/restAPI';
import { AuthContext } from "../../../components/Auth/AuthContext";


const AddNewItems = (props) => {
    const { authConfig } = useContext(AuthContext); 
    const { PO, setGetDataTrigger} = props
    const [selectedItem, setSelectedItem] = useState(null)
    const [addedItems, setAddedItems] = useState([])
    const [inputQuantity, setInputQuantity] = useState("")
    const [rerenderTriggerKey, setRerenderTriggerKey] = useState("rerenderTriggerKey")
    const { message,modal } = App.useApp()
    const [loading, setLoading] = useState(false)


    // console.log(PO)
    const addItem = () => {
        if (addedItems.some(item => (item.selectedItem.name === selectedItem.name))) {
            message.error(`${selectedItem.name} is already added`)
            return
        }
        const tempArr = [...addedItems]
        tempArr.push({ selectedItem: selectedItem, quantity: inputQuantity })
        setAddedItems(tempArr)
        setInputQuantity("")
        setRerenderTriggerKey(Date.now)
        setSelectedItem(null)
        // message.success(`${selectedItem.name} added`)
    }

    const removeItem = (removingItem) => {
        const tempArr = addedItems.filter((item) => item.selectedItem.name !== removingItem.name)
        setAddedItems(tempArr)
        // message.success(`${removingItem.name} removed`)
    }


    const onSubmit = () => {
        const submittedItems = addedItems.map((item) => {
            return {
                itemNumber:item.selectedItem.item_number,
                itemID:item.selectedItem.id,
                quantity:item.quantity,
                POid:PO.purchase_order_id,
                purchasePrice:0
            }
        })

        const updatedPO = {
            submittedItems,
            POid:PO.purchase_order_id
        }


        try {
            setLoading(true)
            axioss.post('/po/add-items-to-existing-po', {updatedPO:updatedPO}, authConfig)
            setAddedItems([])
            message.success('The PO was updated')
            // window.location.reload();
            setGetDataTrigger(Date.now)

        } catch (error) {
            console.error(error)
            modal.warning({
                title:'Error while updating the PO:',
                content:error.message
            })
        }
        finally{
            setLoading(false)
        }

        // console.log(updatedPO)
    }

    const columns = [
        {
            title: 'Item Name',
            dataIndex: 'itemName',
            key: 'itemName',
            render: (_, record) => (<>{record.selectedItem.name}</>)
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Remove',
            dataIndex: 'remove',
            key: 'remove',
            render: (_, record) => (<Button type='link' size='small' onClick={() => removeItem(record.selectedItem)}> remove </Button>)
        },
    ]

    return (
        <LoadingSpinLocal loading={loading}>
            <Space wrap key={`add item ${rerenderTriggerKey}`}>
                <ItemsSearcher selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
                {selectedItem && (
                    <>
                        <Tag>{selectedItem.name}</Tag>
                        <InputNumber placeholder='quantity' value={inputQuantity} onChange={(value) => { setInputQuantity(value) }} min={1} style={{ width: 80 }} />
                        <Button onClick={addItem} disabled={inputQuantity?.length === 0}>Add</Button>
                    </>
                )}
            </Space>

            <br />
            <br />

            <Table dataSource={addedItems} columns={columns} size='small' />

            <Button type='primary' onClick={onSubmit} disabled={addedItems.length === 0}>Add to the PO</Button>
        </LoadingSpinLocal>
    )
}

export default AddNewItems

