import {React, } from "react";
import {Routes, Route} from "react-router-dom";
// import { AuthContext } from "./components/Auth/AuthContext";

import AppLayout from "./Layout";
import About from "./pages/About";
import Login from "./pages/Login";
import Home from "./pages/Home";
import {Users} from "./pages/Users";
import AddNewUser from "./pages/Users/AddNewUser";
import {AuthRequired} from "./components/Auth/AuthRequired";
import LoadingSpin from "./components/LoadingSpin";
import {Page404} from "./pages/Page404";
import {Page401} from "./pages/Page401";
import {Items} from "./pages/Items";
import AddNewItem from "./pages/Items/AddNewItem";
import { ReceiveSingleAsset } from "./pages/AssetsReceiving/ReceiveSingleAsset";
import { ReceiveByPO } from "./pages/AssetsReceiving/ReceiveByPO";
import AdaptiveReceiving from "./pages/AssetsReceiving/AdaptiveReceiving";
import CustomBarcodesReceiving from "./pages/AssetsReceiving/CustomBarcodesReceiving";
import {Orders} from "./pages/Orders";
import {Warehouses} from "./pages/Warehouses";
import {AddNewWarehouse} from "./pages/Warehouses/AddNewWarehouse"; 
import { AddNewLocation } from "./pages/Warehouses/Locations/AddNewLocation";
import {PO} from "./pages/PO";
import {CreateNewPO} from "./pages/PO/CreateNewPO";
import {App} from 'antd';
import { PrintDocPO } from "./pages/PO/PrintDocPO";
import InStock from "./pages/InStock";
import TakeFromStorage from "./pages/AssetsWithdrawal/TakeFromStorage";  // public
import DecreaseStockApp from "./pages/AssetsWithdrawal/DecreaseStockApp";// public
import ProductsDistributing from "./pages/ProductsDistributing"; // public
import DistributingAuthorizationPage from "./pages/ProductsDistributing/DistributingAuthorizationPage";
import TransactionsStock from "./pages/TransactionsStock";
import TransactionsManagementData from "./pages/TransactionsManagementData";
import ItemsUsage from "./pages/Reports/Stock/ItemsUsage";
import UsedItemsReport from "./pages/Reports/Stock/UsedItemsReport";
import AssetDiscarding from "./pages/AssetDiscarding";
import AssetsAdjustment from "./pages/AssetsAdjustment";
import EditPO from "./pages/PO/EditPO";
import DepartmentStock from "./pages/Reports/Stock/DepartmentStock";

export default function Root() {
    // const {user} = useContext(AuthContext)
    // const userManagement = [1,10, 26] //Sergey, Anatoly, Ainur

    return (
        <App>
            <LoadingSpin>
                <Routes>
                    <Route path="withdraw-an-asset" element={<TakeFromStorage/>}/> 
                    <Route path="withdraw-an-asset/:barcodeValue" element={<DecreaseStockApp/>}/> 

                    <Route path="products-distributing/:barcodeValue" element={<ProductsDistributing/>}/> 
                    <Route path="distributing-authorization" element={<DistributingAuthorizationPage/>}/> 
               
                    <Route path="about" element={ <About/> }/>


                    <Route path="login" element={<Login/>}/>
                    <Route path="PO/print-PO/:POId" element={<PrintDocPO/>}/>

                    <Route path="/" element={<AuthRequired> <AppLayout/> </AuthRequired>}>
                        <Route path="home" element={<AuthRequired> <Home/> </AuthRequired>}/>
                        <Route path="users" element={<AuthRequired> <Users/> </AuthRequired>}/>
                        <Route path="items" element={<AuthRequired> <Items/> </AuthRequired>}/>
                        <Route path="items/add-new" element={<AuthRequired> <AddNewItem/> </AuthRequired>}/>
                        <Route path="orders" element={<AuthRequired> <Orders/> </AuthRequired>}/>
                        <Route path="adaptive-receiving" element={<AuthRequired> <AdaptiveReceiving/> </AuthRequired>}/>
                        <Route path="custom-barcodes-receiving" element={<AuthRequired> <CustomBarcodesReceiving/> </AuthRequired>}/>
                        <Route path="receive-single-asset" element={<AuthRequired> <ReceiveSingleAsset/> </AuthRequired>}/>
                        <Route path="receive-by-PO" element={<AuthRequired> <ReceiveByPO/> </AuthRequired>}/>
                        <Route path="users/add-new-user" element={<AuthRequired> <AddNewUser/> </AuthRequired>}/>
                        <Route path="warehouses" element={<AuthRequired> <Warehouses/> </AuthRequired>}/>
                        <Route path="warehouses/add-new-warehouse" element={<AuthRequired> <AddNewWarehouse/> </AuthRequired>}/>
                        <Route path="warehouses/add-new-location/:warehouseID/:warehouseName" element={<AuthRequired> <AddNewLocation/> </AuthRequired>}/>
                        <Route path="PO" element={<AuthRequired> <PO/> </AuthRequired>}/>
                        <Route path="PO/create-new-PO" element={<AuthRequired> <CreateNewPO/> </AuthRequired>}/>
                        <Route path="PO/edit-PO/:poID" element={<AuthRequired> <EditPO/> </AuthRequired>}/>
                        <Route path="in-stock" element={<AuthRequired> <InStock/> </AuthRequired>}/>
                        <Route path="transactions-stock" element={<AuthRequired> <TransactionsStock/> </AuthRequired>}/>
                        <Route path="transactions-management-data" element={<AuthRequired> <TransactionsManagementData/> </AuthRequired>}/>
                        <Route path="reports/stock/items-usage" element={<AuthRequired> <ItemsUsage/> </AuthRequired>}/>
                        <Route path="reports/stock/used-items-report" element={<AuthRequired> <UsedItemsReport/> </AuthRequired>}/>
                        <Route path="reports/stock/department-stock" element={<AuthRequired> <DepartmentStock/> </AuthRequired>}/>
                        <Route path="asset-discarding" element={<AuthRequired> <AssetDiscarding/> </AuthRequired>}/>
                        <Route path="asset-adjustment" element={<AuthRequired> <AssetsAdjustment/> </AuthRequired>}/>
                        <Route path="about" element={ <About/> }/>
                        <Route path="*" element={<Page404/>}/>
                        <Route path="401" element={<Page401/>}/>
                    </Route>
                </Routes>
            </LoadingSpin>
        </App>
    );
} 
